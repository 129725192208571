import React from 'react'
import { UploadOutlined } from "@ant-design/icons";
import type { UploadFile, UploadProps } from "antd";
import type { Dispatch, SetStateAction } from 'react'
import {
    Button,
    Upload,
} from "antd";


interface DataSourceSelectProps {
    dataSource: UploadFile,
    setDataSource: Dispatch<SetStateAction<UploadFile>>
    reset: () => void
}

const DataSourceSelect: React.FC<DataSourceSelectProps> = ({ dataSource, setDataSource, reset }) => {

    const props: UploadProps = {
        name: "file",
        multiple: false,
        accept: ".xlsx",
        // listType: "picture",
        onChange(info) {
            console.log({ info });
            info.file?.status !== "removed"
                ? setDataSource(info.file)
                : reset()
        },
        onRemove: () => {
            reset()
        },
        beforeUpload: () => {
            return false
        },
    };

    return (
        <Upload {...props}>
            {!dataSource && <Button block icon={<UploadOutlined />}>Select or drop your data source</Button>
            }
        </Upload >
    );
};

export default DataSourceSelect