import React, { createContext, useContext, useState } from 'react'
import axios from 'axios'
import { ChildrenProps } from '../../types/ChildrenReactType'
import { userDataType } from '../../types/UserAuthTypes'

import { useLocalStorage } from "../../hooks/useLocalStorage"

export type UserContextType = {
    user: userDataType | null
    signin: (email: string, password: string) => Promise<boolean>
    signout: () => void,
}

export const UserContext = createContext<UserContextType | null>(null)

export const UserContextProvider: React.FC<ChildrenProps> = ({ children }) => {

    const { setStorageItem, getStorageItem, removeStorageItem } = useLocalStorage()

    const storedUser = getStorageItem('user')

    const [user, setUser] = useState<userDataType | null>(storedUser)

    const signin = async (email: string, password: string): Promise<boolean> => {
        let isUser = false
        console.log("Signing in")
        removeStorageItem('user')
        const data = new FormData();
        data.append("username", email);
        data.append("password", password);
        const tokenEndpoint = "/oauth2/token?client_id=DevClient&client_name=AutoDoc&grant_type=password"
        const headers = { "Content-Type": "application/x-www-form-urlencoded" }

        console.log(`Fetching Token Endpoint`)
        await axios.post(tokenEndpoint, data, { headers })
            .then((response) => {
                const userData = response.data
                // Debug Purposes Only
                console.log(`Getting Auth User Data`)
                const userResponse = {
                    access_token: userData.access_token,
                    scopes: userData.scope,
                    accounts: userData.accounts,
                    uin: userData.user.username,
                    email: userData.user.email,
                    first_name: userData.user.first_name,
                    last_name: userData.user.last_name,
                    fullname: userData.user.fullname,
                    ouc: userData.user.ouc,
                    telephone_number: userData.user.telephone_number,
                    title: userData.user.title,
                }
                // Save items to local storage
                setStorageItem("user", userResponse)
                console.log(`Sucessfully Authenticated`)
                setUser(userResponse)
                isUser = true

            })
            .catch((error) => {
                console.log(`Auth Failed, Fail to get Token`)
                console.log(error.response);
                signout()
                isUser = false
            })
        return isUser
    };

    // Sign out Function
    const signout = () => {
        console.log("Signing Out")
        setUser(null)
        removeStorageItem('user')
        removeStorageItem('currentPage')
    };

    return (
        <UserContext.Provider value={{ user, signin, signout }}>
            {children}
        </UserContext.Provider>
    )
}

export const useUser = () => useContext(UserContext)

export default UserContext




// // revalidate
// const userReturnValidation = async (): Promise<boolean> => {
//     let isSuccess: boolean = false
//     // read local storage
//     // then if there is user in local stoage,
//     // read it, check token and if active, save into context
//     const userStored = getStorageItem('user')
//     if (userStored) {
//         const userStorageData = JSON.parse(userStored)
//         const access_token = userStorageData.access_token

//         const instrospectEndpoint = "/oauth2/introspect"
//         const introspect = new FormData();
//         introspect.append("token", `${access_token}`);
//         introspect.append("client_secret", "secret");
//         introspect.append("client_id", "ApiResourceClient");

//         console.log(`Fetching Instrospect Endpoint`)
//         await axios({
//             method: "post",
//             url: instrospectEndpoint,
//             data: introspect,
//         })
//             .then((response) => {
//                 const isUserActive = response.data.active
//                 if (isUserActive) {
//                     isSuccess = true
//                 } else {
//                     signout()
//                 }

//             })
//             .catch((error) => {
//                 console.log(`Auth Failed, Fail to Instrospect`)
//                 console.log(error.response);
//                 signout()
//             });
//         setUser(userStorageData)
//     }
//     return isSuccess
// }