import React, { useEffect } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Tabs,
  Tag,
  Typography,
  message,
} from "antd";

// Components
import ContentWrapper from "../../../../../components/ContentWrapper";

// Contexts
import type { TabsProps } from "antd";
// import Networks from './sections/Networks';
import {
  ApiOutlined,
  ArrowsAltOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
// import Devices from './sections/Devices';
import { usePaloAltoConnectors } from "../../../../../contexts/Connectors/PaloAltoContext";
import { ColumnsType } from "antd/es/table";
import BaseTable from "../../../../../components/Tables/BaseTable";
import { dynamicSort } from "../../../../../utils/sorter";
import ShowRecordDetails from "../../../../../components/Tables/ShowRecordDetails";
// import CreateSite from "./CreateSite";

const { Title, Text } = Typography;

const DnsServices = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const paloAlto = usePaloAltoConnectors();
  const tenantData = paloAlto?.tenantData;
  const dnsProfiles = tenantData?.get_dns_service_profiles;
  const loadingTenantData = paloAlto?.loadingTenantData;
  const errorTenantData = paloAlto?.errorTenantData;

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      // width: "18%",
    },
    {
      title: "Name",
      dataIndex: "name",
      // width: '16%'
    },
  ];

  const actionMenu = [
    {
      key: "listDetails",
      component: ShowRecordDetails,
      icon: <ArrowsAltOutlined />,
    },
  ];

  const items = [
    {
      key: "1",
      label: `DNS Roles`,
      children: (
        <BaseTable
          loading={loadingTenantData && !errorTenantData}
          tableName={"Elements"}
          dataSource={tenantData?.get_dns_service_roles}
          columns={columns}
          rowActions={actionMenu}
        />
      ),
    },
    {
      key: "2",
      label: `DNS Profiles`,
      children: (
        <BaseTable
          loading={loadingTenantData && !errorTenantData}
          tableName={"DNSProfile"}
          dataSource={tenantData?.get_dns_service_profiles}
          columns={columns}
          rowActions={actionMenu}
        />
      ),
    },
  ];

  return (
    <Space
      direction="vertical"
      size="large"
      style={{ width: "100%", marginTop: 16 }}
    >
      {contextHolder}
      <Tabs items={items} size="large" />
    </Space>
  );
};

export default DnsServices;
