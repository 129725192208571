import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

//material ui components
import Container from "@material-ui/core/Container";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CustomButton from "../../components/Button/CustomButton.js";
import Button from "@material-ui/core/Button";
import {
  downloadTemplate,
  downloadTemplatesByLibraryId,
  handleHttpError,
} from "../../common/utils.js";

//swal
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

//toaster components
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function createData(Template, Rename, Update, Delete) {
  return { Template, Rename, Update, Delete };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "30px",
    marginBottom: "80px",
  },
  purple: {
    color: "rgb(85, 20, 180)",
  },
  templateName: {
    fontSize: "small",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: 'rgba(85, 20, 180,0.9)',
    color: "black",
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function LibraryManageView(props) {
  const classes = useStyles();
  const history = useHistory();
  const [templatesLoaded, setTemplatesLoaded] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isCrashing, setCrashing] = useState(false);
  const MySwal = withReactContent(Swal);
  const [templatesList, setTemplatesList] = useState(null);
  useEffect(() => {
    const template_list = [];
    axios
      .get("/api/v1/libraries/" + props.library + "/templates", {
        headers: { Authorization: "Bearer " + props.access_token },
      })
      .then((response) => {
        const templates = response.data;
        for (let template of templates) {
          const template_name = template.name;
          const template_id = template.id;
          template_list.push(
            createData(
              template_name,
              <CustomButton
                label={"Download"}
                color="purple"
                action={() => downloadTemplate(template_id)}
              />,
              <CustomButton
                label={"Replace"}
                color="purple"
                action={() =>
                  MySwal.fire({
                    title: "Are you sure?",
                    html:
                      "You are about to replace <b>" + template_name + "</b>",
                    icon: "warning",
                    width: 500,
                    showCancelButton: true,
                    confirmButtonColor: "green",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Continue",
                  }).then((result) => {
                    if (result.value) {
                      MySwal.fire({
                        title: "File Upload",
                        html: "You won't be able to revert this!<br><br><input style='padding-left:70px' accept='.docx,.xlsx,.pptx,.txt,.xml,.j2,.vsdx,.vsd,.vsdm,.csv' type='file' id='new_template' name='new_template'/>",
                        showCancelButton: "true",
                        showConfirmButton: "true",
                        confirmButtonColor: "green",
                        cancelButtonColor: "red",
                        confirmButtonText: "Yes, update template!",
                      }).then((res) => {
                        if (res.value) {
                          updateTemplate(template_id);
                        }
                      });
                    }
                  })
                }
              />,
              <CustomButton
                color="purple"
                label={"Delete "}
                action={() =>
                  MySwal.fire({
                    title: "Are you sure?",
                    html:
                      "<p>You are about to delete selected template!</p>" +
                      "<p><strong>All associated tertiary data will also be deleted.</strong></p>",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "green",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Confirm",
                  }).then((answer) => {
                    if (answer.value) {
                      MySwal.fire({
                        title: "Last chance!",
                        html:
                          "<p>You won't be able to revert this!</p>" +
                          "<p><strong>Remember, <em>all</em> associated tertiary data will also be deleted.</strong></p>",
                        showCancelButton: "true",
                        showConfirmButton: "true",
                        confirmButtonColor: "green",
                        cancelButtonColor: "red",
                        confirmButtonText: "Yes, delete it!",
                      }).then((ans) => {
                        if (ans.value) {
                          deleteTemplate(template_id);
                        }
                      });
                    }
                  })
                }
              />
            )
          );
        }

        setTemplatesList(template_list);
        setTemplatesLoaded(true);
        setLoading(false);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          history.push("/login");
        }
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        setCrashing(true);
      });
  }, []);

  const updateTemplate = (template_id) => {
    var bodyFormData = new FormData();
    let new_template = document.getElementById("new_template").files[0];
    bodyFormData.append("template", new_template);
    axios({
      method: "put",
      url: "/api/v1/templates/" + template_id,
      data: bodyFormData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        toast.success("Template successfully updated.");
      })
      .catch((err) => {
        toast.error("Template could not be updated.");
        handleHttpError(err);
      });
  };

  const uploadNewTemplate = (library_id) => {
    var bodyFormData = new FormData();
    let new_template_upload = document.getElementById("new_template_upload")
      .files[0];
    bodyFormData.append("template", new_template_upload);
    axios({
      method: "post",
      url: `/api/v1/libraries/${library_id}/templates`,
      data: bodyFormData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        toast.success("Template successfully uploaded.");
      })
      .catch((err) => {
        handleHttpError(err);
      });
  };

  const deleteTemplate = (template_id) => {
    axios({
      method: "delete",
      url: "/api/v1/templates/" + template_id,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((res) => {
        toast.success("Template successfully deleted.");
      })
      .catch((err) => {
        toast.error("Template could not be deleted.");
        handleHttpError(err);
      });
  };

  if (templatesLoaded) {
    return (
      <Container>
        <Grid container className={classes.root} justify="center" spacing={3}>
          <Grid item xs={10}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Templates</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>
                      <CustomButton
                        label={"Upload template"}
                        color="green"
                        action={() =>
                          MySwal.fire({
                            title: "Are you sure?",
                            html: "You are about to upload a new template",
                            icon: "warning",
                            width: 500,
                            showCancelButton: true,
                            confirmButtonColor: "green",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Continue",
                          }).then((result) => {
                            if (result.value) {
                              MySwal.fire({
                                title: "File Upload",
                                html: "You won't be able to revert this!<br><br><input style='padding-left:70px' accept='.docx,.xlsx,.pptx,.txt,.xml,.j2,.vsdx,.vsd' type='file' id='new_template_upload' name='new_template_upload'/>",
                                showCancelButton: "true",
                                showConfirmButton: "true",
                                confirmButtonColor: "green",
                                cancelButtonColor: "red",
                                confirmButtonText: "Yes, upload template!",
                              }).then((res) => {
                                if (res.value) {
                                  uploadNewTemplate(props.library);
                                }
                              });
                            }
                          })
                        }
                      />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {templatesList.map((template) => (
                    <StyledTableRow key={template.Template}>
                      <StyledTableCell className={classes.templateName}>
                        {template.Template}
                      </StyledTableCell>
                      <StyledTableCell>{template.Rename}</StyledTableCell>
                      <StyledTableCell>{template.Update}</StyledTableCell>
                      <StyledTableCell>{template.Delete}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                  <StyledTableRow>
                    <StyledTableCell colSpan={4}>
                      {templatesList.length === 0 ? (
                        <Button variant="contained" disabled>
                          Download all templates
                        </Button>
                      ) : (
                        <CustomButton
                          label={"Download all templates"}
                          color="purple"
                          action={() =>
                            downloadTemplatesByLibraryId(props.library)
                          }
                        />
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <ToastContainer />
          </Grid>
        </Grid>
      </Container>
    );
  }
  if (isCrashing) {
    return (
      <Container>
        <Grid container className={classes.root} justify="center" spacing={3}>
          <div className="App">
            There seems to be an error receiving templates. Contact the
            development team if this issue persists.
          </div>
        </Grid>
      </Container>
    );
  }
  if (isLoading) {
    return (
      <Container>
        <Grid container className={classes.root} justify="center" spacing={3}>
          <div className="App">Loading...</div>
        </Grid>
      </Container>
    );
  }
}
