import React, { useEffect } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Tabs,
  Tag,
  Typography,
  message,
} from "antd";

// Components
import ContentWrapper from "../../../../../components/ContentWrapper";

// Contexts
import type { TabsProps } from "antd";
// import Networks from './sections/Networks';
import {
  ApiOutlined,
  ArrowsAltOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
// import Devices from './sections/Devices';
import { usePaloAltoConnectors } from "../../../../../contexts/Connectors/PaloAltoContext";
import { ColumnsType } from "antd/es/table";
import BaseTable from "../../../../../components/Tables/BaseTable";
import { dynamicSort } from "../../../../../utils/sorter";
import ShowRecordDetails from "../../../../../components/Tables/ShowRecordDetails";
// import CreateSite from "./CreateSite";

const { Title, Text } = Typography;

const Domains = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const paloAlto = usePaloAltoConnectors();
  const tenantData = paloAlto?.tenantData;

  const domains = tenantData?.get_domains;

  const loadingTenantData = paloAlto.loadingTenantData;
  const errorTenantData = paloAlto.errorTenantData;

  const columnsLabels: ColumnsType<any> = [
    {
      title: "Domain ID",
      dataIndex: "id",
      // width: "20",
    },
    {
      title: "Name",
      dataIndex: "name",
      // width: "20%",
    },
  ];

  const actionMenu = [
    {
      key: "listDetails",
      component: ShowRecordDetails,
      icon: <ArrowsAltOutlined />,
    },
  ];

  return (
    <Space
      direction="vertical"
      size="large"
      style={{ width: "100%", marginTop: 16 }}
    >
      {contextHolder}
      <BaseTable
        loading={loadingTenantData && !errorTenantData}
        tableName={"Domains"}
        dataSource={domains?.sort(dynamicSort("name"))}
        columns={columnsLabels}
        rowActions={actionMenu}
      />
    </Space>
  );
};

export default Domains;
