import { Space, Modal, Typography } from "antd"
import React, { FC, useState } from "react"
import { useConnectors } from "../../../../../contexts/Connectors/ZscalerContext"
import { ZscalerLocationType } from "../../../../../types/ConnectorsType"
import ResultError from "../../Results/ResultError"
import { NgdsErrorType } from "../../../../../types/NgdsErrorType"
import { deleteLocation } from "../../functions/fetchZscaler"
import LoadingProgress from "../../LoadingProgress"
import { listSublocationsByParentId } from "../../functions/fetchZscaler"

const { Text } = Typography

type Props = {
    record: ZscalerLocationType
}

const DeleteSubLocation: FC<Props> = ({ record }) => {

    const connectors = useConnectors()
    const [open, setOpen] = useState(false);
    const [progressDelete, setProgressDelete] = useState(false)

    // Delete Location States
    const [deleteLocationLoading, setDeleteLocationLoading] = useState<boolean>(false)
    const [deleteLocationError, setDeleteLocationError] = useState<NgdsErrorType | undefined>(undefined)
    const [deletedLocationOK, setDeleteLocationOk] = useState<boolean>(false)


    const onReset = () => {
        setProgressDelete(false)
        setDeleteLocationLoading(false)
        setDeleteLocationError(undefined)
    }


    const deleteSubLocation = async (record: ZscalerLocationType) => {
        setProgressDelete(true)
        // This Funcion will delete all related VPN Credeentials from the Location
        setDeleteLocationLoading(true)

        console.log(`Deleting Sub-Location ID ${record.id}`)
        const params = { location_id: +record.id }
        const response = await deleteLocation(params)
        setDeleteLocationLoading(false)

        if (response?.hasOwnProperty("error")) setDeleteLocationError({ ...response.error })
        else if (response.status !== 200) setDeleteLocationError({ error: "Something whent wrong", response: null })
        else setDeleteLocationOk(true)
    }

    // After closing modal
    const updateIfDeleteOk = () => {
        if (deletedLocationOK) {
            // connectors?.fetchLocations()
            connectors?.fetchLocationsAndCredentials()
            listSublocationsByParentId({ parent_id: record.parentId })
        }
        onReset()
    }

    const handleCancel = () => {
        console.log("Clicked Cancel")
        onReset()
        setOpen(false)
    };

    const progressParams = {
        descriptors: [{
            key: "1",
            label: "Location ID",
            value: record.id
        },
        {
            key: "2",
            label: "Location Name",
            value: record.name
        }
        ],
        progressors: [
            {
                key: "3",
                text: "Deleting Sub Location",
                loading: deleteLocationLoading,
                exception: deleteLocationError,
                dependOn: progressDelete,
                success: deletedLocationOK
            }
        ]
    }

    return (
        <>
            <a onClick={() => setOpen(true)}>
                Delete Sub-Location
            </a >
            <Modal
                title="Are sure you want to delete it?"
                open={open}
                onOk={() => deleteSubLocation(record)}
                okText={"Delete"}
                okButtonProps={{ style: { backgroundColor: !progressDelete ? 'red' : undefined }, disabled: progressDelete }}
                cancelButtonProps={{ disabled: progressDelete }}
                confirmLoading={deleteLocationLoading}
                onCancel={handleCancel}
                closable={!deleteLocationLoading}
                maskClosable={!deleteLocationLoading}
                afterClose={updateIfDeleteOk}
            >
                <Space direction='vertical' size='large' wrap style={{ width: "100%" }}>
                    {LoadingProgress(progressParams)}
                    {deletedLocationOK && <Text>Task Completed Successfully!</Text>}
                    {deleteLocationError && <ResultError errorPayload={deleteLocationError} />}
                </Space >
            </Modal >
        </>
    )
}

export default DeleteSubLocation
