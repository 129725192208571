import portAssignment from "./portAssignment";

export default function mmgAssignment(mmg, correlation) {
  const coreSwitches = correlation.filter((sw) =>
    sw.new_hostname.includes("mcs")
  );

  for (const core of coreSwitches) {
    let lastPort = core.new_ports_qty;

    // it will delete the last port entry to avoid duplication
    // that occurs when there are difference bettwen number of ports between old and new
    core.port_mapping.splice(
      core.port_mapping.findIndex((entry) => entry.toPort === lastPort),
      1
    );

    for (const mobileName of mmg) {
      const mmgEntry = {
        hostname: "",
        name: "",
        toPort: lastPort,
        toMode: "trunk",
        toVlan: "",
        toDevice: mobileName,
        toNote: `to MMG - ${mobileName}`,
        isMapped: true,
      };

      // // Adds the entry after the last port found.
      const lastIndex = core.port_mapping.length + 1;
      core.port_mapping.splice(lastIndex, 0, mmgEntry);
      portAssignment(core, lastPort);
      lastPort -= lastPort;
    }
  }
}
