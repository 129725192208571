// Function that reads the sites from rollouts object.
// Additional Ports - If the some switch has more ports than the respective correlated switch
const portFiller = (i, newSwName) => {
  return {
    duplex: "",
    hostname: "",
    name: "",
    port: "",
    speed: "",
    status: "",
    type: "",
    vlan: "",
    toPort: i,
    toMode: "",
    toVlan: "",
    toDevice: newSwName,
    isMapped: false,
  };
};

export default function switchesCorrelation(site) {
  // Creates the dictionary for old / legacy access switches
  const legacySwitches = site.legacy.map((item) => ({
    legacy_hostname: item.hostname,
    legacy_ports_qty: item.ports,
  }));

  // Creates the dictionary for new access switches
  const newSwitches = site.devices
    .filter((name) => name.type === "Switch")
    .map((item) => ({
      new_hostname: item.hostname,
      new_ports_qty: item.ports,
    }));

  //Starts correlation -

  // leveraging the number of swicthes for comparisson
  const numberOfEntries = Math.max(legacySwitches.length, newSwitches.length);

  // It will check the number of entries and create a pair switch if needed.
  const correlation = [];
  for (let i = 0; i <= numberOfEntries - 1; i++) {
    const legacySwitch = legacySwitches[i]
      ? legacySwitches[i]
      : {
          legacy_hostname: "",
          legacy_ports_qty: newSwitches[i].new_ports_qty,
        };
    const newSwitch = newSwitches[i]
      ? newSwitches[i]
      : {
          new_hostname: "",
          new_ports_qty: legacySwitches[i].legacy_ports_qty,
        };

    const portMapping = site.show_interfaces_status.filter(
      (port) => port.hostname === legacySwitch.legacy_hostname
    );

    // If there no command output returned.
    if (!portMapping.length) {
      for (let m = 2; m <= legacySwitch.legacy_ports_qty; m++) {
        portMapping.push({
          port: "",
          hostname: "",
          status: "",
          name: "",
          vlan: "",
          toDevice: newSwitch.new_hostname,
          toPort: m,
          isMapped: false,
        });
      }
    }

    const additionalPorts = [];
    // Adds the remaining port for mapping purpose

    if (newSwitch.new_ports_qty > legacySwitch.legacy_ports_qty) {
      // Additional ports for maping purposes
      for (
        let p = legacySwitch.legacy_ports_qty + 1;
        p <= newSwitch.new_ports_qty;
        p++
      ) {
        additionalPorts.push(portFiller(p, newSwitch.new_hostname));
      }
    }
    // Creates an array to list the available ports on new switch
    const higherPort = Math.max(
      newSwitch.new_ports_qty,
      legacySwitch.legacy_ports_qty
    );
    const portAssignment = [];
    for (let a = 1; a <= higherPort; a++) {
      portAssignment.push({
        port: a,
        isAssigned: a > newSwitch.new_ports_qty,
      });
    }

    correlation.push({
      ...legacySwitch,
      ...newSwitch,
      port_mapping: [...portMapping, ...additionalPorts],
      port_assignment: portAssignment,
    });
  }

  // Correlation Object
  return correlation;
}
