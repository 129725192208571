import React from "react";
import {
  Button,
  Divider,
  Flex,
  Tabs,
  TabsProps,
  Typography,
  message,
} from "antd";
import { usePaloAltoConnectors } from "../../../contexts/Connectors/PaloAltoContext";
import ApiForm from "./ApiForm";
import MainCard from "./MainCard";
import Sites from "./MainTables/Sites";
import ExportToExcel from "../MerakiConnector/functions/ExportToExcel";
import { ReloadOutlined } from "@ant-design/icons";
import Elements from "./MainTables/Elements";
import CreateSite from "./DeploySite";
import { ChildrenProps } from "../../../types/ChildrenReactType";
// import Machines from "./MainTables/Machines";
import SecurityZones from "./MainTables/SecurityZones";
import Policies from "./MainTables/Policies";
import DnsServices from "./MainTables/DnsServices";
import Wan from "./MainTables/Wan";
import Domains from "./MainTables/Domains";
const { Title, Text } = Typography;

const PaloAltoConnector = () => {
  const paloAlto = usePaloAltoConnectors();
  const tenantData = paloAlto.tenantData;
  const get_all_from_tenant = paloAlto.get_all_from_tenant;
  const api_key = paloAlto.apiKey;

  const user = JSON.parse(localStorage.getItem("user"));
  const scopes = JSON.parse(user?.scopes);

  const resetTables = async () => {
    console.log("reset");
    await get_all_from_tenant(api_key);
  };

  const operations = (
    <>
      <CreateSite />
      <Divider type="vertical" />
      {/* <ExportToExcel />
      <Divider type="vertical" /> */}
      <Button
        size="small"
        icon={<ReloadOutlined />}
        onClick={() => resetTables()}
      >
        Reset
      </Button>
    </>
  );

  const BorderedContent: React.FC<ChildrenProps> = ({ children }) => {
    return (
      <div
        style={{
          padding: 16,
          marginTop: -16,
          borderLeft: "1px solid #F5F5F5",
          borderRight: "1px solid #F5F5F5",
          borderBottom: "1px solid #F5F5F5",
        }}
      >
        {children}
      </div>
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "sites",
      label: "Sites",
      children: (
        <BorderedContent>
          <Sites tenantData={tenantData} />
        </BorderedContent>
      ),
    },
    {
      key: "domains",
      label: "Domains",
      children: (
        <BorderedContent>
          <Domains />
        </BorderedContent>
      ),
    },
    {
      key: "elements",
      label: "Elements",
      children: (
        <BorderedContent>
          <Elements />
        </BorderedContent>
      ),
    },
    // // {
    // //   key: "machines",
    // //   label: "Machines",
    // //   children: (
    // //     <BorderedContent>
    // //       <Machines />
    // //     </BorderedContent>
    // //   ),
    // // },
    {
      key: "wan",
      label: "WAN",
      children: (
        <BorderedContent>
          <Wan />
        </BorderedContent>
      ),
    },

    {
      key: "DnsServices",
      label: "DNS Services",
      children: (
        <BorderedContent>
          <DnsServices />
        </BorderedContent>
      ),
    },
    {
      key: "secZones",
      label: "Security Zones",
      children: (
        <BorderedContent>
          <SecurityZones />
        </BorderedContent>
      ),
    },
    {
      key: "polices",
      label: "Policies",
      children: (
        <BorderedContent>
          <Policies />
        </BorderedContent>
      ),
    },
  ];

  return (
    <Flex vertical gap="middle" style={{ width: "100%" }}>
      <h1 className="colorIndigo" style={{ marginBottom: 16 }}>
        Palo Alto Connector
      </h1>
      {!tenantData ? (
        <ApiForm />
      ) : (
        <MainCard>
          <Tabs
            tabBarExtraContent={operations}
            type="card"
            defaultActiveKey="1"
            items={items}
            size="large"
          />
        </MainCard>
      )}
    </Flex>
  );
};

export default PaloAltoConnector;
