import React, { useState, useEffect } from 'react';
import axios from 'axios'

//material ui components
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

//components
import CustomButton from "./../../../components/Button/CustomButton.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { handleHttpError } from "../../../common/utils.js"

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "130px",
        textAlign: "center",
        marginBottom: "80px",
    },
    title: {
        color: "rgb(85, 20, 180)",
    },
    table: {
        minWidth: 650,
    },
}))

function createData(Access, Action) {
    return { Access, Action };
}

export default function Scopes(props) {

    const classes = useStyles();
    const [scopesList, setScopesList] = useState(null);
    const [scopesLoaded, setScopesLoaded] = useState(false);
    const [access, setAccess] = useState("");

    const deleteScope = (scope_id) => {
        axios({
            method: 'delete',
            url: '/oauth2/scopes/' + scope_id,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + props.access_token,
            },
        }).then((res) => {
            toast.success('Scope deleted')
            getScopes()
        })
            .catch((err) => {
                handleHttpError(err)
            });
    }
    const createScope = () => {
        const data = {
            "name": access,
        }
        axios({
            method: 'post',
            url: '/oauth2/scopes',
            data: data,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + props.access_token,
            },
        }).then((res) => {
            toast.success('Scope created')
            getScopes()
        })
            .catch((err) => {
                handleHttpError(err)
            });
    }
    const handleAccess = (e) => {
        setAccess(e.target.value);

    }
    const getScopes = () => {
        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?scopes';
        window.history.pushState({ path: newurl }, '', newurl);
        axios({
            method: 'get',
            url: '/oauth2/scopes',
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + props.access_token,
            },
        }).then((res) => {
            var scopes_list = []
            for (let x = 0; x < res.data.length; x++) {
                scopes_list.push(createData((res.data[x].name), <CustomButton
                    label={"Delete"}
                    color="purple"
                    action={() => deleteScope(res.data[x].id)}
                />))
                // user_list.push(createData(res.data[x].first_name + ' ' + res.data[x].last_name, res.data[x].username, res.data[x].ouc, res.data[x].title, res.data[x].email, res.data[x].telephone_number, 'scope, admin, test'))
            }
            setScopesList(scopes_list)
            setScopesLoaded(true)
        })
            .catch((err) => {
                handleHttpError(err)
            });
    }
    useEffect(() => {
        getScopes()
    }, []);

    return (
        <Container>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Access Type</b></TableCell>
                            <TableCell><b>Action</b></TableCell>
                        </TableRow>
                    </TableHead>
                    {scopesLoaded ? <TableBody>
                        {scopesList.map((scope) => (
                            <TableRow key={scope.name}>
                                <TableCell >{scope.Access}</TableCell>
                                <TableCell >{scope.Action}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody> : null}
                    <TableBody>
                        <TableRow>
                            <TableCell >
                                <TextField id="standard-basic" variant="outlined"
                                    size="small"
                                    onChange={handleAccess} />
                            </TableCell>
                            <TableCell >
                                <CustomButton
                                    label={"Add Scope"}
                                    color="purple"
                                    action={() => createScope()}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <div id='error_body'></div>
            </TableContainer>
            <ToastContainer />
        </Container>
    );
}
