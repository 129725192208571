import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Descriptions,
  Divider,
  Drawer,
  Form,
  Space,
  Tabs,
  Typography,
  message,
} from "antd";
import { useConnectors } from "../../../../../contexts/Connectors/ZscalerContext";
import type { TabsProps } from "antd";

// Forms
import NewLocationForm from "../../Forms/NewLocationForm";
import NewVpnCredentialsForm from "../../Forms/NewVpnCredentialForm";
import {
  ZscalerLocationType,
  NewVpnType,
  VpnCredentialType,
} from "../../../../../types/ConnectorsType";
import {
  PlusOutlined,
  SafetyCertificateOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { NgdsErrorType } from "../../../../../types/NgdsErrorType";

// Fetch Functions
import { vpnCreate, addLocation } from "../../functions/fetchZscaler";
import { LoadingProgress } from "../../LoadingProgress";
import ResultOk from "../../Results/ResultOk";
import ResultError from "../../Results/ResultError";

const { Text } = Typography;

const CreateLocation: React.FC = ({ isZscalerReadOnly }) => {
  const [form] = Form.useForm();

  const [country, setCountry] = useState<string | undefined>(undefined);
  const [siteCode, setSiteCode] = useState<string | undefined>(undefined);
  const [rfc, setRfc] = useState<string | undefined>(undefined);
  const [fqdn, setFqdn] = useState<string | undefined>(undefined);
  const [locationName, setLocationName] = useState<string | undefined>(
    undefined
  );
  const [submittable, setSubmittable] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [vpnNew, setVpnNew] = useState<VpnCredentialType | undefined>(
    undefined
  );
  const [vpnNewLoading, setVpnNewLoading] = useState<boolean>(false);
  const [vpnNewError, setVpnNewError] = useState<NgdsErrorType | undefined>(
    undefined
  );

  const [locationNew, setLocationNew] = useState<
    ZscalerLocationType | undefined
  >(undefined);
  const [locationNewLoading, setLocationNewLoading] = useState<boolean>(false);
  const [locationNewError, setLocationNewError] = useState<
    NgdsErrorType | undefined
  >(undefined);

  const [isCreatedOk, setIsCreatedOk] = useState<boolean>(false);

  const [isLocationExists, setIsLocationExists] = useState<boolean>(false);
  const [isVPNExists, setIsVPNExists] = useState<boolean>(false);

  const [reuseVPNID, setReuseVPNID] = useState<number | undefined>(undefined);

  const [open, setOpen] = useState(false);

  const onReset = () => {
    form.resetFields();
    setSubmitting(false);
    setSubmittable(false);
    setSiteCode(undefined);
    setCountry(undefined);
    setRfc(undefined);
    setFqdn(undefined);
    setLocationNew(undefined);
    setLocationNewLoading(false);
    setLocationNewError(undefined);
    setVpnNew(undefined);
    setVpnNewLoading(false);
    setVpnNewError(undefined);
    setIsVPNExists(false);
    setIsLocationExists(false);
    setReuseVPNID(undefined);
  };

  const connectors = useConnectors();

  // Validates if fields are completed
  useEffect(() => {
    if (siteCode && country && country !== "NONE" && rfc) setSubmittable(true);
    else setSubmittable(false);
  }, [siteCode, country, rfc, isLocationExists, isVPNExists]);

  // Location Name Validation

  const locationNameList = connectors?.locations?.map(
    (loc: ZscalerLocationType) => loc.name
  );
  const evaluteLocationExistence = () =>
    locationNameList?.some((item) => item === locationName)
      ? setIsLocationExists(true)
      : setIsLocationExists(false);
  useEffect(() => evaluteLocationExistence(), [locationName]);

  // fqdn Validation

  const fqdnAvailableList = connectors?.vpnCredentials?.map(
    (vpn: VpnCredentialType) => !vpn.location && vpn.fqdn
  );
  const evaluteFqdnExistence = () =>
    fqdnAvailableList?.some((item) => item === fqdn)
      ? setIsVPNExists(true)
      : setIsVPNExists(false);
  useEffect(() => evaluteFqdnExistence(), [fqdn]);

  const onChange = (key: string) => console.log(key);

  const showDrawer = () => setOpen(true);

  const onClose = () => {
    setOpen(false);
    onReset();

    // Hard Reload
    if (isCreatedOk) {
      connectors?.fetchLocationsAndCredentials();
      // connectors?.fetchLocations();
      // connectors?.fetchVpnCredentials()
    }
  };

  const dataForm = form.getFieldsValue();

  const createLocation = async (vpn_credential_id: number) => {
    console.log("Creating New Location with Credentials");
    const locationParams = {
      ...dataForm,
      vpnCredentials: [{ id: vpn_credential_id, type: "UFQDN" }],
    };
    setLocationNewLoading(true);
    const locationResponse = await addLocation(locationParams);
    setLocationNewLoading(false);
    if (locationResponse?.hasOwnProperty("error"))
      setLocationNewError({ ...locationResponse.error });
    else if (
      locationResponse?.status !== 200 ||
      !locationResponse.data.hasOwnProperty("id")
    )
      setLocationNewError({
        error: "Something whent wrong",
        response: undefined,
      });
    else {
      setLocationNew(locationResponse.data);
      setIsCreatedOk(true);
    }
  };

  const submitLocation = async () => {
    console.log("Submitting New Location");
    setSubmitting(true);

    if (reuseVPNID) {
      await createLocation(reuseVPNID);
    } else {
      // Step 1: Create VPN Credentials
      console.log("Creating New VPN Credentials");
      const fqdn = dataForm.vpnCredentials.fqdn.trim();
      const comments = dataForm.vpnCredentials.comments.trim();
      const rfc = dataForm.vpnCredentials.rfc.trim();
      const vpnParams = { vpnCredentials: [{ fqdn, comments, rfc }] };

      setVpnNewLoading(true);
      const vpnResponse = await vpnCreate(vpnParams);
      setVpnNewLoading(false);

      if (vpnResponse?.hasOwnProperty("error"))
        setVpnNewError({ ...vpnResponse.error });
      else if (vpnResponse.status !== 200)
        setVpnNewError({ error: "Something whent wrong", response: undefined });
      else {
        setVpnNew(vpnResponse.data);
        // Step 2: Create Location
        await createLocation(+vpnResponse.data[0]?.id);
      }
    }
  };

  const progressParams = {
    descriptors: [
      {
        key: "1",
        label: "Location",
        value: form.getFieldValue(["name"]),
      },
      {
        key: "2",
        label: "FQDN",
        value: form.getFieldValue(["vpnCredentials", "fqdn"]),
      },
    ],
    progressors: [
      !reuseVPNID && {
        key: "3",
        text: "Creating VPN Credentials",
        loading: vpnNewLoading,
        success: vpnNew,
        exception: vpnNewError,
        dependOn: true,
      },
      {
        key: "4",
        text: "Creating Location",
        loading: locationNewLoading,
        success: isCreatedOk,
        exception: locationNewError,
        dependOn: vpnNew || reuseVPNID !== undefined,
      },
    ],
  };

  const locationAlert = isLocationExists && (
    <>
      <Alert
        banner
        showIcon
        message={`Location ${locationName} already exists`}
        type="warning"
        action={
          <Button
            size="small"
            type="link"
            onClick={() => {
              onReset();
            }}
          >
            Reset
          </Button>
        }
      />
    </>
  );

  const reuseCredential = (fqdn: string) => {
    const thisFqdn = connectors?.vpnCredentials.filter(
      (vpn: VpnCredentialType) => vpn.fqdn === fqdn
    )[0];
    form.setFieldValue(["vpnCredentials", "comments"], thisFqdn.comments);
    form.setFieldValue(["vpnCredentials", "rfc"], thisFqdn.rfc);
    form.setFieldValue(["vpnCredentials", "fqdn"], thisFqdn.fqdn);
    setReuseVPNID(thisFqdn.id);
    setSubmittable(true);
  };

  const credentialAlert = !isLocationExists && isVPNExists && (
    <>
      <Alert
        closeIcon={
          <Space direction="vertical">
            <Button
              size="small"
              type="text"
              onClick={() => reuseCredential(fqdn)}
            >
              Use It
            </Button>
            <Button size="small" type="link" onClick={() => onReset()}>
              Reset
            </Button>
          </Space>
        }
        closable
        showIcon
        banner
        message={`VPN Credential ${fqdn} already exists`}
        type="warning"
      />
    </>
  );

  const items: TabsProps["items"] = [
    {
      key: "NewLocation",
      label: (
        <>
          <HomeOutlined />
          Location Attributes
        </>
      ),
      children: (
        <NewLocationForm
          form={form}
          siteCode={siteCode}
          country={country}
          setSiteCode={setSiteCode}
          setCountry={setCountry}
          setFqdn={setFqdn}
          setLocationName={setLocationName}
        />
      ),
    },
    {
      key: "NewVpn",
      label: (
        <>
          <SafetyCertificateOutlined />
          VPN Credentials
        </>
      ),
      children: (
        <NewVpnCredentialsForm form={form} setRfc={setRfc} setFqdn={setFqdn} />
      ),
      disabled: (!isLocationExists && isVPNExists && true) || false,
    },
  ];

  return (
    <>
      <Button
        icon={<PlusOutlined />}
        onClick={showDrawer}
        type="primary"
        disabled={isZscalerReadOnly}
      >
        Create Location
      </Button>
      <div style={{ position: "absolute", zIndex: 1000 }}>
        <Drawer
          maskClosable={vpnNewLoading === false && locationNewLoading === false}
          closable={vpnNewLoading === false && locationNewLoading === false}
          title="New Location"
          placement="left"
          onClose={onClose}
          open={open}
          width={450}
          extra={
            <Space>
              <Divider type="vertical" />
              <Button onClick={onReset}>Reset</Button>
              <Button
                disabled={!submittable || locationNew !== undefined}
                type="primary"
                onClick={() => submitLocation()}
              >
                Create
              </Button>
            </Space>
          }
        >
          {/* Loading Progress */}
          {submitting ? (
            LoadingProgress(progressParams)
          ) : (
            <>
              {locationAlert}
              {credentialAlert}

              <Tabs
                defaultActiveKey="NewLocation"
                items={items}
                onChange={onChange}
                centered
              />
            </>
          )}

          {/* Results */}
          {isCreatedOk && (
            <ResultOk
              title="Location Created Successfully"
              subTitle={
                <Descriptions
                  size="small"
                  column={1}
                  bordered
                  style={{ marginTop: 32 }}
                >
                  <Descriptions.Item label="Location ID">
                    <Text> {locationNew.id} </Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Name">
                    <Text> {locationNew.name} </Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Country">
                    <Text> {locationNew.country} </Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Upload BW">
                    {" "}
                    <Text>{locationNew.upBandwidth} Kbps </Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Download BW">
                    <Text>{locationNew.dnBandwidth} Kbps</Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Time Zone">
                    <Text> {locationNew.tz} </Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Vpn Credential ID">
                    {" "}
                    {locationNew.vpnCredentials.map(
                      (vpn: VpnCredentialType) => (
                        <>
                          <Text>{vpn.id}</Text>
                          <br />
                        </>
                      )
                    )}{" "}
                  </Descriptions.Item>
                </Descriptions>
              }
            />
          )}

          {!isCreatedOk && (locationNewError || vpnNewError) && (
            <ResultError errorPayload={vpnNewError || locationNewError} />
          )}
        </Drawer>
      </div>
    </>
  );
};

export default CreateLocation;
