import axios from "axios";
import { saveAs } from "file-saver";

// Main Function

export const exportAsExcel = async (payload: any): Promise<any> => {
  console.log("Extracting to Excel");

  //  Get Localstorage Data
  const user = JSON.parse(localStorage.getItem("user"));
  const account = JSON.parse(localStorage.getItem("currentAccount"));
  const account_id = account?.id;
  const access_token = user?.access_token;

  if (!account_id || !access_token)
    return {
      error:
        !account_id &&
        "Account ID not found" &&
        !access_token &&
        " Access Token not Found",
    };

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
  };

  const body = payload;
  console.log({ body });

  return await axios
    .post("/api/v1/utils/json_to_excel_v2", body, {
      headers,
      responseType: "blob",
      maxContentLength: 100000000,
      maxBodyLength: 1000000000,
    })
    .then((response: any) => {
      console.log({ response });
      const status = response.status;
      console.log(`${status} - Success Response`);
      const data = response.data;
      const disposition = response.headers["content-disposition"];

      const fileName = disposition.includes("filename")
        ? disposition.split("filename=")[1].split('"')[1]
        : "download.xlsx";
      saveAs(data, fileName);

      return true;
    })
    .catch((err: any) => {
      const error = err.response?.data;
      const status = error?.status;
      console.log(`${status} - Failed Response`);
      console.log({ err });
      return false;
    });
};
