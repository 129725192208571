import React, { createContext, useContext, useState } from "react";
import { ChildrenProps } from "../../../types/ChildrenReactType";
import {
  VipType,
  VpnCredentialType,
  ZscalerLocationType,
  ZscalerSettingType,
} from "../../../types/ConnectorsType";
import { NgdsErrorType } from "../../../types/NgdsErrorType";
import { connectorCall } from "../connectorCall";

type locationsAndCredentialsType = {
  locations: ZscalerLocationType[];
  vpn_credentials: VpnCredentialType[];
};

export type ConnectorsContextType = {
  locationsAndCredentials: locationsAndCredentialsType | undefined;
  loading: boolean;
  error: NgdsErrorType | undefined;
  duration: number | undefined;
  fetchLocationsAndCredentials: () => Promise<any>;
  //-----------------------------------------------------------
  vips: VipType[] | undefined;
  vipsLoading: boolean;
  vipsError: NgdsErrorType | undefined;
  vipsDuration: number | undefined;
  fetchVips: () => Promise<any>;
  //-----------------------------------------------------------
  settings: ZscalerSettingType[] | undefined;
  settingsLoading: boolean;
  settingsError: NgdsErrorType | undefined;
  settingsDuration: number | undefined;
  fetchSettings: () => Promise<any>;
  resetLocationsAndCredentials: () => void;
};

// cloud domain - default is zscaler

export const ConnectorsContext = createContext<ConnectorsContextType | null>(
  null
);

export const ConnectorsContextProvider: React.FC<ChildrenProps> = ({
  children,
}) => {
  const determineCloud = () => {
    const cloud = localStorage.getItem("zscaler_cloud_domain");
    if (!cloud) localStorage.setItem("zscaler_cloud_domain", "zscaler");
    return cloud;
  };

  // Locations States and VPN Credentials
  const [locationsAndCredentials, setLocationsAndCredentials] = useState<
    locationsAndCredentialsType | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<NgdsErrorType | undefined>(undefined);
  const [duration, setDuration] = useState<number | undefined>(undefined);

  const fetchLocationsAndCredentials = async () =>
    await connectorCall(
      "zscaler",
      "list_locations_and_vpn_credentials",
      { cloud: determineCloud() },
      setLocationsAndCredentials,
      setLoading,
      setError,
      setDuration
    );

  // VIP
  const [vips, setVips] = useState<VipType[] | undefined>(undefined);
  const [vipsLoading, setVipsLoading] = useState<boolean>(false);
  const [vipsError, setVipsError] = useState<NgdsErrorType | undefined>(
    undefined
  );
  const [vipsDuration, setVipsDuration] = useState<number | undefined>(
    undefined
  );

  const fetchVips = async () =>
    await connectorCall(
      "zscaler",
      "list_vips",
      { cloud: determineCloud() },
      setVips,
      setVipsLoading,
      setVipsError,
      setVipsDuration
    );

  // Settings
  const [settings, setSettings] = useState<ZscalerSettingType[] | undefined>(
    undefined
  );
  const [settingsLoading, setSettingsLoading] = useState<boolean>(false);
  const [settingsError, setSettingsError] = useState<NgdsErrorType | undefined>(
    undefined
  );
  const [settingsDuration, setSettingsDuration] = useState<number | undefined>(
    undefined
  );

  const fetchSettings = async () =>
    await connectorCall(
      "zscaler",
      "zscaler_settings_json",
      { cloud: determineCloud() },
      setSettings,
      setSettingsLoading,
      setSettingsError,
      setSettingsDuration
    );

  const resetLocationsAndCredentials = () => {
    fetchLocationsAndCredentials();
    fetchVips();
  };

  return (
    <ConnectorsContext.Provider
      value={{
        //-----------------------------------------------------------
        locationsAndCredentials,
        loading,
        error,
        fetchLocationsAndCredentials,
        duration,
        //-----------------------------------------------------------
        vips,
        vipsLoading,
        vipsError,
        vipsDuration,
        fetchVips,
        //-----------------------------------------------------------
        settings,
        settingsLoading,
        settingsError,
        settingsDuration,
        fetchSettings,
        // -----------------------------------------------------------
        resetLocationsAndCredentials,
        // -----------------------------------------------------------
      }}
    >
      {children}
    </ConnectorsContext.Provider>
  );
};

export const useConnectors = () => useContext(ConnectorsContext);

export default ConnectorsContext;
