import { Alert, Space, Typography, Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import { fetchData } from "../../actions/fetchData";
const { Title } = Typography;

const InventoryTables = ({
  site_id,
  devices,
  setDevices,
  bearers,
  setBearers,
}) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getInventoryDetails = async () => {
      setLoading(true);
      const params = { site_id: [site_id], raw: false };
      const response = await fetchData(
        "get_devices_bins_details_by_site_id",
        params
      );
      setData(response.data);
      setDevices(response.data?.devices);
      setBearers(response.data?.bearers);
      setError(response.error);
      setLoading(false);
    };
    if (!data && !error && !loading) getInventoryDetails(); // fetch if data is not set
  });

  const devices_attr = [
    "site_id",
    "node_id",
    "node_hostname",
    "node_type",
    "node_model",
    "node_serial_number",
    "node_mgmt_ip",
    "node_customer_ip",
    "node_location",
    "node_status",
  ];

  const devices_columns = devices_attr.map((item) => ({
    key: item,
    title: item,
    dataIndex: item,
  }));

  const bearers_attr = [
    "bearerInstanceSiteId",
    "bearerInstanceCustomerId",
    "bearerInstanceBearerIdentifier",
    "bearerInstanceId",
    "bearerInstanceLocationId",
    "bearerInstanceNetworkProduct",
    "bearer_bandwidth",
    "bearer_role",
    "bearer_status",
    "bearer_supply",
  ];

  const bearers_columns = bearers_attr.map((item) => ({
    key: item,
    title: item,
    // title: item.replace("bearerInstance", "bearer_"),
    dataIndex: item,
  }));

  const errorMessage = {
    emptyText: error ? <Alert message={error} type="error" /> : null,
  };

  return (
    <Space direction="vertical">
      <Table
        bordered
        dataSource={data?.devices}
        columns={devices_columns}
        loading={loading}
        size="small"
        title={() => <Title level={4}>Devices</Title>}
        pagination={false}
        locale={errorMessage}
      />
      <Table
        bordered
        dataSource={data?.bearers}
        columns={bearers_columns}
        loading={loading}
        size="small"
        title={() => <Title level={4}>Bearers</Title>}
        pagination={false}
        locale={errorMessage}
      />
    </Space>
  );
};

export default InventoryTables;

// export interface InvetoryDetails {
//   bearers: Bearer[];
//   devices: Device[];
// }

// export interface Bearer {
//   bearerInstanceBearerIdentifier: string;
//   bearerInstanceCustomerId: string;
//   bearerInstanceId: string;
//   bearerInstanceLocationId: string;
//   bearerInstanceNetworkProduct: {
//     id: string;
//     name: string;
//   };
//   bearerInstanceSiteId: string;
//   bearer_bandwidth: string;
//   bearer_role: string;
//   bearer_status: string;
//   bearer_supply: string;
// }

// export interface Device {
//   node_customer_ip: string;
//   node_hostname: string;
//   node_id: string;
//   node_location: any;
//   node_mgmt_ip: string;
//   node_model: string;
//   node_serial_number: string;
//   node_status: string;
//   node_type: any;
//   site_id: string;
// }
