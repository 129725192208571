import React, { FC, useState, useEffect } from "react";
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Space,
  Statistic,
  Table,
  Tag,
  Typography,
  theme,
} from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs";

import type { Dayjs } from "dayjs";
import axios from "axios";
import { useUser } from "../../contexts/UserContext";
import { TemplateStatsType, UserStatsType } from "../../types/StatisticsType";
import { ColumnsType } from "antd/es/table";
import { ChildrenProps } from "../../types/ChildrenReactType";

import { Alert } from "@mui/material";
import ColumnChart from "./Charts/ColumnChart";
import BarChart from "./Charts/BarChart";
import { exportAsExcel } from "../../utils/exportAsExcel";
import { StatsType, User } from "./interfaces";

dayjs.extend(customParseFormat);

const { Title, Text } = Typography;

export default function Statistics() {
  const user = useUser();
  const access_token = user?.user?.access_token;

  const userStored = localStorage.getItem("user");
  const parsedUser = userStored ? JSON.parse(userStored) : null;
  const scopes = JSON.parse(parsedUser?.scopes);

  // Range Picker -- Start
  const { RangePicker } = DatePicker;

  const defaultStartDate = dayjs().subtract(30, "d");
  const defaultEndDate = dayjs();
  const defaultTitle = `Last 30 Days (${defaultStartDate.format(
    "DD/MM/YYYY"
  )} to ${defaultEndDate.format("DD/MM/YYYY")})`;

  // Default set to last 12 months
  const [title, setTitle] = useState(defaultTitle);
  const [startDate, setStartDate] = useState<any>(
    defaultStartDate.format("YYYY-M-D")
  );
  const [endDate, setEndDate] = useState<any>(
    defaultEndDate.format("YYYY-M-D")
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [stats, setStats] = useState<StatsType | undefined>(undefined);
  const [error, setError] = useState<any>(undefined);

  type PresetType = {
    label: string;
    value: [Dayjs, Dayjs];
  };

  // https://day.js.org/docs/en/manipulate/start-of
  const rangePresets: PresetType[] = [
    { label: "This Month", value: [dayjs().startOf("M"), dayjs()] },
    {
      label: "Last Month",
      value: [
        dayjs().startOf("M").subtract(1, "M"),
        dayjs().endOf("M").subtract(1, "M"),
      ],
    },
    { label: "Last 7 Days", value: [dayjs().subtract(7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().subtract(14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().subtract(30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().subtract(90, "d"), dayjs()] },
    { label: "Last 12 Months", value: [dayjs().subtract(1, "y"), dayjs()] },
  ];

  const onRangeChange = (dates: null | (Dayjs | null)[]) => {
    if (dates) {
      setStartDate(dates[0].format("YYYY-M-D"));
      setEndDate(dates[1].format("YYYY-M-D"));

      if (dates[1].format("YYYY-M-D") === dayjs().format("YYYY-M-D")) {
        rangePresets.every((range: PresetType) => {
          if (
            range.value[0].format("YYYY-M-D") === dates[0].format("YYYY-M-D")
          ) {
            setTitle(
              `${range.label} (${dates[0].format(
                "DD/MM/YYYY"
              )} to ${dates[1].format("DD/MM/YYYY")})`
            );
            return false;
          }
          return true;
        });
      } else
        setTitle(
          `From ${dates[0].format("DD/MM/YYYY")} To ${dates[1].format(
            "DD/MM/YYYY"
          )}`
        );
    } else {
      console.log("Clear");
    }
  };

  // Range Picker -- End,

  const getStatistics = async () => {
    console.log("getting_stats");
    setStats(undefined);
    setLoading(true);
    try {
      const response = await axios.get("/api/v1/stats/analysis", {
        headers: {
          Authorization: "Bearer " + access_token,
          Accept: "application/json",
        },
        params: {
          start: startDate,
          end: endDate,
        },
      });
      const data = response.data;
      console.log({ data });
      setStats(data);
    } catch (err) {
      if (!axios.isCancel(err)) {
        setError(err);
        console.log({ err });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let unmounted = false;
    if (startDate && endDate && !unmounted) getStatistics();
    return () => {
      unmounted = true;
    };
  }, [startDate, endDate]);

  const ContentWrapper2: React.FC<ChildrenProps> = ({ children }) => {
    const { token } = theme.useToken();
    const contentStyle: React.CSSProperties = {
      color: token.colorTextTertiary,
      backgroundColor: "#E8E8E8",
      padding: "1.5em 2rem 2rem 2rem",
      borderRadius: token.borderRadiusLG,
      border: `1px solid ${token.colorBorder}`,
      width: "92vw",
    };
    return <div style={contentStyle}>{children}</div>;
  };

  function convertLastLogin(users) {
    users.forEach((user: any) => {
      // Convert the last_login timestamp to a Date object
      let date = new Date(user.last_login * 1000); // Multiply by 1000 to convert seconds to milliseconds
      // Format the date to DD/MM/YYYY
      let formattedDate = date.toLocaleDateString("en-GB"); // 'en-GB' for DD/MM/YYYY format
      // Update the last_login field with the formatted date
      user.last_login = formattedDate;
    });
    return users;
  }

  function filterUsersByUsername(users, validUsernames) {
    return users.filter((user: any) => validUsernames.includes(user.username));
  }

  const [loadingExcel, setLoadingExcel] = useState(false);
  const exportToExcel = async () => {
    const documents_generated = stats?.reports.templating;
    const tools_usage = stats?.reports.tools;
    const all_users = convertLastLogin(stats?.reports.users);
    const period_users = filterUsersByUsername(
      all_users,
      stats?.analysis.overall.users.ein
    );

    const rangeDate = [
      { data_range: `ngDS Stats collect from ${startDate} to ${endDate}` },
    ];
    setLoadingExcel(true);
    const payload = {
      cover: rangeDate,
      documents_generated,
      tools_usage,
      period_users,
      all_users,
    };

    const isCreated = await exportAsExcel(payload);
    setLoadingExcel(false);
    isCreated ? console.log("File Downloaded") : console.log("Failed Download");
  };

  return (
    <>
      {scopes?.includes("admin") || scopes?.includes("reporter") ? (
        <>
          <Space
            direction="vertical"
            style={{ width: "100%", alignItems: "center" }}
            size="large"
          >
            <Title style={{ marginBottom: 10 }}>ngDS Stats Dashboard</Title>

            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Text>Select the time range:</Text>
              <RangePicker presets={rangePresets} onChange={onRangeChange} />
            </Space>

            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Title level={3}>{title}</Title>

              <div
                style={{
                  float: "right",
                }}
              >
                <Button
                  size="small"
                  onClick={() => exportToExcel()}
                  loading={loadingExcel}
                  disabled={stats === undefined}
                >
                  Download Stats Data
                </Button>
              </div>
            </Space>

            {loading && <p>Loading...</p>}
            {stats && !loading && (
              <Space
                direction="vertical"
                style={{ width: "100%", alignItems: "flex-start" }}
                size="large"
              >
                {/* OVERALL */}
                <ContentWrapper2>
                  <OverallStats stats={stats} />
                </ContentWrapper2>

                {/* TEMPLATING */}
                <ContentWrapper2>
                  <TemplatingStats stats={stats} />
                </ContentWrapper2>

                {/* API CONNECTORS */}
                <ContentWrapper2>
                  <ConnectorStats stats={stats} />
                </ContentWrapper2>

                {/* PARSERS */}
                <ContentWrapper2>
                  <ParserStats stats={stats} />
                </ContentWrapper2>
              </Space>
            )}
          </Space>
        </>
      ) : (
        <Alert style={{ marginTop: 16 }} severity="error">
          You do not have the necessary permissions to view this page.
          <br />
          Please ensure you have the ‘reporter’ scope to proceed.
        </Alert>
      )}
    </>
  );
}

const StatsCard = ({ title, value }) => {
  const cardStyle = { width: 180, height: 120 };
  return (
    <Card style={cardStyle}>
      <Statistic
        title={title}
        value={value}
        valueStyle={{
          color: "#3f8600",
        }}
      />
    </Card>
  );
};

// OVERALL STATS
export const OverallStats = ({ stats }) => {
  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Title level={4}>Overall Usage:</Title>
      <Space size="large">
        <StatsCard
          title="Active Unique Users"
          value={stats?.analysis.overall.users.count}
        />

        <StatsCard
          title="Accounts"
          value={`+ ${stats?.analysis.overall.accounts.count}`}
        />

        <StatsCard
          title="Documents"
          value={stats?.analysis.templates.documents.count}
        />

        <StatsCard
          title="API Connector Users"
          value={stats?.analysis.connectors.overall.users.count}
        />

        <StatsCard
          title="Parser Users"
          value={stats?.analysis.parsers.overall.users.count}
        />
      </Space>
    </Space>
  );
};

// TEMPLATING STATS
export const TemplatingStats = ({ stats }) => {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (stats?.reports.templating) {
      setDataSource(stats?.reports.templating);
    }
  }, [stats]);

  const account_documents =
    stats?.analysis.templates.documents_per_account || [];
  const documents_per_account_data = Object.keys(account_documents)
    .map((key) => ({ account: key, quantity: account_documents[key] }))
    .sort((a, b) => b.quantity - a.quantity); // Sort in descending order

  const users_documents = stats?.analysis.templates.documents_per_user || [];
  const documents_per_user_data = Object.keys(users_documents)
    .map((key) => ({ user: key, quantity: users_documents[key] }))
    .sort((a, b) => b.quantity - a.quantity); // Sort in descending

  function getUserName(username: number, users: User[]): string | undefined {
    const user = users.find((user) => user.username === username);
    return user ? `${user.first_name} ${user.last_name}` : `${username}`;
  }

  const userFilter = Array.from(
    new Set(dataSource.map((item: any) => item.generated_by))
  )
    .map((generated_by) => ({
      text: getUserName(generated_by, stats?.reports.users || []),
      value: generated_by,
    }))
    .sort((a, b) => a.text.localeCompare(b.text));

  const accountFilter = Array.from(
    new Set(dataSource.map((item: any) => item.account))
  )

    .map((account) => ({
      text: account ? account : "Ommited",
      value: account ? account : null,
    }))
    .sort((a, b) => a.text.localeCompare(b.text));

  const columns = [
    {
      key: "generated_by",
      title: "generated_by",
      dataIndex: "generated_by",
      align: "center",
      render: (username: number) => {
        return getUserName(username, stats?.reports.users || []);
      },
      filters: userFilter,
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value: string, record: any) => record.generated_by === value,
    },
    {
      key: "account",
      title: "account",
      dataIndex: "account",
      align: "center",
      filters: accountFilter,
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value: string, record: any) =>
        value ? record.account === value : !record.account,
    },
    {
      key: "template_name",
      title: "template_name",
      dataIndex: "template_name",
      align: "center",
    },
    {
      key: "document_name",
      title: "document_name",
      dataIndex: "document_name",
      align: "center",
    },
    {
      key: "quantity",
      title: "quantity",
      dataIndex: "quantity",
      align: "center",
    },
    {
      key: "generated_time",
      title: "generated_time",
      dataIndex: "generated_time",
      align: "center",
    },
    {
      key: "time_taken",
      title: "time_taken",
      dataIndex: "time_taken",
      align: "center",
    },
  ];

  return (
    <>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Title level={4}>Templating Usage:</Title>
        <Space size="large" wrap>
          <StatsCard
            title="Users"
            value={stats?.analysis.templates.users.count}
          />

          <StatsCard
            title="Accounts"
            value={`+ ${stats?.analysis.templates.accounts.count}`}
          />

          <StatsCard
            title="Templates"
            value={stats?.analysis.templates.templates.count}
          />

          <StatsCard
            title="Generated Docs"
            value={stats?.analysis.templates.documents.count}
          />
        </Space>
        <Divider />
        <ColumnChart
          data={documents_per_account_data}
          xField={"account"}
          yField={"quantity"}
          title="# Documents by Account"
        />
        <Divider />
        <BarChart
          data={documents_per_user_data}
          yField={"user"}
          xField={"quantity"}
          title="# Documents by User"
        />
      </Space>

      <Divider />

      <Title level={5}>Documents Records:</Title>
      {/* Table */}
      <Space direction="vertical" size="small">
        <Table
          // onChange={handleChange}
          tableLayout="fixed"
          rowKey={(record) => `${record.document_name}${record.generated_time}`}
          // size='small'
          pagination={{ hideOnSinglePage: true }}
          columns={columns}
          dataSource={dataSource}
        />
      </Space>
    </>
  );
};

// CONNECTORS STATS
export const ConnectorStats = ({ stats }) => {
  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Title level={4}>API Connectors Usage:</Title>
      <Space size="large">
        <StatsCard
          title="Users"
          value={stats?.analysis.connectors.overall.users.count}
        />

        <StatsCard
          title="Accounts"
          value={`+ ${stats?.analysis.connectors.overall.accounts.count}`}
        />

        <StatsCard
          title="Iterations"
          value={`~ ${stats?.analysis.connectors.overall.iterations}`}
        />
      </Space>
      <Space size="large">
        <StatsCard
          title="Zscaler Users"
          value={stats?.analysis.connectors.zscaler.users.count}
        />

        <StatsCard
          title="Palo Alto Users"
          value={stats?.analysis.connectors.palo_alto.users.count}
        />

        <StatsCard
          title="Meraki Users"
          value={stats?.analysis.connectors.meraki.users.count}
        />

        <StatsCard
          title="BFG Users"
          value={stats?.analysis.connectors.bfg.users.count}
        />
      </Space>
      <Title level={5}>Connector Users:</Title>
      <Space size={[0, 8]} wrap>
        {stats?.analysis.connectors.overall.users.names
          ?.sort((a: string, b: string) => a.localeCompare(b))
          .map((name: string) => (
            <Tag key={name} bordered={false} color="geekblue">
              {name}
            </Tag>
          ))}
      </Space>
      <Title level={5}>Connector Accounts:</Title>
      <Space size={[0, 8]} wrap>
        {stats?.analysis.connectors.overall.accounts.names
          ?.sort((a: string, b: string) => a.localeCompare(b))
          .map((name: string) => (
            <Tag key={name} bordered={false} color="purple">
              {name}
            </Tag>
          ))}
      </Space>
    </Space>
  );
};

// PARSER STATS
export const ParserStats = ({ stats }) => {
  return (
    <>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Title level={4}>Parsers Usage:</Title>
        <Space size="large">
          <StatsCard
            title="Users"
            value={stats?.analysis.parsers.overall.users.count}
          />

          <StatsCard
            title="Accounts"
            value={`+ ${stats?.analysis.parsers.overall.accounts.count}`}
          />

          <StatsCard
            title="Iterations"
            value={stats?.analysis.parsers.overall.iterations}
          />
        </Space>

        <Space size="large">
          <StatsCard
            title="Config Users"
            value={stats?.analysis.parsers.configs.users.count}
          />

          <StatsCard
            title="CnGo File Users"
            value={stats?.analysis.parsers.cngo.users.count}
          />

          <StatsCard
            title="Ionix Email Users"
            value={stats?.analysis.parsers.ionix.users.count}
          />
        </Space>
        <Title level={5}>Parser Users:</Title>
        <Space size={[0, 8]} wrap>
          {stats?.analysis.parsers.overall.users.names
            ?.sort((a: string, b: string) => a.localeCompare(b))
            .map((name: string) => (
              <Tag key={name} bordered={false} color="geekblue">
                {name}
              </Tag>
            ))}
        </Space>
        <Title level={5}>Parser Accounts:</Title>
        <Space size={[0, 8]} wrap>
          {stats?.analysis.parsers.overall.accounts.names
            ?.sort((a: string, b: string) => a.localeCompare(b))
            .map((name: string) => (
              <Tag key={name} bordered={false} color="purple">
                {name}
              </Tag>
            ))}
        </Space>
      </Space>
    </>
  );
};
