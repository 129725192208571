import { axiosFetch } from "../library/fetchRequests";
import { useEffect, useState } from "react";
import Setup from "../library/setup.json";
import portMapping from "../library/portMapping";

import { Chip } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import { Alert } from "@mui/material";

import { handleHttpError } from "../../../common/utils.js";

const LegacyTable = ({
  accountId,
  templatesList,
  rolloutSites,
  setRolloutSites,
  siteProps,
}) => {
  const [hasCommands, setHasCommands] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState();
  const [hasInventory, setHasInventory] = useState(true);
  const [runProcessing, setRunProcessing] = useState(false);
  const [hasInterfaces, setHasInterfaces] = useState(false);

  const commandsRequired = templatesList
    .filter((item) => item.autodata)
    .map((item) => item.autodata.commands_required)
    .flat();

  const hostnames = rolloutSites
    .filter((site) => site.name === siteProps.name)
    .map((item) => item.legacy)
    .flat()
    .map((item) => item.hostname);

  useEffect(() => {
    const hostnameList = hostnames.filter((hostname) => hostname !== null);
    if (hostnameList.length > 0) {
      axiosFetch
        .get(
          `autodata/commands?account_id=${accountId}&commands=${commandsRequired}&hostnames=${hostnameList}`
        )
        .then((response) => {
          const data = response.data;
          const dataInterfaces = data.interfaces_status;

          dataInterfaces && setHasInterfaces(true);

          setRolloutSites((prevState) => {
            return prevState.map((site) =>
              site.name === siteProps.name
                ? {
                    ...site,
                    show_interfaces_status: dataInterfaces,
                    legacy: site.legacy.map((device) => ({
                      ...device,
                      // Adds the estimated number of ports of the switch based in the count
                      ports:
                        Math.floor(
                          dataInterfaces.filter(
                            (port) => port.hostname === device.hostname
                          ).length / 24
                        ) * 24,
                    })),
                  }
                : site
            );
          });
          setIsLoading(false);
          setHasCommands(true);
          setRunProcessing(true);
        })
        .catch((err) => {
          setError(err);
          setHasError(true);
          setIsLoading(false);
        });
    } else {
      setRolloutSites((prevState) => {
        return prevState.map((site) =>
          site.name === siteProps.name
            ? {
                ...site,
                show_interfaces_status: [],
                legacy: [{ hostname: null, ports: 0 }],
              }
            : site
        );
      });
      setHasInventory(false);
      setRunProcessing(true);
    }
  }, []);

  // The actual Mapping
  useEffect(() => {
    if (runProcessing) {
      // Here is the actuall portMapping Processing
      setRolloutSites((prevState) => portMapping(prevState));
    }
  }, [runProcessing]);

  const x = isLoading ? (
    <p>Fetching...</p>
  ) : (
    <p style={{ color: "red" }}>Interfaces Status Unavailable</p>
  );

  return (
    <div style={{ padding: "1rem" }}>
      {hasInventory ? (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left" colSpan={3}>
                  <h4>Legacy Access Swicthes Output</h4>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">
                  <strong>Hostname</strong>
                </TableCell>
                <TableCell>
                  <strong>Command Status</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rolloutSites
                .filter((site) => site.name === siteProps.name)
                .map((item) => item.legacy)
                .flat()
                .map((row) => (
                  <TableRow key={row.hostname}>
                    <TableCell align="right">{row.hostname}</TableCell>
                    <TableCell align="left">
                      {rolloutSites
                        .filter((site) => site.name === siteProps.name)
                        .map((item) => item.show_interfaces_status)
                        .flat().length > 1 ? (
                        <p style={{ color: "green" }}>Completed</p>
                      ) : (
                        x
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <p>
          No existing access switches have been found on inventory...
          <br /> If you continue it will generate a file with new devices info
          only.
        </p>
      )}
    </div>
  );
};

function GetInventory({
  siteProps,
  accountId,
  templatesList,
  rolloutSites,
  setRolloutSites,
}) {
  const legacyIdentifier = Setup.PortMapping.legacySwitches;

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    axiosFetch
      .get(`autodata/sites/${siteProps.id}/inventory`)
      .then((response) => {
        const data = response.data;
        setRolloutSites((prevState) => {
          return prevState.map((item) =>
            item.name == siteProps.name
              ? {
                  ...item,
                  legacy: data.devices
                    .filter((device) =>
                      device.hostname?.includes(legacyIdentifier)
                    )
                    .sort((a, b) => a.hostname.localeCompare(b.hostname)),
                }
              : item
          );
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setHasError(true);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="messages">
        <p>Fetching inventory...</p>
      </div>
    );
  } else if (hasError) {
    return (
      <Alert variant="outlined" severity="error">
        Something went wrong while fetching the data...
      </Alert>
    );
  } else {
    return (
      <LegacyTable
        siteProps={siteProps}
        templatesList={templatesList}
        accountId={accountId}
        rolloutSites={rolloutSites}
        setRolloutSites={setRolloutSites}
      />
    );
  }
}

export default function PortMappingProcessing({
  accountId,
  rolloutSites,
  setRolloutSites,
  templatesList,
}) {
  return (
    <div className="backBox">
      {rolloutSites.map((site) => (
        <>
          <div key={site.name} className="dashedBorderWhite">
            <div className="chip">
              <Chip label={site.name} color="primary" variant="outlined" />
              <Chip label={site.type} variant="outlined" />
            </div>
            <GetInventory
              key={site.id}
              siteProps={site}
              templatesList={templatesList}
              accountId={accountId}
              rolloutSites={rolloutSites}
              setRolloutSites={setRolloutSites}
            />
          </div>
        </>
      ))}
    </div>
  );
}
