import React from "react";

import { UserContextProvider } from "./UserContext";
import { SessionContextProvider } from "./SessionContext";
import { ChildrenProps } from "../types/ChildrenReactType";
import { ConnectorsContextProvider } from "./Connectors/ZscalerContext";
import { MerakiConnectorsContextProvider } from "./Connectors/MerakiContext";
import { TablesContextProvider } from "./TablesContext";
import { BFGConnectorsContextProvider } from "./Connectors/BFGContext";
import { PaloAltoContextProvider } from "./Connectors/PaloAltoContext";
import { GitLabConnectorsContextProvider } from "./Connectors/GitLabContext";

const GlobalContext: React.FC<ChildrenProps> = ({ children }) => {
  return (
    <>
      <UserContextProvider>
        <SessionContextProvider>
          <TablesContextProvider>
            <ConnectorsContextProvider>
              <MerakiConnectorsContextProvider>
                <BFGConnectorsContextProvider>
                <PaloAltoContextProvider>
                  <GitLabConnectorsContextProvider>
                    {children}
                  </GitLabConnectorsContextProvider>
                </PaloAltoContextProvider>
                </BFGConnectorsContextProvider>
              </MerakiConnectorsContextProvider>
            </ConnectorsContextProvider>
          </TablesContextProvider>
        </SessionContextProvider>
      </UserContextProvider>
      ;
    </>
  );
};

export default GlobalContext;
