// react dependencies
import { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

// import local library
import multipleDocuments from "./library/multipleDocuments";

//Material UI Component for Steppers
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { Container, Alert } from "@mui/material";

// toastr dependencies
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Cookies
import Cookies from "universal-cookie";

// Views
import AccountHeader from "./MerakiRollOutViews/AccountHeader";
import TemplatesOptions from "./MerakiRollOutViews/TemplatesOptions";
import SiteList from "./MerakiRollOutViews/SiteList";
import BomBuilder from "./MerakiRollOutViews/BomBuilder";
import BomDataProcessing from "./MerakiRollOutViews/BomDataProcessing";
import PortMappingProcessing from "./MerakiRollOutViews/PortMappingProcessing";

export default function MerakiRollOutPage() {
  const cookies = new Cookies();
  const [rolloutSites, setRolloutSites] = useState([]);
  const [templatesList, setTemplatesList] = useState([]);
  const [sitesList, setSitesList] = useState([]);
  const [sitesListSelected, setSitesListSelected] = useState([]);
  const [sitesTypes, setSitesTypes] = useState([]);

  const account = {
    name: cookies.get("account_name"),
    id: cookies.get("account_id"),
  };

  // Steps active
  const [activeStep, setActiveStep] = useState(0);

  // Button State
  const [isNextEnabled, setIsNextEnabled] = useState(true); //next button control

  // This effect will handle the next button - disable/enable
  useEffect(() => {
    switch (activeStep) {
      case 0:
        const isTemplateSelected = templatesList
          .map((item) => item.checked)
          .some((item) => item === true);
        return setIsNextEnabled(isTemplateSelected);
      case 1:
        const isSitesSelected = () => {
          if (
            sitesListSelected.length !== 1 &&
            templatesList
              .filter((item) => item.checked)
              .some((item) => item.name.includes("Port Mapping"))
          ) {
            return false;
          } else if (sitesListSelected.length > 0) {
            return true;
          }
          return false;
        };
        return setIsNextEnabled(isSitesSelected());
      case 2:
        const isRolloutSites =
          rolloutSites.filter((site) => site.type.includes("Select")).length ===
          0;
        return setIsNextEnabled(isRolloutSites);
      case 3:
        const isBomDataProcessed = rolloutSites.some((item) => item.devices);
        return setIsNextEnabled(isBomDataProcessed);
      case 4:
        const isPortsProcessed = rolloutSites.some(
          (item) => item.show_interfaces_status
        );
        return setIsNextEnabled(isPortsProcessed);
      default:
        return setIsNextEnabled(false);
    }
  }, [activeStep, templatesList, sitesListSelected, rolloutSites]);

  const createDocuments = () => {
    const documents_ids = templatesList
      .filter((template) => template.checked)
      .map((template) => template.id);

    const context = { sites: rolloutSites };

    // For Debugging Purposes
    // console.log({
    //   documents_ids,
    //   context,
    // });

    multipleDocuments(documents_ids, context);
  };
  // Stepper handlers
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // Header name of steps

  const steps = [
    "Select Templates",
    "Select Sites",
    "Select Products",
    "Devices Setup",
  ];

  if (
    templatesList.filter(
      (item) => item.checked === true && item.autodata != null
    ).length > 0
  ) {
    steps.push("Data Gathering");
  }

  // switch for steps render return
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <TemplatesOptions
            templatesList={templatesList}
            setTemplatesList={setTemplatesList}
          />
        );
      case 1:
        return (
          <SiteList
            accountId={account.id}
            sitesList={sitesList}
            setSitesList={setSitesList}
            sitesListSelected={sitesListSelected}
            setSitesListSelected={setSitesListSelected}
            rolloutSites={rolloutSites}
            setRolloutSites={setRolloutSites}
            templatesList={templatesList}
          />
        );
      case 2:
        return (
          <BomBuilder
            accountId={account.id}
            sitesTypes={sitesTypes}
            setSitesTypes={setSitesTypes}
            rolloutSites={rolloutSites}
            setRolloutSites={setRolloutSites}
          />
        );
      case 3:
        if (rolloutSites.map((site) => site.id)) {
          return (
            <BomDataProcessing
              accountId={account.id}
              rolloutSites={rolloutSites}
              setRolloutSites={setRolloutSites}
            />
          );
        }
        return "No sites selected yet";
      case 4:
        return (
          <PortMappingProcessing
            accountId={account.id}
            rolloutSites={rolloutSites}
            setRolloutSites={setRolloutSites}
            templatesList={templatesList}
          />
        );
      default:
        return "Unknown step";
    }
  }

  return (
    <Container>
      <Header />
      <div className="rootContainer">
        <h1 className="colorIndigo">Meraki Roll Out</h1>
        <AccountHeader accountName={account.name} />
        {account.name && account.name != "None" ? (
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>
                  <h3
                    className={
                      activeStep === index ? "colorIndigo" : "colorGrey"
                    }
                  >
                    {label}
                  </h3>
                </StepLabel>
                <StepContent
                // TransitionProps={{ unmountOnExit: false, mountOnEnter: true }}
                >
                  <Typography>{getStepContent(index)}</Typography>
                  <div className="actionsContainer">
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className="buttonStepper"
                    >
                      Back
                    </Button>
                    <Button
                      disabled={!isNextEnabled}
                      variant="contained"
                      color="primary"
                      onClick={
                        activeStep === steps.length - 1
                          ? createDocuments
                          : handleNext
                      }
                      className="buttonStepper"
                    >
                      {activeStep === steps.length - 1 ? "Generate" : "Next"}
                    </Button>
                    {activeStep === steps.length - 1 && (
                      <Button onClick={handleReset} className="buttonStepper">
                        Reset
                      </Button>
                    )}
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        ) : (
          <Alert
            variant="outlined"
            severity="warning"
            className="noAccountWarning"
          >
            Please select an account in the dropdown menu from the top bar
          </Alert>
        )}
      </div>
      <Footer />
      <ToastContainer />
    </Container>
  );
}
