import {
  Button,
  Dropdown,
  Input,
  MenuProps,
  Popconfirm,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { FC, useCallback, useEffect, useState } from "react";
import {
  ZscalerLocationType,
  VpnCredentialType,
  ZscalerSettingType,
} from "../../../../../types/ConnectorsType";
import { useConnectors } from "../../../../../contexts/Connectors/ZscalerContext";
import {
  EditOutlined,
  DeleteOutlined,
  ApiOutlined,
  UnorderedListOutlined,
  ReloadOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import React from "react";
import axios from "axios";

const { Text } = Typography;

const ChangePSK = ({
  vpn_credential_id,
  comments,
  rfc,
  fqdn,
  currentPSK,
  isZscalerReadOnly,
}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const access_token = user.access_token;
  const currentAccount = JSON.parse(localStorage.getItem("currentAccount"));
  const account_id = currentAccount.id;

  const connectors = useConnectors();
  const [isLoading, settingsLoading] = useState(false);
  const [isError, setIsError] = useState(undefined);

  const updatePSK = async () => {
    console.log("Updating Credential PSK");
    settingsLoading(true);

    console.log({
      vpn_credential_id,
      comments,
      rfc,
    });

    await axios
      .post(
        "/api/v1/api_connectors",
        {
          account_id: +account_id,
          connector: "zscaler",
          action: "update_vpn_credential_psk",
          params: { vpn_credential_id: +vpn_credential_id, comments, rfc },
        },
        { headers: { Authorization: "Bearer " + access_token } }
      )
      .then((resp: any) => {
        const status = resp.status;
        const data = resp.data;
        console.log({
          status,
          data,
        });
        if (status === 200 && data.psk !== currentPSK) {
          message.success("PSK Updated");
        } else {
          console.log({ something_went_wrong: data });
          message.error(
            `Something went wrong. Please try again! Received Status: ${status}`
          );
        }
        console.log({ status, response_psk_update: resp.data });
      })
      .catch((error: any) => {
        console.log({ error_update_vpn_psk: error });
        setIsError(error);
        message.error("Something went wrong. Please try again!");
      })
      .finally(() => {
        settingsLoading(false);
        // connectors?.fetchLocations()
        // connectors?.fetchVpnCredentials()
        connectors?.fetchLocationsAndCredentials();
      });
  };

  return (
    <Tooltip placement="right" title={isZscalerReadOnly ? "" : "Change PSK"}>
      <Popconfirm
        disabled={isZscalerReadOnly}
        icon={null}
        title={<Text style={{ color: "red" }}>Change PSK</Text>}
        description={
          <Text>
            {`Are you sure you want to change the PSK `} <br /> for{" "}
            <strong>{fqdn}</strong> ?
          </Text>
        }
        onConfirm={updatePSK}
        okButtonProps={{ loading: isLoading }}
        okText="Yes"
        cancelText="No"
      >
        <ReloadOutlined style={{ color: "lightgrey" }} />
      </Popconfirm>
    </Tooltip>
  );
};

export default ChangePSK;
