import React from "react";
import { Button, Form, Input, Typography } from "antd";
import { usePaloAltoConnectors } from "../../../../contexts/Connectors/PaloAltoContext";

const ApiForm = () => {
  const paloAlto = usePaloAltoConnectors();
  const paloAltoConnect = paloAlto.paloAltoConnect;
  const loadingTenantData = paloAlto.loadingTenantData;

  const [form] = Form.useForm<{ apiKey: string }>();
  const apiKeyInput = Form.useWatch("apiKey", form);
  const onFinish = async (values: any) => {
    await paloAltoConnect(values.apiKey);
  };

  return (
    <Form
      form={form}
      name="connect"
      onFinish={onFinish}
      autoComplete="off"
      layout={"inline"}
    >
      <Form.Item name="apiKey">
        <Input.Password
          placeholder="Enter your Palo Alto API Key"
          style={{ width: 400 }}
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={!apiKeyInput}
          loading={loadingTenantData}
        >
          {loadingTenantData ? "Connecting" : "Connect"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ApiForm;
