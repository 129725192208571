import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Descriptions,
  Divider,
  Drawer,
  Form,
  Space,
  Tag,
  Typography,
} from "antd";
import { useConnectors } from "../../../../../contexts/Connectors/ZscalerContext";

// Forms
// import NewVpnCredentialsForm from "../../Forms/NewVpnCredentialForm";
import {
  VpnCredentialType,
  ZscalerLocationType,
} from "../../../../../types/ConnectorsType";
import { PlusOutlined } from "@ant-design/icons";
import { NgdsErrorType } from "../../../../../types/NgdsErrorType";

// Fetch Functions
import {
  updateLocationVpnCredentials,
  vpnCreate,
} from "../../functions/fetchZscaler";
import { LoadingProgress } from "../../LoadingProgress";
import ResultOk from "../../Results/ResultOk";
import ResultError from "../../Results/ResultError";
import NewMultipleCredentialsForm from "../../Forms/NewMultipleCredentialsForm";

const { Text } = Typography;

const CreateVPN: React.FC<{ location?: ZscalerLocationType }> = ({
  location,
}) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const [vpnNew, setVpnNew] = useState<VpnCredentialType | undefined>(
    undefined
  );

  const [vpnAssociateResponse, setVpnAssociateResponse] = useState<
    any | undefined
  >(undefined);

  const [vpnNewLoading, setVpnNewLoading] = useState<boolean>(false);
  const [vpnNewError, setVpnNewError] = useState<NgdsErrorType | undefined>(
    undefined
  );

  const [isVPNExists, setIsVPNExists] = useState<boolean>(false);

  const [isCreatedOk, setIsCreatedOk] = useState<boolean>(false);

  const [isAssignedOk, setIsAssignedOk] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  // Location Update
  const [modifyLocationLoading, setModifyLocationLoading] =
    useState<boolean>(false);
  const [modifyLocationError, setModifyLocationError] = useState<
    NgdsErrorType | undefined
  >(undefined);

  const connectors = useConnectors();

  const showDrawer = () => setOpen(true);

  const onClose = () => {
    setOpen(false);
    // onReset();

    // Hard Reload
    if (isCreatedOk) {
      connectors?.fetchLocationsAndCredentials();
    }
  };

  const submitVPN = async (location_id: string) => {
    setSubmitting(true);
    // setSubmittable(false);

    console.log("Creating New VPN Credentials");

    const evaluate = evaluteExistence();
    if (evaluate) {
      setIsVPNExists(true);
      return;
    }

    const vpnParams = form.getFieldsValue();
    setVpnNewLoading(true);
    const vpnResponse = await vpnCreate(vpnParams);
    setVpnNewLoading(false);

    if (vpnResponse?.hasOwnProperty("error"))
      setVpnNewError({ ...vpnResponse.error });
    else if (vpnResponse.status !== 200)
      setVpnNewError({ error: "Something whent wrong" });
    else {
      setVpnNew(vpnResponse.data);
      setIsCreatedOk(true);

      console.log({ response_vpn_create: vpnResponse.data });
      console.log({ location_id_association: location_id });

      const currentVPNs = location?.vpnCredentials.map((currentVpn: any) => ({
        id: currentVpn.id,
        type: "UFQDN",
      }));
      const newVPNs = vpnResponse.data.map((newVpn: any) => ({
        id: newVpn.id,
        type: "UFQDN",
      }));

      if (location_id && vpnResponse.data) {
        console.log("Assigning VPNs to Location");
        const payload = {
          vpnCredentials: [...currentVPNs, ...newVPNs],
        };

        const params = {
          location_id,
          payload,
        };

        console.log({ params_vpn_association: params });

        setModifyLocationLoading(true);
        const response = await updateLocationVpnCredentials(params);
        setModifyLocationLoading(false);

        if (response?.hasOwnProperty("error"))
          setModifyLocationError({ ...response.error });
        else if (response.status !== 200)
          setModifyLocationError({ error: "Something whent wrong" });
        else {
          setVpnAssociateResponse(response.data);
          setIsAssignedOk(true);
        }
      }
    }
  };

  const progressParams = {
    descriptors: [
      {
        key: "1",
        label: "FQDN",
        value: form
          .getFieldsValue()
          .vpnCredentials?.map((item: any) => item.fqdn)
          .map((item: string) => <Tag>{item}</Tag>),
      },
      location && {
        key: "2",
        label: "Location",
        value: location.name,
      },
    ],
    progressors: [
      {
        key: "3",
        text: "Creating VPN Credentials",
        loading: vpnNewLoading,
        exception: vpnNewError,
        success: isCreatedOk,
        dependOn: true,
      },
      location && {
        key: "4",
        text: `Associating Credentials to ${location.name}`,
        loading: modifyLocationLoading,
        success: isAssignedOk,
        exception: modifyLocationError,
        dependOn: isCreatedOk,
      },
    ],
  };

  // fqdn Validation
  const fqdnList = connectors?.vpnCredentials?.map(
    (vpn: VpnCredentialType) => vpn.fqdn
  );

  const evaluteExistence = () => {
    return fqdnList?.some((item: any) =>
      form
        .getFieldsValue()
        .vpnCredentials.map((item: any) => item.fqdn)
        .includes(item)
    );
  };

  

  return (
    <>
      <Button primary icon={<PlusOutlined />} onClick={showDrawer}>
        Add New VPN Credential
      </Button>
      <Drawer
        destroyOnClose={true}
        title="New VPN Credential"
        maskClosable={!vpnNewLoading}
        placement="right"
        onClose={onClose}
        closable={!vpnNewLoading}
        open={open}
        width={450}
      >
        {/* Loading Progress */}
        {submitting ? (
          LoadingProgress(progressParams)
        ) : (
          <>
            {isVPNExists && (
              <>
                <Alert
                  showIcon
                  banner
                  message={`You have entered at least one FQDN which already exists`}
                  type="warning"
                />
              </>
            )}
            <NewMultipleCredentialsForm
              form={form}
              location={location}
              submitVPN={submitVPN}
            />
          </>
        )}

        {/* Results */}
        {isAssignedOk ? (
          <>
            <ResultOk
              title={`New VPN Credentials associated successfully`}
              subTitle={
                <Descriptions
                  size="small"
                  column={1}
                  bordered
                  style={{ marginTop: 32 }}
                >
                  <Descriptions.Item label="Location">
                    <Text> {location.name} </Text>
                  </Descriptions.Item>

                  {vpnNew?.map((vpn: any) => {
                    <>
                      <Text>
                        {`New VPN Credentials have been associated to ${location.name}`}
                      </Text>
                      <Descriptions.Item label="New IDs">
                        <Tag> {vpn.id} </Tag>
                      </Descriptions.Item>
                    </>;
                  })}
                </Descriptions>
              }
            />
          </>
        ) : (
          <>
            {isCreatedOk &&
              !isAssignedOk &&
              !vpnNewLoading &&
              !modifyLocationLoading && (
                <Alert
                  message="Warning"
                  description="VPN Credential created but it was not associated to the location "
                  type="warning"
                  showIcon
                />
              )}
            {vpnNewError && <ResultError errorPayload={vpnNewError} />}
            {modifyLocationError && (
              <ResultError errorPayload={modifyLocationError} />
            )}
          </>
        )}
      </Drawer>
    </>
  );
};

export default CreateVPN;
