import axios from "axios"

// Main Function
const callAPI = async (
    endpoint: string
): Promise<any> => {

    //  Get Localstorage Data
    const user = JSON.parse(localStorage.getItem('user'))
    const access_token = user?.access_token

    return await axios
        .get(endpoint, { headers: { Authorization: "Bearer " + access_token } })
        .then((response: any) => {
            const status = response.status
            const data = response.data
            console.log(`${status} - Success Response from ${endpoint}`)
            console.log({ response })
            return {
                status,
                data,
                response
            }
        })
        .catch((err: any) => {
            const error = err.response?.data
            const status = error?.status
            console.log(`${status} - Failed Response from ${endpoint}`)
            console.log({ err })
            return {
                status,
                error,
                response: err
            }
        })
}

// LIST LIBRARIES
export const listLibraries = async (): Promise<any> => { return await callAPI('/api/v1/libraries') }

// LIST LABELS
export const listLabels = async (): Promise<any> => { return await callAPI('/api/v1/labels') }

// LIST TEMPLATES
export const listTemplates = async (id: number, account_id?: number, labelId?: number): Promise<any> => {
    let endpoint = `/api/v1/templates?library_id=${id}`
    if (account_id) endpoint = `${endpoint}&account_id=${account_id}`
    if (labelId) endpoint = `${endpoint}&label_id=${labelId}`
    return await callAPI(endpoint)
}


