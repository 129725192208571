import React, { useEffect } from "react";
import { Space, Typography, message } from "antd";

import { ArrowsAltOutlined } from "@ant-design/icons";
import { usePaloAltoConnectors } from "../../../../../contexts/Connectors/PaloAltoContext";
import { ColumnsType } from "antd/es/table";
import BaseTable from "../../../../../components/Tables/BaseTable";
import { dynamicSort } from "../../../../../utils/sorter";
import ShowRecordDetails from "../../../../../components/Tables/ShowRecordDetails";
import { TenantDataType } from "../../../../../types/PaloAltoConnectorsGetTenantData";

const Sites = ({ tenantData }: { tenantData: TenantDataType }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const paloAlto = usePaloAltoConnectors();
  const loadingTenantData = paloAlto.loadingTenantData;
  const errorTenantData = paloAlto.errorTenantData;
  const sites = paloAlto.tenantData?.get_sites;

  const columns: ColumnsType<any> = [
    {
      title: "Site ID",
      dataIndex: "id",
      // width: '16%'
    },
    {
      title: "Site Name",
      dataIndex: "name",
      // width: "18%",
    },
    {
      title: "Admin State",
      dataIndex: "admin_state",
      // width: "18%",
    },
    {
      title: "Description",
      dataIndex: "description",
      // width: "18%",
    },
    {
      title: "Cluster Role",
      dataIndex: "element_cluster_role",
      // width: "18%",
    },
  ];

  const actionMenu = [
    {
      key: "listDetails",
      component: ShowRecordDetails,
      icon: <ArrowsAltOutlined />,
    },
  ];

  return (
    <Space
      direction="vertical"
      size="large"
      style={{ width: "100%", marginTop: 16 }}
    >
      {contextHolder}
      {/* Sites */}
      <BaseTable
        loading={loadingTenantData && !errorTenantData}
        tableName={"Sites"}
        dataSource={sites?.sort(dynamicSort("name"))}
        columns={columns}
        rowActions={actionMenu}
      />
    </Space>
  );
};

export default Sites;
