import React, { useState } from "react";
import { Button, Modal, Typography, Upload, UploadProps, message } from "antd";
import axios from "axios";
import { UploadOutlined } from "@ant-design/icons";
import { NgdsErrorType } from "../../../../../../types/NgdsErrorType";
const { Paragraph, Text } = Typography;

// Gets from local Storage
// Gets from local Storage
const user = JSON.parse(localStorage.getItem("user"));
const account = JSON.parse(localStorage.getItem("currentAccount"));
const account_id = account?.id;
const account_name = account?.name;

const access_token = user?.access_token;

const ReplaceFile = ({ record, setToggleRefresh }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    if (+record.account_id === +account_id) {
      setIsModalOpen(true);
    } else {
      message.warning(`Template not directly assigned to ${account_name}`);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const props: UploadProps = {
    name: "file",
    action: "/api/v1/templates/" + record.id,
    customRequest: (options: any) => {
      const data = new FormData();
      data.append("template", options.file);
      data.append("account_id", account_id);
      const config = {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          options.onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };
      axios
        .put(options.action, data, config)
        .then((res: any) => {
          options.onSuccess(res.data, options.file);
        })
        .catch((err: NgdsErrorType) => {
          const statusText = err.response?.data?.error;
          options.onError((statusText && { statusText }) || err);
          message.error(`${record.name}: failed to replace -  ${statusText}`);
          console.log({ err });
        });
    },
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <>
      <a onClick={showModal}>Replace File</a>
      <Modal
        title="Are you sure?"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Paragraph>
          You are about to replace : <strong>{record.name}</strong>
        </Paragraph>
        <Paragraph strong type="danger">
          You won't be able to revert this!
        </Paragraph>

        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Modal>
    </>
  );
};

export default ReplaceFile;
