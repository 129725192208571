import React, { useEffect } from "react";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Tabs,
  Tag,
  Typography,
  message,
} from "antd";

// Components
import ContentWrapper from "../../../components/ContentWrapper";

// Contexts
import type { TabsProps } from "antd";
// import Networks from './sections/Networks';
import {
  ApiOutlined,
  ArrowsAltOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import { CloudDownloadOutlined, ReloadOutlined } from "@ant-design/icons";
// import Devices from './sections/Devices';
import { ColumnsType } from "antd/es/table";
import BaseTable from "../../../../../components/Tables/BaseTable";
import ShowRecordDetails from "../../../../../components/Tables/ShowRecordDetails";
import { dynamicSort } from "../../../../../utils/sorter";
import { useBFGConnectors } from "../../../../../contexts/Connectors/BFGContext";
import SiteDetails from "../SiteDetails";

const { Title, Text } = Typography;

const Sites = () => {
  const bfg = useBFGConnectors();
  const sites = bfg.sites;
  const loadingSites = bfg.loadingSites;
  // const get_sites_by_bfg_id = bfg.get_sites_by_bfg_id;

  // // Fetch once connected
  // useEffect(() => {
  //     // Fetch Locations (includes VPNs)
  //     if (!sites && !loadingSites) get_sites_by_bfg_id && get_sites_by_bfg_id()
  // }, [])

  const columns: ColumnsType<any> = [
    {
      title: "Site ID",
      dataIndex: "siteId",
      // width: '16%'
    },
    {
      title: "Site Reference",
      dataIndex: "siteReference",
    },
    {
      title: "Site Name",
      dataIndex: "siteName",
      // width: "18%",
    },
    {
      title: "Site Address ID",
      dataIndex: "siteAddressId",
      // render: (text: any) => ProductsTagColoredStack(text),
    },
  ];

  const actionMenu = [
    {
      key: "siteDetails",
      component: SiteDetails,
      icon: <ArrowsAltOutlined />,
    },
    // {
    //     key: 'listIventory',
    //     component: ShowRecordDetails,
    //     icon: <ArrowsAltOutlined />
    // }
  ];

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {/* Sites */}
      <BaseTable
        loading={loadingSites}
        tableName={`Sites`}
        dataSource={sites?.sort(dynamicSort("siteReference"))}
        columns={columns}
        // reloadAction={() => get_sites_by_bfg_id()}
        // rowSelectionActions={true}
        rowActions={actionMenu}
        //   expandedRowRender={{
        //     expandedRowRender,
        //     // expandRowByClick: true,
        //     // fixed: true,
        //     indentSize: 0,
        //     rowExpandable: (record: MerakiNetworksType) =>
        //       products
        //         ?.map((product: MerakiProductsType) => product.networkId)
        //         .includes(record.id),
        //   }}
      />
    </Space>
  );
};

export default Sites;
