import { Children, FC, useEffect, useState } from "react";
import {
  Button,
  Card,
  Divider,
  Select,
  Space,
  Spin,
  Tabs,
  Tag,
  Typography,
} from "antd";

// Components
import ContentWrapper from "../../../components/ContentWrapper";
import LocationsManagement from "./Locations";
import VPNCredentials from "./VPNCredentials";
import VirtualIPs from "./VirtualIPs";
import Settings from "./Settings";

// Contexts
import { useConnectors } from "../../../contexts/Connectors/ZscalerContext";

import type { TabsProps } from "antd";

import React from "react";
import ResultError from "./Results/ResultError";
import { ChildrenProps } from "../../../types/ChildrenReactType";
import { IdcardOutlined, ReloadOutlined } from "@ant-design/icons";
import ExportToExcel from "./functions/ExportToExcel";

// Redux
import { useSelector } from "react-redux";
// import { setStoreAccount } from "../../../../features/account";

const { Text, Title } = Typography;
const onChange = (key: string) => {
  console.log(key);
};

const ZscalerConnector: FC = () => {
  const accountState = useSelector((state: any) => state.account.value);
  const user = JSON.parse(localStorage?.getItem("user"));
  const currentAccount = JSON.parse(localStorage?.getItem("currentAccount"));
  const scopes = JSON.parse(user?.scopes);

  // if user is RO his scope doesn't have 'zscaler_read_write', only zscaler_user
  const isZscalerReadOnly = !scopes?.includes("zscaler_read_write");
  console.log({ isZscalerReadOnly });

  const connectors = useConnectors();

  // Locations Context
  const locationsAndCredentials = connectors?.locationsAndCredentials;
  const locations = locationsAndCredentials?.locations;
  const vpnCredentials = locationsAndCredentials?.vpn_credentials;
  const error = connectors?.error;
  const loading = connectors?.loading;
  const fetchLocationsAndCredentials = connectors?.fetchLocationsAndCredentials;

  // Virtual IP Context
  const vips = connectors?.vips;
  const vipsError = connectors?.vipsError;
  const vipsLoading = connectors?.vipsLoading;
  const fetchVips = connectors?.fetchVips;

  // Setting Context
  const settings = connectors?.settings;
  const settingsError = connectors?.settingsError;
  const settingsLoading = connectors?.settingsLoading;
  const fetchSettings = connectors?.fetchSettings;

  // log errors if it happens
  useEffect(() => {
    error && console.log({ error });
    vipsError && console.log({ vipsError });
    settingsError && console.log({ settingsError });
  }, [error, vipsError, settingsError]);

  // Fetch once connected
  useEffect(() => {
    // Fetch Locations (includes VPNs)
    if (!locations && !loading)
      fetchLocationsAndCredentials && fetchLocationsAndCredentials();

    // Fetch VIPs
    if (!vips && !vipsLoading) fetchVips && fetchVips();

    // Fetch Settings
    if (!settings && !settingsLoading) fetchSettings && fetchSettings();
  }, []);

  const BorderedContent: FC<ChildrenProps> = ({ children }) => {
    return (
      <div
        style={{
          padding: 16,
          marginTop: -16,
          borderLeft: "1px solid #F5F5F5",
          borderRight: "1px solid #F5F5F5",
          borderBottom: "1px solid #F5F5F5",
        }}
      >
        {children}
      </div>
    );
  };

  const onReload = () => {
    fetchSettings();
    fetchVips();
    fetchLocationsAndCredentials();
  };

  useEffect(() => {
    console.log("zscaler reloading, account changed");
    onReload();
  }, [accountState]);

  const operations = (
    <>
      <ExportToExcel />
      <Divider type="vertical" />
      <Button size="small" icon={<ReloadOutlined />} onClick={onReload}>
        Reload
      </Button>
    </>
  );

  const locationsPage = (
    <BorderedContent>
      <LocationsManagement isZscalerReadOnly={isZscalerReadOnly} />
    </BorderedContent>
  );
  const vpnsPage = (
    <BorderedContent>
      <VPNCredentials isZscalerReadOnly={isZscalerReadOnly} />
    </BorderedContent>
  );
  const vipsPage = (
    <BorderedContent>
      <VirtualIPs />
    </BorderedContent>
  );
  // const settingsPage = <Settings />

  const items: TabsProps["items"] = [
    {
      key: "locations",
      label: `Locations Management`,
      children: locationsPage,
    },
    {
      key: "vpns",
      label: `VPN Credentials`,
      children: vpnsPage,
    },
    {
      key: "vips",
      label: `Virtual IPs`,
      children: vipsPage,
    },
    // {
    //     key: 'sesttings',
    //     label: `Default Settings`,
    //     children: settingsPage,
    //     disabled: true
    // },
  ];

  const options = [
    {
      value: "zscaler",
      label: "zscaler",
    },
    {
      value: "zscloud",
      label: "zscloud",
    },
  ];

  const cloud = localStorage.getItem("zscaler_cloud_domain");
  const reset = connectors.resetLocationsAndCredentials;

  return (
    <Space direction="vertical" size="small" style={{ width: "100%" }}>
      {/* <Title style={{ marginBottom: 16 }}>Zscaler Connector</Title> */}
      <div className="title-container">
        <h1 className="colorIndigo" style={{ marginBottom: 16 }}>
          Zscaler Connector
        </h1>
      </div>
      <Card
        title={
          <Space wrap align="baseline">
            Cloud Domain:
            <Select
              size="large"
              bordered={false}
              placeholder="Select Cloud"
              style={{ color: "red" }}
              // disabled={loading || vpnCredentialsLoading}
              disabled={loading}
              defaultValue={"zscaler"}
              value={cloud}
              onChange={(value) => {
                localStorage.setItem("zscaler_cloud_domain", value);
                reset();
              }}
              options={options}
            />
          </Space>
        }
      >
        {!error ? (
          <Tabs
            tabBarExtraContent={operations}
            type="card"
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
            size="large"
          />
        ) : (
          <ResultError errorPayload={error} />
        )}
      </Card>
    </Space>
  );
};

export default ZscalerConnector;
