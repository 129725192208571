import React, { Dispatch, SetStateAction } from "react";
import axios from "axios";
import { NgdsErrorType } from "../../types/NgdsErrorType";
import { convertMs } from "../../utils/convertMs";
import { AccountType, userStorageType } from "../../types/UserAuthTypes";

const logRequest = (
  connector: string,
  action: string,
  params: any,
  account: AccountType,
  user: userStorageType
) => {
  console.log(
    `Started ${connector}: "${action}" for account: ${account?.name}(${account?.id}) by ${user?.fullname} (${user?.uin})`
  );
  console.log({
    params: {
      ...params,
      api_key: params?.api_key
        ? `***${params.api_key?.slice(-4, params.api_key?.length)}`
        : null,
    },
  });
};

export const connectorCall = async (
  connector: string,
  action: string,
  params: any, // {}
  dataStateCallback: Dispatch<SetStateAction<any>>,
  loadingCallback: Dispatch<SetStateAction<boolean | undefined>>,
  errorCallback: Dispatch<SetStateAction<NgdsErrorType | undefined>>,
  durationCallback: Dispatch<SetStateAction<number | undefined>> = undefined
) => {
  // Gets user and account from local Storage
  const user = JSON.parse(localStorage.getItem("user")!);
  const account = JSON.parse(localStorage.getItem("currentAccount")!);

  // reset states
  dataStateCallback(undefined);
  loadingCallback(false);
  errorCallback(undefined);
  durationCallback && durationCallback(undefined);

  loadingCallback(true);
  const url = "/api/v1/api_connectors";
  const body = {
    user: {
      ein: user?.uin,
      fullname: user?.fullname,
      account,
    },
    account_id: +account?.id,
    connector,
    params,
    action,
  };

  logRequest(connector, action, params, account, user);

  const start = performance.now();

  let isTrue = false;
  await axios
    .post(url, body, {
      headers: { Authorization: "Bearer " + user.access_token },
    })
    .then((resp: any) => {
      console.log({ [`${action}_RESPONSE`]: resp.data });
      if (resp?.hasOwnProperty("error")) errorCallback({ ...resp.error });
      else if (resp.status !== 200) {
        errorCallback({ error: "Something whent wrong", response: null });
        isTrue = false;
      } else {
        dataStateCallback(resp.data);
        isTrue = true;
      }
    })
    .catch((error: any) => {
      errorCallback(error);
      console.log({ [`${action}_ERROR`]: error });
      isTrue = false;
    })
    .finally(() => {
      loadingCallback(false);
      const end = performance.now();
      const duration = end - start;
      durationCallback && durationCallback(duration);
      console.log(
        `Finished ${connector}: "${action}" in ${convertMs(duration)}`
      );
    });
  return isTrue;
};
