import {selectStyle, removeDuplicates} from "../utils.js"
import {useState, useEffect} from 'react'
import axios from 'axios'
import { handleHttpError } from "../../../../common/utils.js"



import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
  } from "@mui/material";
import Cookies from "universal-cookie";

export default function SelectLabel(
    {
      labelId, 
      setLabelId, 
      setIsLabelFilter, 
      isLoading,
      isAccountOnly,
      libraryId,
      accountId,
      labelOptions,
      setLabelOptions,
      setRefresh
    }) {

  const cookies = new Cookies();
  const access_token = cookies.get("access_token");
  
  useEffect(() => {
    const getAssignedLabels = (libraryId, accountId) => {
      let endpoint = `/api/v1/templates?library_id=${libraryId}`
      if (isAccountOnly) endpoint = `${endpoint}&account_id=${accountId}`

      axios
        .get(endpoint, {
          headers: { Authorization: "Bearer " + access_token },
        })
        .then((response) => {
          const templates = response.data;

          const labels = templates.map((template) => ({
            value: template.label?.id,
            name: template.label?.label,
          })).filter(item => item.value !== undefined);

          setLabelOptions(removeDuplicates(labels))
        })
        .catch((error) => {
          handleHttpError(error);
        });
      }
      getAssignedLabels(libraryId, accountId)
    },[libraryId, accountId])

    const handleLabel = (event) => {
      setLabelId(event.target.value);
      setIsLabelFilter(event.target.value > 0 ? true : false)
      setRefresh((prev) => !prev)
      
    };
      
        return isLoading ? (
          <small
            style={{ color: "grey", fontWeight: "lighter", textAlign: "center" }}
          >
            Loading Labels...
          </small>
        ) : (
          <>
            <FormControl sx={selectStyle}>
              <InputLabel id="library-label">Label Filter</InputLabel>
              <Select
                sx={{backgroundColor: "white"}}
                size="small"
                labelId="library-label"
                id="library-select"
                value={labelId}
                label="Label Filter"
                onChange={handleLabel}
              >
                <MenuItem value={0}>Any Label</MenuItem>
                {labelOptions?.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    name={option.name}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        );
      };
      
      