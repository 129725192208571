import { useState } from "react";

// Componenets
import SelectRemoteTemplate from "./SelectRemoteTemplate.js";
import SelectLibrary from "./SelectLibrary.js";
import SelectLabel from "./SelectLabel.js";
import SelectLocal from "./SelectLocal.js";

// MUI
import {
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

// Icons
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ArticleIcon from "@mui/icons-material/Article";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const account_name = cookies.get("account_name");
const account_id = cookies.get("account_id");

const selectStyle = {
  minWidth: "15rem",
  backgroundColor: "white",
  marginRight: "1rem",
  marginTop: "1rem",
};

const removeDuplicates = (array) => {
  return array.filter(
    (data, index) => index === array.findIndex((obj) => obj.name === data.name)
  );
};

export default function TemplateSelect({
  templates,
  setTemplates,
  classes,
  location,
  setLocation,
  libraryId,
  setLibraryId,
  templateId,
  setTemplateId,
  templatesOptions,
  setTemplatesOptions,
  libraryOptions,
  setLibraryOptions,
  labelOptions,
  setLabelOptions,
  labelId,
  setLabelId,
  showManager,
  setShowManager,
  refresh,
  setRefresh,
  setStartProgress,
}) {
  const [accountId, setAccountId] = useState(account_id);
  const [isLabelFilter, setIsLabelFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAccountOnly, setIsAccountOnly] = useState(
    accountId == 0 ? false : true
  );

  console.log({
    location,
    templates,
    libraryOptions,
    templatesOptions,
    libraryId,
    templateId,
    labelOptions,
    labelId,
  });

  return (
    <>
      {/* Location Selector */}
      <FormControl sx={selectStyle}>
        <InputLabel id="location-label">Location</InputLabel>
        <Select
          // sx={{ minWidth: "40%" }}
          size="small"
          labelId="location-label"
          id="location-select"
          value={location}
          label="Location"
          onChange={(e) => setLocation(e.target.value)}
        >
          <MenuItem value={"custom"}>Local / Custom</MenuItem>
          <MenuItem value={"library"}>Library</MenuItem>
        </Select>
      </FormControl>

      {/* Library Selector */}

      {location === "library" ? (
        <>
          <SelectLibrary
            libraryId={libraryId}
            setLibraryId={setLibraryId}
            libraryOptions={libraryOptions}
            setLibraryOptions={setLibraryOptions}
          />

          {libraryId !== 0 && (
            <>
              <SelectLabel
                labelOptions={labelOptions}
                setLabelOptions={setLabelOptions}
                labelId={labelId}
                setLabelId={setLabelId}
                setIsLabelFilter={setIsLabelFilter}
                isLoading={isLoading}
                isAccountOnly={isAccountOnly}
                libraryId={libraryId}
                accountId={accountId}
                setRefresh={setRefresh}
              />
              <SelectRemoteTemplate
                templates={templates}
                setTemplates={setTemplates}
                templatesOptions={templatesOptions}
                setTemplatesOptions={setTemplatesOptions}
                libraryId={libraryId}
                templateId={templateId}
                setTemplateId={setTemplateId}
                isLabelFilter={isLabelFilter}
                labelOptions={labelOptions}
                labelId={labelId}
                setIsLoading={setIsLoading}
                isAccountOnly={isAccountOnly}
                setIsAccountOnly={setIsAccountOnly}
                accountId={accountId}
                accountName={accountId}
                setAccountId={setAccountId}
                isLoading={isLoading}
                showManager={showManager}
                setShowManager={setShowManager}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </>
          )}
        </>
      ) : (
        // Local / Custom Template
        <SelectLocal templates={templates} setTemplates={setTemplates} />
      )}
      <FilesDisplay
        templates={templates}
        setTemplates={setTemplates}
        classes={classes}
        setStartProgress={setStartProgress}
      />
    </>
  );
}

// Components

const FilesDisplay = ({
  templates,
  setTemplates,
  classes,
  setStartProgress,
}) => {
  const handleDelete = (filename) => {
    setStartProgress(false);
    setTemplates((prevState) =>
      prevState.filter((file) => file.name !== filename)
    );
  };
  return (
    <div style={{ marginTop: "2rem" }}>
      {templates.length > 0 && (
        <h5 className="colorIndigo">Seleted Templates</h5>
      )}
      {templates.map((file) => (
        <div className={classes.inputTemplate} key={file.path}>
          <div>
            <ArticleIcon sx={{ marginRight: 1 }} />
          </div>
          <div>{file.name}</div>
          <div className={classes.flexLastRight}>
            <div className={classes.engine}>
              {/* <div>
                <EngineSelect file={file} />
              </div> */}
              <div>
                <IconButton
                  aria-label="Delete"
                  onClick={() => handleDelete(file.name)}
                  sx={{ "&:hover": { color: "red" } }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
