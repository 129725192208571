import React, { useState } from "react";
import { Button, Form, Input, InputNumber, Select } from "antd";
import { useConnectors } from "../../../../contexts/Connectors/ZscalerContext";
import { ZscalerSettingType } from "../../../../types/ConnectorsType";
import { FormInstance } from "antd/es/form/Form";

type FormProps = {
  form: FormInstance<any>;
  setRfc: React.Dispatch<React.SetStateAction<string | undefined>>;
  setFqdn: React.Dispatch<React.SetStateAction<string | undefined>>;
  vpnOnly?: boolean;
};

const NewVpnCredentialsForm: React.FC<FormProps> = ({
  form,
  setRfc,
  setFqdn,
  vpnOnly = false,
}) => {
  // Connectors Context
  const connectors = useConnectors();

  // Getting General Form Settings
  const settings = connectors?.settings;
  const settingsLoading = connectors?.settingsLoading;
  const settingsError = connectors?.settingsError;
  // Getting Specific Group Form Settings

  const vpnFormFields = settings?.filter(
    (item: any) => item.group === "VPN Credentials"
  );
  // Getting Default/initial form Values
  const vpnInitialValues: any = {};
  vpnFormFields?.forEach(
    (item: any) => (vpnInitialValues[item.parameter] = item.default_value)
  );
  const initialVPNValues = { vpnCredentials: vpnInitialValues };
  const mainFields = vpnFormFields
    ?.filter((item) => item.variable === true || item.parameter === "fqdn")
    .map((item) => item.parameter);

  // Form handlers start
  const [showAllVPNAttributes, setShowAllVPNAttributes] = useState(true);

  const OptionValue = (input: ZscalerSettingType) => {
    const possible_values = input.possible_values;
    if (possible_values) {
      if (typeof Array.isArray(possible_values[0])) {
        return (
          <Select showSearch disabled={!input.modifiable}>
            {possible_values?.map((item: any) => (
              <Select.Option key={item} value={item}>
                {`${item}`}
              </Select.Option>
            ))}
          </Select>
        );
      } else {
        <Input style={{ width: "100%" }} defaultValue={input.default_value} />;
      }
    }

    if (typeof input.default_value === "number") {
      return (
        <InputNumber style={{ width: "100%" }} disabled={!input.variable} />
      );
    } else if (input.parameter === "rfc") {
      return (
        <Input
          onBlur={(e) => setRfc(e.target.value)}
          onPressEnter={(e: any) => setRfc(e.target.value)}
          style={{ width: "100%" }}
        />
      );
    } else if (vpnOnly && setFqdn && input.parameter === "fqdn") {
      return (
        <Input
          pattern="[\w@.]"
          onBlur={(e) => setFqdn(e.target.value)}
          onPressEnter={(e: any) => setFqdn(e.target.value)}
          style={{ width: "100%" }}
          placeholder="<location name>@dhl.com"
        />
      );
    }
    return <Input disabled={!input.modifiable} />;
  };

  const onFinish = (values: any) => {
    console.log({ values });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <>
      <div>
        <Button
          style={{ marginTop: 10, marginRight: 0 }}
          type="link"
          size="small"
          onClick={() => setShowAllVPNAttributes(!showAllVPNAttributes)}
        >
          {(showAllVPNAttributes && "Show All Attributes") ||
            "Hide Default Attributes"}
        </Button>
      </div>
      <Form
        layout="vertical"
        style={{ marginTop: 30, maxWidth: "100%" }}
        size="small"
        form={form}
        name="new-vpn-form"
        onFinish={onFinish}
        initialValues={{ ...initialVPNValues }}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelWrap
      >
        {vpnFormFields?.map((item) => {
          return (
            <Form.Item
              key={item.display_name}
              label={item.display_name}
              name={
                vpnOnly ? item.parameter : ["vpnCredentials", item.parameter]
              }
              required={item.mandatory ? true : false}
              rules={[{ required: true, message: "Mandatory Field!" }]}
              tooltip={item.description || null}
              hidden={
                mainFields?.includes(item.parameter)
                  ? undefined
                  : showAllVPNAttributes
              }
            >
              {OptionValue(item)}
            </Form.Item>
          );
        })}
      </Form>
    </>
  );
};

export default NewVpnCredentialsForm;
