// react dependencies
import React, { useEffect, useState } from "react";
import axios from "axios";
import CustomButton from "../../../components/Button/CustomButton";

// dual list box dependencies
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";

//custom loader
import { css } from "@emotion/react";
import PropagateLoader from "react-spinners/PropagateLoader";

// toastr dependencies
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// material UI components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

//Cookies
import Cookies from "universal-cookie";
import {
  getFileName,
  saveBlob,
  handleHttpError,
} from "../../../common/utils.js";

//Loader css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    transitionDuration: "0.3s",
  },
  formControl: {
    minWidth: 120,
  },
  content: {
    textAlign: "center",
    height: "auto",
    paddingTop: "200",
    objectFit: "contain",
    paddingBottom: 100,
  },
  button: {
    marginTop: "3rem",
    minWidth: "210px",
    marginRight: "1rem",
    marginLeft: "1rem",
    marginBottom: "3rem",
    justifyContent: "center",
    textAlign: "center",
  },
  smallButton: {
    marginTop: "1rem",
    minWidth: "110px",
    justifyContent: "center",
    textAlign: "center",
  },
  mystyle: {
    color: "rgb(85, 20, 180)",
  },
  progress: {
    marginTop: "5%",
    marginLeft: "25%",
  },
  tabPanel: {
    flexGrow: 1,
    width: "100%",
  },
  table: {
    "& > *": {
      margin: ".5rem",
      display: "inline",
      textAlign: "center",
    },
  },
});

export default function AutoDataView(props) {
  const classes = useStyles();

  const cookies = new Cookies();
  const access_token = cookies.get("access_token");

  // data states
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [sitesList, setSitesList] = useState([]);
  const [devicesData, setDevicesData] = useState([]);
  const [devicesLoaded, setDevicesLoaded] = useState(false);
  const [documentLoading, setDocumentLoading] = useState(false);
  const [accountName, setAccountName] = useState();
  const [outputType, setOutputType] = useState("2d");
  const [aSiteHasBeenSelected, setASiteHasBeenSelected] = useState(null);
  const [aDeviceHasBeenSelected, setADeviceHasBeenSelected] = useState(null);
  const [isLoadingOutput, setIsLoadingOutput] = useState(false);
  const [options] = React.useState([
    { value: "3d", name: "3D Data Source" },
    { value: "2d", name: "2D Data Source" },
  ]);

  const [bfgId, setBfgId] = useState();

  // loading states
  const [isLoadingSites, setLoadingSites] = useState(true);
  const [isLoadingTwo, setLoadingTwo] = useState(false);
  let [isLoading, setLoading] = useState(false);

  // dualbox options
  const dualListOptions = {
    moveLeft: "<",
    moveAllLeft: "<<",
    moveRight: ">",
    moveAllRight: ">>",
  };

  const handleChange = (event) => {
    setOutputType(event.target.value);
  };

  const onSelectedSitesChange = (selected) => {
    setSelectedSites(selected);
    setASiteHasBeenSelected(true);
    setDevicesLoaded(false)
    setIsLoadingOutput(false)
    if (selected.length === 0) {
      setASiteHasBeenSelected(false);
    }
  };
  const startConverter = () => {
    setDocumentLoading(true)
    setADeviceHasBeenSelected(false)
    let output = outputType;
    let devicesString = selectedDevices.toString();
    axios({
      method: "get",
      url: `/api/v1/autodata/devices/${devicesString}?output_format=` + output,
      headers: {
        Authorization: "Bearer " + access_token,
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
      maxContentLength: 100000000,
      maxBodyLength: 1000000000,
    })
      .then((response) => {
        // Generating filename
        setADeviceHasBeenSelected(true);
        let fileName = getFileName(response.headers);
        saveBlob(response.data, fileName);
        setDocumentLoading(false)
      })
      .catch(function (error) {
        handleHttpError(error);
        setADeviceHasBeenSelected(true);
        setDocumentLoading(false)
      });
  };

  const onSelectedDevicesChange = (selected) => {
    setIsLoadingOutput(true);
    setSelectedDevices(selected);
    setADeviceHasBeenSelected(true)
  };

  const getDevices = (sites) => {
    setLoadingTwo(true);
    setASiteHasBeenSelected(false);
    const sitesString = sites.toString();
    const devicesPayload = [];

    axios
      .get(`/api/v1/autodata/sites/${sitesString}/inventory`, {
        headers: { Authorization: "Bearer " + access_token },
      })
      .then((response) => {
        const results = response.data["devices"];
        results.forEach((device) => {
          devicesPayload.push({
            value: device.id,
            label: device.site_name + ": " + device.hostname,
          });
        });
        setDevicesData(devicesPayload);
        setDevicesLoaded(true);
        setLoadingTwo(false);
      })
      .catch(function (error) {
        handleHttpError(error);
        setASiteHasBeenSelected(false);
      });
  };
  useEffect(() => {
    axios
      .get(`/oauth2/accounts/${props.accountID}`, {
        headers: { Authorization: "Bearer " + access_token },
      })
      .then((response) => {
        const account = response.data;
        if (account.bfg_id !== undefined) {
          setAccountName(account.name);
          getSitesApigw(account.id);
        } else {
          toast.warning("This account does not have access to AutoData");
          setLoadingSites(false);
        }
      })
      .catch(function (error) {
        handleHttpError(error);
      });
  }, [props.accountID, props.selectedAccount]);

  const getSitesApigw = (account_id) => {
    // SelectedAccount is the BFG_ID
    console.log(`Fetching sites for account ${account_id} from APIGW...`);

    // Setting the state for BFG ID
    setBfgId(account_id);

    const sitesEndpoint = `/api/v1/autodata/accounts/${account_id}/sites`;
    axios
      .get(sitesEndpoint, {
        headers: { Authorization: "Bearer " + access_token },
      })
      .then((response) => {
        const sites = response.data;
        const allSites = sites["sites"].map((site) => ({
          value: site.site_id,
          label: site.site_name,
        }));
        setSitesList(allSites);
        setLoadingSites(false);
        setLoading(false);
      })
      .catch(function (error) {
        setLoadingSites(false);
        setLoading(false);
        handleHttpError(error);
      });
  };

  return (
    <div className={classes.content}>
      <ToastContainer />
      {bfgId !== undefined ? (
        <h2 className={classes.mystyle}>AutoData</h2>
      ) : null}
      {isLoadingSites ? (
        <PropagateLoader color={"#5014b9"} css={override} size={20} />
      ) : null}
      <div>
        <div id="site_list" className={classes.list}>
          <br />
          {isLoading ? (
            <PropagateLoader color={"#5014b9"} css={override} size={20} />
          ) : null}
          {!isLoadingSites && sitesList.length > 0 ? (
            <div>
              <h3 className={classes.mystyle}>
                Select sites by moving from left to right
              </h3>
              <DualListBox
                options={sitesList}
                selected={selectedSites}
                onChange={onSelectedSitesChange}
                canFilter
                icons={dualListOptions}
              />
            </div>
          ) : !isLoadingSites &&
            sitesList.length === 0 &&
            bfgId !== undefined ? (
            <p>{`There seems to be an issue accessing the data for ${accountName}.`}</p>
          ) : null}
        </div>
      </div>
      <br />
      {aSiteHasBeenSelected ? (
        <CustomButton
          label={"FETCH DEVICES"}
          color="purple"
          action={() => {
            getDevices(selectedSites);
          }}
        />
      ) : aSiteHasBeenSelected == false ? (
        <>
          <Button variant="contained" disabled>
            FETCH DEVICES
          </Button>
          <br />
        </>
      ) : null}

      <br />
      {isLoadingTwo ? (
        <PropagateLoader color={"#5014b9"} css={override} size={20} />
      ) : null}

      {devicesLoaded ? (
        <div>
          <h3 className={classes.mystyle}>
            Select devices by moving from left to right
          </h3>
          <DualListBox
            options={devicesData}
            selected={selectedDevices}
            onChange={onSelectedDevicesChange}
            canFilter
            icons={dualListOptions}
          />
        </div>
      ) : null}
      <br />
      {isLoadingOutput ? (
        <div>
          <h3 className={classes.mystyle}>Select an output type:</h3>
          <FormControl
            style={{ minWidth: 200 }}
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel>Output Type</InputLabel>
            <Select
              onChange={handleChange}
              inputProps={{
                name: "library",
                id: "library-select",
              }}
              label="Output Selector"
              value={outputType}
            >
              {options.map((e, key) => {
                return (
                  <MenuItem key={e.value} value={e.value}>
                    {e.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <br />
          <br />
          {aDeviceHasBeenSelected ? (
            <CustomButton
              label={"DOWNLOAD FILE"}
              color="purple"
              action={() => {
                startConverter();
              }}
            />
          ) : aDeviceHasBeenSelected == false ? (
            <>
              <Button variant="contained" disabled>
                DOWNLOAD FILE
              </Button>
              <br />
            </>
          ) : null}
        </div>

      ) : null}
      <br/>
      <br/>
      {documentLoading ? (
        <PropagateLoader color={"#5014b9"} css={override} size={20} />
      ) : null}
    </div>
  );
}
