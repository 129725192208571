// React dependencies
import React, { useEffect } from "react";
import axios from "axios";
import CustomCard from "../../components/Card/CustomCard.js";
import GuiTemplateExcelView from "./WizardItemPageViews/WizardItemPageViews.js";
import CustomButton from "../../components/Button/CustomButton.js";
import Button from "@material-ui/core/Button";

// Material UI components
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import ArrowRightIcon from "@material-ui/icons/ArrowRightAlt";

// Custom components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

//tippy
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";

//Toaster
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//redux
import { handleHttpError, getFileName, saveBlob } from "../../common/utils.js";

//images
import excel from "./../../assets/images/xls.svg";
import dynamic from "./../../assets/images/dynamic.svg";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "100px",
    flexGrow: 1,
    marginBottom: "80px",
    textAlign: "center",
    paddingBottom: 150,
  },
  textfield_root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "45ch",
    },
    marginBottom: 30,
  },
  subroot: {
    paddingTop: "50px",
    marginBottom: "80px",
  },
  formControl: {
    minWidth: "40%",
  },
  mystyle: {
    color: "rgb(85, 20, 180)",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  pipelineStepper: {
    paddingBottom: 20,
  },
  pipelineButton: {
    marginRight: 5,
  },
  pipeline_div: {
    display: "inline",
  },
  arrow: {
    position: "relative",
    top: "10px",
  },
}));

export default function GuiTemplatePage(props) {
  const classes = useStyles();
  const [gui_templateID, setGuiTemplateID] = React.useState("");
  const [wizardstoreID, setWizardstoreID] = React.useState(null);
  const [wizardstoreList, setWizardstoreList] = React.useState(null);
  const [masterMenu, setMasterMenu] = React.useState(null);
  const [firstMenuList, setFirstMenuList] = React.useState(null);
  const [secondMenuList, setSecondMenuList] = React.useState(null);
  const [thirdMenuList, setThirdMenuList] = React.useState(null);
  const [fourthMenuList, setFourthMenuList] = React.useState(null);
  const [fifthMenuList, setFifthMenuList] = React.useState(null);
  const [firstMenuReady, setFirstMenuReady] = React.useState(false);
  const [secondMenuReady, setSecondMenuReady] = React.useState(false);
  const [thirdMenuReady, setThirdMenuReady] = React.useState(false);
  const [fourthMenuReady, setFourthMenuReady] = React.useState(false);
  const [fifthMenuReady, setFifthMenuReady] = React.useState(false);
  const [firstMenuValue, setFirstMenuValue] = React.useState(null);
  const [secondMenuValue, setSecondMenuValue] = React.useState(null);
  const [thirdMenuValue, setThirdMenuValue] = React.useState(null);
  const [fourthMenuValue, setFourthMenuValue] = React.useState(null);
  const [fifthMenuValue, setFifthMenuValue] = React.useState(null);
  // const [wizardstoreSelected, setWizardstoreSelected] = React.useState(false);
  const [gui_templateSelected, setGuiTemplateSelected] = React.useState(false);
  const [acceptType, setAcceptType] = React.useState(null);
  const [pageReady, setPageReady] = React.useState(false);
  const [taskOrder, setTaskOrder] = React.useState(null);
  const [taskOrderLength, setTaskOrderLength] = React.useState(null);
  const [loadGlobalVariables, setLoadGlobalVariables] = React.useState(false);
  const [taskOrderReady, setTaskOrderReady] = React.useState(false);
  const [globalReadyCheck2, setGlobalReadyCheck2] = React.useState(false);
  const [taskReadyCheck2, setTaskReadyCheck2] = React.useState(false);
  const [globalObject, setGlobalObject] = React.useState(null);
  const [variables, setVariables] = React.useState(null);
  const [loadExcel, setLoadExcel] = React.useState(false);
  const [showGenerateButton, setShowGenerateButton] = React.useState(false);
  const [state, setState] = React.useState(null);
  const [mapper, setMapper] = React.useState("");

  let count = 1;

  const handleTextChange = (e) => {
    const newGlobalObject = globalObject;
    newGlobalObject[e.target.id] = e.target.value;
    setGlobalObject(newGlobalObject);
  };
  const handleDropdownChange = (e) => {
    const newGlobalObject = globalObject;
    newGlobalObject[e.target.name] = e.target.value;
    setGlobalObject(newGlobalObject);
  };

  const handleFirstMenuChange = (event) => {
    setGuiTemplateSelected(false);
    setTaskOrderReady(false);
    setGlobalReadyCheck2(false);
    setTaskReadyCheck2(false);
    setShowGenerateButton(false);
    setSecondMenuReady(false);
    setThirdMenuReady(false);
    setFourthMenuReady(false);
    setFifthMenuReady(false);
    setSecondMenuValue(false);
    setThirdMenuValue(false);
    setFourthMenuValue(false);
    setFifthMenuValue(false);
    setLoadExcel(false);
    setFirstMenuValue(event.target.value);
    if (event.target.value === null) {
      console.log("No option selected in first menu");
    }
    if (event.target.value !== null) {
      const optionsPayload = [{ value: null, name: "Select a option" }];
      const secondMenuKeys = Object.keys(masterMenu[event.target.value]);
      if (secondMenuKeys.includes("id") && secondMenuKeys.length === 1) {
        const id = masterMenu[event.target.value]["id"];
        getGuiTemplate(id);
      } else {
        secondMenuKeys.forEach((entry) => {
          optionsPayload.push({ value: entry, name: entry });
        });
        setSecondMenuList(optionsPayload);
        setSecondMenuReady(true);
      }
    }
  };
  const handleSecondMenuChange = (event) => {
    setGuiTemplateSelected(false);
    setTaskOrderReady(false);
    setGlobalReadyCheck2(false);
    setTaskReadyCheck2(false);
    setShowGenerateButton(false);
    setThirdMenuReady(false);
    setFourthMenuReady(false);
    setFifthMenuReady(false);
    setThirdMenuValue(false);
    setFourthMenuValue(false);
    setFifthMenuValue(false);
    setSecondMenuValue(event.target.value);
    setLoadExcel(false);
    if (event.target.value === null) {
      console.log("No option selected in second menu");
    }
    if (event.target.value !== null) {
      const optionsPayload = [{ value: null, name: "Select a option" }];
      const thirdMenuKeys = Object.keys(
        masterMenu[firstMenuValue][event.target.value]
      );
      if (thirdMenuKeys.includes("id") && thirdMenuKeys.length === 1) {
        const id = masterMenu[firstMenuValue][event.target.value]["id"];
        getGuiTemplate(id);
      } else {
        thirdMenuKeys.forEach((entry) => {
          optionsPayload.push({ value: entry, name: entry });
        });
        setThirdMenuList(optionsPayload);
        setThirdMenuReady(true);
      }
    }
  };

  const handleThirdMenuChange = (event) => {
    setGuiTemplateSelected(false);
    setTaskOrderReady(false);
    setGlobalReadyCheck2(false);
    setTaskReadyCheck2(false);
    setShowGenerateButton(false);
    setFourthMenuReady(false);
    setFifthMenuReady(false);
    setFourthMenuValue(false);
    setFifthMenuValue(false);
    setThirdMenuValue(event.target.value);
    setLoadExcel(false);
    if (event.target.value === null) {
      console.log("No option selected in third menu");
    }
    if (event.target.value !== null) {
      const optionsPayload = [{ value: null, name: "Select a option" }];
      const fourthMenuKeys = Object.keys(
        masterMenu[firstMenuValue][secondMenuValue][event.target.value]
      );
      if (fourthMenuKeys.includes("id") && fourthMenuKeys.length === 1) {
        const id =
          masterMenu[firstMenuValue][secondMenuValue][event.target.value]["id"];
        getGuiTemplate(id);
      } else {
        fourthMenuKeys.forEach((entry) => {
          optionsPayload.push({ value: entry, name: entry });
        });
        setFourthMenuList(optionsPayload);
        setFourthMenuReady(true);
      }
    }
  };
  const handleFourthMenuChange = (event) => {
    setGuiTemplateSelected(false);
    setTaskOrderReady(false);
    setGlobalReadyCheck2(false);
    setTaskReadyCheck2(false);
    setShowGenerateButton(false);
    setFifthMenuReady(false);
    setFifthMenuValue(false);
    setFourthMenuValue(event.target.value);
    setLoadExcel(false);
    if (event.target.value === null) {
      console.log("No option selected in fourth menu");
    }
    if (event.target.value !== null) {
      const optionsPayload = [{ value: null, name: "Select a option" }];
      const fifthMenuKeys = Object.keys(
        masterMenu[firstMenuValue][secondMenuValue][thirdMenuValue][
          event.target.value
        ]
      );
      if (fifthMenuKeys.includes("id") && fifthMenuKeys.length === 1) {
        const id =
          masterMenu[firstMenuValue][secondMenuValue][thirdMenuValue][
            event.target.value
          ]["id"];
        getGuiTemplate(id);
      } else {
        fifthMenuKeys.forEach((entry) => {
          optionsPayload.push({ value: entry, name: entry });
        });
        setFifthMenuList(optionsPayload);
        setFifthMenuReady(true);
      }
    }
  };
  const handleFifthMenuChange = (event) => {
    setGuiTemplateSelected(false);
    setTaskOrderReady(false);
    setGlobalReadyCheck2(false);
    setTaskReadyCheck2(false);
    setShowGenerateButton(false);
    setLoadExcel(false);
    setFifthMenuValue(event.target.value);
    if (event.target.value === null) {
      console.log("No option selected in fifth menu");
    }
    if (event.target.value !== null) {
      const id =
        masterMenu[firstMenuValue][secondMenuValue][thirdMenuValue][
          fourthMenuValue
        ][event.target.value]["id"];
      getGuiTemplate(id);
    }
  };
  const getJourneyId = () => {
    axios({
      method: "post",
      url: "/api/v1/stats/journeys",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((res) => {
        cookies.set("journey_id", res.data["id"], { path: "/" });
      })
      .catch((err) => {
        if (err.response.status === 400) {
          console.log(err.response.data);
        }
        throw err;
      });
  };
  const handleWizardstoreChange = (event) => {
    getJourneyId();
    setGuiTemplateSelected(false);
    setTaskOrderReady(false);
    setGlobalReadyCheck2(false);
    setTaskReadyCheck2(false);
    setShowGenerateButton(false);
    setFirstMenuReady(false);
    setSecondMenuReady(false);
    setThirdMenuReady(false);
    setFourthMenuReady(false);
    setFifthMenuReady(false);
    setWizardstoreID(event.target.value);
    // setWizardstoreSelected(true)
    getWizardstoreByID(event.target.value);
    setFirstMenuValue(null);
    setLoadExcel(false);
  };

  const getWizardstoreByID = (wizardstore_id) => {
    axios({
      method: "get",
      url: `/api/v1/wizardstores/${wizardstore_id}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((response) => {
        const menu = response.data["menu"];
        setMapper(response.data["mapper"]);
        if ("id" in menu) {
          getGuiTemplate(menu["id"]);
        } else {
          setMasterMenu(menu);
          const menuKeys = Object.keys(menu);
          const optionsPayload = [{ value: null, name: "Select a option" }];
          menuKeys.forEach((entry) => {
            optionsPayload.push({ value: entry, name: entry });
          });
          setFirstMenuList(optionsPayload);
          setFirstMenuReady(true);
        }
      })
      .catch((error) => {
        handleHttpError(error);
      });
  };
  const generateDocument = () => {
    let journey_id = cookies.get("journey_id");
    var finalData = { mapper };
    finalData["sites"] = globalObject;
    finalData["tasks"] = {};
    axios({
      method: "post",
      url: `/api/v1/gui_templates/${gui_templateID}?journey_id=${journey_id}`,
      data: finalData,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/octet-stream",
        Authorization: "Bearer " + props.access_token,
      },
      responseType: "blob",
    })
      .then((response) => {
        let fileName = getFileName(response.headers);
        saveBlob(response.data, fileName);
      })
      .catch((error) => {
        handleHttpError(error);
      });
  };

  const getGuiTemplate = (gui_template_id) => {
    setGuiTemplateID(gui_template_id);
    setGuiTemplateSelected(true);
    axios({
      method: "get",
      url: "/api/v1/gui_templates/" + gui_template_id,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((res) => {
        let obj = {};
        let taskObj = {};
        const results = res.data["variables"];
        const _taskOrder = res.data["taskorder"];
        if (_taskOrder) {
          setTaskOrder(_taskOrder);
          setTaskOrderLength(_taskOrder.length);
          _taskOrder.forEach((task) => (taskObj[task] = "default"));
          setTaskOrderReady(true);
        }
        if (results) {
          setLoadGlobalVariables(true);
        }
        setState(taskObj);
        setAcceptType(res.data["template"]["type"]);
        results.forEach((global_key) => (obj[global_key["name"]] = null));
        setGlobalObject(obj);
        setVariables(results);
      })
      .catch((err) => {
        handleHttpError(err);
      });
  };
  const getTask = (task) => {
    var _finalData = {};
    _finalData["sites"] = globalObject;
    _finalData["tasks"] = {};
    axios({
      method: "post",
      url: `/api/v1/gui_templates/${gui_templateID}/tasks/${task}`,
      data: _finalData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((res) => {
        if (
          "api" in res.data["context"] &&
          "status" in res.data["context"]["api"]
        ) {
          setState({ ...state, [task]: "secondary" });
        } else {
          setState({ ...state, [task]: "primary" });
        }
      })
      .catch((err) => {
        handleHttpError(err);
        setState({ ...state, [task]: "secondary" });
      });
  };

  const setMode = (mode) => {
    if (mode === "online") {
      setLoadExcel(false);
      setShowGenerateButton(true);
      setGlobalReadyCheck2(loadGlobalVariables);
      setTaskReadyCheck2(taskOrderReady);
    }
    if (mode === "excel") {
      setLoadExcel(true);
      setGlobalReadyCheck2(false);
      setTaskReadyCheck2(false);
      setShowGenerateButton(false);
    }
  };
  useEffect(() => {
    const wizard_type = cookies.get("wizard_type");
    axios({
      method: "get",
      url: `/api/v1/wizardstores?wizardtype=${wizard_type}`,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((res) => {
        const optionsPayload = [{ value: null, name: "Select a Wizardstore" }];
        res.data.forEach((entry) => {
          const name = entry.description;
          const id = entry.id;
          optionsPayload.push({ id: id, name: name });
        });
        setWizardstoreList(optionsPayload);
        setPageReady(true);
      })
      .catch((err) => {
        handleHttpError(err);
      });
  }, []);
  if (pageReady) {
    const cookies = new Cookies();
    const title = cookies.get("title");
    const wizard_type = cookies.get("wizard_type");
    return (
      <Container>
        <Header />
        <Grid container className={classes.root} justify="center" spacing={3}>
          <Grid item xs={12}>
            <div className="title-container">
              <h1>{title}</h1>
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel>Select a {`${wizard_type} store`}</InputLabel>
              <Select
                value={wizardstoreID}
                onChange={handleWizardstoreChange}
                className={classes.selectEmpty}
              >
                {wizardstoreList.map((entry) => (
                  <MenuItem value={entry.id}>{entry.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            {firstMenuReady ? (
              <FormControl className={classes.formControl}>
                <InputLabel>Select an option</InputLabel>
                <Select
                  variant="outlined"
                  value={firstMenuValue}
                  onChange={handleFirstMenuChange}
                  style={{ width: 300 }}
                  className={classes.selectEmpty}
                >
                  {firstMenuList.map((entry) => (
                    <MenuItem value={entry.value}>{entry.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Grid>
          <Grid item xs={3}>
            {firstMenuReady && secondMenuReady ? (
              <FormControl className={classes.formControl}>
                <InputLabel>Select an option</InputLabel>
                <Select
                  variant="outlined"
                  style={{ width: 300 }}
                  value={secondMenuValue}
                  onChange={handleSecondMenuChange}
                  className={classes.selectEmpty}
                >
                  {secondMenuList.map((entry) => (
                    <MenuItem value={entry.value}>{entry.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Grid>
          <Grid item xs={3}>
            {firstMenuReady && secondMenuReady && thirdMenuReady ? (
              <FormControl className={classes.formControl}>
                <InputLabel>Select an option</InputLabel>
                <Select
                  value={thirdMenuValue}
                  variant="outlined"
                  style={{ width: 300 }}
                  onChange={handleThirdMenuChange}
                  className={classes.selectEmpty}
                >
                  {thirdMenuList.map((entry) => (
                    <MenuItem value={entry.value}>{entry.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={3}>
            {firstMenuReady &&
            secondMenuReady &&
            thirdMenuReady &&
            fourthMenuReady ? (
              <FormControl className={classes.formControl}>
                <InputLabel>Select an option</InputLabel>
                <Select
                  value={fourthMenuValue}
                  onChange={handleFourthMenuChange}
                  variant="outlined"
                  style={{ width: 300 }}
                  className={classes.selectEmpty}
                >
                  {fourthMenuList.map((entry) => (
                    <MenuItem value={entry.value}>{entry.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Grid>
          <Grid item xs={3}>
            {firstMenuReady &&
            secondMenuReady &&
            thirdMenuReady &&
            fourthMenuReady &&
            fifthMenuReady ? (
              <FormControl className={classes.formControl}>
                <InputLabel>Select an option</InputLabel>
                <Select
                  value={fifthMenuValue}
                  onChange={handleFifthMenuChange}
                  variant="outlined"
                  style={{ width: 300 }}
                  className={classes.selectEmpty}
                >
                  {fifthMenuList.map((entry) => (
                    <MenuItem value={entry.value}>{entry.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Grid>
          <Grid item xs={12}></Grid>
          {gui_templateSelected ? (
            <Grid item xs={3}>
              <CustomCard
                title="Excel"
                description=""
                label="Select"
                image={excel}
                action={() => setMode("excel")}
              />
            </Grid>
          ) : null}
          {gui_templateSelected ? (
            <Grid item xs={3}>
              <CustomCard
                title="Online Portal"
                description=""
                label="Select"
                image={dynamic}
                action={() => setMode("online")}
              />
            </Grid>
          ) : null}
          <Grid item xs={8}>
            {globalReadyCheck2 ? (
              <div>
                <h3>Enter Global Variables:</h3>
                <form
                  className={classes.textfield_root}
                  noValidate
                  autoComplete="off"
                >
                  {variables.map((element) => (
                    <Tooltip
                      // options
                      title={element.tooltip}
                      trigger="mouseenter"
                    >
                      {element.type === "dropdown" ? (
                        <FormControl className={classes.formControl}>
                          <InputLabel htmlFor={element.name}>
                            {element.label}
                          </InputLabel>
                          <Select
                            onChange={handleDropdownChange}
                            id={element.name}
                            name={element.name}
                          >
                            {element.values.map((val) => (
                              <MenuItem value={val.value}>{val.label}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <TextField
                          className={classes.textfield}
                          id={element.name}
                          label={element.label}
                          variant="outlined"
                          onChange={handleTextChange}
                        />
                      )}
                    </Tooltip>
                  ))}
                </form>
              </div>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            {taskReadyCheck2 ? (
              <div className={classes.pipelineStepper}>
                <h3>Taskorder:</h3>
                {taskOrder.map((task) => {
                  if (count != taskOrderLength) {
                    count = count + 1;
                    return (
                      <div className={classes.pipeline_div}>
                        <Button
                          startIcon={<PlayCircleOutlineIcon />}
                          variant="outlined"
                          size="medium"
                          color={state[task]}
                          onClick={() => getTask(task)}
                          style={{ minWidth: "170px" }}
                          className={classes.pipelineButton}
                        >
                          {task}
                        </Button>
                        <ArrowRightIcon
                          className={classes.arrow}
                          fontSize="large"
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div className={classes.pipeline_div}>
                        <Button
                          startIcon={<PlayCircleOutlineIcon />}
                          id={task}
                          variant="outlined"
                          size="medium"
                          color={state[task]}
                          onClick={() => getTask(task)}
                          style={{ minWidth: "170px" }}
                          className={classes.pipelineButton}
                        >
                          {task}
                        </Button>
                      </div>
                    );
                  }
                })}
              </div>
            ) : null}
            {showGenerateButton ? (
              <CustomButton
                className={classes.gui_templateButton}
                label={`Generate ${wizard_type}`}
                color="purple"
                action={() => generateDocument()}
              />
            ) : null}
          </Grid>
          <Grid item xs={12}>
            {loadExcel ? (
              <GuiTemplateExcelView
                access_token={props.access_token}
                gui_templateID={gui_templateID}
                acceptType={acceptType}
              />
            ) : null}
          </Grid>
        </Grid>
        <ToastContainer />
        <Footer />
      </Container>
    );
  } else {
    return (
      <Container>
        <Header />
        <Grid item xs={12}>
          <div className="title-container">
            <h1>GuiTemplate Automation</h1>
          </div>
        </Grid>
        <Grid item xs={6}>
          Loading...
        </Grid>
        <Footer />
      </Container>
    );
  }
}
