import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";
import Button from "@material-ui/core/Button";
import CustomButton from "../../../components/Button/CustomButton.js";
import Grid from "@material-ui/core/Grid";
import { generateDCF, fetchExampleDCF } from "../../../common/utils.js";
import Container from "@material-ui/core/Container";
import TabComponent from "../../../components/TabPanel/TabComponent";

import { css } from "@emotion/react";
import XLSX from "xlsx";

// XSS Filter
import DOMPurify from 'dompurify';

//Loader css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    transitionDuration: "0.3s",
  },

  content: {
    textAlign: "center",
    height: "auto",
    paddingTop: "200",
    objectFit: "contain",
    paddingBottom: 100,
  },
  button: {
    width: "80%",
    marginRight: "10%",
    marginLeft: "10%",
    marginBottom: "1%",
    justifyContent: "center",
    textAlign: "center",
  },
  mystyle: {
    color: "rgb(85, 20, 180)",
  },
  inputWrapper: {
    paddingTop: "100",
  },
  inputfile: {
    paddingLeft: "right",
    backgroundColor: "rgb(85, 20, 180)",
    color: "white",
    borderRadius: "3px",
    marginBottom: "3rem",
  },
});

export default function ExcelView(props) {
  const templateId = props.template_id;
  const exampleDcf = props.exampleDcf;
  let [isDisabledDcf, setIsDisabledDcf] = useState(false);
  let [isDisabledGenerate, setIsDisabledGenerate] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [tableTabs, setTableTabs] = useState([]);
  const [isLoadingTable, setLoadingTable] = useState(true);

  const [inputReceived, setInputReceived] = useState("");
  const classes = useStyles();
  const cookies = new Cookies();
  const access_token = cookies.get("access_token");

  function convertListToDic(data) {
    let dict = {};
    data.forEach((entry) => {
      dict[entry] = "";
    });
    return [dict];
  }

  const generateDocument = () => {
    let file = document.getElementById("excel_file").files[0];
    const fileReader = new FileReader();

    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (e) => {
      const bufferArray = e.target.result
      // const bufferArray = e.target.result;
      const wb = XLSX.read(bufferArray, { type: "buffer" })
      const sheetNames = wb.SheetNames
      let tabs = [];
      let data = [];

      for (let i = 0; i < sheetNames.length; i++) {
        let ws = wb.Sheets[sheetNames[i]];
        let dataset = XLSX.utils.sheet_to_json(ws, { defval: "" });
        if (dataset.length === 0) {
          dataset = convertListToDic(
            XLSX.utils.sheet_to_csv(ws, { defval: "" }).split(",")
          );
        }
        
        tabs.push({ name: sheetNames[i] });

        let columns = [];
        let headers = Object.keys(dataset[0]);
        for(let header of headers) {
          let safeHeader = DOMPurify.sanitize(header) 
          if (safeHeader !== header ) {
            toast.error('Unsafe instruction detected: \n' + header);
            setTableTabs([])
            setTableData([])
            return false
          }
          columns.push({
            title: safeHeader,
            field: safeHeader,
            width: "15px",
            editor: "input",
          })}
        columns.push({
          title: "Delete",
          formatter: "buttonCross",
          width: "10px",
          headerSort: false,
          cellClick: function (f, cell) {
            if (window.confirm("Are you sure you want to delete this entry?"))
              cell.getRow().delete();
          },
        });

        data.push({ index: i, dataset: dataset, columns: columns });
      }

      setTableData(data);
      setTableTabs(tabs);
      setLoadingTable(false);
    };
  };

  return (
    <Container>
      <h3 className={classes.mystyle}>
        Do you have a pre-populated DCF spreadsheet?
      </h3>
      <br />
      <label
        className="container"
        onClick={() => {
          setInputReceived("true");
          setIsDisabledGenerate(true);
          if (document.getElementById("excel_file")) {
            document.getElementById("excel_file").value = null;
          }
          setLoadingTable(true);
        }}
      >
        Yes, continue
        <input id="yes-radio" type="radio" name="radio" />
      </label>
      <label
        className="container"
        onClick={() => {
          setLoadingTable(true);
          setIsDisabledGenerate(true);
          if (document.getElementById("excel_file")) {
            document.getElementById("excel_file").value = null;
          }
          setInputReceived("false");
        }}
      >
        No, help me
        <input id="no-radio" type="radio" name="radio" />
      </label>
      <br />
      <br />
      <br />

      {inputReceived === "false" ? (
        <div>
          <Grid item xs={12}>
            <div className={classes.button_container}>
              <div className={classes.button}>
                <CustomButton
                  label={"Generate Blank DCF"}
                  color="purple"
                  action={() => {
                    setIsDisabledDcf(true);
                    generateDCF(templateId, access_token, function () {
                      setIsDisabledDcf(false);
                    });
                  }}
                />
                <br />
              </div>
            </div>
          </Grid>
        </div>
      ) : null}
      {exampleDcf ? (
        <div className={classes.button}>
          <CustomButton
            label={"Download Example DCF"}
            color="green"
            action={() => {
              setIsDisabledDcf(true);
              fetchExampleDCF(templateId, access_token, function () {
                setIsDisabledDcf(false);
              });
            }}
          />
        </div>
      ) : (
        <div></div>
      )}

      {inputReceived !== "" ? (
        <Grid item xs={12}>
          <div className={classes.inputWrapper}>
            <input
              className={classes.inputfile}
              accept=".xlsx"
              type="file"
              id="excel_file"
              name="filename"
              onChange={function () {
                setIsDisabledGenerate(false);
                setLoadingTable(true);
              }}
            />
            <br />
          </div>
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <div className={classes.button}>
          {inputReceived !== "" && !isDisabledGenerate ? (
            <CustomButton
              label={"Generate Data Table"}
              color="purple"
              action={() => {
                generateDocument();
                setIsDisabledGenerate(false);
              }}
            />
          ) : null}

          {inputReceived !== "" && isDisabledGenerate ? (
            <Button variant="contained" disabled>
              Generate Data Table
            </Button>
          ) : null}
        </div>
      </Grid>

      {!isLoadingTable ? (
        <Grid item xs={12}>
          <div>
            <div id="tabulator">
              <TabComponent
                tabs={tableTabs}
                data={tableData}
                access_token={props.access_token}
                template_id={props.template_id}
                show_buttons={true}
              />
            </div>
          </div>
        </Grid>
      ) : null}

      <ToastContainer />
    </Container>
  );
}
