import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";
import { Space } from "antd";

const ColumnChart = ({ data, xField, yField, title }) => {
  const config = {
    data,
    xField,
    yField,
    autoFit: true,
    marginRatio: 0,
    autoFit: true,
    width: "100%", // Ensures the chart background is 100%
    color: () => {
      return "#5514b4";
    },
    label: {
      position: "top",
      style: {
        fill: "#000",
        fontSize: 14,
        fontWeight: "bold",
      },
    },
    legend: true,
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
        style: {
          fill: "#000",
          maxWidth: 10,
          //   fontSize: 14,
          fontWeight: "bold",
        },
      },
    },
  };
  return (
    <Space direction="vertical" size="large" style={{ display: "flex" }}>
      <h4 style={{ color: "#5514b4" }}>{title}</h4>
      <Column {...config} maxColumnWidth={25} />
    </Space>
  );
};

export default ColumnChart;
