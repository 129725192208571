import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import {
  Grid,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "../../components/Button/CustomButton.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { setStoreAccount } from "../../features/account";
import { useLocalStorage } from "../../modern/hooks/useLocalStorage";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "80px",
    marginBottom: "80px",
  },
  warning: {
    color: "gray ",
    // textAlign: "center",
    // width: "53vh",
    marginBottom: "0.5rem",
  },
  button: {
    width: "100%",
    marginBottom: "5%",
    justifyContent: "center",
    textAlign: "center",
  },
  link: {
    cursor: "pointer",
  },
  paperStyle: {
    padding: 40,
    height: "auto",
    margin: "50px auto",
    width: 300,
  },
  login: {
    marginTop: "50px",
    textAlign: "center",
  },
}));

export default function LoginPage() {
  const { setStorageItem, removeStorageItem } = useLocalStorage();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const classes = useStyles();
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [isUserValid, setIsUserValid] = useState(false);
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [buttonLabel, setButtonLabel] = useState("Login");

  const clearPesistentData = () => {
    const cookies = new Cookies();
    cookies.remove("access_token", {
      expires: "expires=Thu, 01 Jan 1970 00:00:00 UTC",
      path: "/",
    });
    cookies.remove("isAuthenticated", {
      expires: "expires=Thu, 01 Jan 1970 00:00:00 UTC",
      path: "/",
    });
    cookies.remove("scopes", {
      expires: "expires=Thu, 01 Jan 1970 00:00:00 UTC",
      path: "/",
    });
    cookies.remove("user", {
      expires: "expires=Thu, 01 Jan 1970 00:00:00 UTC",
      path: "/",
    });
    cookies.remove("email", {
      expires: "expires=Thu, 01 Jan 1970 00:00:00 UTC",
      path: "/",
    });
    cookies.remove("journey_id", {
      expires: "expires=Thu, 01 Jan 1970 00:00:00 UTC",
      path: "/",
    });
    cookies.remove("accounts", {
      expires: "expires=Thu, 01 Jan 1970 00:00:00 UTC",
      path: "/",
    });
    cookies.remove("uin", {
      expires: "expires=Thu, 01 Jan 1970 00:00:00 UTC",
      path: "/",
    });
    // cookies.remove("account_id", {
    //   expires: "expires=Thu, 01 Jan 1970 00:00:00 UTC",
    //   path: "/",
    // });
    // cookies.remove("account_name", {
    //   expires: "expires=Thu, 01 Jan 1970 00:00:00 UTC",
    //   path: "/",
    // });
    removeStorageItem("user");
  };

  useEffect(() => {
    clearPesistentData();
  });

  useEffect(() => {
    if (username.endsWith("@bt.com")) {
      setIsUserValid(true);
    }
  }, [username]);

  const handleUserValidation = () => {
    setIsUserValid(username.length > 0 && username.endsWith("@bt.com"));
  };

  const handleUsername = (e) => {
    setUsername(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      Login();
    }
  };

  const [loading, setLoading] = useState(false);

  const setAccount = (account) => {
    console.log("account selected", account);
    cookies.set("account_id", account.id, { path: "/" });
    cookies.set("account_name", account.name, { path: "/" });
    setStorageItem("currentAccount", {
      id: account.id,
      name: account.name,
    });
    dispatch(setStoreAccount({ id: account.id, name: account.name }));
  };

  const Login = async () => {
    setLoading(true);
    setButtonLabel("Authenticating...");
    const data = new FormData();
    const body = new FormData();
    data.append("username", username);
    data.append("password", password);
    await axios({
      method: "post",
      url: "/oauth2/token?client_id=DevClient&client_name=AutoDoc&grant_type=password",
      data: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => {
        const access_token = res.data["access_token"];
        const scopes = JSON.stringify(res.data["scope"]);
        const accounts = JSON.stringify(res.data["accounts"]);
        const uin = res.data.user["username"];
        const name =
          res.data.user["first_name"] + " " + res.data.user["last_name"];
        const email = res.data.user["email"];
        const last_name = res.data.user["last_name"];
        const first_name = res.data.user["first_name"];

        body.append("token", `${access_token}`);
        body.append("client_secret", "secret");
        body.append("client_id", "ApiResourceClient");
        axios({
          method: "post",
          url: "/oauth2/introspect",
          data: body,
        })
          .then((response) => {
            if (response.data.active === true) {
              cookies.set("access_token", access_token, { path: "/" });
              cookies.set("isAuthenticated", "true", { path: "/" });
              cookies.set("scopes", scopes, { path: "/" });
              cookies.set("user", name, { path: "/" });
              cookies.set("email", email, { path: "/" });
              cookies.set("uin", uin, { path: "/" });
              cookies.set("accounts", accounts, { path: "/" });
              // Setting Current Account
              const currentAccountId = JSON.parse(
                localStorage.getItem("currentAccount")
              )?.id;
              const isCustomerSet = JSON.parse(accounts)?.some(
                (account) => account["id"] === currentAccountId
              );
              console.log({ isCustomerSet, currentAccountId });
              if (
                accounts?.length > 0 &&
                (!currentAccountId || currentAccountId === "None")
              ) {
                console.log(
                  "login: setting up the first account from user account_list"
                );
                setAccount({
                  id: JSON.parse(accounts)[0]?.id,
                  name: JSON.parse(accounts)[0]?.name,
                });
              } else if (accounts?.length === 0) {
                setAccount({
                  id: "None",
                  name: "None",
                });
              }

              // Save items to local storage
              setStorageItem("user", {
                access_token,
                scopes,
                uin,
                email,
                first_name,
                last_name,
                fullname: name,
                accounts,
              });
              history.push("/");
              window.location.reload(true);
            }
          })
          .catch((err) => {
            console.log(err.response);
            toast.error("User session expired. Please login again.");
            history.push("/login");
            throw err;
          });
      })
      .catch((err) => {
        console.log(err.response);
        toast.error("Incorrect IUSER and/or password. Please try again.");
        throw err;
      });
    setLoading(false);
    setButtonLabel("Login");
  };

  const HandleTerms = (choice) => {
    setLoading(!choice);
    setOpenTerms(false);
  };

  return (
    <Container>
      {/* <Header page="journey" /> */}
      <Grid container className={classes.root} justify="center" spacing={3}>
        <Paper elevation={1} className={classes.paperStyle}>
          <h1 style={{ color: "#5514B4", textAlign: "center" }}>ngDS</h1>

          <form noValidate autoComplete="off">
            <TextField
              disabled={loading}
              size="small"
              error={!isUserValid}
              helperText={
                !isUserValid && "Please use a valid BT E-mail address"
              }
              label="Email"
              fullWidth
              margin="normal"
              onBlur={handleUserValidation}
              onChange={handleUsername}
              onKeyDown={handleKeyDown}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
            <TextField
              disabled={loading}
              size="small"
              label="Password"
              fullWidth
              margin="normal"
              type="password"
              onChange={handlePassword}
              onKeyDown={handleKeyDown}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
            <br />
            <br />

            <CustomButton
              color="purpleLogin"
              label={buttonLabel}
              action={() => Login()}
              disabled={loading}
            />

            <br />
            <Grid container justify="center">
              <Button
                onClick={() => setOpenTerms(true)}
                size="small"
                href="#text-buttons"
                // color="primary"
              >
                Terms and Conditions
              </Button>
            </Grid>

            <Grid container justify="center"></Grid>

            <Dialog open={openTerms} onClose={() => setOpenTerms(false)}>
              <DialogTitle color="blue">Terms and Conditions</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  You have accessed a BT proprietary system! You are required to
                  have a personal authorisation from the System Administrator
                  before you use this system and you are strictly limited to the
                  use set out in that written authorisation. Unauthorised access
                  to or misuse of this system is prohibited and may constitute
                  an offence under the Computer Misuse Act 1990. If you disclose
                  any information obtained through this system without authority
                  BT will take appropriate action against you.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => HandleTerms(false)}>I Disagree</Button>
                <Button
                  onClick={() => HandleTerms(true)}
                  color="primary"
                  autoFocus
                >
                  I Agree
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={open} onClose={() => setOpen(false)}>
              <DialogTitle>First Time User?</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <p>
                    To login use your BT Email and Password. <br /> If you have
                    not logged in before, an account will automatically be
                    created.
                  </p>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpen(false)}
                  color="primary"
                  autoFocus
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </Paper>
      </Grid>
      <ToastContainer />
      {/* <Footer /> */}
    </Container>
  );
}
