import { Space } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import BaseTable from "../../../../components/Tables/BaseTable";
import ShowRecordDetails from "../../../../components/Tables/ShowRecordDetails";
import { AlignLeftOutlined, ArrowsAltOutlined } from "@ant-design/icons";
import ShowRunning from "../../CiscoConnector/actions/ShowRunning";

interface RowAction {
  key: string;
  component: any;
  icon: React.JSX.Element;
  props?: any;
}

export default function TabPage({
  name,
  columns,
  data,
  actions,
  ciscoAccount,
}) {
  const actionMenu: RowAction[] = [
    {
      key: "listDetails",
      component: ShowRecordDetails,
      icon: <ArrowsAltOutlined />,
    },
    ...(actions?.includes("show_running")
      ? [
          {
            key: "showRunning",
            component: ShowRunning,
            props: { ciscoAccount },
            icon: <AlignLeftOutlined />,
          },
        ]
      : []),
  ];

  return (
    <Space
      direction="vertical"
      size="large"
      style={{ width: "100%", marginTop: 16 }}
    >
      {/* {contextHolder} */}
      <BaseTable
        // loading={loadingTenantData && !errorTenantData}
        tableName={name}
        dataSource={data}
        columns={columns}
        actions={actions}
        rowActions={actionMenu}
      />
    </Space>
  );
}
