import React, { useEffect } from "react";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Tabs,
  Tag,
  Typography,
  message,
} from "antd";

// Components
import ContentWrapper from "../../../components/ContentWrapper";

// Contexts
import type { TabsProps } from "antd";
// import Networks from './sections/Networks';
import {
  ApiOutlined,
  ArrowsAltOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import { CloudDownloadOutlined, ReloadOutlined } from "@ant-design/icons";
import { useMerakiConnectors } from "../../../contexts/Connectors/MerakiContext";
// import Devices from './sections/Devices';
import { useBFGConnectors } from "../../../contexts/Connectors/BFGContext";
import { ColumnsType } from "antd/es/table";
import BaseTable from "../../../components/Tables/BaseTable";
import { dynamicSort } from "../../../utils/sorter";
import ShowRecordDetails from "../../../components/Tables/ShowRecordDetails";
import { ChildrenProps } from "../../../types/ChildrenReactType";
import Sites from "./sections/Sites";
import ExportSiteListToExcel from "./functions/ExportSiteListToExcel";

// Redux
import { useSelector } from "react-redux";

const { Title, Text } = Typography;

const BFGConnector = () => {
  const accountState = useSelector((state: any) => state.account.value);

  const [messageApi, contextHolder] = message.useMessage();

  const bfg = useBFGConnectors();
  const sites = bfg.sites;
  const loadingSites = bfg.loadingSites;
  const errorSites = bfg.errorSites;
  const get_sites_by_bfg_id = bfg.get_sites_by_bfg_id;

  // Fetch once connected
  useEffect(() => {
    // Fetch Locations (includes VPNs)
    if (!sites && !errorSites && !loadingSites)
      get_sites_by_bfg_id && get_sites_by_bfg_id();
  }, [sites, errorSites, loadingSites]);

  const onReload = () => {
    get_sites_by_bfg_id();
  };

  useEffect(() => {
    onReload();
  }, [accountState]);

  // const sitesOptions = sites?.map((site: any) => (
  //     {
  //         label: <Space><Text>{site.name}</Text><Tag> {site.id}</Tag></Space>,
  //         value: site.id
  //     }))

  const BorderedContent: React.FC<ChildrenProps> = ({ children }) => {
    return (
      <div
        style={{
          padding: 16,
          marginTop: -16,
          borderLeft: "1px solid #F5F5F5",
          borderRight: "1px solid #F5F5F5",
          borderBottom: "1px solid #F5F5F5",
        }}
      >
        {children}
      </div>
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "sites",
      label: `Sites`,
      children: (
        <BorderedContent>
          <Sites />
        </BorderedContent>
      ),
    },
  ];

  const operations = (
    <>
      <ExportSiteListToExcel />
      <Divider type="vertical" />
      <Button size="small" icon={<ReloadOutlined />} onClick={onReload}>
        Reload
      </Button>
    </>
  );

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <h1 className="colorIndigo" style={{ marginBottom: 16 }}>
        BFG Connector
      </h1>
      <Card>
        <Space
          direction="vertical"
          size="large"
          style={{ width: "100%", marginTop: 16 }}
        >
          {contextHolder}
          <Tabs
            type="card"
            defaultActiveKey="1"
            items={items}
            // onChange={onChange}
            size="large"
            tabBarExtraContent={operations}
          />
        </Space>
      </Card>
    </Space>
  );
};

export default BFGConnector;
