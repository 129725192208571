import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";

const getAccounts = (access_token) => {
  let all_accounts = [];
  axios({
    method: "get",
    url: "/oauth2/accounts?complete=true",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
    },
  })
    .then((results) => {
      for (let entry of results.data) {
        all_accounts.push(entry.name);
      }
    })
    .catch((error) => {
      handleHttpError(error);
    });
  return all_accounts;
};

const getScopes = (access_token) => {
  let all_scopes = [];
  axios({
    method: "get",
    url: "/oauth2/scopes",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
    },
  })
    .then((results) => {
      for (let entry of results.data) {
        all_scopes.push(entry.name);
      }
    })
    .catch((error) => {
      handleHttpError(error);
    });
  return all_scopes;
};

const saveBlob = (blob, fileName) => {
  // IE11 check
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    var a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName.replace(/['"]+/g, "");
    const event = new MouseEvent("click", { bubbles: false });
    a.dispatchEvent(event);
  }
  toast.success(
    <div>
      {"Document generated:"} {fileName}
      <br />
      {"Successfully downloaded!"}
    </div>
  );
};

const getFileName = (headers) => {
  const fileNameString = headers["content-disposition"];
  return fileNameString.substring(
    fileNameString.lastIndexOf("=") + 1,
    fileNameString.length
  );
};

const generateDCF = (template_id, access_token, callback) => {
  axios({
    method: "get",
    url: "/api/v1/templates/" + template_id,
    headers: {
      Accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      Authorization: "Bearer " + access_token,
    },
    responseType: "blob",
  })
    .then((res) => {
      let fileName = getFileName(res.headers);
      saveBlob(res.data, fileName);
    })
    .catch((err) => {
      handleHttpError(err);
      throw err;
    });
  runCallback(callback);
};

const fetchExampleDCF = (template_id, access_token, callback) => {
  axios({
    method: "get",
    url: "/api/v1/templates/" + template_id + "/dcf",
    headers: {
      Accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      Authorization: "Bearer " + access_token,
    },
    responseType: "blob",
  })
    .then((res) => {
      let fileName = getFileName(res.headers);
      saveBlob(res.data, fileName);
    })
    .catch((err) => {
      handleHttpError(err);
      throw err;
    });
  runCallback(callback);
};
const downloadDocument = async (template_id, context, callback) => {
  const cookies = new Cookies();
  let access_token = cookies.get("access_token");
  let account_id = cookies.get("account_id");
  let journey_id = cookies.get("journey_id");
  let journey = journey_id !== undefined ? `&journey_id=${journey_id}` : "";

  let url = "";
  if (
    account_id === "null" ||
    account_id === undefined ||
    account_id === "None"
  ) {
    url = `/api/v1/templates/${template_id}?${journey}`;
  } else {
    url = `/api/v1/templates/${template_id}?account_id=${account_id}${journey}`;
  }
  await axios({
    method: "post",
    url: url,
    headers: {
      Accept: "application/octet-stream",
      Authorization: "Bearer " + access_token,
      contentType: "application/json",
    },
    responseType: "blob",
    data: context,
  })
    .then((res) => {
      let fileName = getFileName(res.headers);
      saveBlob(res.data, fileName);
    })
    .catch((err) => {
      handleHttpError(err);
      throw err;
    });
  runCallback(callback);
};

// Function to create multiple documents -- multiple calls
export const multipleDocuments = async (templatesIds, context) => {
  for (const templateID of templatesIds) {
    await downloadDocument(templateID, context);
  }
};

const downloadTemplate = async (template_id, callback) => {
  const cookies = new Cookies();
  let access_token = cookies.get("access_token");
  await axios({
    method: "get",
    url: "/api/v1/templates/" + template_id,
    headers: {
      Accept: "application/octet-stream",
      Authorization: "Bearer " + access_token,
    },
    responseType: "blob",
  })
    .then((res) => {
      let fileName = getFileName(res.headers);
      saveBlob(res.data, fileName);
    })
    .catch((err) => {
      handleHttpError(err);
      throw err;
    });
  runCallback(callback);
};

const downloadTemplatesByLibraryId = async (library_id, callback) => {
  const cookies = new Cookies();
  let access_token = cookies.get("access_token");
  await axios({
    method: "get",
    url: "/api/v1/libraries/" + library_id + "/templates",
    headers: {
      Accept: "application/zip",
      Authorization: "Bearer " + access_token,
    },
    responseType: "blob",
  })
    .then((res) => {
      let fileName = getFileName(res.headers);
      saveBlob(res.data, fileName);
    })
    .catch((err) => {
      handleHttpError(err);
      throw err;
    });
  runCallback(callback);
};

const runCallback = (callback) => {
  if (typeof callback != "undefined") {
    callback();
  }
};

const handleHttpError = (err) => {
  // Check there's actually an HTTP response first
  if (err.response) {
    if (err.response.data instanceof Blob) {
      var reader = new FileReader();
      reader.onload = function () {
        // Extract the blob and overwrite the err response data section
        err.response.data = JSON.parse(reader.result);
        // Send the error to be properly handled by the http handler
        showError(err);
      };
      // Send it to the file reader
      reader.readAsText(err.response.data);
    } else {
      // Pass it straight on through!
      showError(err);
    }
  }
};
/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * @param {String} property Key of the object to sort.
 */
function dynamicSort(property) {
  var sortOrder = 1;

  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a, b) {
    if (sortOrder === -1) {
      return b[property].localeCompare(a[property]);
    } else {
      return a[property].localeCompare(b[property]);
    }
  };
}
const showError = (err) => {
  if (err.response.status === 500) {
    toast.error(
      "ngDS has encountered a problem, please contact your support channels and report an issue"
    );
  }
  if (err.response.status === 400) {
    // Return the error to the user
    toast.error(err.response.data.error);
  }
  if (err.response.status === 401) {
    // User is not properly logged in,
    // redirect to login screen
    window.href = "/login";
    // Fallback if the above doesn't work
    window.location = "/login";
  }
  if (err.response.status === 403) {
    // User does not have access
    toast.error("I'm afraid you do not have access to this feature");
  }
  if (err.response.status === 422) {
    // This is returned when there's something wrong
    // with the template that we provide
    toast.error(err.response.data.error);
  }
  if (err.response.status === 417) {
    // This is returned when there's something wrong
    // with the template that we provide
    toast.error(err.response.data.error);
  }
  if (err.response.status === 425) {
    // This is returned when there's something wrong
    // with the template that we provide
    toast.error(err.response.data.error);
  }
};

export {
  handleHttpError,
  saveBlob,
  getFileName,
  generateDCF,
  fetchExampleDCF,
  downloadDocument,
  downloadTemplate,
  downloadTemplatesByLibraryId,
  getAccounts,
  getScopes,
  dynamicSort,
};
