import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  Form,
  Progress,
  Space,
  Typography,
  Tabs,
  Descriptions,
} from "antd";
import { useConnectors } from "../../../../../contexts/Connectors/ZscalerContext";
import type { TabsProps } from "antd";
import { PlusOutlined } from "@ant-design/icons";
// Forms
import NewSubLocationForm from "../../Forms/NewSubLocationForm";
import { ZscalerLocationType } from "../../../../../types/ConnectorsType";
import axios from "axios";
import { NgdsErrorType } from "../../../../../types/NgdsErrorType";
import ResultError from "../../Results/ResultError";
import ResultOk from "../../Results/ResultOk";
import LoadingProgress from "../../LoadingProgress";
import { listSublocationsByParentId } from "../../functions/fetchZscaler";

const { Text, Paragraph } = Typography;

type SubProps = { record: ZscalerLocationType; isButton?: boolean };

const CreateSubLocation: React.FC<SubProps> = ({
  record,
  isButton = false,
}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const access_token = user.access_token;
  const currentAccount = JSON.parse(localStorage.getItem("currentAccount"));
  const account_id = currentAccount.id;

  const connectors = useConnectors();

  const [form] = Form.useForm();

  const [submittable, setSubmittable] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [completed, setCompleted] = useState(false);

  const [subLocationNew, setSubLocationNew] = useState<
    ZscalerLocationType | undefined
  >(undefined);
  const [subLocationNewLoading, setSubLocationNewLoading] =
    useState<boolean>(false);
  const [subLocationNewError, setSubLocationNewError] = useState<
    NgdsErrorType | undefined
  >(undefined);

  const [ipAddresses, setIpAddresses] = useState<string | undefined>(undefined);
  const [subLocationName, setSubLocationName] = useState<string | undefined>(
    undefined
  );

  const onReset = () => {
    form.resetFields();
    setSubmitting(false);
    setSubmittable(false);
    setSubLocationNew(undefined);
    setSubLocationNewError(undefined);
    setIpAddresses(undefined);
    setSubLocationName(undefined);
    setCompleted(false);
  };

  useEffect(() => {
    if (ipAddresses && subLocationName) {
      setSubmittable(true);
    } else {
      setSubmittable(false);
    }
  }, [ipAddresses, subLocationName]);

  const items: TabsProps["items"] = [
    {
      key: "NewSubLocation",
      label: `Sub-Location Attributes`,
      children: (
        <NewSubLocationForm
          form={form}
          record={record}
          setIpAddresses={setIpAddresses}
          setSubLocationName={setSubLocationName}
        />
      ),
    },
  ];

  const onChange = (key: string) => {
    console.log(key);
  };

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    if (subLocationNew?.parentId) {
      // connectors?.fetchLocations()
      connectors?.fetchLocationsAndCredentials();
      listSublocationsByParentId({ parent_id: subLocationNew.parentId });
    }
    setOpen(false);
    onReset();
  };

  const submitSubLocation = async () => {
    console.log("Submitting New Sub-Location");
    setSubmitting(true);
    setSubmittable(false);
    const data = form.getFieldsValue();
    console.log({ sublocations_values: data });

    const url = "/api/v1/api_connectors";
    const cloud = localStorage.getItem("zscaler_cloud_domain");

    console.log("Creating New Sub-Location");
    setSubLocationNewLoading(true);
    axios
      .post(
        url,
        {
          account_id: +account_id,
          connector: "zscaler",
          action: "add_location",
          params: { ...data, ipAddresses: [data.ipAddresses], cloud },
        },
        { headers: { Authorization: "Bearer " + access_token } }
      )
      .then((resp: any) => {
        const response = resp.data;
        console.log({ response });
        const status = resp.status;
        if (status === 200) {
          setSubLocationNew(resp.data);
          console.log({ response_sub_create: resp.data });
        } else if (response.includes("error")) {
          setSubLocationNewError(response);
        }
      })
      .catch((error: any) => {
        console.log({ error_create_location: error });
        setSubLocationNewError(error.response?.data || error);
      })
      .finally(() => {
        setSubLocationNewLoading(false);
        setCompleted(true);
      });
  };

  const progressParams = {
    descriptors: [
      {
        key: "1",
        label: "Location",
        value: form.getFieldValue(["name"]),
      },
    ],
    progressors: [
      {
        key: "2",
        text: "Creating Location",
        loading: subLocationNewLoading,
        success: subLocationNew?.hasOwnProperty("id"),
        exception: subLocationNewError,
        dependOn: true,
      },
    ],
  };
  // const subLocations = connectors?.subLocations
  // const [isLocationExists, setIsLocationExists] = useState<boolean>(false)
  // // Location Name Validation
  // const locationNameList = connectors?.subLocations?.map((loc: ZscalerLocationType) => (loc.name))
  // const evaluteLocationExistence = () => locationNameList?.some(item => item === subLocationName) ? setIsLocationExists(true) : setIsLocationExists(false)
  // useEffect(() => evaluteLocationExistence(), [subLocationName])
  // const locationAlert = isLocationExists &&
  //     <>
  //         <Alert
  //             banner
  //             showIcon
  //             message={`Location ${subLocationName} already exists`}
  //             type="warning"
  //             action={
  //                 <Button size="small" type='link' onClick={() => {
  //                     onReset()
  //                 }}>Reset</Button>
  //             }
  //         />
  //     </>

  const CreationProgress = (
    <Space direction="vertical" size="large" wrap style={{ width: "100%" }}>
      {/* {locationAlert} */}

      <Descriptions size="small" layout="vertical" column={1}>
        <Descriptions.Item label="Sub-Location">
          {form.getFieldValue(["name"])}
        </Descriptions.Item>
      </Descriptions>

      {LoadingProgress(progressParams)}

      {subLocationNew?.id && (
        <div style={{ backgroundColor: "#eee" }}>
          <ResultOk
            title="Sub-Location Created Successfully!"
            subTitle={
              <Descriptions
                size="small"
                column={1}
                bordered
                style={{ marginTop: 32 }}
              >
                <Descriptions.Item label="Name">
                  {" "}
                  <Text>{subLocationName} Kbps </Text>
                </Descriptions.Item>
                <Descriptions.Item label="Upload BW">
                  {" "}
                  <Text>{subLocationNew.upBandwidth} Kbps </Text>
                </Descriptions.Item>
                <Descriptions.Item label="Download BW">
                  <Text>{subLocationNew.dnBandwidth} Kbps</Text>
                </Descriptions.Item>
                <Descriptions.Item label="IP Addresses">
                  {subLocationNew.ipAddresses.map((ip: string) => (
                    <Paragraph key={ip}>{ip}</Paragraph>
                  ))}{" "}
                </Descriptions.Item>
                <Descriptions.Item label="Country">
                  <Text> {subLocationNew.country} </Text>
                </Descriptions.Item>
                <Descriptions.Item label="Time Zone">
                  <Text> {subLocationNew.tz} </Text>
                </Descriptions.Item>
                <Descriptions.Item label="Parent ID">
                  <Text> {subLocationNew.parentId} </Text>
                </Descriptions.Item>
              </Descriptions>
            }
          />
        </div>
      )}

      {subLocationNewError && (
        <ResultError errorPayload={subLocationNewError} />
      )}
    </Space>
  );

  return (
    <>
      {isButton && (
        <Button icon={<PlusOutlined />} onClick={showDrawer}>
          Create Sub-Location
        </Button>
      )}
      {!isButton && <a onClick={showDrawer}>Create Sub-Location</a>}

      <Drawer
        maskClosable={!subLocationNewLoading}
        closable={!subLocationNewLoading}
        title="New Sub-Location"
        placement="right"
        onClose={onClose}
        open={open}
        width={450}
        extra={
          <Space>
            <Divider type="vertical" />
            <Button onClick={onReset}>Reset</Button>
            <Button
              disabled={!submittable || completed}
              type="primary"
              onClick={submitSubLocation}
            >
              Create
            </Button>
          </Space>
        }
      >
        {submitting ? (
          CreationProgress
        ) : (
          <Tabs
            defaultActiveKey="NewSubLocation"
            items={items}
            onChange={onChange}
            centered
          />
        )}
      </Drawer>
    </>
  );
};

export default CreateSubLocation;
