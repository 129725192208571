import { PlusOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import React, { useState } from "react";
import { ChildrenProps } from "../../../../../types/ChildrenReactType";

interface Props {
  children: React.ReactNode;
  reset: () => void;
}

const ButtonDrawer: React.FC<Props> = ({ children, reset }) => {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
    reset();
  };

  return (
    <>
      <Button
        size="small"
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setOpen(true)}
        // type="primary"
      >
        Deploy Site
      </Button>
      <Drawer
        destroyOnClose
        onClose={() => onClose()}
        maskClosable={false}
        title="Palo Alto API Connector | Deploy Site"
        width={"50%"}
        open={open}
        extra={
          <Button size="small" onClick={() => reset()}>
            Reset
          </Button>
        }
      >
        {children}
      </Drawer>
    </>
  );
};

export default ButtonDrawer;
