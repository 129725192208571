import { Button, Space, Typography, Tree, Tag } from "antd";
import type { TreeDataNode } from "antd";
import React, { useState } from "react";
import { parse_datasource } from "../functions/parse_datasource";
import { generate_payload } from "../functions/generate_payload";
import { jsonPayloadsType } from ".";
import { parse_context } from "../functions/post_api_calls";
import { usePaloAltoConnectors } from "../../../../contexts/Connectors/PaloAltoContext";
import { ParsedContext } from "../../../../types/PaloAltoConnectorsType";

import {
  DownOutlined,
  FrownFilled,
  FrownOutlined,
  MehOutlined,
  SmileOutlined,
  HomeOutlined,
  ClusterOutlined,
  BarcodeOutlined,
  CloudOutlined,
  TagOutlined,
  SecurityScanOutlined,
  GlobalOutlined,
  ClockCircleOutlined,
  ShareAltOutlined,
  SwapOutlined,
  DeploymentUnitOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import DeploySite from "./DeployContext";

const { Text } = Typography;

const ParseContext = ({
  dataSource,
  selected,
  extracted,
  setExtracted,
  disabled,
  setDisabled,
  context,
  setContext,
}) => {
  const [loading, setLoading] = useState(false);

  const connector = usePaloAltoConnectors();
  const api_key = connector.apiKey;

  const parseContextToSiteData = async () => {
    setLoading(true);
    console.log("parsing the data source");
    const ctx = await parse_datasource(selected, dataSource);
    const context_response = ctx.data;
    setContext(context_response);
    const error = ctx.error;
    error && console.log({ error });
    if (context_response) {
      const extracted_context = await parse_context(api_key, context_response);
      setExtracted(extracted_context?.data);
      setLoading(false);
      setDisabled(true);
    }
  };

  console.log({ context });

  return (
    <>
      {!disabled && (
        <Button
          onClick={async () => await parseContextToSiteData()}
          disabled={!dataSource || selected.length === 0 || disabled}
          loading={loading}
        >
          Select
        </Button>
      )}

      {extracted?.map((siteData: ParsedContext) => (
        <>
          <SiteTree extractedSite={siteData} context={context} />
        </>
      ))}
    </>
  );
};

export default ParseContext;

const SiteTree: React.FC<{
  extractedSite: ParsedContext;
  context: any;
}> = ({ extractedSite, context }) => {
  const devices = extractedSite?.devices;
  const policies = extractedSite?.policies;

  const devicesLeafs = [
    {
      key: "WAN",
      data: extractedSite?.wan,
      icon: <CloudOutlined />,
      titleKey: "circuit_name",
    },
    {
      key: "LAN",
      data: extractedSite?.lan,
      icon: <ClusterOutlined />,
      titleKey: "lan_port",
    },
    {
      key: "VLANs",
      data: extractedSite?.vlans,
      icon: <ClusterOutlined />,
      titleKey: "subinterface",
    },
    {
      key: "DNS",
      data: extractedSite?.dns,
      icon: <GlobalOutlined />,
      titleKey: "dns_service_profile",
    },
    {
      key: "NTP",
      data: extractedSite?.ntp,
      icon: <ClockCircleOutlined />,
      titleKey: "ntp_name",
    },
    {
      key: "Security",
      data: extractedSite?.security_zones,
      icon: <SecurityScanOutlined />,
      titleKey: "zone",
    },
    {
      key: "BGP Instance",
      data: extractedSite?.bgp,
      icon: <ShareAltOutlined />,
      titleKey: "local_bgp",
    },
    {
      key: "BGP Peers",
      data: extractedSite?.bgp_peers,
      icon: <ShareAltOutlined />,
      titleKey: "peer_name",
    },
    {
      key: "Static Routes",
      data: extractedSite?.static_routes,
      icon: <SwapOutlined />,
      titleKey: "route_name",
    },
  ];

  console.log({
    extractedSite,
  });

  const title = extractedSite?.site;
  const region = extractedSite?.region;

  const treeData: TreeDataNode[] = [
    {
      title: (
        <Space size="small">
          <Text strong>{title}</Text>
          {region && <Tag>{region}</Tag>}
        </Space>
      ),
      key: title,
      icon: <HomeOutlined />,
      children: [
        {
          title: "Policies",
          key: `policies-${extractedSite?.hostname}`,
          icon: <AuditOutlined />,
          children: Object.keys(policies[0]).map(
            (item: any, index: number) => ({
              title: (
                <Space size="small">
                  <Text>{item} :</Text>
                  <Tag>{policies[0][item] || "N/A"}</Tag>
                </Space>
              ),
              key: `${item.name}-${index}`,
            })
          ),
        },
        {
          title: "Devices",
          key: "devices",
          icon: <BarcodeOutlined />,
          children: devices.map((device: any, index: number) => ({
            title: (
              <Space size="small">
                <Text strong>{device.hostname}</Text>
                <Tag icon={<BarcodeOutlined />}>{device.serial_number}</Tag>
                <Tag>{device.model}</Tag>
              </Space>
            ),
            key: `device-${device.hostname}`,
            children: devicesLeafs.map((leaf: any, index: number) => {
              const isData = leaf.data.length > 0;
              return {
                title: `${leaf.key}${isData ? "" : " (N/A)"}`,
                key: `leaf-${leaf.key}-${index}-${device.hostname}`,
                icon: leaf.icon,
                disabled: !isData,
                children: leaf.data
                  .filter((item: any) => {
                    const itemHostname = item.hostname;
                    return itemHostname
                      ? itemHostname === device.hostname
                      : item;
                  })
                  .map((item: any, index: number) => ({
                    title: item[leaf.titleKey] || index + 1,
                    key: `childleaf-${leaf.key}-${index}-${device.hostname}`,
                    children: Object.keys(item).map((attribute) => ({
                      title: (
                        <Space size="small">
                          <Text>{attribute} :</Text>
                          <Tag>{item[attribute]}</Tag>
                        </Space>
                      ),
                      key: `grandchildleaf${attribute}-${index}-${device.hostname}`,
                    })),
                  })),
              };
            }),
          })),
        },
      ],
    },
  ];

  // const expandKeys = treeData.flatMap((item: TreeDataNode) =>
  //   item.children.flatMap((child: TreeDataNode) => child.key)
  // );

  const expandKeys = devices.map((device: any) => `device-${device.hostname}`);

  return (
    <Tree
      blockNode
      showLine={{ showLeafIcon: false }}
      showIcon
      defaultExpandAll={false}
      switcherIcon={<DownOutlined />}
      treeData={treeData}
      defaultExpandedKeys={expandKeys}
    />
  );
};
