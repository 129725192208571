import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "@material-ui/core/Container";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handleHttpError } from "../../../common/utils.js";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "130px",
    textAlign: "center",
    marginBottom: "80px",
  },
  title: {
    color: "rgb(85, 20, 180)",
  },
  table: {
    minWidth: 650,
  },
  secondaryTab: {
    width: 500,
    marginLeft: "28%",
  },
}));

function createData(EIN, Email, Scope, Account, Justification, Date, Action) {
  return { EIN, Email, Scope, Account, Justification, Date, Action };
}

export default function AccessManagement(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [requestsList, setrequestsList] = useState(null);
  const [requestsLoaded, setrequestsLoaded] = useState(false);
  const [closedRequestsList, setClosedRequestsList] = useState(null);
  const [closedRequestsLoaded, setClosedRequestsLoaded] = useState(false);
  const [newResults, setNewResults] = useState(false);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const actionRequest = (action, request_id) => {
    const bodyData = { status: action };
    axios({
      method: "patch",
      url: "/oauth2/requests/" + request_id,
      data: bodyData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((res) => {
        toast.success("Request successfully actioned.");
        getRequests();
      })
      .catch((err) => {
        handleHttpError(err);
      });
  };

  const getRequests = () => {
    axios({
      method: "get",
      url: "/oauth2/requests",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((res) => {
        if (res.data.length === 0) {
          setNewResults(false);
          document.getElementById("error_body").innerHTML =
            "No new requests have been made by users.";
        } else {
          setNewResults(true);
          var accounts_list = [];
          for (let x = 0; x < res.data.length; x++) {
            var accounts = [];
            for (let y = 0; y < res.data[x].accounts.length; y++) {
              accounts.push(res.data[x].accounts[y].name);
            }
            accounts_list.push(
              createData(
                res.data[x].username,
                res.data[x].user.email,
                res.data[x].scope,
                accounts.join(", "),
                res.data[x].description,
                res.data[x].timestamp,
                <div>
                  <button
                    onClick={() => {
                      actionRequest("approved", res.data[x].id);
                    }}
                  >
                    Accept
                  </button>
                  <button
                    onClick={() => {
                      actionRequest("rejected", res.data[x].id);
                    }}
                  >
                    {" "}
                    Reject
                  </button>
                </div>
              )
            );
          }
          setrequestsList(accounts_list);
          setrequestsLoaded(true);
        }
      })
      .catch((err) => {
        handleHttpError(err);
      });
  };
  const getClosedRequests = () => {
    axios({
      method: "get",
      url: "/oauth2//requests?closed=true",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((closed_results) => {
        var accounts_list = [];
        for (let x = 0; x < closed_results.data.length; x++) {
          var accounts = [];
          for (let y = 0; y < closed_results.data[x].accounts.length; y++) {
            accounts.push(closed_results.data[x].accounts[y].name);
          }
          accounts_list.push(
            createData(
              closed_results.data[x].username,
              closed_results.data[x].user.email,
              closed_results.data[x].scope,
              accounts.join(", "),
              closed_results.data[x].description,
              closed_results.data[x].timestamp
            )
          );
        }
        setClosedRequestsList(accounts_list);
        setClosedRequestsLoaded(true);
      })
      .catch((err) => {
        handleHttpError(err);
      });
  };

  useEffect(() => {
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?access";
    window.history.pushState({ path: newurl }, "", newurl);
    getRequests();
    getClosedRequests();
  }, [value]);

  return (
    <Container>
      <AppBar
        className={classes.secondaryTab}
        position="static"
        color="default"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="OPEN" {...a11yProps(0)} />
          <Tab label="CLOSED" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          {newResults ? (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>EIN</b>
                    </TableCell>
                    <TableCell>
                      <b>Email</b>
                    </TableCell>
                    <TableCell>
                      <b>Scope Requested</b>
                    </TableCell>
                    <TableCell>
                      <b>Account</b>
                    </TableCell>
                    <TableCell>
                      <b>Justification</b>
                    </TableCell>
                    <TableCell>
                      <b>Date</b>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {requestsLoaded ? (
                  <TableBody>
                    {requestsList.map((request) => (
                      <TableRow key={request.name}>
                        <TableCell>{request.EIN}</TableCell>
                        <TableCell>{request.Email}</TableCell>
                        <TableCell>{request.Scope}</TableCell>
                        <TableCell>{request.Account}</TableCell>
                        <TableCell>{request.Justification}</TableCell>
                        <TableCell>{request.Date}</TableCell>
                        <TableCell>{request.Action}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>
          ) : (
            <div id="error_body"></div>
          )}
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>EIN</b>
                  </TableCell>
                  <TableCell>
                    <b>Email</b>
                  </TableCell>
                  <TableCell>
                    <b>Scope Requested</b>
                  </TableCell>
                  <TableCell>
                    <b>Account</b>
                  </TableCell>
                  <TableCell>
                    <b>Justification</b>
                  </TableCell>
                  <TableCell>
                    <b>Date</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              {closedRequestsLoaded ? (
                <TableBody>
                  {closedRequestsList.map((request) => (
                    <TableRow key={request.name}>
                      <TableCell>{request.EIN}</TableCell>
                      <TableCell>{request.Email}</TableCell>
                      <TableCell>{request.Scope}</TableCell>
                      <TableCell>{request.Account}</TableCell>
                      <TableCell>{request.Justification}</TableCell>
                      <TableCell>{request.Date}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : null}
            </Table>
          </TableContainer>
        </TabPanel>
      </SwipeableViews>
      <ToastContainer />
    </Container>
  );
}
