import React from "react";
import { exportAsExcel } from "../../../../utils/exportAsExcel";
import { useTables } from "../../../../contexts/TablesContext";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useBFGConnectors } from "../../../../contexts/Connectors/BFGContext";

const ExportSiteListToExcel = () => {
  const bfg = useBFGConnectors();
  const sites = bfg.sites;
  const loadingSites = bfg.loadingSites;

  const exportToExcel = async () => {
    const isCreated = await exportAsExcel({
      sites,
    });
    isCreated ? console.log("File Downloaded") : console.log("Failed Download");
  };

  return (
    <Button
      disabled={sites?.length === 0}
      size="small"
      icon={<CloudDownloadOutlined />}
      onClick={exportToExcel}
    >
      Site List to Excel
    </Button>
  );
};

export default ExportSiteListToExcel;
