import React from "react";
import { exportAsExcel } from "../../../../utils/exportAsExcel";
import { useTables } from "../../../../contexts/TablesContext";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useConnectors } from "../../../../contexts/Connectors/ZscalerContext";

const ExportToExcel = () => {
  const zscaler = useConnectors();
  const locations = zscaler?.locationsAndCredentials?.locations
  const vpnCredentials = zscaler?.vpnCredentials?.vpn_credentials
  // const locations = zscaler.locations;
  const vips = zscaler.vips;
  // const vpnCredentials = zscaler.vpnCredentials;

  const exportToExcel = async () => {
    const isCreated = await exportAsExcel({
      locations,
      vpnCredentials,
      vips,
    });
    isCreated ? console.log("File Downloaded") : console.log("Failed Download");
  };

  return (
    <Button
      disabled={locations?.length === 0}
      size="small"
      icon={<CloudDownloadOutlined />}
      onClick={exportToExcel}
    >
      Export to Excel
    </Button>
  );
};

export default ExportToExcel;
