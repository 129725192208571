import { FC, useState, useEffect } from "react";
import {
  Button,
  message,
  Flex,
  Card,
  Upload,
  UploadProps,
  UploadFile,
  Collapse,
  Typography,
} from "antd";
import type { MenuProps } from "antd";
import type { SelectProps } from "antd";
import { UploadOutlined, FileOutlined } from "@ant-design/icons";
import { useMerakiConnectors } from "../../../../../contexts/Connectors/MerakiContext";
import { InboxOutlined } from "@ant-design/icons";
import LoadingProgress from "../../../ZscalerConnector/LoadingProgress";
import React from "react";
import { deploy_calls } from "./functions/deploy_calls";
// import { update_network } from "./functions/update_network";

const { Title, Text } = Typography;

const Deployement = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { Dragger } = Upload;
  const meraki = useMerakiConnectors();
  const orgId = meraki.orgId;
  // const loadingUpdateNetwork = meraki.loadingUpdateNetwork;
  // const updateNetwork = meraki.updateNetwork;
  const api_key = meraki.apiKey

  const [files, setFiles] = useState([]);
  const [dataList, setDataList] = useState([]);


  console.log({ dataList })

  const readFile = async (file) => {
    return await new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const processData = () => {
    const updateContainer = [];
    files.forEach((file) => {
      const filename = file.name;
      const [network_id, crud_method, operation_name] = filename?.split("-");
      readFile(file.originFileObj).then((payload) => {
        updateContainer.push({
          filename,
          crud_method,
          network_id,
          operation_name,
          payload,
        });
      });
    });
    setDataList(updateContainer);
  };

  useEffect(() => {
    processData();
  }, [files]);

  const props: UploadProps = {
    name: "file",
    listType: "picture",
    multiple: true,
    accept: ".json",
    defaultFileList: files,
    onChange(info) {
      // console.log(info.file, info.fileList);
      setFiles(info.fileList);
    },
    beforeUpload: (file) => {
      const filenames = files.map((item) => item.name);
      const isDuplicated = filenames.includes(file.name);
      if (isDuplicated) {
        message.error(`${file.name} is already selected`);
      }
      return false; // To not upload it, just load it
    },
    onRemove: (file: UploadFile) => {
      if (dataList.some((data) => data.filename === file.name)) {
        setDataList((update) =>
          update.filter((data) => data.filename !== file.name)
        );
        return true;
      }
      return false;
    },
  };
  const [next, setNext] = useState(false);

  // useEffect(() => {
  //   success();
  // }, [loadingUpdateNetwork]);

  // const success = () => {
  //   loadingUpdateNetwork &&
  //     messageApi.open({
  //       type: "loading",
  //       content: "Update in progress..",
  //       duration: 0,
  //     });
  // };

  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(undefined)

  return (
    <Flex vertical gap="large">
      {contextHolder}
      <Title level={3}>Deployment</Title>
      {!next ? (
        <>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag your JSON files to this area
            </p>
            <p className="ant-upload-hint">
              {
                " Support for a single or bulk upload. Filename should follow this pattern: <network_id>-<crud>-<update_feature>-payload.json"
              }
            </p>
          </Dragger>

          <Button
            onClick={() => setNext(true)}
            type="primary"
            disabled={files.length === 0}
          >
            Next
          </Button>
        </>
      ) : (
        <Card
          title="Are you sure you want to deploy the following?"
          actions={[
            <Flex style={{ marginRight: 24, float: "right" }}>
              <Button
                onClick={() => setNext(false)}
                type="text"
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  setLoading(true)
                  const resp = await deploy_calls(api_key, dataList)
                  setResponse(resp)
                  setLoading(false)
                }}
                type="primary"
                danger
                loading={loading}
              >
                Deploy
              </Button>
            </Flex>,
          ]}
        >
          <Collapse
            items={dataList.map((update) => ({
              key: update.filename,
              label: (
                <Text>
                  {update.crud_method} <strong>{update.name}</strong> for{" "}
                  <strong>{update.network_id}</strong>
                </Text>
              ),
              children: (
                <pre>
                  <Text>
                    {JSON.stringify(JSON.parse(update.payload), null, 4)}
                  </Text>
                </pre>
              ),
            }))}
          />
        </Card>
      )}
    </Flex>
  );
};

export default Deployement;
