import React, { FC, useState } from "react";
import { Button, Col, Row, Space, Typography, message } from "antd";
import { saveAs } from "file-saver";
import TemplateWidget from "./TemplatesSelect";
import DataSourceWidget from "./DataSourcesSelect";
import axios from "axios";

const { Title } = Typography;

//  Get Localstorage Data
const user = JSON.parse(localStorage.getItem("user"));
const access_token = user?.access_token;
const account = JSON.parse(localStorage.getItem("currentAccount"));
const account_id = account?.id;

const generateJourneyId = async () => {
  await axios({
    method: "post",
    url: "/api/v1/stats/journeys",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
    },
  })
    .then((res) => {
      localStorage.setItem("journey_id", res.data["id"]);
    })
    .catch((err) => {
      console.log(err);
    });
};

const downloadDocument = async (template_id: any, context: any) => {
  await generateJourneyId();
  let journey_id = localStorage.getItem("journey_id");
  let journey = journey_id !== undefined ? `&journey_id=${journey_id}` : "";

  let url = "";
  if (
    account_id === "null" ||
    account_id === undefined ||
    account_id === "None"
  ) {
    url = `/api/v1/templates/${template_id}?${journey}`;
  } else {
    url = `/api/v1/templates/${template_id}?account_id=${account_id}${journey}`;
  }
  axios({
    method: "post",
    url: url,
    headers: {
      Accept: "application/octet-stream",
      Authorization: "Bearer " + access_token,
      contentType: "application/json",
    },
    responseType: "blob",
    data: context,
  })
    .then((res) => {
      const disposition = res.headers["content-disposition"];
      const fileName = disposition.split("filename=")[1].split('"')[1];
      saveAs(res.data, fileName);
    })
    .catch((err) => {
      message.error("Something went wrong");
      console.log(err);
    });
};

const downloadDocumentCustom = async (file: any, context: any) => {
  await generateJourneyId();
  let journey_id = localStorage.getItem("journey_id");
  const bodyFormData = new FormData();
  bodyFormData.append("document", file);
  bodyFormData.append("context", JSON.stringify(context));
  let url = `/api/v1/documents?journey_id=${journey_id}`; // &template_engine=${engineName}&engine_id=${mapperID}
  if (
    account_id !== "null" ||
    account_id !== undefined ||
    account_id !== "None"
  ) {
    url = `${url}&account_id=${account_id}`;
  }
  axios({
    method: "post",
    url: url,
    data: bodyFormData,
    headers: {
      Authorization: "Bearer " + access_token,
      Accept: "application/octet-stream",
    },
    responseType: "blob",
  })
    .then((res) => {
      const disposition = res.headers["content-disposition"];
      const fileName = disposition.split("filename=")[1].split('"')[1];
      saveAs(res.data, fileName);
    })
    .catch((err) => {
      message.error("Something went wrong");
      console.log(err);
    });
};

const TemplateEngine: FC = () => {
  const [progress, setProgress] = useState(0);
  const [dataSourceList, setDataSourceList] = useState<any>([]);
  const [sitesSelected, setSiteSelected] = useState<string[]>([]);
  const [customTemplates, setCustomTemplates] = useState([]);
  const [templatesList, setTemplatesList] = useState([]);

  const generateDocuments = async () => {
    let progressInitial = 20;
    setProgress(progressInitial);
    const bodyFormData = new FormData();
    // bodyFormData.append("data_source", dataSourceList);
    dataSourceList.map((dataSource) =>
      bodyFormData.append("data_source", dataSource)
    );
    await axios({
      method: "post",
      url: `/api/v1/contexts?site_list=${sitesSelected}&main_sheet_prepend_all=${false}`,
      data: bodyFormData,
      headers: {
        Authorization: "Bearer " + access_token,
        "content-type": "xlsx",
      },
    })
      .then((res) => {
        const qtyTemplates = templatesList.length + customTemplates.length;
        if (qtyTemplates > 0) {
          const progressSlice = (100 - progressInitial) / qtyTemplates;
          for (const template of templatesList) {
            progressInitial = progressInitial + progressSlice;
            setProgress(progressInitial);
            res.data.forEach((context) =>
              downloadDocument(template.id, context)
            );
            customTemplates.forEach((template) =>
              res.data.forEach((context) =>
                downloadDocumentCustom(template, context)
              )
            );
          }
        }
      })
      .catch((error) => {
        console.log(error);
        message.error("Something went wrong");
        setProgress(0);
      });
  };

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Title>Templating Engine</Title>

      <Row gutter={[16, 16]}>
        {/* <Col span={8}>
          <DataSourceWidget
            dataSourceList={dataSourceList}
            setDataSourceList={setDataSourceList}
            sitesSelected={sitesSelected}
            setSiteSelected={setSiteSelected}
          />
        </Col> */}
        <Col span={16}>
          <TemplateWidget
            customTemplates={customTemplates}
            setCustomTemplates={setCustomTemplates}
            templatesList={templatesList}
            setTemplatesList={setTemplatesList}
          />
        </Col>
        {/* <Col span={24}>
          <div style={{ float: "right" }}>
            <Button disabled type="primary" onClick={generateDocuments}>
              Generate Documents (UNDER MAINTENANCE)
            </Button>
          </div>
        </Col> */}
      </Row>
    </Space>
  );
};

export default TemplateEngine;
