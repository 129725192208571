import { Button, Drawer, Select, Space, Tag, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import BaseTable from "../../../../../../components/Tables/BaseTable";
import { SelectOutlined } from "@ant-design/icons";
import { TemplateStatsType } from "../../../../../../types/StatisticsType";
import UploadTemplate from "../Upload";
import { TemplatesType } from "../..";

const { Text, Title } = Typography;

const account = JSON.parse(localStorage.getItem("currentAccount"));
const accountId = account?.id;
const accountName = account?.name;
const user = JSON.parse(localStorage.getItem("user"));
const scopes = user?.scopes;
const isTemplateAdmin = scopes?.includes("template_admin");

const Management = ({
  libraryId,
  libraryName,
  labels,
  templates,
  setToggleRefresh,
}) => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setToggleRefresh((prev: boolean) => !prev);
    setOpen(false);
  };

  const tooltipProps = !isTemplateAdmin
    ? {
        title: "Template Admin is required.",
      }
    : {};

  return (
    <>
      <Tooltip {...tooltipProps}>
        <Button
          disabled={libraryId === 0 || !isTemplateAdmin}
          danger
          onClick={showDrawer}
        >
          Upload New Templates
        </Button>
      </Tooltip>
      <Drawer
        width={"50%"}
        title="Template Uploader"
        extra={
          <Space>
            <Tag color="purple">{libraryName}</Tag>
            <Tag color="blue">{accountName}</Tag>
          </Space>
        }
        placement="right"
        onClose={onClose}
        open={open}
        destroyOnClose
      >
        <UploadTemplate
          libraryId={libraryId}
          setToggleRefresh={setToggleRefresh}
          labels={labels}
        />
      </Drawer>
    </>
  );
};

export default Management;
