import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import TertiaryTabulatorView from "./TertiaryTabulatorView";
import Cookies from "universal-cookie";
import { handleHttpError } from '../../common/utils.js';

//material ui components
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

//toaster components
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 50,
    flexGrow: 1,
    marginTop: "30px",
    marginBottom: "80px",
    textAlign: "center",
  },
  purple: {
    color: "rgb(85, 20, 180)",
  },
  templateName: {
    fontSize: "small",
  },
}));

export default function TertiaryManagePage(props) {
  const classes = useStyles();
  const cookies = new Cookies();
  const scopes = cookies.get("scopes");
  const [accountsLoaded, setAccountsLoaded] = useState(false);
  const [templatesLoaded, setTemplatesLoaded] = useState(false);
  const [templateSelected, setTemplateSelected] = useState(false);
  const [accountsList, setAccountsList] = useState(false);
  const [templatesList, setTemplatesList] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isCrashing, setCrashing] = useState(false);
  const [isReady, setReady] = useState(false);
  const [tertiaryType, setTertiaryType] = useState(false);
  const [accountID, setAccountID] = useState(false);
  const [templateID, setTemplateID] = useState();
  const [isLoadingTable, setLoadingTable] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [age, setAge] = React.useState("");


  const handleAccountChange = (event) => {
    setLoadingTable(false);
    const selected_id = event.target.value;
    if (selected_id !== "None") {
      setAccountID(event.target.value);
      setTemplatesLoaded(true);
    } else {
      setTemplatesLoaded(false);
      setTemplateSelected(false);
    }
  };

  const handleTemplateChange = (event) => {
    setAge("");
    setLoadingTable(false);
    const selected_id = event.target.value;
    if (selected_id !== "None") {
      setTemplateID(event.target.value);
      setTemplateSelected(true);
    } else {
      setTemplateSelected(false);
    }
  };

  const handleTertiaryChange = (event) => {
    setLoadingTable(false);
    setAge(event.target.value);
    const tertiary = event.target.value;
    generateTabulatorData(templateID, tertiary);
    setTertiaryType(tertiary);
  };

  const generateTabulatorData = (template_id, tertiary) => {
    axios
      .get(`/api/v1/templates/${template_id}/${tertiary}?account_id=${accountID}`, {
        headers: { Authorization: "Bearer " + props.access_token, Accept: "application/json" },
      })
      .then((res) => {
        const parsed_data = res.data;
        setTableData(parsed_data);
        setLoadingTable(true);
      })
      .catch(function (error) {
        handleHttpError(error)
      });
  };
  const getTemplates = () => {
    const templatesDropdown = [{ value: "None", name: "Select a template" }];
    axios
      .get("/api/v1/libraries/1/templates", { headers: { Authorization: "Bearer " + props.access_token } })
      .then((response) => {
        const templates = response.data;
        templates.forEach((entry) => {
          const template_name = entry.name;
          const template_id = entry.id;
          templatesDropdown.push({ value: template_id, name: template_name });
        });

        setTemplatesList(templatesDropdown);
      })
      .catch(function (error) {
        handleHttpError(error)
        setCrashing(true);
      });
  };
  useEffect(() => {
    const accountsDropdown = [{ value: "None", name: "Select an account" }];
    axios
      .get("/oauth2/accounts", {
        headers: {
          Authorization: "Bearer " + props.access_token,
        },
        params: {
          "complete": true,
        }
      })
      .then((response) => {
        const accounts = response.data;
        accounts.forEach((entry) => {
          const account_name = entry.name;
          const account_id = entry.id;
          accountsDropdown.push({ value: account_id, name: account_name });
        });
        setAccountsList(accountsDropdown);
        setAccountsLoaded(true);
      })
      .catch(function (error) {
        handleHttpError(error)
        setCrashing(true);
      });
    getTemplates();
    setLoading(false);
    setReady(true);
  }, []);

  if (isReady && scopes.includes("catalogue_user")) {
    return (
      <Container>
        <Header />
        <Grid container className={classes.root} justify="center" spacing={3}>
          <Grid item xs={12}>
            <div className="title-container">
              <h1>Tertiary Management</h1>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={3}>
              <Grid item xs={12}>
                {accountsLoaded ? (
                  <FormControl className={classes.formControl}>
                    <Select
                      native
                      onChange={handleAccountChange}
                      inputProps={{
                        name: "account",
                        id: "account-select",
                      }}
                    >
                      <optgroup label="Accounts">
                        {accountsList.map((e, key) => {
                          return (
                            <option key={e.value} value={e.value}>
                              {e.name}
                            </option>
                          );
                        })}
                      </optgroup>
                    </Select>
                  </FormControl>
                ) : (
                  "Accounts Loading"
                )}
              </Grid>
              <Grid item xs={12}>
                {templatesLoaded ? (
                  <FormControl className={classes.formControl}>
                    <Select
                      native
                      onChange={handleTemplateChange}
                      inputProps={{
                        name: "template",
                        id: "template-select",
                      }}
                    >
                      <optgroup label="Templates">
                        {templatesList.map((e, key) => {
                          return (
                            <option key={e.value} value={e.value}>
                              {e.name}
                            </option>
                          );
                        })}
                      </optgroup>
                    </Select>
                  </FormControl>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                {templateSelected ? (
                  <FormControl style={{ minWidth: 330 }} className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Select a tertiary</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      onChange={handleTertiaryChange}
                    >
                      <MenuItem value={"assumptions"}>Assumptions</MenuItem>
                      <MenuItem value={"boms"}>BOMs</MenuItem>
                      <MenuItem value={"risks"}>Risks</MenuItem>
                      {/* <MenuItem value={'setups'}>Setups</MenuItem> */}
                      <MenuItem value={"implementation_options"}>Implementation Options</MenuItem>
                      <MenuItem value={"implementation_plans"}>Implementation Plans</MenuItem>
                    </Select>
                  </FormControl>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                {isLoadingTable ? (
                  <TertiaryTabulatorView
                    data={tableData}
                    tertiary_type={tertiaryType}
                    account_id={accountID}
                    template_id={templateID}
                    access_token={props.access_token}
                  />
                ) : null}
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </Grid>
        </Grid>
        <Footer />
        <ToastContainer />
      </Container>
    );
  }
  if (isCrashing) {
    return (
      <Container>
        <Header />
        <Grid container className={classes.root} justify="center" spacing={3}>
          <div className="App">There seems to be an error. Contact the development team if this issue persists.</div>
        </Grid>
        <Footer />
      </Container>
    );
  }
  if (isLoading) {
    return (
      <Container>
        <Header />
        <Grid container className={classes.root} justify="center" spacing={3}>
          <div className="App">Loading...</div>
        </Grid>
        <Footer />
      </Container>
    );
  }
}
