import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import "../MerakiRolloutPage.css";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 10,
    maxWidth: 500,
  },
}));

export default function AccountHeader({ accountName }) {
  return (
    <div className="styleAccount">
      <div>
        <strong>{accountName}</strong>
      </div>
      <LightTooltip
        title="For other accounts use the dropdown selection in the top bar menu"
        placement="left"
        arrow
      >
        <div className="tooltipAccount">?</div>
      </LightTooltip>
    </div>
  );
}
