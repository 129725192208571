import React, { useState, useEffect, useRef } from "react";

import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CustomButton from "../../../components/Button/CustomButton.js";
import SpinnerLoader from "../../../components/Loader/SpinnerLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from '@mui/material/TextField';

import { getFileName, saveBlob, handleHttpError } from "../../../common/utils.js";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "50px",
    flexGrow: 1,
    marginBottom: "80px",
    textAlign: "center",
  },

  mystyle: {
    color: "rgb(85, 20, 180)",
  },
  inputheader: {
    paddingRight: "30px",
    fontSize: "large",
    fontWeight: "bold",
  },
  inputfile: {
    paddingLeft: "right",
    backgroundColor: "rgb(85, 20, 180)",
    color: "white",
    borderRadius: "3px",
  },
  headergrid: {
    textAlign: "right",
  },
  button: {
    paddingTop: "30px",
  },
  dragDrop: {
    height: 300,
    width: "100%",
  },
  dragDropContainer: {
    paddingTop: 110,
  },
  spinner: {
    paddingTop: 40,
  },
  confs: {
    paddingTop: 40,
    paddingBottom: 80,
    marginInline: "30%",
    alignContent: "center",
    textAlign: "center",
  },
}));

export default function MultipleConfigView(props) {
  const classes = useStyles();
  const [outputType, setOutputType] = useState("2d");
  const [isConfigLoading, setConfigLoading] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [isWaitingResponse, setWaitingResponse] = useState(false);
  const [statusText, setStatusText] = useState("");
  const [options, setOptions] = React.useState("");
  let ionixBox = useRef(null);

  const handleChange = (event) => {
    setOutputType(event.target.value);
  };

  useEffect(() => {
    const optionsPayload = [{ value: "3d", name: "3D Data Source" }];
    optionsPayload.push({ value: "2d", name: "2D Data Source" });
    setOptions(optionsPayload);
    setLoading(false);
  }, []);

  const startConverter = () => {
    setConfigLoading(true)
    setWaitingResponse(true)
    let output = outputType;
    let bodyFormData = new FormData();
    if (props.mode === 'CnGo') {
      let file = document.getElementById("cngo_file").files[0];
      bodyFormData.append("configs", file);
    }
    if (props.mode === 'Ionix') {
      bodyFormData.append("configs", ionixBox.current.value)
    }
    axios({
      method: "post",
      url: `/api/v1/parsers?${props.mode === 'CnGo' ? 'output_type=cngo' : 'output_type=ionix'}&output_format=` + output,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + props.access_token,
        Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      },
      data: bodyFormData,
      onUploadProgress: function (progressEvent) {
        // Use this calculation to make a progress bar
        const time = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(0);
        setStatusText("Uploading... " + time + "/100");
        if (time === "100") {
          setStatusText("Converting...");
        }
      },
      responseType: "blob",
      maxContentLength: 100000000,
      maxBodyLength: 1000000000,
    })
      .then((response) => {
        // Generating filename
        let fileName = getFileName(response.headers);
        saveBlob(response.data, fileName);
        setWaitingResponse(false);
        setConfigLoading(false);
      })
      .catch(function (error) {
        setWaitingResponse(false);
        handleHttpError(error);
        setConfigLoading(false);
      });
  };

  return (
    <Container>
      <Grid container className={classes.root} justify="center" spacing={3}>
        {props.mode === 'CnGo' ? <div><Grid item xs={12}>
          <div className="title-container">
            <h3>Please provide the CnGo output files</h3>
          </div>
        </Grid><br />
          <Grid item xs={12}>
            <div className={classes.inputWrapper}>
              <input
                className={classes.inputfile}
                accept=".zip"
                type="file"
                id="cngo_file"
                name="filename"
                onChange={function () {
                  setConfigLoading(false);
                }}
              />
              <br />
            </div>
          </Grid></div> : null}
        {props.mode === 'Ionix' ? <div><Grid item xs={12}>
          <div className="title-container">
            <h3>Please copy the entirety of the Ionix email and paste it in the box below:</h3>
          </div>
        </Grid><br />
          <Grid item xs={12}>
            <TextField
              label="Ionix Email"
              multiline
              rows={4}
              inputRef={ionixBox}
              style={{ width: 500 }}
              placeholder="Paste email here"
              onChange={() => setConfigLoading(false)}
            />
          </Grid></div> : null}
        {!isLoading ? (
          <Grid item xs={12}>
            <div className="title-container">
              <h3>Select an output type:</h3>

              <FormControl style={{ minWidth: 200 }} variant="outlined" className={classes.formControl}>
                <InputLabel>Output Type</InputLabel>
                <Select
                  onChange={handleChange}
                  inputProps={{
                    name: "library",
                    id: "library-select",
                  }}
                  label="Output Selector"
                  value={outputType}
                >
                  {options.map((e, key) => {
                    return (
                      <MenuItem key={e.value} value={e.value}>
                        {e.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          {isConfigLoading ? (
            <div className={classes.button}>
              <Button variant="contained" disabled>
                Convert Configs
              </Button>
            </div>
          ) : (
            <>
              <div className={classes.button}>
                <CustomButton
                  label={"Convert Configs"}
                  color="purple"
                  action={() => {
                    startConverter();
                  }}
                />
              </div>
            </>
          )}

          {isWaitingResponse ? (
            <div className={classes.spinner}>
              <SpinnerLoader />
              {statusText}
            </div>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <ToastContainer />
    </Container>
  );
}
