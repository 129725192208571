import React from "react";
import { exportAsExcel } from "../../../../utils/exportAsExcel";
import { useTables } from "../../../../contexts/TablesContext";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useBFGConnectors } from "../../../../contexts/Connectors/BFGContext";

const ExportSiteDetailsToExcel = ({ address, devices, bearers }) => {
  const exportToExcel = async () => {
    const isCreated = await exportAsExcel({
      address,
      devices,
      bearers,
    });
    isCreated ? console.log("File Downloaded") : console.log("Failed Download");
  };

  return (
    <Button
      disabled={address === null && devices === null && bearers === null}
      size="small"
      icon={<CloudDownloadOutlined />}
      onClick={exportToExcel}
    >
      Site Details to Excel
    </Button>
  );
};

export default ExportSiteDetailsToExcel;
