import React, { createContext, useContext, useEffect, useState } from 'react'
import { ChildrenProps } from '../../types/ChildrenReactType'
import { useLocalStorage } from "../../hooks/useLocalStorage"
import type { UploadFile } from 'antd/es/upload/interface';
import { AccountType } from '../../types/UserAuthTypes';

export type SessionContextType = {
    currentAccount: AccountType
    dataSourceList: any
    siteList: string[]
    templateRemoteList: number[]
    dispatchAccount: (account: AccountType) => void
    updateDataSource: (file: any) => void
    removeDataSource: (file: any) => void
    removeAdditionalDataSource: () => void
    resetDataSource: () => void
    dispatchSiteList: (sites: string[]) => void
    dispatchRemoteTemplates: (templateIds: number[]) => void
    dispatchLocalTemplates: (templateIds: any) => void
}

export const SessionContext = createContext<SessionContextType | null>(null)

export const SessionContextProvider: React.FC<ChildrenProps> = ({ children }) => {

    const { setStorageItem, getStorageItem } = useLocalStorage()
    const storedAccount = getStorageItem('currentAccount')

    const DEFAULT_ACCOUNT = {
        id: storedAccount?.id || 0,
        name: storedAccount?.name || "None",
        nickname: storedAccount?.nickname,
        bfg_id: storedAccount?.bfg_id
    }

    const [currentAccount, setCurrentAccount] = useState<AccountType>(DEFAULT_ACCOUNT)
    const [dataSourceList, setDataSourceList] = useState<any>([])
    const [siteList, setSiteList] = useState<string[]>([])
    const [templateRemoteList, setTemplateRemoteList] = useState<number[]>([])


    const dispatchAccount = (account: AccountType) => {
        setCurrentAccount(account)
        setStorageItem('currentAccount', account)
    }

    const updateDataSource = (file: any) => {
        const currentFiles = dataSourceList || []
        const currentFileNames = currentFiles.map((file: any) => file.name)
        const newFileNames = file.map((file: any) => file.name)
        const isDuplicated = newFileNames.some((name: string) => currentFileNames.includes(name))
        !isDuplicated &&
            setDataSourceList([...dataSourceList, ...file])
    }

    const removeDataSource = (file: any) => {
        const index = dataSourceList.indexOf(file);
        const newFileList = dataSourceList.slice();
        newFileList.splice(index, 1);
        setDataSourceList(newFileList);
    }

    const removeAdditionalDataSource = () => {
        setDataSourceList(dataSourceList.splice(0, 1));
    }

    const resetDataSource = () => {
        setDataSourceList([]);
    }

    const dispatchSiteList = (sites: string[]) => {
        setSiteList(sites)
    }

    const dispatchRemoteTemplates = (templateIds: number[]) => {
        setTemplateRemoteList(templateIds)
    }

    const dispatchLocalTemplates = (template: any[]) => {
        setTemplateRemoteList(template)
    }


    // // makes sure the stored account is set into the context
    // useEffect(() => { storedAccount && dispatchAccount(storedAccount) })

    // console.log({
    //     session: {
    //         currentAccount,
    //         dataSourceList,
    //         siteList,
    //     }
    // })

    return (
        <SessionContext.Provider value={{
            currentAccount,
            dispatchAccount,
            dataSourceList,
            updateDataSource,
            removeDataSource,
            removeAdditionalDataSource,
            resetDataSource,
            siteList,
            dispatchSiteList,
            dispatchLocalTemplates,
            templateRemoteList,
            dispatchRemoteTemplates

        }}>
            {children}
        </SessionContext.Provider>
    )
}

export const useSession = () => useContext(SessionContext)

export default SessionContext



