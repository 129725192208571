import { Button, Divider, List, Space, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTables } from "../../../../contexts/TablesContext";
import { CloseOutlined, FileTextOutlined } from "@ant-design/icons";
const { Title, Text, Paragraph } = Typography;

const SelectedList = ({
  customTemplates,
  templates,
  selectedIds,
  setSelectedIds,
  setCustomTemplates,
  templatesList,
  setTemplatesList,
}) => {
  useEffect(() => {
    if (selectedIds) {
      setTemplatesList(
        selectedIds?.map((id: number) => {
          var index = templates?.findIndex(
            (template: any) => template.id == id
          );
          return { id, name: templates[index]?.name || "" };
        })
      );
    }
  }, [selectedIds]);

  console.log({ selectedIds, templatesList, customTemplates });

  const Selected = ({ template, source }) => {
    return (
      <div
        style={{
          width: "100%",
          backgroundColor: "#F9F0FF",
          padding: "0.5rem",
          borderRadius: 5,
        }}
      >
        <Space>
          <FileTextOutlined />
          <Text>{template.name}</Text>
        </Space>
        <div style={{ float: "right" }}>
          <Button
            type="text"
            icon={<CloseOutlined />}
            size="small"
            onClick={() => {
              source === "library" &&
                setSelectedIds((prev: any) =>
                  prev.filter((id: number) => id !== template.id)
                );
              source === "custom" &&
                setCustomTemplates((prev: any) =>
                  prev.filter((custom: any) => custom.uid !== template.uid)
                );
            }}
          />
        </div>
      </div>
    );
  };

  const isSelected = customTemplates.length > 0 || selectedIds.length > 0;
  console.log({ isSelected });

  return (
    isSelected && (
      <>
        <Divider orientation="left" orientationMargin="0">
          Selected Templates
        </Divider>
        <Space direction="vertical" style={{ width: "100%" }}>
          {customTemplates?.map((template: any) => (
            <Selected template={template} source={"custom"} />
          ))}
          {templatesList?.map((template: any) => (
            <Selected template={template} source={"library"} />
          ))}
        </Space>
        <Divider />
      </>
    )
  );
};

export default SelectedList;
