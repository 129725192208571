import "../MerakiRolloutPage.css";
import { axiosFetch } from "../library/fetchRequests";
import { useEffect, useState } from "react";
import Loading from "./Loading";
import { Alert } from "@mui/material";
// dual list box dependencies
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";

// dualbox options
const dualListOptions = {
  moveLeft: "<",
  moveAllLeft: "<<",
  moveRight: ">",
  moveAllRight: ">>",
};

export default function SiteList({
  accountId,
  sitesList,
  setSitesList,
  rolloutSites,
  setRolloutSites,
  sitesListSelected,
  setSitesListSelected,
  templatesList,
}) {
  const [sitesData, setSitesData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (sitesList.length === 0) {
      axiosFetch
        .get(`autodata/accounts/${accountId}/sites`)
        .then((response) => {
          setSitesData(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setHasError(true);
        });
    } else {
      setIsLoading(false);
    }
  }, [accountId]);

  const onSelectedSitesChange = (selectedSites) => {
    // Remove unselected sites
    const rolloutSitesSelected = rolloutSites.filter((site) =>
      selectedSites.includes(site.id)
    );

    // add a new entry to state only if the site is not there so it wouldn't change the already selected type
    for (const selected of selectedSites) {
      const siteIndex = sitesList.findIndex((x) => x.value == selected);

      const siteAlreadyOnList = sitesListSelected.some(
        (siteID) => siteID === sitesList[siteIndex].value
      );

      if (!siteAlreadyOnList) {
        rolloutSitesSelected.push({
          name: sitesList[siteIndex].label,
          id: sitesList[siteIndex].value,
          type: "Select Type...",
        });
      }
    }

    setSitesListSelected(selectedSites);
    setRolloutSites(rolloutSitesSelected);
  };

  useEffect(() => {
    if (Object.keys(sitesData).length > 0) {
      const optionsList = sitesData["sites"].map((site) => ({
        value: site.site_id,
        label: site.site_name,
      }));
      setSitesList(optionsList);
    }
  }, [sitesData]);

  const SiteLimitMessage = () => {
    return (
      <>
        <br />
        <Alert variant="outlined" severity="warning">
          Port Mapping templating doesn't support multiple sites for now...
          <br />
          Please select only a single site!
        </Alert>
      </>
    );
  };

  const LoadedData = () => {
    return (
      <>
        <DualListBox
          options={sitesList.sort((a, b) =>
            a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1
          )}
          selected={sitesListSelected}
          onChange={onSelectedSitesChange}
          canFilter
          icons={dualListOptions}
        />
        {sitesListSelected.length > 1 &&
          templatesList
            .filter((item) => item.checked)
            .some((item) => item.name.includes("Port Mapping")) && (
            <SiteLimitMessage />
          )}
      </>
    );
  };

  const MainDisplayedData = () => {
    if (!isLoading) {
      return <LoadedData />;
    } else if (hasError) {
      return (
        <Alert variant="outlined" severity="error">
          Something went wrong while fetching the data...
        </Alert>
      );
    } else {
      return (
        <Loading
          page="sitesListSelection"
          text="Fetching site list... please bear with me as it might take a moment!"
        />
      );
    }
  };

  return (
    <>
      <div className="backBox">
        <MainDisplayedData />
      </div>
    </>
  );
}
