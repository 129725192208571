const btIndigo = '#5514B4'


export const appTheme = {
    token: {
        colorPrimary: btIndigo,
        colorTextHeading: btIndigo,
        fontFamilyCode: 'BT-Curve, Arial, sans-serif',
        fontWeightStrong: 700
    }
}
