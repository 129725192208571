import React, { memo, useEffect } from "react";
import { Space, Tag, Typography, message } from "antd";

// Contexts
import { useMerakiConnectors } from "../../../../../contexts/Connectors/MerakiContext";
import { NgdsErrorType } from "../../../../../types/NgdsErrorType";
import {
  ArrowsAltOutlined,
  DownloadOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import BaseTable from "../../../../../components/Tables/BaseTable";
import {
  MerakiNetworksType,
  MerakiProductsType,
} from "../../../../../types/MerakiConnectorsType";
import ShowRecordDetails from "../../../../../components/Tables/ShowRecordDetails";
import type { ColumnsType } from "antd/es/table/interface";
import ProductsTagColoredStack from "../../functions/TagProductColor.tsx";
import createTagList from "../../functions/createTagList";
import { exportAsExcel } from "../../../../../utils/exportAsExcel";

const { Text, Title } = Typography;

const Networks: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const meraki = useMerakiConnectors();
  const orgId = meraki.orgId;

  const organizations = meraki.organizations;
  const loadingOrganizations = meraki.loadingOrganizations;
  const errorOrganizations = meraki.errorOrganizations;

  const networks = meraki.networks;
  const loadingNetworks = meraki.loadingNetworks;
  const errorNetworks = meraki.errorNetworks;

  const products = meraki.products;
  const loadingProducts = meraki.loadingProducts;
  const errorProducts = meraki.errorProducts;

  const columns: ColumnsType<MerakiNetworksType> = [
    {
      title: "Network Id",
      dataIndex: "id",
      // width: '16%'
    },
    {
      title: "Network Name",
      dataIndex: "name",
    },
    {
      title: "Timezone",
      dataIndex: "timeZone",
      width: "18%",
    },
    {
      title: "Products",
      dataIndex: "productTypes",
      render: (text: any) => ProductsTagColoredStack(text),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      render: (text: string[]) => createTagList(text),
    },
  ];

  // Error Handling
  useEffect(() => {
    const errorMessage = (error: NgdsErrorType) => {
      messageApi.open({
        type: "error",
        content: `${error.response.data.error}`,
        duration: 5,
        style: {
          marginTop: 64,
        },
      });
    };

    errorNetworks && errorMessage(errorNetworks);
    errorOrganizations && errorMessage(errorOrganizations);
  }, [errorNetworks, errorOrganizations]);

  const ShowNetDevices = () => {
    return <a>Filter Devices</a>;
  };

  const actionMenu = [
    {
      key: "listDetails",
      component: ShowRecordDetails,
      icon: <ArrowsAltOutlined />,
    },
    {
      key: "listDevices",
      component: ShowNetDevices,
      icon: <ArrowsAltOutlined />,
    },
  ];

  const rowSelectionActions = [
    // {
    //     key: '1',
    //     text: 'Selection to Excel',
    //     icon: <DownloadOutlined />,
    //     type: "primary",
    //     action: async (selected: string[]) => {
    //         console.log({ selected_from_meraki: selected })
    //         const selectedData = networks.filter(network => selected.includes(network.id))
    //         console.log({ selectedData })
    //         const isCreated = await exportAsExcel({ networks: selectedData })
    //         isCreated ? console.log("File Downloaded") : console.log("Failed Download")
    //     },
    // },
    // // {
    // //     key: '2',
    // //     text: 'Network Devices',
    // //     icon: <DownloadOutlined />,
    // //     type: "primary",
    // //     action: async (selected: string[]) => {
    // //         // console.log({ selected_from_meraki: selected })
    // //         // const selectedData = networks.filter(network => selected.includes(network.id))
    // //         // console.log({ selectedData })
    // //         // const isCreated = await exportAsExcel({ networks: selectedData })
    // //         // isCreated ? console.log("File Downloaded") : console.log("Failed Download")
    // //     },
    // }
  ];

  const productColumns: ColumnsType<MerakiProductsType> = [
    {
      title: "Product",
      dataIndex: "productType",
      render: (text: any) => ProductsTagColoredStack([text]),
      width: "10%",
    },
    {
      title: "Model",
      dataIndex: "model",
      width: "10%",
    },
    {
      title: "Product Name",
      dataIndex: "name",
      width: "16%",
    },
    {
      title: "Serial",
      dataIndex: "serial",
      width: "15%",
    },
    {
      title: "MAC",
      dataIndex: "mac",
      width: "12%",
    },
    {
      title: "LAN IP",
      dataIndex: "lanIp",
      width: "10%",
    },
    {
      title: "Tags",
      dataIndex: "tags",
      width: "10%",
      render: (text: string[]) => createTagList(text),
    },
  ];

  const expandedRowRender = (record: MerakiNetworksType) => {
    return (
      <div
        style={{ padding: "1rem", backgroundColor: "white", margin: "0.5rem" }}
      >
        <Text strong style={{ color: "#5514b4" }}>
          {record.name} Products{" "}
        </Text>
        <BaseTable
          loading={loadingProducts}
          tableName={`Products`}
          dataSource={products?.filter(
            (product: MerakiProductsType) => product.networkId === record.id
          )}
          columns={productColumns}
          // reloadAction={() => meraki?.fetchDevices(orgId)}
          // rowActions={actionMenu}
          // rowSelectionActions={true}
          rowKey={"serial"}
          searchBar={false}
        />
      </div>
    );
  };

  return (
    <Space direction="vertical" style={{ width: "100%", marginTop: 16 }}>
      {contextHolder}
      {/* ORGANIZATION SELCTION */}
      {networks && (
        <BaseTable
          loading={loadingNetworks}
          tableName={`Networks`}
          dataSource={networks}
          columns={columns}
          rowSelectionActions={true}
          // reloadAction={() => meraki?.fetchNetworks(orgId)}
          rowActions={actionMenu}
          expandedRowRender={{
            expandedRowRender,
            // expandRowByClick: true,
            // fixed: true,
            indentSize: 0,
            rowExpandable: (record: MerakiNetworksType) =>
              products
                ?.map((product: MerakiProductsType) => product.networkId)
                .includes(record.id),
          }}
        />
      )}
    </Space>
  );
};

export default Networks;
