import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Users from "../AdminPageViews/AdminMasterComponents/Users";
import Scopes from "../AdminPageViews/AdminMasterComponents/Scopes";
import AccessManagement from "../AdminPageViews/AdminMasterComponents/AccessManagement";
import Accounts from "../AdminPageViews/AdminMasterComponents/Accounts";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Cookies from "universal-cookie";

//toaster components
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LayoutPage } from "../../pages/JourneyPage/JourneyPage";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "130px",
    textAlign: "center",
    marginBottom: "120px",
  },
  menu: {
    backgroundColor: "rgb(85, 20, 180)",
    color: "white",
  },
  gridBorder: {
    border: "black",
    borderStyle: "solid",
  },
  title: {
    color: "rgb(85, 20, 180)",
  },
  adminView: {
    marginBottom: 250,
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const adminMenu = [
  { name: "Users" },
  { name: "Scopes" },
  { name: "Access Management" },
  { name: "Accounts" },
];

export default function AdminMaster(props) {
  const classes = useStyles();
  const [adminStatus, setAdminStatus] = React.useState("Users");
  const cookies = new Cookies();
  const scopes = cookies.get("scopes");
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  if (!scopes.includes("admin")) {
    return (
      <LayoutPage>
        <Grid item xs={12}>
          <div className="title-container">
            <h1>ngDS Admin</h1>
          </div>
        </Grid>
        <Grid item xs={12}>
          <p>You do not have access to this page</p>
        </Grid>
      </LayoutPage>
    );
  } else {
    return (
      <LayoutPage maxWidth="lg">
        <Grid item xs={12}>
          <div className="title-container">
            <h1>ngDS Admin</h1>
          </div>
        </Grid>
        <Grid item xs={9}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              {adminMenu.map((e, key) => {
                return (
                  <Tab
                    label={e.name}
                    {...a11yProps(key)}
                    onClick={() => {
                      setAdminStatus(e.name);
                    }}
                  />
                );
              })}
            </Tabs>
          </AppBar>

          {adminMenu.map((e, key) => {
            return (
              <TabPanel value={value} index={key} dir={theme.direction}>
                <h2 className={classes.title}>{e.name}</h2>
              </TabPanel>
            );
          })}
        </Grid>
        <Grid item xs={12} className={classes.adminView}>
          {adminStatus === "Users" ? (
            <Users access_token={props.access_token} />
          ) : null}
          {adminStatus === "Scopes" ? (
            <Scopes access_token={props.access_token} />
          ) : null}
          {adminStatus === "Access Management" ? (
            <AccessManagement access_token={props.access_token} />
          ) : null}
          {adminStatus === "Accounts" ? (
            <Accounts access_token={props.access_token} />
          ) : null}
        </Grid>
      </LayoutPage>
    );
  }
}
