import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import React, { useState, useEffect } from "react";
import CustomButton from "./../../components/Button/CustomButton.js";
import axios from "axios";

//sweet alert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

//material ui components
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { handleHttpError } from "../../common/utils";
import Divider from "@material-ui/core/Divider";

//toaster components
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LayoutPage } from "../JourneyPage/JourneyPage.js";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "80px",
    marginBottom: "80px",
    textAlign: "center",
    paddingBottom: 150,
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  commentfield: {
    cursor: "pointer",
  },
  grid: {
    marginTop: "6rem",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  submitFeedback: {
    paddingLeft: 1000,
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      color: "rgb(255, 128, 255)",
    },
  },
  comment_title: {
    display: "inline-block",
    paddingTop: 10,
    fontSize: "large",
    fontWeight: "bold",
  },
  description_text: {
    fontSize: "medium",
  },
  closed_button: {
    marginBottom: 30,
  },
  button_container: {
    display: "inline-block",
    paddingRight: "5%",
    paddingLeft: "5%",
  },
  table: {
    marginBottom: 0,
  },
  title: {
    color: "rgb(85, 20, 180)",
  },
  feedbackCSS: {
    cursor: "pointer",
    "&:hover": {
      background: "rgb(255, 128, 255)",
    },
  },
}));

function createData(Title, Description, Type, iD, User, Action) {
  return { Title, Description, Type, iD, User, Action };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
export default function UserFeedbackPage(props) {
  const MySwal = withReactContent(Swal);
  let [title, setTitle] = useState("");
  let [feedback, setFeedback] = useState("");
  const [feedbackOpenList, setFeedbackOpenList] = useState(null);
  const [feedbackClosedList, setFeedbackClosedList] = useState(null);
  const [feedbackLoaded, setFeedbackLoaded] = useState(false);
  console.log({ feedbackOpenList, feedbackClosedList, feedbackLoaded });

  const [openCount, setOpenCount] = useState(false);
  const [bug, setBugType] = React.useState("");
  const toastId = React.useRef(null);
  const [journey, setJourney] = useState(null);
  const [comments, setComments] = useState(null);
  const [feedbackID, setfeedbackID] = useState(null);
  const [newComment, setNewComment] = useState(null);
  const [commentTitle, setCommentTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [selectedBug, setSelectedBug] = useState(null);
  const bug_types = ["Bug", "Feature", "General"];
  const [value, setValue] = React.useState(0);

  const classes = useStyles();
  const width = 500;

  const handleChange = (event) => {
    setBugType(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleFeedback = (e) => {
    setFeedback(e.target.value);
  };

  const closeFeedback = (feedback_id) => {
    const data = { status: "Closed" };
    axios({
      method: "patch",
      url: "/api/v1/feedback/" + feedback_id,
      data: data,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((res) => {
        toastSuccess("Feedback closed.");
        setJourney(null);
        getFeedback();
      })
      .catch((err) => {
        console.log(err.response.data);
        toastError("Feedback could not be closed.");
      });
  };

  const toastError = (message) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(message);
    }
  };
  const toastSuccess = (message) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.success(message);
    }
  };
  const sendFeedback = () => {
    const data = { title: title, description: feedback, type: bug };
    if (title === "") {
      toastError("Feedback title missing.");
    }
    if (feedback === "") {
      toastError("Feedback missing.");
    }
    if (bug === "") {
      toastError("Feedback type missing.");
    } else {
      axios({
        method: "post",
        url: "/api/v1/feedback",
        data: data,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + props.access_token,
        },
      })
        .then((res) => {
          toastSuccess("Feedback submitted.");
          setJourney(null);
          getFeedback();
        })
        .catch((err) => {
          console.log(err.response.data);
          toastError("Feedback could not sent.");
        });
    }
  };
  const sendNewComment = (feedback_id, status) => {
    const data = { comment: newComment };
    axios({
      method: "post",
      url: "/api/v1/feedback/" + feedbackID + "/comments",
      data: data,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((res) => {
        toastSuccess("Comment submitted.");
        getComment(feedback_id, status);
      })
      .catch((err) => {
        console.log(err.response.data);
        toastError("Comment could not be updated.");
      });
  };

  const getComment = (feedback_id, status) => {
    axios({
      method: "get",
      url: "/api/v1/feedback/" + feedback_id + "/comments",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
      },
    }).then((res) => {
      if (Object.keys(res.data).length === 0) {
        setComments([]);
        setfeedbackID(feedback_id);
      } else {
        console.log(res.data);
        setComments(res.data);
        setfeedbackID(feedback_id);
        // setCommentsRecieved(true)
      }
    });
    axios({
      method: "get",
      url: "/api/v1/feedback/" + feedback_id,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
      },
    }).then((res) => {
      setCommentTitle(res.data.title);
      setDescription(res.data.description);
      setSelectedBug(res.data.type);
    });
    if (status === "open") {
      setJourney("display_comment");
    } else if (status === "closed") {
      setJourney("display_closed_comment");
    }
  };
  const handleCommentBox = (e) => {
    setNewComment(e.target.value);
  };

  const newFeedback = () => {
    setJourney("new_feedback");
  };
  const getFeedback = () => {
    axios({
      method: "get",
      url: "/api/v1/feedback",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((res) => {
        var feedback_open_list = [];
        var feedback_closed_list = [];
        let openCounter = 0;

        for (let entry of res.data) {
          if (entry.status === "Open") {
            openCounter = openCounter + 1;
            feedback_open_list.push(
              createData(
                entry.title,
                entry.description,
                entry.type,
                entry.id,
                entry.user,
                <div>
                  <button
                    onClick={() => {
                      MySwal.fire({
                        title: "Whcih field do you wish to edit?",
                        html: '<select id="edit_field"><option key="" value="" disabled selected hidden>Please Choose...</option><option value="Title" key="Title" selected="selected">Title</option><option value="Description" key="Description" selected="selected">Description</option></select>',
                        icon: "question",
                        width: 500,
                        showCancelButton: true,
                        confirmButtonColor: "green",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Continue",
                      }).then((result) => {
                        if (result.value) {
                          const data = document
                            .getElementById("edit_field")
                            .value.toLowerCase();
                          MySwal.fire({
                            title:
                              'Enter new value for "' +
                              document.getElementById("edit_field").value +
                              '"',
                            html: '<textarea id="textbox" style="resize: none; rows="10" cols="50"></textarea>',
                            icon: "question",
                            width: 500,
                            showCancelButton: true,
                            confirmButtonColor: "green",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Continue",
                          }).then((resp) => {
                            if (resp.value) {
                              var new_data =
                                document.getElementById("textbox").value;
                              var dictionary = {};
                              dictionary[data] = new_data;
                              axios({
                                method: "patch",
                                url: "/api/v1/feedback/" + entry.id,
                                data: dictionary,
                                headers: {
                                  Accept: "application/json",
                                  Authorization: "Bearer " + props.access_token,
                                },
                              })
                                .then((re) => {
                                  toastSuccess(data + " updated.");
                                  getFeedback();
                                })
                                .catch((err) => {
                                  console.log(err.response.data);
                                  toastError("Feedback could not be closed.");
                                });
                            }
                          });
                        }
                      });
                    }}
                  >
                    {" "}
                    Edit
                  </button>
                  <button
                    onClick={() => {
                      MySwal.fire({
                        title: "Are you sure?",
                        html:
                          "You are about to close feedback with the title <b>" +
                          entry.title +
                          "</b>",
                        icon: "warning",
                        width: 500,
                        showCancelButton: true,
                        confirmButtonColor: "green",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Close Feedback",
                      }).then((results_) => {
                        if (results_.value) {
                          closeFeedback(entry.id);
                        }
                      });
                    }}
                  >
                    Close
                  </button>
                </div>
              )
            );
          } else if (entry.status === "Closed") {
            feedback_closed_list.push(
              createData(
                entry.title,
                entry.description,
                entry.type,
                entry.id,
                entry.user
              )
            );
          }
        }
        if (openCounter === 0) {
          setOpenCount(true);
        }
        setFeedbackOpenList(feedback_open_list);
        setFeedbackClosedList(feedback_closed_list);
        setFeedbackLoaded(true);
      })
      .catch((err) => {
        handleHttpError(err);
      });
  };
  const deleteComment = (comment_id) => {
    console.log(comment_id);
  };
  useEffect(() => {
    getFeedback();
  }, []);

  if (feedbackLoaded) {
    return (
      <LayoutPage>
        <Grid item xs={12}>
          <div className="title-container">
            <h1>Feedback</h1>
          </div>
        </Grid>
        <Grid item xs={5}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="OPEN" {...a11yProps(0)} />
              <Tab label="CLOSED" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
        </Grid>
        <Grid item xs={12}>
          <TabPanel value={value} index={0}>
            <Button type="outlined" onClick={() => newFeedback()}>
              Submit New Feedback
            </Button>

            {journey === "new_feedback" && value === 0 && (
              <Grid item xs={12}>
                {/* <h2>Submit New Feedback</h2> */}

                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Feedback Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={bug}
                    onChange={handleChange}
                  >
                    {bug_types.map((entry) => (
                      <MenuItem value={entry}>{entry}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  fullWidth
                  id="outlined-full-width"
                  label="Title "
                  margin="normal"
                  onChange={handleTitle}
                  inputProps={{
                    style: {
                      width,
                    },
                  }}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={4}
                  variant="outlined"
                  onChange={handleFeedback}
                  inputProps={{
                    style: {
                      width,
                    },
                  }}
                />
                <Button type="primary" onClick={() => sendFeedback()}>
                  Send Feedback
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider style={{ margin: "48px" }} />
            </Grid>
            <Grid item xs={12}>
              {openCount ? (
                <div>You currently do not have any pending feedback.</div>
              ) : (
                <TableContainer className={classes.table} component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>Title</b>
                        </TableCell>
                        <TableCell>
                          <b>Description</b>
                        </TableCell>
                        <TableCell>
                          <b>Type</b>
                        </TableCell>
                        <TableCell>
                          <b>User</b>
                        </TableCell>
                        <TableCell>
                          <b>Action</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {feedbackLoaded ? (
                      <TableBody>
                        {feedbackOpenList.map((feedback_) => (
                          <TableRow
                            className={classes.feedbackCSS}
                            key={feedback_.name}
                          >
                            <TableCell
                              onClick={() => getComment(feedback_.iD, "open")}
                            >
                              {feedback_.Title}
                            </TableCell>
                            <TableCell
                              onClick={() => getComment(feedback_.iD, "open")}
                            >
                              {feedback_.Description}
                            </TableCell>
                            <TableCell
                              onClick={() => getComment(feedback_.iD, "open")}
                            >
                              {feedback_.Type}
                            </TableCell>
                            <TableCell
                              onClick={() => getComment(feedback_.iD, "open")}
                            >
                              {feedback_.User}
                            </TableCell>
                            <TableCell>{feedback_.Action}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : null}
                  </Table>
                  <div id="error_body"></div>
                </TableContainer>
              )}
            </Grid>
          </TabPanel>
        </Grid>
        {journey === "display_comment" && value === 0 ? (
          <Grid item xs={7}>
            <Button size="small" variant="outlined">
              {selectedBug}
            </Button>
            <br />
            <div className={classes.comment_title}>{commentTitle}</div>
            <br />
            <div className={classes.description_text}>{description} </div>
          </Grid>
        ) : null}
        {journey === "display_comment" && value === 0 ? (
          <Grid item xs={10}>
            {comments != null
              ? comments.map((comment) => (
                  <TextField
                    id={comment.id}
                    multiline
                    rowsMax={4}
                    onClick={() => deleteComment(comment.id)}
                    label={
                      "@" +
                      comment.user +
                      " " +
                      comment.timestamp.replace("T", " ").replace("Z", "")
                    }
                    fullWidth
                    style={{ margin: 8 }}
                    defaultValue={comment.comment}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                ))
              : null}
          </Grid>
        ) : null}
        {journey === "display_comment" && value === 0 ? (
          <Grid item xs={10}>
            <TextField
              id="outlined-full-width"
              label="NEW COMMENT"
              style={{ margin: 8 }}
              placeholder="Write a comment"
              onChange={handleCommentBox}
              fullWidth
              multiline
              rows={4}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
            <CustomButton
              label={"Comment"}
              color="green"
              action={() => sendNewComment(feedbackID, "open")}
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <TabPanel value={value} index={1}>
            <TableContainer className={classes.table} component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Timestamp</b>
                    </TableCell>
                    <TableCell>
                      <b>Title</b>
                    </TableCell>
                    <TableCell>
                      <b>Description</b>
                    </TableCell>
                    <TableCell>
                      <b>Type</b>
                    </TableCell>
                    <TableCell>
                      <b>User</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {feedbackLoaded ? (
                  <TableBody>
                    {feedbackClosedList.map((feedbk) => (
                      <TableRow
                        className={classes.feedbackCSS}
                        onClick={() => getComment(feedbk.iD, "closed")}
                        key={feedbk.name}
                      >
                        <TableCell>{feedbk.Timestamp}</TableCell>
                        <TableCell>{feedbk.Title}</TableCell>
                        <TableCell>{feedbk.Description}</TableCell>
                        <TableCell>{feedbk.Type}</TableCell>
                        <TableCell>{feedbk.User}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : null}
              </Table>
              <div id="error_body"></div>
            </TableContainer>
          </TabPanel>
        </Grid>

        {journey === "display_closed_comment" && value === 1 ? (
          <Grid item xs={10}>
            <Button size="small" variant="outlined">
              {selectedBug}
            </Button>
            <br />
            <div className={classes.comment_title}>{commentTitle} </div>
            <br />
            <div className={classes.description_text}>{description} </div>
          </Grid>
        ) : null}
        {journey === "display_closed_comment" && value === 1 ? (
          <Grid item xs={10}>
            {comments != null
              ? comments.map((comment) => (
                  <TextField
                    id={comment.id}
                    multiline
                    rowsMax={4}
                    label={
                      "@" +
                      comment.user +
                      " " +
                      comment.timestamp.replace("T", " ").replace("Z", "")
                    }
                    fullWidth
                    style={{ margin: 8 }}
                    defaultValue={comment.comment}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                ))
              : null}
            {comments != null && comments.length === 0
              ? "No comments associated with selected feedback."
              : null}
          </Grid>
        ) : null}
      </LayoutPage>
    );
  } else {
    return (
      <Container>
        <Grid container className={classes.root} justify="center" spacing={3}>
          <div className="App">Loading...</div>
        </Grid>
      </Container>
    );
  }
}
