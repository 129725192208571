import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  Form,
  Progress,
  Space,
  Typography,
  Descriptions,
  message,
  Result,
} from "antd";
import { useConnectors } from "../../../../../contexts/Connectors/ZscalerContext";

// Forms
import { ZscalerLocationType } from "../../../../../types/ConnectorsType";
import ModifyLocationForm from "../../Forms/ModifyLocationForm";
import { NgdsErrorType } from "../../../../../types/NgdsErrorType";
import {
  listSublocationByParentId,
  updateLocation,
} from "../../functions/fetchZscaler";
import ResultError from "../../Results/ResultError";
import { LoadingProgress } from "../../LoadingProgress";
import ResultOk from "../../Results/ResultOk";

const { Text } = Typography;

type SubProps = {
  record: ZscalerLocationType;
};

const ModifyLocation: React.FC<SubProps> = ({ record }) => {
  const connectors = useConnectors();

  const [form] = Form.useForm();

  const [submittable, setSubmittable] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [completed, setCompleted] = useState(false);

  const [modifyLocation, setModifyLocation] = useState<
    ZscalerLocationType | undefined
  >(undefined);
  const [modifyLocationLoading, setModifyLocationLoading] =
    useState<boolean>(false);
  const [modifyLocationError, setModifyLocationError] = useState<
    NgdsErrorType | undefined
  >(undefined);

  const [upBandwidth, setUpBandwidth] = useState<number | undefined>(undefined);
  const [dnBandwidth, setDnBandwidth] = useState<number | undefined>(undefined);
  const [tz, setTz] = useState<string | undefined>(undefined);
  const [payload, setPayload] = useState({});

  const onReset = () => {
    form.resetFields();
    setSubmitting(false);
    setSubmittable(false);
    setModifyLocation(undefined);
    setModifyLocationError(undefined);
    setUpBandwidth(undefined);
    setDnBandwidth(undefined);
    setTz(undefined);
    setCompleted(false);
  };

  useEffect(() => {
    if (
      (upBandwidth && +upBandwidth !== +record.upBandwidth) ||
      (dnBandwidth && +dnBandwidth !== +record.dnBandwidth) ||
      (tz && tz !== record.tz)
    ) {
      setSubmittable(true);
    } else setSubmittable(false);

    if (upBandwidth && +upBandwidth !== +record.upBandwidth) {
      setPayload((prev: object) => ({ ...prev, upBandwidth }));
    } else {
      setPayload((prev: object) => {
        delete prev["upBandwidth"];
        return prev;
      });
    }

    if (dnBandwidth && +dnBandwidth !== +record.dnBandwidth) {
      setPayload((prev: object) => ({ ...prev, dnBandwidth }));
    } else {
      setPayload((prev: object) => {
        delete prev["dnBandwidth"];
        return prev;
      });
    }

    if (tz && tz !== record.tz) {
      setPayload((prev: object) => ({ ...prev, tz }));
    } else {
      setPayload((prev: object) => {
        delete prev["tz"];
        return prev;
      });
    }
  }, [upBandwidth, dnBandwidth, tz]);

  const [open, setOpen] = useState(false);
  const showDrawer = () => setOpen(true);

  const onClose = () => {
    if (completed && modifyLocation) {
      // connectors?.fetchLocations()
      connectors?.fetchLocationsAndCredentials()
      // if sublocation
      record.parentId !== 0 &&
        listSublocationByParentId({ parent_id: record.parentId });
    }
    onReset();
    setOpen(false);
  };
  

  const updateLocationId = async () => {
    console.log("Submitting Location Changes");
    setSubmitting(true);
    setSubmittable(false);
    setModifyLocationLoading(true);
    const params = {
      location_id: +record.id,
      payload,
    };
    const response = await updateLocation(params);
    setModifyLocationLoading(false);

    if (response?.hasOwnProperty("error"))
      setModifyLocationError({ ...response.error });
    else if (response.status !== 200)
      setModifyLocationError({
        error: "Something whent wrong",
        response: undefined,
      });
    else {
      setModifyLocation(response.data);
      setCompleted(true);
    }
  };

  const progressParams = {
    descriptors: [
      {
        key: "1",
        label: "Location ID",
        value: +record.id,
      },
      {
        key: "2",
        label: "Location Name",
        value: record.name,
      },
    ],
    progressors: [
      {
        key: "3",
        success: completed,
        text: `Updating ${record.name}`,
        loading: modifyLocationLoading,
        exception: modifyLocationError,
        dependOn: submitting,
      },
    ],
  };

  const UpdatingProgress = (
    <Space direction="vertical" size="large" wrap style={{ width: "100%" }}>
      {LoadingProgress(progressParams)}
      {modifyLocation?.id && (
        <ResultOk
          title="Location Modified Successfully!"
          subTitle={
            <Descriptions
              size="small"
              column={1}
              bordered
              style={{ marginTop: 32 }}
            >
              {Object.keys(payload).includes("upBandwidth") && (
                <Descriptions.Item label="New Upload BW">
                  {" "}
                  {modifyLocation.upBandwidth} Kbps{" "}
                </Descriptions.Item>
              )}
              {Object.keys(payload).includes("dnBandwidth") && (
                <Descriptions.Item label="New Download BW">
                  {modifyLocation.dnBandwidth} Kbps
                </Descriptions.Item>
              )}
              {Object.keys(payload).includes("tz") && (
                <Descriptions.Item label="Time Zone">
                  {" "}
                  {modifyLocation.tz}{" "}
                </Descriptions.Item>
              )}
            </Descriptions>
          }
        />
      )}

      {modifyLocationError && (
        <ResultError errorPayload={modifyLocationError} />
      )}
    </Space>
  );

  const buttonLabel =
    record.parentId === 0 ? "Modify Location" : "Modify Sub-Location";

  return (
    <>
      <a onClick={showDrawer}>{buttonLabel}</a>
      <Drawer
        maskClosable={!modifyLocationLoading}
        closable={!modifyLocationLoading}
        title="Modify Location"
        placement="right"
        onClose={onClose}
        open={open}
        width={450}
        extra={
          <Space>
            <Divider type="vertical" />
            <Button disabled={submitting} onClick={onReset}>
              Reset
            </Button>
            <Button
              disabled={!submittable || completed}
              type="primary"
              onClick={updateLocationId}
            >
              Apply
            </Button>
          </Space>
        }
      >
        {submitting ? (
          UpdatingProgress
        ) : (
          <Space
            direction="vertical"
            size="large"
            wrap
            style={{ width: "100%" }}
          >
            <Descriptions column={2} size="small" layout="vertical" bordered>
              <Descriptions.Item label="Location ID ">
                <Text>{record.id}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Location Name">
                <Text>{record.name}</Text>
              </Descriptions.Item>
            </Descriptions>

            <ModifyLocationForm
              form={form}
              record={record}
              setUpBandwidth={setUpBandwidth}
              setDnBandwidth={setDnBandwidth}
              setTz={setTz}
            />
          </Space>
        )}
      </Drawer>
    </>
  );
};

export default ModifyLocation;
