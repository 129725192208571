import axios from "axios";
import Cookies from "universal-cookie";
import DataMappingPageLegacy, { useStyles } from "./DataMappingPageLegacy";
import { useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import VersionSelection from "./Mappers/components/VersionSelection";
import { ToastContainer } from "react-toastify";
import MapperSelection, {
  ViewRules,
} from "./Mappers/components/MapperSelection";
import SourceSelection, {
  LocalInputFile,
  RemoteInputFile,
  AuthModal,
} from "./Mappers/components/SourceSelection";
import TargetSelection from "./Mappers/components/TargetSelection";
import {
  Container,
  Grid,
  Stack,
  Box,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { getFileName, saveBlob, handleHttpError } from "../../common/utils.js";

const cookies = new Cookies();
const access_token = cookies.get("access_token");

const DataMappingPage = () => {
  const [version, setVersion] = useState(null);
  const [mapperId, setMapperId] = useState(0);
  const [sourceFileLocation, setSourceFileLocation] = useState("local");
  const [dataSourceFile, setDataSourceFile] = useState(null);
  const [dataSourceUrl, setDataSourceUrl] = useState(null);
  const [dataTargetFile, setDataTargetFile] = useState(null);
  // const [useTarget, setUseTarget] = useState(false);
  const [outputOption, setOutputOption] = useState("default");
  const [selectedSite, setSelectedSite] = useState(null);
  const [loading, setLoading] = useState(false);
  console.log({
    version,
    mapperId,
    dataSourceFile,
    selectedSite,
  });

  const RESET_ALL_STATES = (stage = "all") => {
    setVersion(null);
    setMapperId(0);
    setSourceFileLocation("local");
    setDataSourceFile(null);
    setDataSourceUrl(null);
    setDataTargetFile(null);
    // setUseTarget(false);
    setOutputOption("default");
    setSelectedSite(null);
  };

  const createMapperDocumemnt = () => {
    setLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.append("data_source", dataSourceFile);
    outputOption !== "default" &&
      bodyFormData.append("existing_file", dataTargetFile);
    axios({
      method: "post",
      url: `/api/v1/mappers/${mapperId}?site_list=${[selectedSite]}`,
      headers: {
        Authorization: "Bearer " + access_token,
        "content-type": "xlsx",
      },
      data: bodyFormData,
      responseType: "blob",
    })
      .then((response) => {
        // Generating filename
        let fileName = getFileName(response.headers);
        saveBlob(response.data, fileName);
      })
      .catch((error) => {
        handleHttpError(error);
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleOutputOption = (e) => {
    const option = e.target.value;
    setOutputOption(option);

    // make sure there is no Target File if not needed
    option === "default" && setDataTargetFile(null);
  };

  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Header />
      {/* Header */}
      <h1 className={classes.mystyle}>Data Mapping</h1>
      {/* Header */}
      <Stack justifyContent="center" alignItems="center" mt={2}>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={0}
          justifyContent="flex-start"
        >
          <CenteredGrid>
            <VersionSelection version={version} setVersion={setVersion} />
          </CenteredGrid>

          {version === "current" && (
            <CenteredGrid>
              <DataMappingPageLegacy access_token={access_token} />
            </CenteredGrid>
          )}

          {version === "experimental" && (
            <>
              <CenteredGrid additional={<ViewRules mapperId={mapperId} />}>
                {version && (
                  <MapperSelection
                    mapperId={mapperId}
                    setMapperId={setMapperId}
                  />
                )}
              </CenteredGrid>

              {mapperId > 0 && (
                <>
                  {version !== "experimental" && (
                    <CenteredGrid>
                      <SourceSelection
                        sourceFileLocation={sourceFileLocation}
                        setSourceFileLocation={setSourceFileLocation}
                        setDataSourceFile={setDataSourceFile}
                        setDataSourceUrl={setDataSourceUrl}
                      />
                    </CenteredGrid>
                  )}
                  {sourceFileLocation === "local" && (
                    <CenteredGrid>
                      <LocalInputFile
                        dataSourceFile={dataSourceFile}
                        setDataSourceFile={setDataSourceFile}
                        selectedSite={selectedSite}
                        setSelectedSite={setSelectedSite}
                      />
                    </CenteredGrid>
                  )}
                  {sourceFileLocation === "remote" && (
                    <CenteredGrid additional={<AuthModal />}>
                      <RemoteInputFile
                        dataSourceUrl={dataSourceUrl}
                        setDataSourceUrl={setDataSourceUrl}
                      />
                    </CenteredGrid>
                  )}
                  {/* {version !== "experimental" && ( */}
                  {selectedSite && (
                    <CenteredGrid>
                      <FormControl size="small" fullWidth>
                        <InputLabel>Output Option</InputLabel>
                        <Select
                          value={outputOption}
                          label="Output Option"
                          onChange={handleOutputOption}
                        >
                          <MenuItem value={"default"}>
                            Create new file based on mapper rules
                          </MenuItem>
                          {/* <MenuItem value={"populate_only"}>
                          Merge mapped data into an existing file and ignore its
                          existing data
                        </MenuItem> */}
                          <MenuItem value={"populate_preserve"}>
                            Merge mapped data into an existing file
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </CenteredGrid>
                  )}
                  {/* )} */}
                  {(dataSourceFile || dataSourceUrl) && (
                    <CenteredGrid>
                      <TargetSelection
                        dataTargetFile={dataTargetFile}
                        setDataTargetFile={setDataTargetFile}
                        // useTarget={useTarget}
                        // setUseTarget={setUseTarget}
                        outputOption={outputOption}
                      />
                    </CenteredGrid>
                  )}
                  <CenteredGrid>
                    <Stack spacing={1}>
                      <LoadingButton
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<ArrowCircleRightIcon />}
                        onClick={createMapperDocumemnt}
                        disableElevation
                        fullWidth
                        color="primary"
                        variant="contained"
                        disabled={
                          mapperId === 0 ||
                          !selectedSite ||
                          !dataSourceFile ||
                          (outputOption !== "default" && !dataTargetFile)
                        }
                      >
                        Run Mapper
                      </LoadingButton>
                      <Button
                        fullWidth
                        color="secondary"
                        variant="outlined"
                        onClick={() => RESET_ALL_STATES()}
                      >
                        Reset
                      </Button>
                    </Stack>
                  </CenteredGrid>
                </>
              )}
            </>
          )}
        </Grid>
      </Stack>
      <Footer />
      <ToastContainer />
    </Container>
  );
};

export default DataMappingPage;

// {version === "current" && (
//   <DataMappingPageLegacy access_token={selectedAccessToken} />
// )}
// {version === "experimental" && <Mappers />}

const CenteredGrid = ({ children, additional }) => {
  return (
    <>
      <Grid item xs={4} sx={{ backgroundColor: "white" }}></Grid>
      <Grid item xs={4}>
        {children}
      </Grid>
      <Grid item xs={4} sx={{ backgroundColor: "white" }}>
        <Box display="flex" justifyContent="flex-start">
          {additional}
        </Box>
      </Grid>
    </>
  );
};
