import React, { createContext, useContext, useState } from "react";
import { ChildrenProps } from "../../../types/ChildrenReactType";
import { NgdsErrorType } from "../../../types/NgdsErrorType";
import {
  SiteType,
  Profile,
  Tenant,
  Machine,
  Element,
  WanInterfaceLabel,
  WanNetwork,
  DnsProfile,
  DnsRole,
  SecurityZone,
} from "../../../types/PaloAltoConnectorsType";
import { connectorCall } from "../connectorCall";
import { TenantDataType } from "../../../types/PaloAltoConnectorsGetTenantData";

export type PaloAltoConnectorsContextType = {
  apikey: string;
  tenantData: TenantDataType | undefined;
  loadingTenants: boolean;
  errorTenantData: NgdsErrorType | undefined;
  durationTenantData: number | undefined;
  get_all_from_tenant: (api_key: string) => Promise<boolean>;
  paloAltoConnect: (api_key: string) => Promise<boolean>;
};

export const PaloAltoConnectorsContext =
  createContext<PaloAltoConnectorsContextType | null>(null);

export const PaloAltoContextProvider: React.FC<ChildrenProps> = ({
  children,
}) => {
  const [apiKey, setApiKey] = useState<string | undefined>(undefined);

  // Tenant Data STATES
  const [tenantData, setTenantData] = useState<TenantDataType>(undefined);
  const [loadingTenantData, setLoadingTenantData] = useState<boolean>(false);
  const [errorTenantData, setErrorTenantData] = useState<
    NgdsErrorType | undefined
  >(undefined);
  const [durationTenantData, setDurationTenantData] = useState<
    number | undefined
  >(undefined);

  // FIRST ENDPOINT TO CONTACT
  const paloAltoConnect = async (api_key: string) => {
    if (api_key) {
      const isTrue = await get_all_from_tenant(api_key);
      if (isTrue) {
        setApiKey(api_key);
      } else {
        setApiKey(undefined);
        setTenantData(undefined);
      }
      return isTrue;
    }
    setApiKey(undefined);
    setTenantData(undefined);
    return false;
  };

  // get_all_from_tenant
  const get_all_from_tenant = async (api_key: string) => {
    if (!api_key) return;
    return await connectorCall(
      "palo_alto",
      "get_all_from_tenant",
      { api_key },
      setTenantData,
      setLoadingTenantData,
      setErrorTenantData,
      setDurationTenantData
    );
  };

  // // Machines
  // const [machines, setMachines] = useState<any>(undefined);
  // const [loadingMachines, selLoadingMachines] = useState<boolean>(false);
  // const [errorMachines, setErrorMachines] = useState<NgdsErrorType | undefined>(
  //   undefined
  // );
  // const [durationMachines, setDurationMachines] = useState<number | undefined>(
  //   undefined
  // );

  // const get_machines = async () => {
  //   if (!apiKey) return;
  //   return await connectorCall(
  //     "palo_alto",
  //     "get_machines",
  //     { api_key: apiKey },
  //     setMachines,
  //     selLoadingMachines,
  //     setErrorMachines,
  //     setDurationMachines
  //   );
  // };

  return (
    <PaloAltoConnectorsContext.Provider
      value={{
        apiKey,
        tenantData,
        loadingTenantData,
        errorTenantData,
        durationTenantData,
        get_all_from_tenant,
        paloAltoConnect,
      }}
    >
      {children}
    </PaloAltoConnectorsContext.Provider>
  );
};

export const usePaloAltoConnectors = () =>
  useContext(PaloAltoConnectorsContext);

export default PaloAltoConnectorsContext;
