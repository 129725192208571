import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CustomButton from "./../../components/Button/CustomButton.js";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../components/Loader/Loader";

// Tabulator dependencies
import { ReactTabulator } from "react-tabulator";
import "react-tabulator/lib/styles.css";
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css";

// Custom components

const useStyles = makeStyles((theme) => ({
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  grid: {
    marginTop: "6rem",
  },
  mystyle: {
    color: "rgb(85, 20, 180)",
  },
  button: {
    marginTop: "2rem",
    minWidth: "120px",
    marginBottom: "2rem",
    justifyContent: "center",
    textAlign: "center",
  },
  tabButton: {
    minWidth: "110px",
    marginRight: "1rem",
  },
}));

const apColumn = [
  { title: "AP Name", field: "name", width: 200, editor: "input" },
  { title: "Mac Address", field: "mac_address", width: 200, editor: "input" },
  {
    title: "Delete Row",
    formatter: "buttonCross",
    align: "center",
    headerSort: false,
    cellClick: function (e, cell) {
      if (window.confirm("Are you sure you want to delete this entry?")) cell.getRow().delete();
    },
  },
];

const configColumn = [
  { title: "Command", field: "command", width: 200, editor: "input" },
  { title: "Value", field: "value", width: 200, editor: "input" },
  {
    title: "Delete Row",
    formatter: "buttonCross",
    align: "center",
    headerSort: false,
    cellClick: function (e, cell) {
      if (window.confirm("Are you sure you want to delete this entry?")) cell.getRow().delete();
    },
  },
];
const initialApData = [
  {
    name: "wap_example_01",
    mac_address: "1a:1b:1c:1d:1e:1f",
  },
  {
    name: "wap_example_02",
    mac_address: "",
  },
  {
    name: "wap_example_03",
    mac_address: "31:32:33:34:34:a3",
  },
];
const initialConfigData = [
  {
    command: "command_01_here",
    value: "value_here",
  },
  {
    command: "command_02_here",
    value: "",
  },
];

export default function WAPConfigTabulatorView(props) {
  var fileDownload = require("js-file-download");
  const classes = useStyles();
  let [isLoading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const apTableRef = React.createRef(); // table reference
  const [tableApData, setTableApData] = React.useState(initialApData); // stores original data
  const [newTableApData, setNewTableApData] = React.useState(initialApData); // stores updated data
  const configTableRef = React.createRef(); // table reference
  const [tableConfigData, setTableConfigData] = React.useState(initialConfigData); // stores original data
  const [newTableConfigData, setNewTableConfigData] = React.useState(initialConfigData); // stores updated data
  const options = {
    height: 275,
    movableRows: true,
    history: true,
  };
  const onAddRow = () => {
    apTableRef.current.table.addRow({}, false); //true adds to top
  };

  const onActionUndo = () => {
    apTableRef.current.table.undo();
  };

  const onActionRedo = () => {
    apTableRef.current.table.redo();
  };
  const onAddRow2 = () => {
    configTableRef.current.table.addRow({}, false); //true adds to top
  };

  const onActionUndo2 = () => {
    configTableRef.current.table.undo();
  };

  const onActionRedo2 = () => {
    configTableRef.current.table.redo();
  };

  const generateData = () => {
    const bodyOut = organiseData();
    generateFile(bodyOut);
  };
  const organiseData = () => {
    const waps = [];
    const cfgs = [];
    var i;
    for (i = 0; i < newTableApData.length; i++) {
      waps.push({ name: newTableApData[i].name, mac_address: newTableApData[i].mac_address });
    }
    for (i = 0; i < newTableConfigData.length; i++) {
      cfgs.push({ command: newTableConfigData[i].command, value: newTableConfigData[i].value });
    }
    const wapConfig = { waps: [], config: [], customer: "customerName", site: "siteName" };
    for (const wap of waps) {
      if (wap["name"]) {
        wap["name"] = wap["name"].replace(/\r/, ""); //removes the carriage return from name *happens when pasting from excel clipboard
        wap["mac_address"] = wap["mac_address"].replace(/\r/, ""); //removes the carriage return from mac_address
        wapConfig.waps.push({ ...wap });
      }
    }
    for (const cfg of cfgs) {
      if (cfg["command"]) {
        cfg["command"] = cfg["command"].replace(/\r/, ""); //removes the carriage return from command
        cfg["value"] = cfg["value"].replace(/\r/, ""); //removes the carriage return from value
        wapConfig.config.push({ ...cfg });
      }
    }
    return JSON.stringify(wapConfig, null, 2);
  };

  const generateFile = (bodyOut) => {
    setLoading(true);
    axios({
      method: "post",
      url: "/api/v1/wlan/config/wap",
      data: bodyOut,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.access_token,
        Accept: "text/csv",
      },
      responseType: "blob",
    })
      .then((res) => {
        setLoading(false);
        console.log();
        fileDownload(res.data, "AP_Configs.csv");
        toast.success("Results successfully generated!");
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          toast.error("Error, please contact admin.");
        }
        if (error.response.headers === 401) {
          toast.error("Error 401, please login.");
        }
        throw error;
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const exportJson = () => {
    const bodyOut = organiseData();
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(bodyOut);
    const link = document.createElement("a");
    link.setAttribute("href", dataStr);
    link.setAttribute("download", "AP_Configs.json");
    document.body.appendChild(link); // required for firefox
    link.click();
  };
  const importJson = () => {
    const input = document.getElementById("import-btn");
    const file = input.files[0];
    const reader = new FileReader();
    reader.readAsText(file);

    reader.onload = function () {
      try {
        JSON.parse(reader.result);
      } catch (err) {
        toast(
          `Import failed!\n\n*****\n\nIt seems the file you are trying to import is not a proper JSON structured data!\n\n*****`
        );
      }
      const readerData = JSON.parse(reader.result);
      const wapData = readerData["waps"];
      const cfgData = readerData["config"];
      const customerName = readerData["customer"];
      const siteName = readerData["site"];
      setTableApData(wapData);
      setTableConfigData(cfgData);

      if (document.getElementById("customerName") !== null || document.getElementById("siteName") !== null) {
        document.getElementById("customerName").value = customerName;
        document.getElementById("siteName").value = siteName;
      }
      console.log("Config loaded from JSON File");
    };
    reader.onerror = function () {
      console.log(reader.error);
    };
  };

  return (
    <Grid container className={classes.root} justify="center" spacing={3}>
      <Grid item xs={12}>
        <input className={classes.input} id="import-btn" type="file" accept="application/json" onChange={importJson} />
        <label htmlFor="import-btn">
          <Button className={classes.tabButton} variant="outlined" component="span">
            Import JSON
          </Button>
        </label>
        <Button className={classes.tabButton} variant="outlined" onClick={exportJson}>
          Export JSON
        </Button>
        <Button className={classes.tabButton} variant="outlined" onClick={handleClickOpen}>
          Need help?
        </Button>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"WAP Config Generator Instructions"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            1. Input required AP names under AP Name column <br />
            2. Input AP MAC addresses corresponding to their names (optional)*
            <br />
            3. Input CLI Commads under CLI Command
            <br />
            4. Input values under Values (optional)
            <br />
            <br />
            Note: If MAC address field is left empty, no configs are generated for naming the APs "config ap name AP
            Name MAC Address"
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={6}>
        <h3>Access Points</h3>
      </Grid>
      <Grid item xs={6}>
        <h3>Configurations</h3>
      </Grid>
      <Grid item xs={6}>
        <div>
          <ReactTabulator
            ref={apTableRef}
            columns={apColumn}
            data={tableApData}
            dataChanged={(newData) => setNewTableApData(newData)}
            options={options}
            data-custom-attr="test-custom-attribute"
            className="custom-css-class"
          />
          <Button className={classes.tabButton} variant="outlined" id="add-row" onClick={onAddRow}>
            Add Row
          </Button>
          <Button className={classes.tabButton} variant="outlined" id="action-undo" onClick={onActionUndo}>
            Undo
          </Button>
          <Button className={classes.tabButton} variant="outlined" id="action-redo" onClick={onActionRedo}>
            Redo
          </Button>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div>
          <ReactTabulator
            ref={configTableRef}
            columns={configColumn}
            data={tableConfigData}
            dataChanged={(newData) => setNewTableConfigData(newData)}
            options={options}
            data-custom-attr="test-custom-attribute"
            className="custom-css-class"
          />
          <Button className={classes.tabButton} variant="outlined" id="add-row" onClick={onAddRow2}>
            Add Row
          </Button>
          <Button className={classes.tabButton} variant="outlined" id="action-undo" onClick={onActionUndo2}>
            Undo
          </Button>
          <Button className={classes.tabButton} variant="outlined" id="action-redo" onClick={onActionRedo2}>
            Redo
          </Button>
        </div>
      </Grid>
      {isLoading ? (
        <Grid item xs={12}>
          <Loader text="L O A D I N G" />
        </Grid>
      ) : null}
      <div className={classes.button}>
        <CustomButton label={"Generate Data"} color="purple" action={() => generateData()} />
      </div>
      <ToastContainer />
    </Grid>
  );
}
