import { Result } from 'antd'
import React, { FC } from 'react'
import { NgdsErrorType } from '../../../../types/NgdsErrorType'

type Props = {
    errorPayload: NgdsErrorType
    title?: string
    subTitle?: string
}

const ResultError: FC<Props> = ({
    errorPayload,
    title = "Action Failed",
    subTitle = "Please try again later, if the problem persists report this issue."
}) => {

    const ngDS_error = errorPayload.response?.data?.error

    const error = errorPayload?.error
    const code = errorPayload?.code
    const message = ngDS_error || errorPayload?.message

    return (

        <div style={{ marginTop: 24, borderStyle: 'dashed', borderColor: '#ff4d4f', borderWidth: 1, borderRadius: 5, backgroundColor: "#f3f6f4" }}>
            <Result
                status="error"
                title={code ? code : title}
                subTitle={message ? message : error ? error : subTitle}
            />
        </div>

    )
}

export default ResultError