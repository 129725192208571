import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ChildrenProps } from "../../../types/ChildrenReactType";
import {
  MerakiProductsType,
  MerakiNetworksType,
  MerakiOrganisationType,
} from "../../../types/MerakiConnectorsType";
import axios from "axios";
import { NgdsErrorType } from "../../../types/NgdsErrorType";

function convertMs(millis: number) {
  const minutes = +Math.floor(millis / 60000);
  const seconds = +((millis % 60000) / 1000).toFixed(0);
  return seconds === 60
    ? minutes + 1 + ":00"
    : minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
}

// const sleep = (ms: number) => new Promise(
//     resolve => setTimeout(resolve, ms));

export type MerakiConnectorsContextType = {
  settings: any;
  loadingSettings: boolean;
  errorSettings: NgdsErrorType | undefined;
  durationSettings: number | undefined;
  fetchSettings: () => void;
  organizations: MerakiOrganisationType[] | undefined;
  loadingOrganizations: boolean;
  errorOrganizations: NgdsErrorType | undefined;
  durationOrganizations: number | undefined;
  fetchOrganizations: (merakiToken: string) => Promise<boolean>;
  merakiConnect: (token: string | undefined) => Promise<boolean>;
  apiKey: string | undefined;
  networks: MerakiNetworksType[] | undefined;
  loadingNetworks: boolean;
  errorNetworks: NgdsErrorType | undefined;
  durationNetworks: number | undefined;
  fetchNetworks: (org_id: number | undefined) => Promise<boolean>;
  orgId: number | undefined;
  products: MerakiProductsType[] | undefined;
  loadingProducts: boolean;
  errorProducts: NgdsErrorType | undefined;
  durationProducts: number | undefined;
  fetchProducts: (org_id: number | undefined) => Promise<boolean>;
  productsDetails: any[] | undefined;
  loadingProductsDetails: boolean;
  errorProductsDetails: NgdsErrorType | undefined;
  durationProductsDetails: number | undefined;
  fetchProductsDetails: (network_ids: string[] | undefined, operation_list: string[] | undefined) => Promise<boolean>;
  // updateNetwork: any[] | undefined;
  // loadingUpdateNetwork: boolean;
  // errorUpdateNetwork: NgdsErrorType | undefined;
  // durationUpdateNetwork: number | undefined;
  // update_network_settings: (
  //   network_ids: string[] | undefined
  // ) => Promise<boolean>;
};

export const MerakiConnectorsContext =
  createContext<MerakiConnectorsContextType | null>(null);

export const MerakiConnectorsContextProvider: React.FC<ChildrenProps> = ({
  children,
}) => {
  // Meraki Api Key
  const [apiKey, setApiKey] = useState<string | undefined>(undefined);

  const [orgId, setOrgId] = useState<number | undefined>(undefined);

  const commonMerakiFetch = useCallback(
    async (
      action: string,
      params: any, // {}
      dataStateCallback: React.Dispatch<React.SetStateAction<any>>,
      loadingCallback: React.Dispatch<
        React.SetStateAction<boolean | undefined>
      >,
      errorCallback: React.Dispatch<
        React.SetStateAction<NgdsErrorType | undefined>
      >,
      durationCallback: React.Dispatch<
        React.SetStateAction<number | undefined>
      > = undefined,
      jsonFile: any = null
    ) => {
      // Gets from local Storage
      const user = JSON.parse(localStorage.getItem("user"));
      const account = JSON.parse(localStorage.getItem("currentAccount"));
      const account_id = account?.id;
      const access_token = user?.access_token;

      // reset states
      dataStateCallback(undefined);
      loadingCallback(false);
      errorCallback(undefined);
      durationCallback && durationCallback(undefined);

      // if the api_key is sent as param. E.g. First Call like get_org_list

      loadingCallback(true);
      const url = "/api/v1/api_connectors";
      const body = {
        user: {
          ein: user?.uin,
          fullname: user?.fullname,
          account,
        },
        account_id: +account_id,
        connector: "meraki",
        params,
        action,
      };

      console.log(
        `Started ${body.connector}: "${body.action}" for account: ${account.name}(${account.id}) by ${user.fullname} (${user.uin})`
      );
      console.log({
        params: {
          ...params,
          api_key: `***${params.api_key?.slice(-4, params.api_key?.length)}`,
        },
      });
      const start = performance.now();

      let isTrue = false;
      await axios
        .post(url, body, {
          headers: { Authorization: "Bearer " + access_token },
        })
        .then((resp: any) => {
          console.log({ [`${action}_RESPONSE`]: resp.data });
          if (resp?.hasOwnProperty("error")) errorCallback({ ...resp.error });
          else if (resp.status !== 200) {
            errorCallback({ error: "Something whent wrong", response: null });
            isTrue = false;
          } else {
            dataStateCallback(resp.data);
            isTrue = true;
          }
        })
        .catch((error: any) => {
          errorCallback(error);
          console.log({ [`${action}_ERROR`]: error });
          isTrue = false;
        })
        .finally(() => {
          loadingCallback(false);
          const end = performance.now();
          const duration = end - start;
          durationCallback && durationCallback(duration);
          console.log(
            `Finished ${body.connector}: "${body.action}" in ${convertMs(
              duration
            )}`
          );
        });
      return isTrue;
    },
    []
  );


  // Organizations Products States ALL
  const [settings, setSettings] = useState<MerakiProductsType[] | undefined>(
    undefined
  );
  const [loadingSettings, setLoadingSettings] = useState<boolean>(false);
  const [errorSettings, serErrorSettings] = useState<NgdsErrorType | undefined>(
    undefined
  );
  const [durationSettings, setDurationSettings] = useState<number | undefined>(
    undefined
  );


  const fetchSettings = async () => {
    setSettings(undefined);
    await commonMerakiFetch(
      "meraki_settings_json",
      { api_key: apiKey, operation_list: null },
      setSettings,
      setLoadingSettings,
      serErrorSettings,
      setDurationSettings
    )
  }

  // Organizations States ALL
  const [organizations, setOrganizations] = useState<
    MerakiOrganisationType[] | undefined
  >(undefined);
  const [loadingOrganizations, setLoadingOrganizations] =
    useState<boolean>(false);
  const [errorOrganizations, setErrorOrganizations] = useState<
    NgdsErrorType | undefined
  >(undefined);
  const [durationOrganizations, setDurationOrganizations] = useState<
    number | undefined
  >(undefined);

  const fetchOrganizations = async (merakiToken: string) => {
    return await commonMerakiFetch(
      "get_org_list",
      {
        operation_list: null,
        api_key: merakiToken,
      },
      setOrganizations,
      setLoadingOrganizations,
      setErrorOrganizations,
      setDurationOrganizations
    );
  };

  const resetOrgs = () => {
    setOrgId(undefined);
    setOrganizations(undefined);
    setNetworks(undefined);
    setProducts(undefined);
  };

  // FIRST ENDPOINT TO CONTACT
  const merakiConnect = async (merakiToken: string | undefined) => {
    if (merakiToken) {
      resetOrgs();
      const isTrue = await fetchOrganizations(merakiToken);
      isTrue ? setApiKey(merakiToken) : setApiKey(undefined);
      return isTrue;
    }
    resetOrgs();
    return false;
  };

  // Organizations Network States ALL
  const [networks, setNetworks] = useState<MerakiNetworksType[] | undefined>(
    undefined
  );
  const [loadingNetworks, setLoadingNetworks] = useState<boolean>(false);
  const [errorNetworks, setErrorNetworks] = useState<NgdsErrorType | undefined>(
    undefined
  );
  const [durationNetworks, setDurationNetworks] = useState<number | undefined>(
    undefined
  );

  const fetchNetworks = async (org_id: number) => {
    if (org_id) {
      setNetworks(undefined);
      const isTrue = await commonMerakiFetch(
        "get_org_networks",
        { org_id, api_key: apiKey, operation_list: null, },
        setNetworks,
        setLoadingNetworks,
        setErrorNetworks,
        setDurationNetworks
      );
      isTrue ? setOrgId(org_id) : setOrgId(undefined);
      return true;
    }
    setNetworks(undefined);
    return false;
  };

  // Organizations Products States ALL
  const [products, setProducts] = useState<MerakiProductsType[] | undefined>(
    undefined
  );
  const [loadingProducts, setLoadingProducts] = useState<boolean>(false);
  const [errorProducts, setErrorProducts] = useState<NgdsErrorType | undefined>(
    undefined
  );
  const [durationProducts, setDurationProducts] = useState<number | undefined>(
    undefined
  );

  const fetchProducts = async (org_id: number) => {
    if (org_id) {
      setProducts(undefined);
      await commonMerakiFetch(
        "get_org_devices",
        { org_id, api_key: apiKey, operation_list: null, },
        setProducts,
        setLoadingProducts,
        setErrorProducts,
        setDurationProducts
      );
      return true;
    }
    setProducts(undefined);
    return false;
  };




  // Organizations Products Details States ALL
  const [productsDetails, setProductsDetails] = useState<any[] | undefined>(
    undefined
  );
  const [loadingProductsDetails, setLoadingProductsDetails] =
    useState<boolean>(false);
  const [errorProductsDetails, setErrorProductsDetails] = useState<
    NgdsErrorType | undefined
  >(undefined);
  const [durationProductsDetails, setDurationProductsDetails] = useState<
    number | undefined
  >(undefined);

  const fetchProductsDetails = async (network_ids: string[], operation_list: string[]) => {
    if (network_ids) {
      setProductsDetails(undefined);
      await commonMerakiFetch(
        "get_call",
        { network_ids, operation_list, api_key: apiKey },
        setProductsDetails,
        setLoadingProductsDetails,
        setErrorProductsDetails,
        setDurationProductsDetails
      );
      return true;
    }
    setProductsDetails(undefined);
    return false;
  };

  // const [updateNetwork, setUpdateNetwork] = useState<any[] | undefined>(
  //   undefined
  // );
  // const [loadingUpdateNetwork, setLoadingUpdateNetwork] =
  //   useState<boolean>(false);
  // const [errorUpdateNetwork, setErrorUpdateNetwork] = useState<
  //   NgdsErrorType | undefined
  // >(undefined);
  // const [durationUpdateNetwork, setDurationUpdateNetwork] = useState<
  //   number | undefined
  // >(undefined);

  // const update_network_settings = async (updates: {}) => {
  //   if (updates) {
  //     setUpdateNetwork(undefined);
  //     await commonMerakiFetch(
  //       "update_network_settings",
  //       { api_key: apiKey, updates },
  //       setUpdateNetwork,
  //       setLoadingUpdateNetwork,
  //       setErrorUpdateNetwork,
  //       setDurationUpdateNetwork
  //     );
  //     return true;
  //   }
  //   setUpdateNetwork(undefined);
  //   return false;
  // };

  return (
    <MerakiConnectorsContext.Provider
      value={{
        //-----------------------------------------------------------
        settings,
        loadingSettings,
        errorSettings,
        durationSettings,
        fetchSettings,
        organizations,
        loadingOrganizations,
        errorOrganizations,
        fetchOrganizations,
        durationOrganizations,
        apiKey,
        merakiConnect,
        networks,
        loadingNetworks,
        errorNetworks,
        durationNetworks,
        fetchNetworks,
        orgId,
        products,
        loadingProducts,
        errorProducts,
        durationProducts,
        fetchProducts,
        productsDetails,
        loadingProductsDetails,
        errorProductsDetails,
        durationProductsDetails,
        fetchProductsDetails,
        // updateNetwork,
        // loadingUpdateNetwork,
        // errorUpdateNetwork,
        // durationUpdateNetwork,
        // update_network_settings,
        //-----------------------------------------------------------
      }}
    >
      {children}
    </MerakiConnectorsContext.Provider>
  );
};

export const useMerakiConnectors = () => useContext(MerakiConnectorsContext);

export default MerakiConnectorsContext;
