import React, { useState } from "react";
import { useHistory } from "react-router-dom";

//material ui components
import { makeStyles } from "@material-ui/core/styles";
import { css } from "@emotion/react";
import CustomButton from "./../../components/Button/CustomButton.js";
import PropagateLoader from "react-spinners/PropagateLoader";
import axios from "axios";

// MUI
import {
  Select,
  MenuItem,
  FormControl,
  Stack,
  Paper,
  Divider,
  Button,
  Alert,
} from "@mui/material";

//date selector components
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";

//toaster
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LayoutPage } from "../JourneyPage/JourneyPage.js";

const override = css`
  display: block;
  text-align: center;
  margin: 0 auto;
  border-color: red;
`;

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginTop: "80px",
    marginBottom: "80px",
  },
  content: {
    paddingTop: "10%",
    textAlign: "center",
  },
  mystyle: {
    color: "rgb(85, 20, 180)",
  },
  button: {
    width: "80%",
    marginRight: "10%",
    marginLeft: "10%",
    marginBottom: "1%",
    justifyContent: "center",
    textAlign: "center",
  },
  dateRange: {
    paddingTop: "10px",
  },
  loader: {
    marginTop: 50,
  },
});

export default function StatsPage(props) {
  const classes = useStyles();
  const history = useHistory();
  const toastId = React.useRef(null);
  const [statsOption, setStatsOption] = React.useState("templates");
  let [isLoadingBar, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const user = JSON.parse(localStorage.getItem("user"));
  const scopes = JSON.parse(user?.scopes);

  const handleChange = (event) => {
    setStatsOption(event.target.value);
  };

  const toastError = (message) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(message);
    }
  };

  const toastSuccess = (message) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.success(message);
    }
  };

  function getStats() {
    const end = dateRange[0]["endDate"];
    if (end === null) {
      toastError("You have not selected a date range");
    } else {
      setLoading(true);
      let startDate = dateRange[0]["startDate"];
      let endDate = dateRange[0]["endDate"];
      startDate =
        startDate.getFullYear() +
        "-" +
        (startDate.getMonth() + 1) +
        "-" +
        startDate.getDate();
      endDate =
        endDate.getFullYear() +
        "-" +
        (endDate.getMonth() + 1) +
        "-" +
        (endDate.getDate() + 1);
      console.log({ startDate, endDate, statsOption });

      axios({
        method: "get",
        url: `/api/v1/stats/${statsOption}?start=${startDate}&end=${endDate}`,

        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          Authorization: "Bearer " + props.access_token,
          "Cache-Control": "no-cache",
        },
        responseType: "blob",
      })
        .then((res) => {
          if (res.status === 200) {
            const fileNameString = res.headers["content-disposition"];
            const fileName = fileNameString.substring(
              fileNameString.lastIndexOf("=") + 2,
              fileNameString.length - 1
            );
            saveBlob(res.data, fileName);
            toastSuccess("Statistics successfully produced");
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            if (err.response.status === 401) {
              history.push("/login");
            }
            // Request made and server responded
            let fr = new FileReader();
            fr.onload = function () {
              const fr_error = JSON.parse(this.result);
              if (typeof fr_error.error != "undefined") {
                toastError(fr_error.error);
              } else {
                toastError(
                  "An unknown error occured, please contact the developers"
                );
              }
            };
            // It's a blob so we need to decode it
            fr.readAsText(err.response.data);
            console.log(err.response.status);
          } else if (err.request) {
            console.log(err.request);
          } else {
            console.log("Error", err.message);
          }
          throw err;
        });
    }
  }

  function saveBlob(blob, fileName) {
    // IE11 check
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = fileName.replace(/['"]+/g, "");
      const event = new MouseEvent("click", { bubbles: false });
      a.dispatchEvent(event);
    }
    setLoading(false);
  }

  return (
    <LayoutPage maxWidth="md">
      <div className="title-container">
        <h1 className="colorIndigo">Global Statistics</h1>
      </div>

      {scopes?.includes("admin") ? (
        <Paper
          variant="outlined"
          elevation={0}
          style={{ padding: "0 32px 0 32px", marginTop: "8px" }}
          width={800}
        >
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={4}
          >
            <div>
              <div className={classes.dateRange}>
                <div className="title-container">
                  <h4>Select an option:</h4>
                </div>
              </div>
              <FormControl sx={{ marginTop: "8px" }}>
                <Select
                  style={{ width: "180px" }}
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  value={statsOption}
                  onChange={handleChange}
                >
                  <MenuItem value={"templates"}>Templates</MenuItem>
                  <MenuItem value={"users"}>Users</MenuItem>
                  <MenuItem value={"tools"}>Tools</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div>
              <div className={classes.dateRange}>
                <div className="title-container">
                  <h4 style={{ marginBottom: "8px" }}>Select a date range:</h4>
                </div>
              </div>

              <DateRange
                editableDateInputs={true}
                onChange={(item) => setDateRange([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={dateRange}
                maxDate={new Date()}
              />
            </div>

            <>
              <div className={classes.dateRange}>
                <div className="title-container">
                  <h4>Download the report:</h4>
                </div>
                <div>
                  <Button
                    style={{ width: "180px", marginTop: "8px" }}
                    variant="outlined"
                    onClick={() => {
                      getStats();
                    }}
                  >{`Get ${statsOption} Stats`}</Button>
                  <div className={classes.loader}>
                    {isLoadingBar ? (
                      <PropagateLoader
                        color={"#5514B4"}
                        css={override}
                        size={20}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          </Stack>
        </Paper>
      ) : (
        <Alert style={{ marginTop: 16 }} severity="error">
          You do not have the necessary permissions to view this page.
        </Alert>
      )}
    </LayoutPage>
  );
}
