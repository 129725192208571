import { css } from "@emotion/react"
import PropagateLoader from "react-spinners/PropagateLoader";
import { makeStyles } from "@material-ui/core/styles";


const override = css`
display: block;
margin: 0 auto;
border-color: red;
`;

const useStyles = makeStyles((theme) => ({
    body: {
        position: "absolute",
        top: "40%",
        textAlign: "center",
        backgroundColor: "rgba(85,20, 180, 0.9)",
        width: "60%",
        height: "130px"

    },
    heading: {
        color: "white",
        fontSize: "larger",
    },

}));

export default function Loader(props) {
    const classes = useStyles();

    return (
            <div className={classes.body}>
                <br/>
                <p className={classes.heading}>{props.text.split('').join(' ')}</p>
                <PropagateLoader color={'white'} css={override} size={20} />
            </div>
    )
}