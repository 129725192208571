import React, { useEffect, useState } from 'react'
import { Button, Form, Input, InputNumber, Progress, Select, Space, Typography, Tabs, Descriptions, Result } from 'antd'
import { useConnectors } from '../../../../contexts/Connectors/ZscalerContext'
import { ZscalerSettingType } from '../../../../types/ConnectorsType'
import { FormInstance, useWatch } from 'antd/es/form/Form'

type FormProps = {
    form: FormInstance<any>
    country: string | undefined
    siteCode: string | undefined
    setSiteCode: React.Dispatch<React.SetStateAction<string | undefined>>
    setCountry: React.Dispatch<React.SetStateAction<string | undefined>>
    setFqdn: React.Dispatch<React.SetStateAction<string | undefined>>
    setLocationName: React.Dispatch<React.SetStateAction<string | undefined>>
}

const NewLocationForm: React.FC<FormProps> = ({ form, country, siteCode, setSiteCode, setCountry, setFqdn, setLocationName }) => {

    // Getting Account Details
    const currentAccount = JSON.parse(localStorage.getItem('currentAccount'))
    const currentAccountName = currentAccount.name

    // Connectors Context
    const connectors = useConnectors()

    // Getting General Form Settings
    const settings = connectors?.settings

    // Getting Specific Group Form Settings
    const locationFormFields = settings?.filter((item: any) => item.group === 'Location Management')

    // Getting Default/initial form Values
    const initialLocationValues: any = {}
    locationFormFields?.forEach((item: any) => initialLocationValues[item.parameter] = item.default_value)


    // Creating List with Timezone and Countries
    const tzPossibleValues = locationFormFields?.filter(item => item.parameter === "tz")[0]?.possible_values
    const countryCodes = locationFormFields?.filter(item => item.parameter === "country")[0].possible_values

    const disabledFields = locationFormFields?.filter(item => item.variable === true || item.parameter === 'name').map(item => item.parameter)

    // Creating Location Names Array for Validation
    const allLocationsNames = connectors?.locations?.map((location: any) => location.name)

    // Form handlers start
    const businessUnit = useWatch('business_unit', form)
    const [showAllLocationAttributes, setShowAllLocationAttributes] = useState(true)


    // Get Timezone Dynamically via Country Select
    useEffect(() => {
        const getTz = (country: string) => {
            const tzValue = tzPossibleValues?.filter((tz: any) => tz.includes(country))[0] || initialLocationValues.tz
            form.setFieldValue('tz', tzValue)
        }
        if (country && country !== "NONE") getTz(country)
    }, [country])

    useEffect(() => {
        const countryCode = countryCodes?.filter((item: any) => item.name === country)[0]?.code
        const prefixName = currentAccountName?.split(/\s|_/)[1] // Too Specific for DHL
        const generateName = () => {
            const locationName = `${prefixName}-${countryCode}-${businessUnit}-${siteCode}`.toLowerCase()
            form.setFieldValue(['name'], locationName)
            form.setFieldValue(['vpnCredentials', 'fqdn'], `${locationName}@dhl.com`)
            setLocationName(locationName)
            setFqdn(`${locationName}@dhl.com`)
        }
        if (siteCode && (country && country !== 'NONE')) {
            generateName()
        } else {
            form.setFieldValue(['name'], undefined)
            form.setFieldValue(['vpnCredentials', 'fqdn'], undefined)
        }
    }, [siteCode, country, businessUnit, currentAccountName])


    const OptionValue = (input: ZscalerSettingType) => {
        const possible_values = input.possible_values
        if (possible_values) {
            // Country
            if (input.parameter === "country") {
                const countries = possible_values?.map((item: { name: string }) => item.name)
                return (
                    <Select
                        showSearch
                        onChange={(countryName) => setCountry(countryName)}
                    >
                        {countries?.map((item: any) =>
                            <Select.Option
                                key={item}
                                value={item}
                            >
                                {`${item}`}
                            </Select.Option>)}
                    </Select>
                )
            } else if (typeof Array.isArray(possible_values[0])) {
                return (
                    <Select showSearch disabled={!input.variable}>
                        {possible_values?.map((item: any) =>
                            <Select.Option
                                key={item}
                                value={item}>
                                {`${item}`}
                            </Select.Option>)}
                    </Select>)
            }
        } else if (typeof input.default_value === "number") {
            return <InputNumber style={{ width: "100%" }} disabled={!input.variable} />
        } else {
            if (input.parameter === "site_code")
                return <Input
                    onBlur={(e) => setSiteCode(e.target.value)}
                    style={{ width: "100%" }}
                />
            return <Input disabled={!input.variable} />
        }
    }

    const onFinish = (values: any) => {
        console.log({ values })
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo)
    }

    const onReset = () => {
        form.resetFields()
    }


    return (
        <>
            <div>
                <Button
                    style={{ marginTop: 10, marginRight: 'auto' }}
                    type="link"
                    size='small'
                    onClick={() => setShowAllLocationAttributes(!showAllLocationAttributes)}
                >
                    {showAllLocationAttributes && 'Show All Attributes' || "Hide Default Attributes"}
                </Button>
            </div>
            <Form
                layout='vertical'
                style={{ marginTop: 30, maxWidth: "100%" }}
                size='small'
                form={form}
                name='new-location-form'
                onFinish={onFinish}
                initialValues={{ ...initialLocationValues }}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                labelWrap
            >
                <Form.Item key={"location"}
                    name={["id"]}
                    label={'Location ID'}
                    tooltip={"Created automatically by Zscaler Controller"}
                    hidden={showAllLocationAttributes}
                >
                    <InputNumber disabled placeholder='NEW' style={{ width: "100%" }} />
                </Form.Item>

                {
                    locationFormFields?.map((item) => {
                        return <Form.Item
                            key={item.display_name}
                            label={item.display_name}
                            name={[item.parameter]}
                            required={item.mandatory ? true : false}
                            rules={[{ required: true }]}
                            tooltip={item.description || null}
                            hidden={disabledFields?.includes(item.parameter) ? undefined : showAllLocationAttributes}
                        >
                            {OptionValue(item)}

                        </Form.Item >
                    })
                }

                <Form.Item key={"vpnCredentials"}
                    name={["vpnCredentials"]}
                    label={'vpnCredentials'}
                    hidden
                >
                    <Input disabled placeholder='DUMMY' style={{ width: "100%" }} />
                </Form.Item>
            </Form>
        </ >
    )
}

export default NewLocationForm