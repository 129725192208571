import { Drawer, Space, Divider, Button, Typography, Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import AddressTable from "./AddressTable";
import ExportToExcel from "../../functions/ExportSiteDetailsToExcel";
import InventoryTables from "./InventoryTables";
import ExportSiteDetailsToExcel from "../../functions/ExportSiteDetailsToExcel";

const { Text } = Typography;

const SiteDetails = ({ record }) => {
  const site_id = record.siteId;
  const [open, setOpen] = useState(false);

  const [address, setAddress] = useState(null);
  const [devices, setDevices] = useState(null);
  const [bearers, setBearers] = useState(null);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAddress(null);
    setDevices(null);
    setBearers(null);
  };

  return (
    <>
      <a onClick={() => handleClick()}>Site Details</a>
      <Drawer
        title={record.siteName}
        placement="right"
        onClose={() => handleClose()}
        open={open}
        width={"100%"}
        extra={
          <ExportSiteDetailsToExcel
            address={address}
            devices={devices}
            bearers={bearers}
          />
        }
      >
        <Space direction="vertical">
          {open && (
            <AddressTable
              site_id={site_id}
              address={address}
              setAddress={setAddress}
            />
          )}
          {open && (
            <InventoryTables
              site_id={site_id}
              devices={devices}
              setDevices={setDevices}
              bearers={bearers}
              setBearers={setBearers}
            />
          )}
        </Space>
      </Drawer>
    </>
  );
};

export default SiteDetails;
