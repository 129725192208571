import { useState, useEffect } from "react";
import axios from "axios";
import { selectStyle } from "../../utils";
import {
  downloadTemplate,
  handleHttpError,
} from "../../../../../common/utils.js";
import { Button, Stack, ButtonGroup } from "@mui/material";

//toaster components
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//swal
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

//Cookies
import Cookies from "universal-cookie";

const MySwal = withReactContent(Swal);
const cookies = new Cookies();
const access_token = cookies.get("access_token");

export const LibraryManagerButton = ({ showManager, setShowManager }) => {
  const action = showManager ? "Close " : "Open ";

  return (
    <Button
      variant="text"
      color="primary"
      onClick={() => setShowManager(!showManager)}
    >
      {action} Manager View
    </Button>
  );
};

export const TemplateManagerButtons = ({
  accountId,
  libraryId,
  templateId,
  selectedTemplate,
  setRefresh,
  setTemplateName,
}) => {
  const replaceTemplate = (selectedTemplate) => {
    console.log({
      selectedTemplate,
    });

    var bodyFormData = new FormData();
    let new_template = document.getElementById("new_template").files[0];
    bodyFormData.append("template", new_template);
    axios({
      method: "put",
      url: "/api/v1/templates/" + templateId,
      data: bodyFormData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        toast.success("Template successfully updated.");
      })
      .catch((err) => {
        toast.error("Template could not be updated.");
        handleHttpError(err);
      });
  };

  const replaceTemplateDialog = (selectedTemplate) => {
    MySwal.fire({
      title: "Template Replacement",
      html: "You are about to replace <b>" + selectedTemplate?.name + "</b>",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continue",
    }).then((result) => {
      if (result.value) {
        MySwal.fire({
          title: "Are you sure?",
          html: "\
                  <font color = 'red'>You won't be able to revert this!</font>\
                  <br>It is a good idea to download a backup before...\
                  <br><br>\
                  <input style='padding-left:70px' accept='.docx,.xlsx,.pptx,.txt,.xml,.j2,.vsdx,.vsd.csv,.yaml, .vsdm' \
                  type='file' \
                  id='new_template' \
                  name='new_template'/> \
                  ",
          showCancelButton: "true",
          showConfirmButton: "true",
          confirmButtonColor: "green",
          cancelButtonColor: "red",
          confirmButtonText: "Yes, update template!",
        }).then((res) => {
          if (res.value) {
            replaceTemplate(templateId);
          }
        });
      }
    });
  };

  const deleteTemplate = (selectedTemplate) => {
    axios({
      method: "delete",
      url: "/api/v1/templates/" + templateId,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    })
      .then((res) => {
        toast.success("Template successfully deleted.");
      })
      .catch((err) => {
        toast.error("Template could not be deleted.");
        handleHttpError(err);
      });
  };

  const deleteTemplateDialog = (selectedTemplate) => {
    MySwal.fire({
      title: "Are you sure?",
      html:
        "<font color = 'red'>You are about to  delete </font> <b>" +
        selectedTemplate?.name +
        "</b>",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((answer) => {
      if (answer.value) {
        MySwal.fire({
          title: "Last chance!",
          html: "<p><font color = 'red'>You won't be able to revert this!</font><br>It is a good idea to download a backup before...</p>",
          showCancelButton: "true",
          showConfirmButton: "true",
          confirmButtonColor: "green",
          cancelButtonColor: "red",
          confirmButtonText: "Yes, delete it!",
        }).then((ans) => {
          if (ans.value) {
            deleteTemplate(templateId);
            setRefresh((prev) => !prev);
            setTemplateName({ name: "None", value: 0 });
          }
        });
      }
    });
  };

  const [allLabelOptions, setAllLabelOptions] = useState([]);

  useEffect(() => {
    const getLabel = () => {
      axios
        .get(`/api/v1/labels`, {
          headers: { Authorization: "Bearer " + access_token },
        })
        .then((resp) => {
          const labels = resp.data;
          const options = { 0: "None" };
          labels.forEach((label) => (options[label.id] = label.label));
          // options["new"] = "CREATE NEW"
          setAllLabelOptions(options);
        })
        .catch((error) => handleHttpError(error));
    };
    getLabel();
  }, []);

  const setLabel = async (templateId, labelId) => {
    const URL = `/api/v1/templates/${templateId}`;
    const newLabel = {
      label_id: parseInt(labelId),
    };
    const options = { headers: { Authorization: "Bearer " + access_token } };

    await axios
      .patch(URL, newLabel, options)
      .then((resp) => {
        const response = resp.data;
        console.log({ response });
      })
      .catch((error) => handleHttpError(error));
  };

  const setLabelDialog = (selectedTemplate) => {
    const currentLabelId = selectedTemplate?.label?.id;
    console.log({ currentLabelId });
    MySwal.fire({
      title: "Change Label",
      input: "select",
      inputValue: currentLabelId,
      inputOptions: allLabelOptions,
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((answer) => {
      if (answer.value != currentLabelId) {
        const labelId = answer.value;
        setLabel(templateId, labelId);
        toast.success("Label Updated");
        setRefresh((prev) => !prev);
        setTemplateName({ name: "None", value: 0 });
      } else {
        toast.warning("There is no change in Label");
      }
    });
  };

  const uploadNewTemplate = (libraryId, account_id, label_id) => {
    const bodyFormData = new FormData();
    const new_template_upload = document.getElementById("new_template_upload")
      .files[0];
    bodyFormData.append("template", new_template_upload);
    bodyFormData.append("label_id", label_id);
    bodyFormData.append("account_id", account_id);

    axios({
      method: "post",
      url: `/api/v1/libraries/${libraryId}/templates`,
      data: bodyFormData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        console.log({ "upload template response": res });
        toast.success("Template successfully uploaded.");
      })
      .catch((err) => {
        console.log({ "upload template error": err });
        err.response?.data?.error
          ? toast.error(err.response?.data?.error)
          : handleHttpError(err);
        console.log(err);
      });
  };

  const uploadTemplateDialog = () => {
    const currentLabelId = 0;
    MySwal.fire({
      title: "Are you sure?",
      html: "You are about to upload a new template",
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continue",
    }).then((result) => {
      if (result.value) {
        MySwal.fire({
          title: "File Upload",
          html: "You won't be able to revert this! \
              <br><br> \
              <span> Select File: </span>\
              <br><br> \
              <input \
                style='padding-left:70px' \
                accept='.docx,.xlsx,.pptx,.txt,.xml,.j2,.vsdx,.vsd,.csv,.json,.yaml,.vsdm' \
                type='file' \
                id='new_template_upload' \
                name='new_template_upload' \
                />\
                <br><br> \
                <span> Select Label: </span>\
                ",
          input: "select",
          inputValue: currentLabelId,
          inputOptions: allLabelOptions,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "green",
          cancelButtonColor: "red",
          confirmButtonText: "Yes, upload template!",
          willOpen: () => {
            const confirmButton = Swal.getConfirmButton();
            confirmButton.disabled = true;
          },
          didOpen: () => {
            const confirmButton = Swal.getConfirmButton();
            const selectElement = Swal.getInput();
            const fileInput = document.getElementById("new_template_upload");

            const updateConfirmButtonState = () => {
              confirmButton.disabled = !(
                selectElement.value > 0 && fileInput.files.length > 0
              );
            };

            selectElement.addEventListener("change", updateConfirmButtonState);
            fileInput.addEventListener("change", updateConfirmButtonState);
          },
        }).then((res) => {
          if (res.value) {
            const new_template_upload = document.getElementById(
              "new_template_upload"
            ).files[0];
            if (new_template_upload) {
              const label_id = res.value;
              uploadNewTemplate(libraryId, accountId, label_id);
              setRefresh((prev) => !prev);
            } else {
              toast.error("You need to select a file to upload");
            }
          }
        });
      }
    });
  };

  return (
    <Stack>
      <ButtonGroup
        variant="outlined"
        disableElevation
        color="error"
        sx={selectStyle}
        disabled={templateId === 0}
      >
        <Button onClick={() => replaceTemplateDialog(selectedTemplate)}>
          Replace
        </Button>

        <Button onClick={() => deleteTemplateDialog(selectedTemplate)}>
          Delete
        </Button>

        <Button onClick={() => setLabelDialog(selectedTemplate)}>Label</Button>

        <Button onClick={() => downloadTemplate(templateId)}>Download</Button>
      </ButtonGroup>

      <div
        style={{ marginTop: "1rem", marginRight: "1rem", marginLeft: "auto" }}
      >
        <Button
          size="small"
          fullwidth
          variant="contained"
          disableElevation
          color="success"
          onClick={() => uploadTemplateDialog()}
        >
          {"Upload New"}
        </Button>
      </div>
    </Stack>
  );
};
