// Custom components
import { useState } from "react";

import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";

const DataMappingPage = ({ version, setVersion }) => {
  return (
    <>
      <SelectVersion version={version} setVersion={setVersion} />
    </>
  );
};

export default DataMappingPage;

const SelectVersion = ({ version, setVersion }) => {
  const handleChange = (event) => {
    setVersion(event.target.value);
  };
  return (
    <FormControl size="small" fullWidth>
      <InputLabel>Mapper Version</InputLabel>
      <Select value={version} label="Mapper Version" onChange={handleChange}>
        <MenuItem value={"current"}>Current</MenuItem>
        <MenuItem value={"experimental"}>Experimental</MenuItem>
      </Select>
    </FormControl>
  );
};
