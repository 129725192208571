import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import axios from "axios";
import ReactDOM from "react-dom";
import { handleHttpError } from '../../common/utils.js';
//sweet alert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// Tabulator dependencies
import { ReactTabulator } from "react-tabulator";
import "react-tabulator/lib/styles.css";
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css";
import CustomButton from "../../components/Button/CustomButton.js";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

const useStyles = makeStyles((theme) => ({
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  grid: {
    marginTop: "6rem",
  },
  mystyle: {
    color: "rgb(85, 20, 180)",
  },
  button: {
    marginTop: "2rem",
    minWidth: "120px",
    marginBottom: "2rem",
    justifyContent: "center",
    textAlign: "center",
  },
  tabButton: {
    minWidth: "110px",
    marginRight: "1rem",
  },
}));

export default function TertiaryTabulatorView(props) {
  const initialData = props.data;
  const tertiary = props.tertiary_type;
  const classes = useStyles();
  const MySwal = withReactContent(Swal);

  const apTableRef = React.createRef(); // table reference
  // const [tableApData, setTableApData] = React.useState(props.data); // stores original data
  const [tableApData] = React.useState(props.data); // stores original data
  const [columns, setColumns] = React.useState([]); // stores original data
  const [newTableApData, setNewTableApData] = React.useState([]); // stores updated data
  const [isLoaded, setLoaded] = React.useState(false); // stores updated data

  const deleteIcon = (cell, formatterParams, onRendered) => {
    onRendered(() => {
      ReactDOM.render(<DeleteForeverIcon color="secondary" />, cell.getElement());
    });
  };
  const headers_map = {
    risks: ["description", "mitigation", "name", "owner", "severity"],
    assumptions: ["assumption"],
    implementation_plans: ["name", "description", "time", "owner"],
    implementation_options: ["option", "required"],
    boms: ["name", "item", "quantity"],
    setups: ["name", "element", "field"],
  };
  useEffect(() => {
    const cols = [];
    let headers = headers_map.hasOwnProperty(props.tertiary_type) ? headers_map[props.tertiary_type] : [];
    headers.forEach((header) => {
      if (header === "id") {
        cols.push({ title: header, field: header, width: 200 });
      } else if (header === "quantity") {
        cols.push({ title: header, field: header, width: 200, editor: "input", validator: "integer" });
      } else if (header === "required") {
        cols.push({
          title: header,
          field: header,
          width: 200,
          formatter: "tickCross",
          sorter: "boolean",
          editor: true,
        });
      } else {
        cols.push({ title: header, field: header, width: 200, editor: "input" });
      }
    });
    cols.push({
      title: "Delete Row",
      width: 100,
      formatter: deleteIcon,
      align: "center",
      headerSort: false,
      cellClick: function (e, cell) {
        if (window.confirm("Are you sure you want to delete this entry?")) cell.getRow().delete();
      },
    });
    setColumns(cols);
    setLoaded(true);
  }, []);

  const options = {
    height: 275,
    movableRows: true,
    history: true,
  };
  const onAddRow = () => {
    apTableRef.current.table.addRow({}, false); //true adds to top
  };

  const onActionUndo = () => {
    apTableRef.current.table.undo();
  };

  const onActionRedo = () => {
    apTableRef.current.table.redo();
  };
  const checkNewData = (original_tertiary_ids) => {
    const updated_ids = [];
    newTableApData.forEach((entry) => {
      updated_ids.push(entry.id);
    });

    let tertiarys_to_remove = original_tertiary_ids.filter((tertiary_id) => !updated_ids.includes(tertiary_id));

    tertiarys_to_remove.forEach((entry) => {
      axios({
        method: "delete",
        url: `/api/v1/tertiary/${tertiary}/${entry}`,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + props.access_token,
        },
      })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    });
  };
  const generateData = () => {
    const original_tertiary_ids = [];
    tableApData.forEach((entry) => {
      original_tertiary_ids.push(entry.id);
    });

    checkNewData(original_tertiary_ids);
    for (var x = 0; x < newTableApData.length; x++) {
      if (newTableApData[x].quantity) {
        newTableApData[x].quantity = parseInt(newTableApData[x].quantity);
      }
      if (newTableApData[x].id === undefined) {
        const new_tertiary_data = newTableApData[x];
        delete new_tertiary_data["id"];
        new_tertiary_data["template_id"] = parseInt(props.template_id);
        new_tertiary_data["account_id"] = parseInt(props.account_id);
        axios({
          method: "post",
          url: `/api/v1/tertiary/${tertiary}`,
          data: newTableApData[x],
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + props.access_token,
          },
        })
          .then((res) => {
            MySwal.fire({
              text: "Tertiary data successfully updated",
              icon: "success",
              width: 500,
              confirmButtonColor: "green",
            });
          })
          .catch((err) => {
            handleHttpError(err)
          });
      }
      // TO DO : PUT LOGIC IN TO STOP PATCH REQUESTS IF NO CHANGE MADE.
      else {
        const tertiary_id = newTableApData[x].id;
        let data = newTableApData[x];
        data["account_id"] = parseInt(props.account_id);
        data["template_id"] = parseInt(props.template_id);
        if (JSON.stringify(newTableApData[x]) !== JSON.stringify(initialData[x])) {
          axios({
            method: "patch",
            url: `/api/v1/tertiary/${tertiary}/${tertiary_id}`,
            data: data,
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + props.access_token,
            },
          })
            .then((res) => {
              console.log("Tertiary data updated");
              MySwal.fire({
                text: "Tertiary data successfully updated",
                icon: "success",
                width: 500,
                confirmButtonColor: "green",
              });
            })
            .catch((err) => {
              console.log(err.response);
            });
        }
      }
    }
  };

  return isLoaded ? (
    <div>
      <Grid item xs={12}>
        <ReactTabulator
          ref={apTableRef}
          columns={columns}
          data={tableApData}
          dataChanged={(newData) => setNewTableApData(newData)}
          options={options}
          data-custom-attr="test-custom-attribute"
          className="custom-css-class"
        />
        <Button className={classes.tabButton} variant="outlined" id="add-row" onClick={onAddRow}>
          Add Row
        </Button>
        <Button className={classes.tabButton} variant="outlined" id="action-undo" onClick={onActionUndo}>
          Undo
        </Button>
        <Button className={classes.tabButton} variant="outlined" id="action-redo" onClick={onActionRedo}>
          Redo
        </Button>
      </Grid>
      <br />
      <Grid item xs={12}>
        <CustomButton label="Update data" color="purple" action={generateData} />
      </Grid>
    </div>
  ) : (
    <div></div>
  );
}
