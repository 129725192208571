import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CustomButton from "../../../components/Button/CustomButton.js";
import SpinnerLoader from "../../../components/Loader/SpinnerLoader";
import Cookies from "universal-cookie";
import { Box } from "@material-ui/core";
import axios from "axios";

import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import ClearIcon from "@material-ui/icons/Clear";
import { getFileName, saveBlob, handleHttpError } from "../../../common/utils.js";

const cookies = new Cookies();
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "50px",
    flexGrow: 1,
    marginBottom: "80px",
    textAlign: "center",
  },

  mystyle: {
    color: "rgb(85, 20, 180)",
  },
  inputheader: {
    paddingRight: "30px",
    fontSize: "large",
    fontWeight: "bold",
  },
  inputfile: {
    paddingLeft: "right",
    backgroundColor: "rgb(85, 20, 180)",
    color: "white",
    borderRadius: "3px",
  },
  headergrid: {
    textAlign: "right",
  },
  button: {
    paddingTop: "30px",
  },
  dragDrop: {
    height: 300,
    width: "100%",
  },
  dragDropContainer: {
    paddingTop: 110,
  },
  spinner: {
    paddingTop: 40,
  },
  confs: {
    paddingTop: 40,
    paddingBottom: 80,
    marginInline: "30%",
    alignContent: "center",
    textAlign: "center",
  },
}));

export default function ManualConfigView(props) {
  const classes = useStyles();
  const [isConfigLoading, setConfigLoading] = useState(true);
  const [isConfigsShowing, setConfigsShowing] = useState(false);
  const [isWaitingResponse, setWaitingResponse] = useState(false);
  const [configs, setConfigs] = useState([]);
  const [configsList, setConfigsList] = useState([]);
  const [statusText, setStatusText] = useState("");
  const [options, setOptions] = React.useState("");
  const [isLoading, setLoading] = useState(true);
  const [outputType, setOutputType] = useState("2d");

  const handleChange = (event) => {
    setOutputType(event.target.value);
  };

  useEffect(() => {
    const optionsPayload = [{ value: "3d", name: "3D Data Source" }];
    optionsPayload.push({ value: "2d", name: "2D Data Source" });

    setOptions(optionsPayload);
    setLoading(false);
  }, []);

  function startConverter() {
    const confs = configs;

    let output = outputType;

    // state used for loading spinner
    setWaitingResponse(true);

    var bodyFormData = new FormData();

    confs.forEach((file) => {
      bodyFormData.append("configs", file);
    });

    setWaitingResponse(true);
    setConfigLoading(true);

    axios({
      method: "post",
      url: "/api/v1/parsers?output_type=configs&output_format=" + output,
      data: bodyFormData,
      onUploadProgress: function (progressEvent) {
        // Use this calculation to make a progress bar
        const time = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(0);
        setStatusText("Uploading... " + time + "/100");
        if (time === "100") {
          setStatusText("Converting...");
        }
      },
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + props.access_token,
        Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
      maxContentLength: 100000000,
      maxBodyLength: 1000000000,
    })
      .then((response) => {
        // Generating filename
        let fileName = getFileName(response.headers);
        saveBlob(response.data, fileName);
        setWaitingResponse(false);
        setConfigLoading(false);
      })
      .catch(function (error) {
        setWaitingResponse(false);
        handleHttpError(error);
        setConfigLoading(false);
      });
  }

  const removeFile = useCallback(
    (file) => {
      var filteredFilelist = configs.filter((e) => JSON.stringify(e) !== JSON.stringify(file));

      setConfigs(filteredFilelist);
      if (filteredFilelist.length === 0) {
        setConfigLoading(true);
        setConfigsShowing(false);
      }
    },
    [configs]
  );

  function arrayUnique(array) {
    return array.filter((data, index) => {
      const _data = JSON.stringify(data);
      return (
        index ===
        array.findIndex((obj) => {
          return JSON.stringify(obj) === _data;
        })
      );
    });
  }

  useEffect(() => {
    const creteFileList = (array) => {
      return array.map((file) => (
        <ListItem key={file.path}>
          <ListItemIcon>
            <InsertDriveFileOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={file.path} />
          <ListItemIcon>
            <ClearIcon
              color="error"
              onClick={() => {
                removeFile(file);
              }}
            />
          </ListItemIcon>
        </ListItem>
      ));
    };
    const files = creteFileList(configs);
    setConfigsList(files);
  }, [configs, removeFile]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const finalConfigs = arrayUnique([...configs, ...acceptedFiles]);

      setConfigs(finalConfigs);
      setConfigLoading(false);
      setConfigsShowing(true);
    },
    [configs]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Container>
      <Grid container className={classes.root} justify="center" spacing={3}>
        <Grid item xs={12}>
          <div className="title-container">
            <h2>Please provide config files below (MaxSize=150MB)</h2>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3}>
            <Box display="flex" m="auto" alignItems="center" justifyContent="center">
              <div {...getRootProps()} className={classes.dragDrop}>
                <input {...getInputProps()} />
                <div className={classes.dragDropContainer}>
                  <p>
                    Drag 'n' drop some files here,
                    <br /> or <br />
                    click to select files
                  </p>
                  <div className={classes.button}>
                    <Button variant="outlined">Select files</Button>
                  </div>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>

        {!isLoading ? (
          <Grid item xs={12}>
            <div className="title-container">
              <h3>Select an output type:</h3>

              <FormControl style={{ minWidth: 200 }} variant="outlined" className={classes.formControl}>
                <InputLabel>Output Type</InputLabel>
                <Select
                  onChange={handleChange}
                  inputProps={{
                    name: "library",
                    id: "library-select",
                  }}
                  label="Output Selector"
                  value={outputType}
                >
                  {options.map((e, key) => {
                    return (
                      <MenuItem key={e.value} value={e.value}>
                        {e.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          {isConfigLoading ? (
            <div className={classes.button}>
              <Button variant="contained" disabled>
                Convert Configs
              </Button>
            </div>
          ) : (
            <>
              <div className={classes.button}>
                <CustomButton
                  label={"Convert Configs"}
                  color="purple"
                  action={() => {
                    startConverter();
                  }}
                />
              </div>
            </>
          )}

          {isWaitingResponse ? (
            <div className={classes.spinner}>
              <SpinnerLoader />
              {statusText}
            </div>
          ) : (
            <></>
          )}

          {isConfigsShowing ? (
            <aside>
              <div className={classes.confs}>
                <h4>Selected Config Files:</h4>
                <List>{configsList}</List>
              </div>
            </aside>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
