import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import WAPConfigTabulatorView from "../../pages/WAPConfigPage/WAPConfigTabulatorView";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "100px",
    flexGrow: 1,
    marginBottom: "80px",
    textAlign: "center",
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    minWidth: "100%",
  },
  grid: {
    marginTop: "6rem",
  },
  mystyle: {
    color: "rgb(85, 20, 180)",
  },
  button: {
    width: "80%",
    marginRight: "10%",
    marginLeft: "10%",
    marginBottom: "5%",
    justifyContent: "center",
    textAlign: "center",
  },
}));

export default function WAPConfigPage(props) {
  const classes = useStyles();
  const [access, setAccess] = useState("");

  useEffect(() => {
    const scopes = cookies.get("scopes");

    if (scopes !== undefined) {
      if (scopes.includes("wlan_user")) {
        setAccess(true);
      }
    } else {
      setAccess(false);
    }
  }, []);
  if (access) {
    return (
      <Container>
        <Header />
        <Grid container className={classes.root} justify="center" spacing={3}>
          <Grid item xs={12}>
            <div className="title-container">
              <h1 className={classes.mystyle}>WAP Config Generator</h1>
            </div>
          </Grid>
          <Grid item xs={12}>
            <WAPConfigTabulatorView access_token={props.access_token} />
          </Grid>
        </Grid>

        <Footer />
      </Container>
    );
  }
  if (!access) {
    return (
      <Container>
        <Header />
        <Grid container className={classes.root} justify="center" spacing={3}>
          <div className="App">
            <br />
            You do not have access to the WAP Config Generator, please{" "}
            <a href="/access_request">click here to request access</a> and apply for <strong>wlan_user</strong> if you
            do.
          </div>
        </Grid>
        <Footer />
      </Container>
    );
  }
}
