import Headers from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import CustomCard from "../../components/Card/CustomCard.js";
import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Divider, Typography } from "@mui/material";
import NoAccountBanner from "./NoAccountBanner.js";
import Toolbar from "@mui/material/Toolbar";
import { useSelector } from "react-redux";

export const LayoutPage = ({ children, maxWidth = "md" }) => {
  const accountState = useSelector((state) => state.account.value);
  return (
    <>
      <Headers />
      <Container maxWidth={maxWidth}>
        <Grid
          container
          style={{ marginTop: "3%" }}
          justify="center"
          spacing={2}
        >
          {children}
        </Grid>
      </Container>
      <Toolbar />
      <Footer />
      <ToastContainer />
    </>
  );
};

export default function JourneyPage() {
  const cookies = new Cookies();
  const accounts = cookies.get("accounts");

  useEffect(() => {
    if (accounts?.length === 0) {
      toast.warning("You have not registered an account.");
    }
  }, [accounts]);

  const Content = () => {
    return (
      <>
        <Grid item xs={12}>
          <div className="title-container">
            <h1 className="colorIndigo">Welcome to Next Gen Design Studio</h1>
          </div>
        </Grid>

        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          {accounts?.length === 0 && <NoAccountBanner />}
        </Grid>

        <Grid item xs={4}>
          <CustomCard
            title="Templating"
            description="Effortlessly generate consistent documents using customizable templates."
            label="Start Here"
            journey="templates"
          />
        </Grid>
        <Grid item xs={4}>
          <CustomCard
            title="API Connectors"
            description="Seamlessly interact with REST API systems to access and retrieve real-time data.”"
            label="Start Here"
            journey="connectors"
          />
        </Grid>
        <Grid item xs={4}>
          <CustomCard
            title="Config Conversion"
            description="Easily convert configuration files into easy-to-read data structures."
            label="Start Here"
            journey="conversion"
          />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          {/* <Divider />
          <Typography variant="body2" style={{ padding: 16 }}>
            <strong>Can't see what you're looking for?</strong> <br />
            We’re currently refactoring our app to focus on the most in-demand
            tools. As a result, you might notice that some tools have been
            removed from the landing page. It looks like these tools haven’t
            been used for a while, but if you still need them, please reach out
            to the ngDS team.
          </Typography>
          <Divider /> */}
        </Grid>
      </>
    );
  };

  return (
    <LayoutPage>
      <Content />
    </LayoutPage>
  );
}
