// import { makeStyles } from "@material-ui/core";
import FooterCustom from "../../modern/components/LayoutMain/components/FooterCustom";

import React from "react";
// import btLogo from "./../../assets/images/logo_white.svg";

// const useStyles = makeStyles(() => ({
//   footer: {
//     top: "auto",
//     backgroundColor: "#5514B4",
//     paddingRight: "10%",
//     paddingLeft: "10%",
//     bottom: 0,
//   },
//   logo: {
//     height: "72px",
//     pointerEvents: "none",
//     float: "left",
//   },
//   toolbar: {
//     display: "flex",
//     justifyContent: "space-between",
//   },
// }));

export default function Footer() {
  // const classes = useStyles();
  // const autodocLogo = <img src={btLogo} className={classes.logo} alt="logo" />;

  return <FooterCustom />;

  // return (
  //   <div>
  //     <footer>
  //       {/* <Footnote /> */}
  //       <AppBar position="fixed" className={classes.footer}>
  //         <Toolbar className={classes.toolbar}>
  //           {autodocLogo}
  //           {new Date().getFullYear()} &#169; All rights reserved. BT Group plc
  //       <div></div>
  //         </Toolbar>
  //       </AppBar>
  //     </footer>
  //   </div>
  // );
}
