import React from "react";

const d = new Date();
const year = d.getFullYear();

export default function FooterCustom() {
  return (
    <footer
      style={{
        display: "flex",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#5514b4", //"#1D1D1B",
        color: "white",
        padding: "0.5rem",
      }}
    >
      <small>
        {" "}
        <strong>BT ngDS {year} </strong> © All rights reserved.
      </small>
    </footer>
  );
}
