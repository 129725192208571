import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Tabs,
  Tag,
  Typography,
  message,
} from "antd";

// Components

// Contexts
import type { TabsProps } from "antd";
import Networks from "./sections/Networks";
import { useMerakiConnectors } from "../../../contexts/Connectors/MerakiContext";
import Products from "./sections/Products";
import { ChildrenProps } from "../../../types/ChildrenReactType";
import { exportAsExcel } from "../../../utils/exportAsExcel";
import { CloudDownloadOutlined, ReloadOutlined } from "@ant-design/icons";
import { MerakiNetworksType } from "../../../types/MerakiConnectorsType";
import { useTables } from "../../../contexts/TablesContext";
import ExportToExcel from "./functions/ExportToExcel";
// import GetDetails from './functions/GetDetails';
import Deployment from "./sections/Deployment";

const { Title, Text } = Typography;

const MerakiConnetor = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const meraki = useMerakiConnectors();
  const apiKey = meraki.apiKey;
  const orgId = meraki.orgId;

  const settings = meraki.settings;
  const loadingSettings = meraki.loadingSettings;
  const fetchSettings = meraki.fetchSettings;
  console.log({ settings });

  const organizations = meraki.organizations;
  const loadingOrganizations = meraki.loadingOrganizations;
  const errorOrganizations = meraki.errorOrganizations;

  const networks = meraki.networks;
  const loadingNetworks = meraki.loadingNetworks;
  const errorNetworks = meraki.errorNetworks;

  const [selectWidth, setSelectWidth] = useState<number | undefined>(undefined);

  const organizationOptions = organizations?.map((org: any) => {
    // Defines the size of the select
    let widthLen = (org.name.length + org.id.length + 3.4) * 8;
    if (widthLen > selectWidth || selectWidth === undefined)
      setSelectWidth(widthLen);
    // Creates the Select List Payload
    return {
      value: org.id,
      key: org.id,
      label: (
        <Space>
          <Text style={{ color: "#5514b4" }}>{org.name}</Text>
          <Tag color="purple"> {org.id}</Tag>
        </Space>
      ),
      title: (
        <Space>
          <Text style={{ color: "#5514b4" }}>{org.name}</Text>
          <Tag color="purple"> {org.id}</Tag>
        </Space>
      ),
    };
  });

  const [form] = Form.useForm<{ apiKey: string }>();
  const apiKeyInput = Form.useWatch("apiKey", form);

  const onFinish = async (values: any) => {
    await meraki.merakiConnect(values.apiKey);
  };

  const handleSelect = async (org_id: number) => {
    if (org_id) {
      await meraki.fetchNetworks(org_id);
      await meraki.fetchProducts(org_id);
    }
  };

  const BorderedContent: FC<ChildrenProps> = ({ children }) => {
    return (
      <div
        style={{
          padding: 16,
          marginTop: -16,
          borderLeft: "1px solid #F5F5F5",
          borderRight: "1px solid #F5F5F5",
          borderBottom: "1px solid #F5F5F5",
        }}
      >
        {children}
      </div>
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "networks",
      label: `Organization Networks`,
      children: (
        <BorderedContent>
          <Networks />
        </BorderedContent>
      ),
      disabled: networks === undefined,
    },
    {
      key: "devices",
      label: `Organization Products`,
      children: (
        <BorderedContent>
          <Products />
        </BorderedContent>
      ),
      disabled: networks === undefined,
    },
    {
      key: "deplyment",
      label: `Deployment`,
      children: (
        <BorderedContent>
          <Deployment />
        </BorderedContent>
      ),
      disabled: networks === undefined,
    },
  ];

  const onReload = () => {
    meraki?.fetchNetworks(orgId);
    meraki?.fetchProducts(orgId);
  };

  const operations = (
    <>
      <ExportToExcel />
      <Divider type="vertical" />
      <Button size="small" icon={<ReloadOutlined />} onClick={onReload}>
        Reload
      </Button>
    </>
  );

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <h1 className="colorIndigo" style={{ marginBottom: 16 }}>
        Meraki Connector
      </h1>
      <Space
        direction="vertical"
        size="large"
        style={{ width: "100%", marginTop: 16 }}
      >
        {contextHolder}
        {
          // Dispays ENTER API KEY
          !organizations ? (
            <Form
              form={form}
              name="connect"
              onFinish={onFinish}
              autoComplete="off"
              layout={"inline"}
            >
              <Form.Item name="apiKey">
                <Input.Password
                  placeholder="Enter your Meraki API Key"
                  style={{ width: 400 }}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!apiKeyInput}
                  loading={loadingOrganizations}
                >
                  {loadingOrganizations ? "Connecting" : "Connect"}
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <Space
              direction="vertical"
              size={"large"}
              style={{ width: "100%" }}
            >
              <Card
                title={
                  // Dispays ORGs Dropdown List
                  // !networks ?
                  <Space wrap align="baseline">
                    Meraki Organization:
                    <Select
                      placeholder="Select an Organization"
                      bordered={false}
                      style={{ width: selectWidth }}
                      defaultOpen
                      // value={orgId}
                      onChange={handleSelect}
                      options={organizationOptions}
                      disabled={loadingNetworks}
                    />
                    <Spin spinning={loadingNetworks || loadingOrganizations} />
                  </Space>
                  //             :
                  // <Space wrap>
                  //     <Title level={4}>{organizations?.filter(org => org.id === orgId?.toString())[0]?.name}</Title>
                  //     <Tag bordered={false} color={'purple'}>{orgId}</Tag>
                  //     <Button
                  //         // icon={<IdcardOutlined />}
                  //         size='small'
                  //         type='text'
                  //         onClick={() => {
                  //             meraki.fetchNetworks(undefined)
                  //             meraki.fetchProducts(undefined)
                  //         }}
                  //     >
                  //         <Text type='danger'> {'Change organization'}</Text>
                  //     </Button>
                  // </Space>
                }
                extra={
                  <Space>
                    {apiKey && (
                      <small
                        style={{ marginLeft: 8 }}
                      >{`Connected with token final *****${apiKey?.slice(
                        -4,
                        apiKey.length
                      )}`}</small>
                    )}
                    <Button
                      // icon={<ApiOutlined />}
                      size="small"
                      type="text"
                      onClick={() => meraki.merakiConnect(undefined)}
                    >
                      <Text type="danger">
                        <small>{"Disconnect"}</small>
                      </Text>
                    </Button>
                  </Space>
                }
              >
                {orgId && (
                  <Tabs
                    tabBarExtraContent={operations}
                    type="card"
                    defaultActiveKey="1"
                    items={items}
                    size="large"
                  />
                )}
              </Card>
            </Space>
          )
        }
      </Space>
    </Space>
  );
};

export default MerakiConnetor;
