import { Select } from 'antd'
import type { SelectProps } from 'antd'
import React, { useEffect, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import { dynamicSort } from '../../../../utils/sorter';
import { read, utils } from "xlsx";

interface SiteSelectProps {
    dataSource: Blob,
    selected: string[] | undefined,
    setSelected: Dispatch<SetStateAction<string[] | undefined>>
    disabled: boolean
}

const SiteSelect: React.FC<SiteSelectProps> = ({ dataSource, selected, setSelected, disabled }) => {


    const [filePayload, setFilePayload] = useState([]);
    const [sites, setSites] = useState([]);

    useEffect(() => {
        if (dataSource) {
            const reader = new FileReader();
            reader.readAsBinaryString(dataSource);
            reader.onload = (evt) => {
                const bstr = evt?.target?.result;
                const wb = read(bstr, { type: "binary" });
                const firstSheetName = wb.SheetNames[0];
                const firstWorksheet = wb.Sheets[firstSheetName];
                const data = utils.sheet_to_json(firstWorksheet, { header: 1 });
                setFilePayload(data);
            };
        }
        else {
            setSelected(undefined);
            setSites([]);
            setFilePayload([]);
        }
    }, [dataSource]);

    useEffect(() => {
        if (filePayload.length > 0) {
            const headers = filePayload[0].map((item: string) =>
                item.toString().toLowerCase()
            );
            if (headers.includes("site_id") || headers.includes("site-id")) {
                const indexOfsiteId = headers.indexOf("site_id");
                const indexOfsiteName = headers.indexOf("site_name");
                const sitesList = filePayload
                    .slice(1) // Removes the header
                    .filter((site) => site[indexOfsiteId] !== undefined)
                    .map((site) => ({
                        key: site[indexOfsiteId],
                        site_id: site[indexOfsiteId],
                        site_name: site[indexOfsiteName]
                            ? site[indexOfsiteName]
                            : site[indexOfsiteId],
                    }));

                setSites(sitesList);
                sitesList.length === 1 && setSelected([sitesList[0].site_id]);
            }
        }
    }, [filePayload]);

    const handleChange = (value: string[]) => {
        console.log(`selected ${value}`);
        setSelected(value);
    };

    const options: SelectProps["options"] = sites.map((site) => ({
        label: site.site_name,
        value: site.site_id.toString(),
    }));

    return (
        <>
            <Select
                disabled={!dataSource || disabled}
                value={selected}
                allowClear
                style={{ width: "100%" }}
                placeholder="Select the site to be deployed"
                onChange={handleChange}
                options={options}
                showSearch
                filterOption={(input, option) => (option?.label ?? "").includes(input)}
                filterSort={(optionA: any, optionB: any) =>
                    (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                }
            />
        </>
    )
}

export default SiteSelect