import React, { useState, useEffect, useRef } from 'react';
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from 'axios'
import CustomButton from "./../../../components/Button/CustomButton.js";
import { Confirm } from 'semantic-ui-react'
import { Button, Modal } from 'semantic-ui-react'

//components
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from '@material-ui/core/TextField';

import { handleHttpError } from "../../../common/utils"

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "130px",
        textAlign: "center",
        marginBottom: "80px",
    },
    title: {
        color: "rgb(85, 20, 180)",
    },
    table: {
        minWidth: 650,
    },
    inputName: {
        paddingRight: 50,
        marginRight: 100
    },
    inputNickname: {
        paddingRight: 50
    }
}))

function createData(Name, Nickname, BFG_ID, Edit, Delete) {
    return { Name, Nickname, BFG_ID, Edit, Delete };
}


export default function Accounts(props) {
    const classes = useStyles();
    let textInputName = useRef(null);
    const toastId = React.useRef(null);
    let textInputNickname = useRef(null);
    let textInputBFG = useRef(null);

    let textInputNewName = useRef(null);
    let textInputNewNickname = useRef(null);
    let textInputNewBfgId = useRef(null);

    const [accountsList, setAccountsList] = useState(null);
    const [accountsLoaded, setAccountsLoaded] = useState(false);
    const [open, setOpen] = useState(false);
    const [openEditMessage, setOpenEditMessage] = useState(false);
    const [accountID, setAccountID] = useState(null);
    const [name, setName] = useState("");
    const [nickname, setNickname] = useState("");
    const [bfgId, setBfgId] = useState("");
    const [updatedAccount, setUpdatedAccount] = useState({});
    const [openEdit, setOpenEdit] = React.useState(false)

    const toastSuccess = (message) => {
        if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(message);
        }
    }
    const addAccount = () => {
        const data = {}
        data.name = name
        if (nickname !== null && nickname !== '') {
            data.nickname = nickname
        }
        if (bfgId !== null && bfgId !== '') {
            data.bfg_id = parseInt(bfgId)
        }
        axios({
            method: 'post',
            url: '/oauth2/accounts',
            data: data,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + props.access_token,
            },
        }).then((res) => {
            toastSuccess('Account successfully created.')
            textInputName.current.value = "";
            textInputNickname.current.value = "";
            textInputBFG.current.value = "";

            getAccounts()
        }).catch((err) => {
            handleHttpError(err)
        });

    }

    const editAccount = (account_id, current_name, current_nickname, bfg_id) => {
        setName(current_name);
        setNickname(current_nickname);
        setBfgId(bfg_id);
        setOpenEdit(true)
        setUpdatedAccount({ ...updatedAccount, 'account_id': account_id, 'name': current_name, 'nickname': current_nickname, 'bfg_id': bfg_id })
    }

    const updateAccountDetails = () => {
        const data = {}
        if (name !== '') {
            data["name"] = name;
        }

        if (nickname !== '') {
            data["nickname"] = nickname;
        }
        else {
            data["nickname"] = null;
        }
        if (bfgId === '') {
            data["bfg_id"] = null;
        }
        else {
            data["bfg_id"] = parseInt(bfgId);
        }
        axios({
            method: 'patch',
            url: '/oauth2/accounts/' + updatedAccount['account_id'],
            data: data,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + props.access_token,
            },
        }).then((res) => {
            toastSuccess('Account successfully updated.')
            textInputName.current.value = "";
            textInputNickname.current.value = "";
            textInputBFG.current.value = "";

            getAccounts()
        }).catch((err) => {
            handleHttpError(err)
        });
    }
    const getAccounts = () => {
        axios({
            method: 'get',
            url: '/oauth2/accounts',
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + props.access_token,
            },
            params: {
                "complete": true,
                "nicknames": true
            }
        }).then((res) => {
            var accounts_list = []
            if (res.data.length === 0) {
                document.getElementById('error_body').innerHTML = 'No accounts available in database.'
            }
            else {
                document.getElementById('error_body').innerHTML = ''
            }
            for (let x = 0; x < res.data.length; x++) {
                accounts_list.push(createData((res.data[x].name), (res.data[x].nickname), (res.data[x].bfg_id), <CustomButton
                    label={"Edit"}
                    color="purple"
                    action={() => editAccount(res.data[x].id, res.data[x].name, res.data[x].nickname, res.data[x].bfg_id)}
                />, <CustomButton
                    label={"Delete"}
                    color="purple"
                    action={() => deleteAccount(res.data[x].id)}
                />))
            }
            setAccountsList(accounts_list)
            setAccountsLoaded(true)

        }).catch((err) => {
            handleHttpError(err)
        });
    }

    const deleteAccount = (account_id) => {
        setAccountID(account_id)
        setOpen(true)
    }
    const handleName = (e) => {
        setName(e.target.value);
    };
    const handleNickname = (e) => {
        setNickname(e.target.value);
    };
    const handleBfgId = (e) => {
        setBfgId(e.target.value);
    };

    const confirmDelete = () => {
        axios({
            method: 'delete',
            url: '/oauth2/accounts/' + accountID,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + props.access_token,
            },
        }).then((res) => {
            toastSuccess('Account successfully deleted.')
            getAccounts()

        }).catch((err) => {
            handleHttpError(err)
        });
    }
    useEffect(() => {
        getAccounts()
    }, []);

    return (
        <Container>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Name</b></TableCell>
                            <TableCell><b>Nickname</b></TableCell>
                            <TableCell><b>BFG ID</b></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    {accountsLoaded ? <TableBody>
                        {accountsList.map((account) => (
                            <TableRow key={account.name}>
                                <TableCell >{account.Name}</TableCell>
                                <TableCell >{account.Nickname}</TableCell>
                                <TableCell >{account.BFG_ID}</TableCell>
                                <TableCell ><div>{account.Edit} <br /> {account.Delete}</div></TableCell>

                            </TableRow>
                        ))}
                    </TableBody> : null}
                </Table>
                <div id='error_body'></div>
            </TableContainer>
            <h2 className={classes.title}>Add New Account </h2>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Name</b></TableCell>
                            <TableCell><b>Nickname</b></TableCell>
                            <TableCell><b>BFG ID</b></TableCell>
                            <TableCell></TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell >
                                <TextField id="standarbasic" variant="outlined"
                                    size="small"
                                    inputRef={textInputName}
                                    onChange={handleName} />
                            </TableCell>
                            <TableCell >
                                <TextField id="standard-basic" variant="outlined"
                                    size="small"
                                    inputRef={textInputNickname}
                                    onChange={handleNickname} />
                            </TableCell>
                            <TableCell >
                                <TextField id="standard-basic" variant="outlined"
                                    size="small"
                                    inputRef={textInputBFG}
                                    onChange={handleBfgId} />
                            </TableCell>
                            <TableCell >
                                <CustomButton
                                    label={"Add New"}
                                    color="purple"
                                    action={() => addAccount()}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Confirm
                open={open}
                content='You are about to remove this account, are you sure you want to continue?'
                onCancel={() => setOpen(false)}
                onConfirm={() => { setOpen(false); confirmDelete() }}
                cancelButton='Cancel'
                confirmButton="Confirm"
            />
            <Confirm
                open={openEditMessage}
                content='You are about to update account, are you sure you want to continue?'
                onCancel={() => setOpenEditMessage(false)}
                onConfirm={() => { setOpenEditMessage(false); updateAccountDetails() }}
                cancelButton='Cancel'
                confirmButton="Confirm"
            />
            <Modal
                onClose={() => setOpenEdit(false)}
                open={openEdit}
                size='mini'

            >
                <Modal.Header>Edit Account</Modal.Header>
                <Modal.Content image>
                    <Modal.Description>
                        <TextField
                            label="Name"
                            inputRef={textInputNewName}
                            defaultValue={updatedAccount['name']}
                        />
                        <br />
                        <TextField
                            label="Nickname"
                            inputRef={textInputNewNickname}
                            defaultValue={updatedAccount['nickname']}
                        />
                        <br />
                        <TextField
                            label="BFG ID"
                            type={"number"}
                            inputRef={textInputNewBfgId}
                            defaultValue={updatedAccount['bfg_id']}
                        />
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={() => setOpenEdit(false)}>
                        Cancel
                    </Button>
                    <Button
                        content="Confirm"
                        labelPosition='right'
                        icon='checkmark'
                        onClick={() => { setOpenEdit(false); setOpenEditMessage(true); setName(textInputNewName.current.value); setNickname(textInputNewNickname.current.value); setBfgId(textInputNewBfgId.current.value) }}
                        positive
                    />
                </Modal.Actions>
            </Modal>
            <ToastContainer />
        </Container >
    );
}
