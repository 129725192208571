import React from "react";
import axios from "axios";
import { NgdsErrorType } from "../../../../../../types/NgdsErrorType";
import { convertMs } from "../../../../../../utils/convertMs";

export const deploy_calls = async (api_key: string, dataList: any) => {

  const response = {
    data: null,
    error: null
  }

  const user = JSON.parse(localStorage.getItem("user"));
  const account = JSON.parse(localStorage.getItem("currentAccount"));
  const account_id = account?.id;
  const access_token = user?.access_token;
  const url = "/api/v1/api_connectors";

  const data = dataList.map((entry: any) => {
    const { network_id, crud_method, operation_name, payload } = entry
    return ({
      network_id,
      crud_method,
      operation_name,
      payload
    })
  })



  const body = {
    user: {
      ein: user?.uin,
      fullname: user?.fullname,
      account,
    },
    account_id: +account_id,
    connector: "meraki",
    action: "deploy_call",
    params: {
      api_key,
      data
    },
  };

  console.log({ body })

  console.log(
    `Started ${body.connector}: "${body.action}" for account: ${account.name}(${account.id}) by ${user.fullname} (${user.uin})`
  );
  console.log({
    params: {
      ...body.params,
      api_key: `***${body.params.api_key?.slice(-4, body.params.api_key?.length)}`,
    },
  });
  const start = performance.now();

  await axios.post(url, body, {
    headers: { Authorization: "Bearer " + access_token },
  }).then((resp: any) => {
    console.log({ [`${body.action}_RESPONSE`]: resp.data });
    if (resp?.hasOwnProperty("error")) response['error'] = { ...resp.error };
    else if (resp.status !== 200) {
      response['error'] = {
        error: "Something whent wrong", response: null
      }
    } else {
      response['data'] = resp.data
    }
  })
    .catch((error: any) => {
      response['error'] = { error };
      console.log({ [`${body.action}_ERROR`]: error });
    })
    .finally(() => {
      const end = performance.now();
      const duration = end - start;
      console.log(
        `Finished ${body.connector}: "${body.action}" in ${convertMs(
          duration
        )}`
      );
    });

  return response
}