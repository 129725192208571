import portAssignment from "./portAssignment";
export default function wapAssignment(newWaps, correlation, site) {
  for (const device of correlation) {
    if (device.new_hostname.includes("-mcs-")) {
      const oldWaps = device.port_mapping
        .map((entry) => (!entry.name ? { ...entry, name: "" } : { ...entry }))
        .map((item, index) => item.name.includes("AP") && index)
        .filter((item) => item);
      // Returns an array with the index of oldwaps.

      const lastAvailablePort = () =>
        device.port_assignment.filter((item) => !item.isAssigned).slice(-1)[0]
          .port;

      // All ports are checked and isMapped should be true to not be considered on next iterations
      for (const index of oldWaps) {
        device.port_mapping[index]["isMapped"] = true;
      }

      const isVlan7 = site.show_interfaces_status.some(
        (entry) => entry.vlan === "7"
      );
      const wapTrunks = `${isVlan7 ? "7," : ""}30,40,50,98`;

      if (newWaps.length > 0) {
        let wapPortIndex = lastAvailablePort() - newWaps.length;

        for (const [index, wap] of newWaps.entries()) {
          const newPort = wapPortIndex + 1;
          // if there is match between old and new WAP
          if (oldWaps[index]) {
            device.port_mapping[oldWaps[index]]["toPort"] = newPort;
            device.port_mapping[oldWaps[index]]["toDevice"] =
              device.new_hostname;
            device.port_mapping[oldWaps[index]]["toMode"] = "trunk";
            device.port_mapping[oldWaps[index]]["toVlan"] = wapTrunks;
            device.port_mapping[oldWaps[index]]["toNote"] = `to WAP - ${wap}`;
            device.port_mapping[oldWaps[index]]["isMapped"] = true;
            portAssignment(device, newPort);
          }

          // else if there are more new waps than old, creates a entry in the end
          else {
            const wapEntry = {
              hostname: "",
              name: "",
              toPort: newPort,
              toMode: "trunk",
              toVlan: wapTrunks,
              toDevice: device.new_hostname,
              toNote: `to WAP - ${wap}`,
              isMapped: true,
            };

            const indexPort = device.port_mapping.findIndex(
              (entry) => entry.toPort === newPort
            );

            if (indexPort > 0) {
              // if there is a port already
              device.port_mapping[indexPort] = wapEntry;
            } else {
              const lastIndex = device.port_mapping.length - 1;
              device.port_mapping.splice(lastIndex, 0, wapEntry);
            }
          }

          wapPortIndex += 1;
        }
      }
    }
  }
}
