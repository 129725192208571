import { Alert, Typography, Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import { fetchData } from "../../actions/fetchData";
const { Title } = Typography;

const AddressTable = ({ site_id, address, setAddress }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getSiteAddress = async () => {
      setLoading(true);
      const params = { site_id: [site_id], raw: false };
      const response = await fetchData("get_addresses_by_site_id", params);
      setAddress(response.data);
      setError(response.error);
      setLoading(false);
    };
    if (!address && !error && !loading) getSiteAddress(); // fetch if data is not set
  });

  const attr = [
    "site_id",
    "site_name",
    "site_type",
    "site_street_name",
    "site_street_number",
    "site_city",
    "site_county",
    "site_country",
    "site_zipcode",
    "site_lat",
    "site_long",
  ];

  const columns = attr.map((item) => ({
    key: item,
    title: item,
    dataIndex: item,
  }));

  const errorMessage = {
    emptyText: error ? <Alert message={error} type="error" /> : null,
  };

  return (
    <Table
      bordered
      dataSource={address}
      columns={columns}
      loading={loading}
      size="small"
      title={() => <Title level={4}>Site Address</Title>}
      pagination={false}
      locale={errorMessage}
    />
  );
};

export default AddressTable;

interface AddressesType {
  site_city: string;
  site_country: string;
  site_county: string;
  site_id: string;
  site_lat: string;
  site_long: string;
  site_name: string;
  site_street_name: string;
  site_street_number: string;
  site_type: string;
  site_zipcode: string;
}
