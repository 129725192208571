import { Bar } from "@ant-design/plots";
import { BarChartOutlined } from "@material-ui/icons";
import { Space } from "antd";
import React from "react";

export default function BarChart({ data, yField, xField, title }) {
  const config = {
    data,
    xField,
    yField,
    // barWidthRatio: 0.1, // Adjust this value to control the max width of the bars
    // autoFit: true,
    // width: "100%", // Ensures the chart background is 100%
    autoFit: true,
    height: data.length < 10 ? 160 : 16 * data.length, // Adjust the height of the chart

    color: () => {
      return "#1ec8e6";
    },
    label: {
      position: "end",
      style: {
        fill: "#000",
        fontSize: 14,
        fontWeight: "bold",
      },
    },
    yAxis: {
      label: {
        autoHide: false,
        style: { fontWeight: "bold", fill: "#000" },
      },
    },
  };
  return (
    <>
      <h4 style={{ color: "#5514b4" }}>{title}</h4>
      <Bar {...config} maxBarWidth={25} />
    </>
  );
}
