import "../MerakiRolloutPage.css";
import { useEffect } from "react";
import { useSiteTypes } from "../library/fetchRequests";
import { Alert, Chip } from "@mui/material";
import ProductsList from "./ProductsList";
import Loading from "./Loading";

export default function BomBuilder({
  accountId,
  sitesTypes,
  setSitesTypes,
  rolloutSites,
  setRolloutSites,
}) {
  const [sitesTypesFromHook, isLoading, hasError, error] =
    useSiteTypes(accountId);

  useEffect(() => {
    // check if the siteTypes are not loaded thus length is zero
    sitesTypes.length === 0 && setSitesTypes(sitesTypesFromHook);
  }, [sitesTypesFromHook]);

  const handleChange = (event) => {
    const targetIndex = rolloutSites.findIndex(
      (site) => site.id == event.target.id
    );

    const siteProducts = sitesTypes
      .filter((type) => type.name === event.target.value)
      .map((item) => item.products)[0];

    const updatedSite = [];
    for (const [index, site] of rolloutSites.entries()) {
      if (index !== targetIndex) {
        // keep the unchanged data
        updatedSite.push({
          id: site.id,
          name: site.name,
          type: site.type,
          products: site.products ? site.products : [],
        });
      } else {
        // update the target site
        updatedSite.push({
          id: rolloutSites[index].id,
          name: rolloutSites[index].name,
          type: event.target.value,
          products: siteProducts,
        });
      }
    }

    setRolloutSites(updatedSite);
  };

  const Options = ({ siteType }) => {
    const optionsList = [
      <option key="focus">{siteType}</option>,
      ...sitesTypes
        .filter((item) => item.name !== siteType)
        .map((item) => <option key={item.id}>{item.name}</option>),
    ];

    if (optionsList.length > 1) {
      return optionsList;
    } else {
      return <option>Loading...</option>;
    }
  };

  const LoadedData = () => {
    return (
      <>
        <p>Select the site type then the quantity needed for each product.</p>
        {rolloutSites.map((site) => (
          <div key={site.id} className="products">
            <div className="chip">
              <Chip label={site.name} color="primary" variant="outlined" />
              <select
                className={
                  site.type.includes("Select") ? "selectedType" : "selectType"
                }
                key={site.name}
                id={site.id}
                onChange={handleChange}
              >
                <Options siteType={site.type} />
              </select>
            </div>
            {!site.type.includes("Select") && (
              <ProductsList
                siteProp={site}
                rolloutSites={rolloutSites}
                setRolloutSites={setRolloutSites}
              />
            )}
          </div>
        ))}
      </>
    );
  };

  const MainDisplayedData = () => {
    if (!isLoading) {
      return <LoadedData />;
    } else if (hasError) {
      console.log(error);
      return (
        <Alert variant="outlined" severity="error">
          Something went wrong while fetching the data...
        </Alert>
      );
    } else {
      return <Loading text="Fetching site types..." />;
    }
  };

  return (
    <div className="backBox">
      <MainDisplayedData />
    </div>
  );
}
