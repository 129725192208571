import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { handleHttpError, getAccounts, getScopes } from "../../../common/utils.js"
import axios from "axios";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

//additional libraries
import { Confirm } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function createData(name, EIN, OUC, Job, Email, Number, Scopes, Accounts, AddScope) {
    return { name, EIN, OUC, Job, Email, Number, Scopes, Accounts, AddScope };
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'EIN', numeric: true, disablePadding: false, label: 'EIN' },
    { id: 'OUC', numeric: false, disablePadding: false, label: 'OUC' },
    { id: 'Job', numeric: false, disablePadding: false, label: 'Job' },
    { id: 'Email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'Number', numeric: false, disablePadding: false, label: 'Number' },
    { id: 'Scopes', numeric: false, disablePadding: false, label: 'Scopes' },
    { id: 'Accounts', numeric: false, disablePadding: false, label: 'Accounts' },
    { id: 'AddScope', numeric: false, disablePadding: false, label: '' },
];



function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '1000',
        marginBottom: theme.spacing(2),
    },

    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        width: 160
    }
}));

export default function Users(props) {
    const classes = useStyles();
    const cookies = new Cookies();
    const history = useHistory();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page] = React.useState(0);
    const [dense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [usersLoaded, setUsersLoaded] = useState(false);
    const [usersList, setUsersList] = useState(null);
    const [open, setOpen] = useState(false);
    const [openAccount, setOpenAccount] = useState(false);
    const [openLogged, setOpenLogged] = useState(false);
    const [finalData, setFinalData] = useState("");
    const [selectedUser, setSelectedUser] = useState("");
    const [openNewScopeConfirmation, setOpenNewScopeConfirmation] = useState(false);
    const [openSelfNewScopeConfirmation, setSelfNewScopeConfirmation] = useState(false);
    const [openNewAccountConfirmation, setOpenNewAccountConfirmation] = useState(false);
    const [storeEvent, setStoreEvent] = useState(null);
    const [storeEventAccount, setStoreEventAccount] = useState(null);

    const handleChange = (uin, event) => {
        setStoreEvent(event.target.value);
        if (parseInt(uin) === parseInt(cookies.get("uin"))) {
            setSelfNewScopeConfirmation(true);
        } else {
            setOpenNewScopeConfirmation(true);
        }
    };

    const handleChangeAccount = (uin, event) => {
        setStoreEventAccount(event.target.value);
        setOpenNewAccountConfirmation(true);

    };
    const confirmNewScope = () => {
        const pickedAccount = storeEvent;
        const uin = pickedAccount.split("-")[0];
        const scope = pickedAccount.split("-")[1];
        axios({
            method: "get",
            url: "/oauth2/users/" + uin,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + props.access_token,
            },
        }).then((results) => {
            let current_scopes = results.data.scope;
            let final_scopes = [];

            for (let entry of current_scopes) {
                final_scopes.push(entry);
            }

            final_scopes.push(scope);
            let data = { scope: final_scopes };
            axios({
                method: "patch",
                url: "/oauth2/users/" + uin + "?force=true",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + props.access_token,
                },
                data: data,
            })
                .then((res) => {
                    if (parseInt(uin) === parseInt(cookies.get("uin"))) {
                        history.push("/login");
                        window.location.reload(true);
                    } else {
                        toast.success("Scope successfully added.");
                        getUserScopes();
                    }
                })
                .catch((err) => {
                    handleHttpError(err)
                });
        });
    };
    const updateUserAccount = (data, uin) => {
        axios({
            method: "patch",
            url: "/oauth2/users/" + uin + "?force=true",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + props.access_token,
            },
            data: data,
        })
            .then((res) => {
                toast.success("Account successfully added.");
                getUserScopes();

            })
            .catch((err) => {
                handleHttpError(err)
            });
    }

    const confirmNewAccount = () => {
        const selectedAccount = storeEventAccount;
        const uin = selectedAccount.split("-")[0];
        const account = selectedAccount.split("-")[1];
        axios({
            method: "get",
            url: "/oauth2/users/" + uin,
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + props.access_token,
            },
        }).then((results) => {
            let current_accounts_list = []
            results.data.accounts.map((acc) => (current_accounts_list.push(acc['name'])))
            current_accounts_list.push(account)
            const updated_accounts = { accounts: current_accounts_list }
            updateUserAccount(updated_accounts, uin)
        });
    };

    useEffect(() => {
        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?users";
        window.history.pushState({ path: newurl }, "", newurl);
        getUserScopes();
    }, []);
    const confirmDelete = (target) => {
        axios({
            method: "patch",
            url: "/oauth2/users/" + selectedUser + "?force=true",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + props.access_token,
            },
            data: finalData,
        })
            .then((res) => {
                if (parseInt(selectedUser) === parseInt(cookies.get("uin"))) {
                    if (target === 'scope') {
                        history.push("/login");
                    }
                    getUserScopes();

                }
                getUserScopes();
                toast.success('Request successful')
            })
            .catch((err) => {
                handleHttpError(err)
            });
    };

    const deleteScope = (user_id, selected_scope, current_scopes) => {
        let scopes = current_scopes;
        let final_scopes = scopes.filter((e) => e !== selected_scope); //finds selected scope in current scopes and removes from array
        setFinalData({ scope: final_scopes });
        setSelectedUser(user_id);
        if (parseInt(user_id) === parseInt(cookies.get("uin"))) {
            setOpenLogged(true);
        } else {
            setOpen(true);
        }
    };

    const deleteAccount = (user_id, selected_account, current_accounts) => {
        let account_ids = []
        let final_accounts = current_accounts.filter((e) => e !== selected_account); //finds selected account in current accounts and removes from array
        final_accounts.map((account) => (account_ids.push(account['id'])))
        setFinalData({ accounts: account_ids });
        setSelectedUser(user_id);
        setOpenAccount(true);
    };
    const getUserScopes = () => {
        const rows = []
        let all_scopes = getScopes(props.access_token)
        let all_accounts = getAccounts(props.access_token)
        axios({
            method: "get",
            url: "/oauth2/users?scopes=true&accounts=true",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + props.access_token,
            },
        })
            .then((res) => {
                for (let entry of res.data) {
                    let current_accounts_list = []
                    const username = entry.username;
                    const current_scopes = entry.scope;
                    const current_accounts = entry.accounts
                    current_accounts.map((account) => (current_accounts_list.push(account['name'])))
                    let unaccessed_scopes = all_scopes.filter((e) => !current_scopes.includes(e)); //checks which scopes user does not currently have
                    let unaccessed_accounts = all_accounts.filter((e) => !current_accounts_list.includes(e)); //checks which scopes user does not currently have
                    rows.push(
                        createData(
                            entry.first_name + " " + entry.last_name,
                            username,
                            entry.ouc,
                            entry.title,
                            entry.email,
                            entry.telephone_number,
                            <div>
                                {entry.scope.map((scope) => (
                                    <div>
                                        <button
                                            title="Click to delete scope"
                                            onClick={() => deleteScope(username, scope, current_scopes)}
                                        >
                                            {scope}
                                        </button>
                                        <br />
                                    </div>
                                ))}
                            </div>,
                            <div>
                                {current_accounts.map((account) => (
                                    <div><button
                                        title="Click to remove account"
                                        onClick={() => deleteAccount(username, account, current_accounts)}
                                    >{account['name']}</button><br /></div>

                                ))}

                            </div>, <div>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="age-native-simple">Add Scope</InputLabel>
                                <Select
                                    native
                                    onChange={(e) => handleChange(username, e)}
                                    inputProps={{
                                        name: "age",
                                        id: "age-native-simple",
                                    }}
                                >
                                    <option aria-label="None" key="None" value="" />
                                    {unaccessed_scopes.map((add_scope) => (
                                        <option value={username + "-" + add_scope} key={username + "-" + add_scope}>
                                            {add_scope}
                                        </option>
                                    ))}{" "}
                                </Select>
                            </FormControl><br />
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="new">Add Account</InputLabel>
                                <Select
                                    native
                                    onChange={(e) => handleChangeAccount(username, e)}
                                    inputProps={{
                                        name: "age",
                                        id: "new",
                                    }}
                                >
                                    <option aria-label="None" key="None" value="" />
                                    {unaccessed_accounts.map((add_account) => (
                                        <option value={username + "-" + add_account} key={username + "-" + add_account}>
                                            {add_account}
                                        </option>
                                    ))}{" "}
                                </Select>
                            </FormControl></div>
                        )
                    );
                }
                setRowsPerPage(rows.length)
                setUsersList(rows)
                setUsersLoaded(true);
            })
            .catch((err) => {
                handleHttpError(err)
            });
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = usersList.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    return (
        <div>
            <Paper>
                {usersLoaded ?
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={usersList.length}
                            />

                            <TableBody>
                                {stableSort(usersList, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow>
                                                <TableCell align="left">{row.name}</TableCell>
                                                <TableCell align="left">{row.EIN}</TableCell>
                                                <TableCell align="left">{row.OUC}</TableCell>
                                                <TableCell align="left">{row.Job}</TableCell>
                                                <TableCell align="left">{row.Email}</TableCell>
                                                <TableCell align="left">{row.Number}</TableCell>
                                                <TableCell align="left">{row.Scopes}</TableCell>
                                                <TableCell align="left">{row.Accounts}</TableCell>
                                                <TableCell align="left">{row.AddScope}</TableCell>
                                            </TableRow>
                                        );
                                    })}

                            </TableBody>
                        </Table>
                    </TableContainer> : null}
                <Confirm
                    open={open}
                    content="Are you sure you want to remove selected scope from user?"
                    onCancel={() => setOpen(false)}
                    onConfirm={() => {
                        setOpen(false);
                        confirmDelete('scope');
                    }}
                    cancelButton="Cancel"
                    confirmButton="Confirm"
                />
                <Confirm
                    open={openAccount}
                    content="Are you sure you want to remove selected account from user?"
                    onCancel={() => setOpenAccount(false)}
                    onConfirm={() => {
                        setOpenAccount(false);
                        confirmDelete('account');
                    }}
                    cancelButton="Cancel"
                    confirmButton="Confirm"
                />
                <Confirm
                    open={openLogged}
                    content="You are about to remove a scope from yourself, If you click confirm this will remove the selected scope and log you out. Do you wish to continue?"
                    onCancel={() => setOpenLogged(false)}
                    onConfirm={() => {
                        setOpenLogged(false);
                        confirmDelete('scope');
                    }}
                    cancelButton="Cancel"
                    confirmButton="Confirm"
                />
                <Confirm
                    open={openSelfNewScopeConfirmation}
                    content="You are about to add a scope to yourself, If you click confirm this will add the selected scope and log you out. Do you wish to continue?"
                    onCancel={() => setSelfNewScopeConfirmation(false)}
                    onConfirm={() => {
                        setSelfNewScopeConfirmation(false);
                        confirmNewScope();
                    }}
                    cancelButton="Cancel"
                    confirmButton="Confirm"
                />
                <Confirm
                    open={openNewScopeConfirmation}
                    content="You are about to give the selected user more privileges, are you sure?"
                    onCancel={() => setOpenNewScopeConfirmation(false)}
                    onConfirm={() => {
                        setOpenNewScopeConfirmation(false);
                        confirmNewScope();
                    }}
                    cancelButton="Cancel"
                    confirmButton="Confirm"
                />
                <Confirm
                    open={openNewAccountConfirmation}
                    content="You are about to give the selected user access to a new account, are you sure?"
                    onCancel={() => setOpenNewAccountConfirmation(false)}
                    onConfirm={() => {
                        setOpenNewAccountConfirmation(false);
                        confirmNewAccount();
                    }}
                    cancelButton="Cancel"
                    confirmButton="Confirm"
                />
                <ToastContainer />
            </Paper>
        </div>
    );
}
