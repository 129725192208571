import {
  Button,
  Modal,
  message,
  Upload,
  Tooltip,
  Select,
  Checkbox,
  Space,
  Typography,
  Collapse,
  Steps,
  Tag,
  Divider,
} from "antd";
import React, { useState } from "react";
import {
  CheckOutlined,
  InboxOutlined,
  LoadingOutlined,
  StarOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import type { UploadProps } from "antd";
import axios from "axios";
import { NgdsErrorType } from "../../../../../../types/NgdsErrorType";

const { Text, Paragraph } = Typography;

// Gets from local Storage
const user = JSON.parse(localStorage.getItem("user"));
const account = JSON.parse(localStorage.getItem("currentAccount"));
const account_id = account?.id;
const access_token = user?.access_token;

const UploadTemplate = ({ libraryId, setToggleRefresh, labels }) => {
  const [labelId, setLabelId] = useState(0);

  const [conflict, setConflit] = useState([]);

  const { Dragger } = Upload;

  const props: UploadProps = {
    style: { width: "100%", marginTop: "1rem", marginBottom: "1rem" },
    iconRender: (file) => {
      if (file.status === "uploading") return <LoadingOutlined />;
      else if (file.status === "done")
        return <CheckOutlined style={{ color: "green" }} />;
      else return <WarningOutlined />;
    },
    name: "file",
    multiple: true,
    action: `/api/v1/libraries/${libraryId}/templates`,
    customRequest: (options: any) => {
      const data = new FormData();
      data.append("template", options.file);
      data.append("account_id", account_id);
      data.append("label_id", `${labelId}`);
      const config = {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          options.onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };
      axios
        .post(options.action, data, config)
        .then((res: any) => {
          options.onSuccess(res.data, options.file);
        })
        .catch((err: NgdsErrorType) => {
          const statusText = err.response?.data?.error;
          options.onError((statusText && { statusText }) || err);

          if (statusText === "A template with this name already exists") {
            setConflit((prev: any) => [...prev, options.file]);
          }

          console.log({ err });
        });
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }

      const undone = info.fileList.map((file) => file.status !== "done");
      console.log({ undone });
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const [current, setCurrent] = useState(0);

  const labelOptions = labels.filter((label: any) => label.label !== "All");

  console.log({ current });

  // const ConflictList = () => {
  //   return (
  //     <>
  //       <Divider />
  //       <Paragraph>
  //         These template uploads failed because the file name already exists in
  //         the database. You can overwrite this files as long as
  //       </Paragraph>
  //       {conflict.map((file: any) => (
  //         <Paragraph>{file.name}</Paragraph>
  //       ))}
  //     </>
  //   );
  // };

  return (
    <Steps
      direction="vertical"
      current={current}
      size="small"
      items={[
        {
          title: "Select Label",
          description: (
            <Select
              placeholder="Set Label"
              style={{ width: "100%", padding: "1rem 0 1rem 0" }}
              onChange={(value: number) => {
                setLabelId(value);
                setCurrent(1);
              }}
              options={labelOptions}
            />
          ),
        },
        {
          title: "Select Templates",
          description: (
            <>
              <Dragger {...props} disabled={current !== 1}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload.
                </p>
              </Dragger>
            </>
          ),
        },
      ]}
    />
  );
};

export default UploadTemplate;
