import React, { useEffect } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Tabs,
  Tag,
  Typography,
  message,
} from "antd";

// Components
import ContentWrapper from "../../../../../components/ContentWrapper";

// Contexts
import type { TabsProps } from "antd";
// import Networks from './sections/Networks';
import {
  ApiOutlined,
  ArrowsAltOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
// import Devices from './sections/Devices';
import { usePaloAltoConnectors } from "../../../../../contexts/Connectors/PaloAltoContext";
import { ColumnsType } from "antd/es/table";
import BaseTable from "../../../../../components/Tables/BaseTable";
import { dynamicSort } from "../../../../../utils/sorter";
import ShowRecordDetails from "../../../../../components/Tables/ShowRecordDetails";
// import CreateSite from "./CreateSite";

const { Title, Text } = Typography;

const Wan = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const paloAlto = usePaloAltoConnectors();
  const tenantData = paloAlto?.tenantData;

  const wanInterfaceLabels = tenantData?.get_waninterfacelabels;
  const wanNetworks = tenantData.get_wannetworks;

  const loadingTenantData = paloAlto.loadingTenantData;
  const errorTenantData = paloAlto.errorTenantData;

  const columnsLabels: ColumnsType<any> = [
    {
      title: "Label ID",
      dataIndex: "id",
      // width: "20",
    },
    {
      title: "Name",
      dataIndex: "name",
      // width: "20%",
    },
    {
      title: "Label",
      dataIndex: "label",
      // width: "10%",
    },
    {
      title: "Description",
      dataIndex: "description",
      // width: "35%",
    },
  ];

  const columnsNetworks: ColumnsType<any> = [
    {
      title: "Network ID",
      dataIndex: "id",
      // width: '16%'
    },
    {
      title: "Name",
      dataIndex: "name",
      // width: "18%",
    },
    {
      title: "Type",
      dataIndex: "type",
      // width: "18%",
    },
    {
      title: "Description",
      dataIndex: "description",
      // width: "18%",
    },
  ];

  const actionMenu = [
    {
      key: "listDetails",
      component: ShowRecordDetails,
      icon: <ArrowsAltOutlined />,
    },
  ];

  const items = [
    {
      label: "Circuit Categories (Labels)",
      key: "WanLabels",
      children: (
        <BaseTable
          loading={loadingTenantData && !errorTenantData}
          tableName={"Labels"}
          dataSource={wanInterfaceLabels?.sort(dynamicSort("name"))}
          columns={columnsLabels}
          rowActions={actionMenu}
        />
      ),
    },
    {
      label: "Wan Networks",
      key: "WanNetworks",
      children: (
        <BaseTable
          loading={loadingTenantData && !errorTenantData}
          tableName={"Networks"}
          dataSource={wanNetworks?.sort(dynamicSort("name"))}
          columns={columnsNetworks}
          rowActions={actionMenu}
          // topButtons={[<CreateSite />]}
        />
      ),
    },
  ];

  return (
    <Space
      direction="vertical"
      size="large"
      style={{ width: "100%", marginTop: 16 }}
    >
      {contextHolder}
      <Tabs items={items} size="large" />
    </Space>
  );
};

export default Wan;
