import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { useConnectors } from "../../../../../contexts/Connectors/ZscalerContext";

// Forms
import { ZscalerLocationType } from "../../../../../types/ConnectorsType";
import { ColumnsType } from "antd/es/table";
import {
  DeleteOutlined,
  ArrowsAltOutlined,
  EditOutlined,
} from "@ant-design/icons";
import ModifyLocation from "./ModifyLocation";
import CreateSubLocation from "./CreateSubLocation";
import BaseTable from "../../../../../components/Tables/BaseTable";
import DeleteSubLocation from "./DeleteSubLocation";
import ShowRecordDetails from "../../../../../components/Tables/ShowRecordDetails";

import { listSublocationByParentId } from "../../functions/fetchZscaler";
import { NgdsErrorType } from "../../../../../types/NgdsErrorType";

type SubProps = { record: ZscalerLocationType };

const ListSubLocation: React.FC<SubProps> = ({ record }) => {
  //   const connectors = useConnectors();
  // const fetchSubLocationsById = connectors?.fetchSubLocationsById
  // const subLocationsLoading = connectors?.subLocationsLoading
  // const subLocationsError = connectors?.subLocationsError
  // const subLocations = connectors?.subLocations

  const [open, setOpen] = useState(false);
  const [list, setList] = useState<ZscalerLocationType[] | undefined>(
    undefined
  );
  const [error, setError] = useState<NgdsErrorType[] | undefined>(undefined);

  console.log({ open });

  const handleList = async () => {
    setOpen(true);
    const subList = await listSublocationByParentId({ parent_id: record.id });
    console.log({ subList });
    if (subList.status === 200) setList(subList.data);
    else setError(subList.error);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Sub-Location ID",
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: "Sub-Location Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Upload BW (Kbps)",
      dataIndex: "upBandwidth",
      key: "upBandwidth",
      align: "center",
    },
    {
      title: "Download BW (Kbps)",
      dataIndex: "dnBandwidth",
      key: "dnBandwidth",
      align: "center",
    },
    {
      title: "IP Addresses",
      dataIndex: "ipAddresses",
      key: "ipAddresses",
      width: "14%",
      align: "center",
    },
  ];

  const actionMenu = [
    {
      key: "listDetails",
      component: ShowRecordDetails,
      icon: <ArrowsAltOutlined />,
    },
    {
      key: "modify",
      component: ModifyLocation,
      icon: <EditOutlined />,
    },
    {
      type: "divider",
    },
    {
      key: "delete-sub",
      component: DeleteSubLocation,
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  return (
    <>
      <a onClick={() => handleList()}>{`List Sub-Locations`}</a>
      <Modal
        maskClosable={!list}
        closable={!list}
        title={`Sub-Locations for ${record.name}`}
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
        width={"80%"}
      >
        <BaseTable
          tableName={"subLocationsTable"}
          columns={columns}
          dataSource={list?.filter((sub) => sub.name !== "other")}
          topButtons={[<CreateSubLocation record={record} isButton={true} />]}
          // topFiltersActions={topFiltersActions}
          loading={list === undefined && !error}
          reloadAction={() => listSublocationByParentId(record.id)}
          // specialRenders={specialRenders}
          rowActions={actionMenu}
        />
      </Modal>
    </>
  );
};

export default ListSubLocation;

// type ActionMenuProps = {
//     record: ZscalerLocationType
//     setDataSource: React.Dispatch<ZscalerLocationType[]>
//     FetchListSubLocation: any
//     dataSource: ZscalerLocationType[]
// }

// const ActionMenu: FC<ActionMenuProps> = ({ record, FetchListSubLocation, setDataSource, dataSource }) => {

//     const connectors = useConnectors()
//     const session = useSession()
//     const account_id = session?.currentAccount?.id
//     const auth = useUser()
//     const access_token = auth?.user?.access_token

//     const [open, setOpen] = useState(false);
//     const [progressDelete, setProgressDelete] = useState(false)

//     // Delete Location States
//     const [deleteSubLocationLoading, setDeleteSubLocationLoading] = useState<boolean>(false)
//     const [deleteSubLocationError, setDeleteSubLocationError] = useState<any | undefined>(undefined)

//     const [deletedOK, setDeletedOk] = useState<boolean>(false)

//     const deleteSubLocation = async (record: ZscalerLocationType) => {

//         setProgressDelete(true)
//         setDeleteSubLocationLoading(true)

//         const url = '/api/v1/api_connectors'

//         let locationdeleteStatus = 0

//         await axios
//             .post(url, {
//                 account_id: +account_id,
//                 connector: "zscaler",
//                 action: "delete_location",
//                 params: { location_id: +record.id }
//             }, { headers: { Authorization: "Bearer " + access_token } })
//             .then((resp: any) => {
//                 console.log({ delete_location_resp: resp.status })
//                 locationdeleteStatus = resp.status
//                 if (resp.status === 200) {
//                     setDeletedOk(true)
//                     FetchListSubLocation(record.parentId)
//                 }
//                 setDeleteSubLocationLoading(false)
//             })
//             .catch((error: any) => {
//                 console.log({ delete_sub_location_error: error })
//                 setDeleteSubLocationError(true)
//             })
//             .finally(() => {
//                 setDeleteSubLocationLoading(false)
//             })
//     }

//     const handleCancel = () => {
//         console.log("Clicked Cancel")
//         setOpen(false)
//     };

//     const actionItems: MenuProps['items'] = [{
//         key: '1',
//         type: 'group',
//         label: <Text style={{ color: '#5514B4' }}>{record.name}</Text>,
//         children: [
//             {
//                 key: 'modify',
//                 label: <ModifyLocation record={record} />,
//                 icon: <EditOutlined />,
//             },
//             {
//                 type: 'divider'
//             },
//             {
//                 key: 'delete',
//                 label: <a type='danger' onClick={() => setOpen(true)}>Delete</a>,
//                 danger: true,
//                 icon: <DeleteOutlined />
//             }]
//     }]

//     const progressParams = {
//         descriptors: [{
//             label: "Sub-Location ID",
//             value: record.id
//         },
//         {
//             label: "Sub-Location Name",
//             value: record.name
//         }],
//         progressors: [
//             {
//                 text: "Deleting Sub-Location",
//                 loading: deleteSubLocationLoading,
//                 exception: deleteSubLocationError,
//                 dependOn: progressDelete
//             }
//         ]
//     }

//     return (
//         <>
//             <Dropdown
//                 menu={{ items: actionItems }}
//                 trigger={['click']}
//             >
//                 <Space>
//                     <UnorderedListOutlined style={{ cursor: "pointer" }} />
//                 </Space>
//             </Dropdown >

//             <Modal
//                 title="Are sure you want to delete it?"
//                 open={open}
//                 onOk={() => deleteSubLocation(record)}
//                 okText={"Delete"}
//                 okButtonProps={{ style: { backgroundColor: !progressDelete ? 'red' : undefined }, disabled: progressDelete }}
//                 cancelButtonProps={{ disabled: progressDelete }}
//                 confirmLoading={deleteSubLocationLoading}
//                 onCancel={handleCancel}
//                 closable={!deleteSubLocationLoading}
//                 afterClose={() => FetchListSubLocation(record.id)}
//             >
//                 <Space direction='vertical' size='large' wrap style={{ width: "100%" }}>
//                     {loadingProgress(progressParams)}
//                     {deletedOK && <Text>Task Completed Successfully!</Text>}
//                     {!deletedOK && <ResultError errorPayload={deleteSubLocationError} />}
//                 </Space >
//             </Modal >
//         </>

//     )
// }
