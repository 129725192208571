import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { ChildrenProps } from '../../../types/ChildrenReactType'
import axios from 'axios';
import { NgdsErrorType } from '../../../types/NgdsErrorType';
import { saveAs } from "file-saver";

function convertMs(millis: number) {
    const minutes = +Math.floor(millis / 60000);
    const seconds = +((millis % 60000) / 1000).toFixed(0);
    return (
        seconds === 60 ?
            (minutes + 1) + ":00" :
            minutes + ":" + (seconds < 10 ? "0" : "") + seconds
    );
}

// const sleep = (ms: number) => new Promise(
//     resolve => setTimeout(resolve, ms));

export type GitLabConnectorsContextType = {
    files: {} | undefined
    loadingFiles: boolean
    errorFiles: NgdsErrorType | undefined
    durationFiles: number | undefined
    list_files: (repo: string) => Promise<boolean>
    downloadStatus: boolean
    loadingDownload: boolean
    errorDownload: NgdsErrorType | undefined
    durationDownloads: number | undefined    
    get_files: (repo: string, path: string) => Promise<boolean>

}

export const GitLabConnectorsContext = createContext<GitLabConnectorsContextType | null>(null)

export const GitLabConnectorsContextProvider: React.FC<ChildrenProps> = ({ children }) => {


    // Gets from local Storage
    const user = JSON.parse(localStorage.getItem('user'))
    const account = JSON.parse(localStorage.getItem('currentAccount'))
    const account_id = account?.id
    const access_token = user?.access_token

    const commonGitLabFetch = useCallback(async (
        action: string,
        params: any,  // {}
        dataStateCallback: React.Dispatch<React.SetStateAction<any>>,
        loadingCallback: React.Dispatch<React.SetStateAction<boolean | undefined>>,
        errorCallback: React.Dispatch<React.SetStateAction<NgdsErrorType | undefined>>,
        durationCallback: React.Dispatch<React.SetStateAction<number | undefined>> = undefined,
    ) => {


        // reset states
        dataStateCallback(undefined)
        loadingCallback(false)
        errorCallback(undefined)
        durationCallback && durationCallback(undefined)

        // if the api_key is sent as param. E.g. First Call like get_org_list

        loadingCallback(true)
        const url = '/api/v1/api_connectors'
        const body = {
            user: {
                ein: user?.uin,
                fullname: user?.fullname,
                account
            },
            account_id: +account_id,
            connector: "gitlab",
            params,
            action
        }
        console.log(`Started ${body.connector}: "${body.action}" for account: ${account.name}(${account.id}) by ${user.fullname} (${user.uin})`)
       
        const start = performance.now();

        let isTrue = false
        await axios
            .post(url, body, { headers: { Authorization: "Bearer " + access_token } })
            .then((resp: any) => {
                console.log({ [`${action}_RESPONSE`]: resp.data })
                if (resp?.hasOwnProperty("error")) errorCallback({ ...resp.error })
                else if (resp.status !== 200) {
                    errorCallback({ error: "Something whent wrong", response: null })
                    isTrue = false
                }
                else {
                    dataStateCallback(resp.data)
                    isTrue = true
                }
            })
            .catch((error: any) => {
                errorCallback(error); console.log({ [`${action}_ERROR`]: error })
                isTrue = false
            })
            .finally(() => {
                loadingCallback(false)
                const end = performance.now();
                const duration = end - start
                durationCallback && durationCallback(duration)
                console.log(`Finished ${body.connector}: "${body.action}" in ${convertMs(duration)}`)
            })
        return isTrue

    }, [])

    // Organizations States ALL
    const [files, setFiles] = useState<{} | undefined>(undefined)
    const [loadingFiles, setLoadingFiles] = useState<boolean>(false)
    const [errorFiles, setErrorFiles] = useState<NgdsErrorType | undefined>(undefined)
    const [durationFiles, setDurationFiles] = useState<number | undefined>(undefined)

    const list_files = async (repo: string) => {
        return await commonGitLabFetch(
            "list_files",
            {
                repo
            },
            setFiles,
            setLoadingFiles,
            setErrorFiles,
            setDurationFiles
        )
    }


    // Download File
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false)
    const [errorDownload, setErrorDownload] = useState<NgdsErrorType | undefined>(undefined)
    const [durationDownload, setDurationDownload] = useState<number | undefined>(undefined)    

    const get_file = async (repo: string, path: string) => {

        const action = "get_file"
        const url = '/api/v1/api_connectors'
        const body = {
            user: {
                ein: user?.uin,
                fullname: user?.fullname,
                account
            },
            account_id: +account_id,
            connector: "gitlab",
            params: {
                repo,
                path
            },
            action
        }

        setLoadingDownload(true)
        return await axios
            .post(url, body, { headers: { Authorization: "Bearer " + access_token }, responseType: "blob" })
            .then((resp: any) => {
                console.log({ [`${action}_RESPONSE`]: resp.data })
                if (resp?.hasOwnProperty("error")) setErrorDownload({ ...resp.error })
                else if (resp.status !== 200) {
                    setErrorDownload({ error: "Something whent wrong", response: null })
                }
                else {
                    const disposition = resp.headers["content-disposition"];
                    console.log({disposition})
                    const fileName = disposition.match('filename="{0,1}(.*)')[1].replace('"', '');
                    console.log(fileName)
                    saveAs(resp.data, fileName);
                }
            })
            .catch((error: any) => {
                setErrorDownload(error); console.log({ [`${action}_ERROR`]: error })
            })
            .finally(() => setLoadingDownload(false))
    }    

    return (
        <GitLabConnectorsContext.Provider value={{
            //-----------------------------------------------------------            
            files,
            loadingFiles,
            errorFiles,
            durationFiles,
            list_files,
            loadingDownload,
            errorDownload,
            durationDownload,
            get_file,
        }}>
            {children}
        </GitLabConnectorsContext.Provider>
    )
}

export const useGitLabConnectors = () => useContext(GitLabConnectorsContext)

export default GitLabConnectorsContext
