// React dependencies
import React, { useEffect, useRef } from "react";
import axios from "axios";
import CustomButton from "../../components/Button/CustomButton.js";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";

// Material UI components
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SpinnerLoader from "../../components/Loader/SpinnerLoader";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

// Custom components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

//Toaster
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getFileName, saveBlob, handleHttpError } from "../../common/utils.js";

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "100px",
    paddingBottom: "100px",
    flexGrow: 1,
    marginBottom: "80px",
    textAlign: "center",
  },
  subroot: {
    paddingTop: "30px",
    marginBottom: "40px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "30%",
  },
  mystyle: {
    color: "rgb(85, 20, 180)",
    paddingBottom: "20px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputfile: {
    paddingLeft: "right",
    backgroundColor: "rgb(85, 20, 180)",
    color: "white",
    borderRadius: "3px",
  },
  usernameField: {
    marginRight: "20px",
  },
}));

export default function DataMappingPageLegacy(props) {
  const cookies = new Cookies();
  const classes = useStyles();
  const selectedAccount = useSelector((state) => state.account.value);
  const [dataReady, setDataReady] = React.useState(false);
  const [mapper, setMapper] = React.useState("");
  const [mapperID, setMapperID] = React.useState(null);
  const [selected, setSelected] = React.useState(false);
  const [dataFilePicked, setDataFilePicked] = React.useState(false);
  const [isWaitingResponse, setWaitingResponse] = React.useState(false);
  const [allMappers, setAllMappers] = React.useState(null);
  const [location, setLocation] = React.useState("none");
  const [checked, setChecked] = React.useState(false);
  const [checkedCSV, setCheckedCSV] = React.useState(false);
  const [checkedSpecifiedKey, setCheckedSpecifiedKey] = React.useState(false);
  const [checkedCSVPreserve, setCheckedCSVPreserve] = React.useState(false);
  const [URL, setURL] = React.useState(null);
  const [disable, setDisable] = React.useState(false);
  const account_id = cookies.get("account_id");
  const accountName = cookies.get("account_name");
  const inputPassword = useRef();
  const inputUsername = useRef();
  const specifiedKeyName = useRef();

  const handleChange = (event) => {
    setLocation("none");
    setChecked(false);
    if (event.target.value !== "None") {
      setMapper(event.target.value);
      setMapperID(event.target.value);
      setSelected(true);
    } else {
      setMapper(event.target.value);
      setSelected(false);
      setDataFilePicked(false);
    }
  };
  const startMapping = () => {
    setDisable(true);
    setWaitingResponse(true);
    let formData = new FormData();
    let url = "";

    if (location === "local") {
      let file = document.getElementById("data_file").files[0];
      formData.append("input", file);
    }
    if (location === "remote") {
      // if(){authentication needs to go here}
      formData.append("input", URL);
    }
    if (checkedCSV) {
      let csv_file = document.getElementById("csv_file").files[0];
      if (csv_file === undefined) {
        toast.error("CSV File missing");
        setDisable(false);
        setWaitingResponse(false);
        return;
      } else {
        formData.append("output", csv_file);
      }
    }
    if (checked) {
      if (
        inputPassword.current.value === "" ||
        inputUsername.current.value === ""
      ) {
        toast.error("Username and/or Password missing!");
      } else {
        formData.append("username", inputUsername.current.value);
        formData.append("password", inputPassword.current.value);
      }
    }
    if (account_id === "null" || account_id === "None") {
      url = "/api/v1/conversion/mappers/" + mapperID + "?";
    } else {
      url = `/api/v1/conversion/mappers/${mapperID}?account_id=${account_id}`;
    }
    if (checkedCSVPreserve) {
      url = url + "&preserve=true";
    }
    axios({
      method: "post",
      url: checkedSpecifiedKey
        ? url + `&only=${specifiedKeyName.current.value}`
        : url,
      headers: {
        Authorization: "Bearer " + props.access_token,
        "Content-Type": "multipart/form-data",
        Accept: checkedCSV
          ? ""
          : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      data: formData,
      responseType: "blob",
    })
      .then((response) => {
        // Generating filename
        let fileName = getFileName(response.headers);
        saveBlob(response.data, fileName);
        setWaitingResponse(false);
        setDisable(false);
      })
      .catch(function (error) {
        setWaitingResponse(false);
        handleHttpError(error);
        setDisable(false);
      });
  };

  const dataFileSelected = () => {
    setDataFilePicked(true);
  };

  const handleDataLocation = (event) => {
    setDataFilePicked(false);
    setChecked(false);
    setCheckedCSVPreserve(false);
    setCheckedSpecifiedKey(false);
    setLocation(event.target.value);
  };
  const handleAuthentication = (event) => {
    setChecked(event.target.checked);
  };
  const handleURL = (event) => {
    setDataFilePicked(true);
    let url = event.target.value;
    const regex = /office\d?.bt.com/;
    setURL(url);
    if (url.match(regex)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  };
  const handleCSV = (event) => {
    let stat = event.target.checked;
    setCheckedCSV(event.target.checked);
    if (!stat) {
      setCheckedCSVPreserve(false);
      setCheckedSpecifiedKey(false);
    }
  };
  const handleSpecifiedKey = (event) => {
    setCheckedSpecifiedKey(event.target.checked);
  };

  const handleCSVPreserve = (event) => {
    let status = event.target.checked;
    setCheckedCSVPreserve(status);
    if (!status) {
      setCheckedSpecifiedKey(false);
    }
  };

  useEffect(() => {
    console.log(accountName);
    axios({
      method: "get",
      url: "/api/v1/conversion/mappers",
      headers: {
        Authorization: "Bearer " + props.access_token,
        Accept: "application/json",
      },
    })
      .then((response) => {
        setAllMappers(response.data);
        setDataReady(true);
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let mapper_id = params.get("mapperId");
        if (mapper_id) {
          setMapper(mapper_id);
          setMapperID(mapper_id);
          setSelected(true);
        }
      })
      .catch(function (error) {
        handleHttpError(error);
      });
  }, [selectedAccount]);
  if (dataReady) {
    return (
      <Container>
        <Header />
        {/* <Grid container className={classes.root} justify="center" spacing={3}> */}
        <Grid container justify="center" spacing={3}>
          {/* <Grid item xs={12}>
            <div className="title-container">
              <h1 className={classes.mystyle}>Data Mapping</h1>
            </div>
          </Grid> */}
          <Grid
            container
            // className={classes.subroot}
            justify="center"
            spacing={3}
          >
            <Grid item xs={12}>
              <small>
                <strong>Note: </strong>
              </small>
              {accountName !== null &&
              accountName !== "None" &&
              accountName !== undefined ? (
                <small>You are about to map data for {accountName}</small>
              ) : (
                <small className="redpulse">
                  You have not selected an account{<br />}
                  Attributes required for a specific account will not be
                  included
                </small>
              )}
              {/* <div className="title-container">
                <h3>Select a mapper:</h3>
              </div> */}
            </Grid>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Select Mapper
              </InputLabel>
              <Select
                value={mapper}
                onChange={handleChange}
                label="Select Mapper"
              >
                <MenuItem disabled key={0} value={"None"}>
                  <em>Select Mapper</em>
                </MenuItem>
                {allMappers.map((map) => (
                  <MenuItem key={map.id} value={map.id}>
                    {map.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {selected ? (
            <Grid item xs={12}>
              <FormControl
                sx={{ marginTop: "20rem" }}
                onChange={handleDataLocation}
                component="fieldset"
              >
                <FormLabel component="legend">
                  Where is your data located?
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="data_location"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="local"
                    control={<Radio />}
                    label="Local"
                  />
                  <FormControlLabel
                    value="remote"
                    control={<Radio />}
                    label="Remote"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          ) : null}
          {location === "local" ? (
            <Grid item xs={12}>
              <h3>Upload Data Capture File:</h3>
              <input
                className={classes.inputfile}
                accept=".xlsx"
                type="file"
                id="data_file"
                name="data_file"
                onChange={() => dataFileSelected()}
              />{" "}
              <br />
              <br />
              <p>Note: Data file can be 2D or 3D.</p>
            </Grid>
          ) : null}
          {location === "remote" ? (
            <div>
              <Grid>
                <TextField
                  id="standard-number"
                  label="Data Capture File URL"
                  placeholder="Enter url here"
                  onChange={handleURL}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: 350 }}
                  variant="standard"
                />{" "}
              </Grid>{" "}
              <br />
              <Grid spacing={0} direction="column" alignItems="center">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checked}
                        onChange={handleAuthentication}
                      />
                    }
                    label="Authentication required"
                  />
                </FormGroup>
              </Grid>
            </div>
          ) : null}
          {checked ? (
            <Grid item xs={12}>
              {" "}
              <TextField
                label="Username"
                margin="normal"
                inputRef={inputUsername}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ paddingRight: 15, width: 300 }}
                variant="standard"
              />
              <TextField
                label="Password"
                margin="normal"
                type="password"
                inputRef={inputPassword}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: 300 }}
                variant="standard"
              />{" "}
            </Grid>
          ) : null}
          <Grid
            item
            xs={12}
            container
            spacing={0}
            direction="column"
            alignItems="center"
          >
            {location !== "none" ? (
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={checkedCSV} onChange={handleCSV} />}
                  label="Populate existing CSV"
                />
              </FormGroup>
            ) : null}
          </Grid>
          {checkedCSV && location !== "none" ? (
            <Grid
              item
              xs={12}
              container
              spacing={0}
              direction="column"
              alignItems="center"
            >
              <Grid item xs={12}>
                <h3>CSV Upload:</h3>
                <input
                  className={classes.inputfile}
                  accept=".csv"
                  type="file"
                  id="csv_file"
                  name="csv_file"
                />{" "}
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedCSVPreserve}
                        onChange={handleCSVPreserve}
                      />
                    }
                    label="Preserve existing CSV Data"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          ) : null}
          <Grid
            item
            xs={12}
            container
            spacing={0}
            direction="column"
            alignItems="center"
          >
            {checkedCSV && location !== "none" ? (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={checkedSpecifiedKey}
                      onChange={handleSpecifiedKey}
                    />
                  }
                  label="Map only specified key data"
                />
              </FormGroup>
            ) : null}
          </Grid>
          {checkedSpecifiedKey && location !== "none" && checkedCSV ? (
            <Grid
              item
              xs={12}
              container
              spacing={0}
              direction="column"
              alignItems="center"
            >
              <Grid item xs={12}>
                <TextField
                  label="Key Name"
                  placeholder="E.g. csv-deviceId"
                  inputRef={specifiedKeyName}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: 350 }}
                  variant="standard"
                />
              </Grid>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            {dataFilePicked ? (
              !disable ? (
                <CustomButton
                  label={"Map Data"}
                  color="purple"
                  action={() => {
                    startMapping();
                  }}
                />
              ) : (
                <Button variant="contained" disabled>
                  MAP DATA
                </Button>
              )
            ) : null}
          </Grid>
          {isWaitingResponse ? (
            <div className={classes.spinner}>
              <SpinnerLoader />
            </div>
          ) : (
            <></>
          )}
          <ToastContainer />
        </Grid>
        <Footer />
      </Container>
    );
  } else {
    return (
      <Container>
        <Header />
        <Grid container className={classes.root} justify="center" spacing={3}>
          <Grid
            container
            className={classes.subroot}
            justify="center"
            spacing={3}
          >
            <Grid item xs={12}>
              <p>Loading...</p>
            </Grid>
          </Grid>
          <ToastContainer />
        </Grid>
        <Footer />
      </Container>
    );
  }
}
