import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { removeDuplicates, selectStyle } from "../utils.js";
import { toast } from "react-toastify";
import axios from "axios";
import { handleHttpError } from "../../../../common/utils.js";
import { TemplateManagerButtons } from "./Manager/LibraryManager";
import RefreshIcon from "@mui/icons-material/Refresh";

// MUI
import {
  IconButton,
  Tooltip,
  Button,
  FormControl,
  Stack,
  Checkbox,
  Autocomplete,
  TextField,
  Box,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.name,
});

export default function SelectRemoteTemplate({
  templates,
  setTemplates,
  setTemplatesOptions,
  templatesOptions,
  libraryId,
  templateId,
  setTemplateId,
  isAccountOnly,
  labelId,
  isLabelFilter,
  isLoading,
  setIsLoading,
  setAccountId,
  accountId,
  accountName,
  setIsAccountOnly,
  showManager,
  refresh,
  setRefresh,
}) {
  // Check Selected Account
  const cookies = new Cookies();
  const access_token = cookies.get("access_token");
  const account_name = cookies.get("account_name");
  const account_id = cookies.get("account_id");
  const account = useSelector((state) => state.account.value);

  const getAccountName = () => {
    if (account.id) return account.name;
    return account_name;
  };

  const getAccountId = () => {
    if (account.name != "None") return account.id;
    return account_id;
  };
  const accountNameFound = getAccountName();
  const accountIdFound = getAccountId();
  setAccountId(accountIdFound);

  console.log({
    templateId,
    templatesOptions,
    templates,
    accountNameFound,
    accountIdFound,
    isAccountOnly,
    accountId,
    accountName,
    refresh,
  });

  const [templateName, setTemplateName] = useState({ name: "None", value: 0 });

  useEffect(() => {
    const getTemplatesByLibraryId = (id, isAccountOnly, isLabelFilter) => {
      // set endpoint
      let endpoint = `/api/v1/templates?library_id=${id}`;
      if (isAccountOnly) endpoint = `${endpoint}&account_id=${accountId}`;
      if (isLabelFilter) endpoint = `${endpoint}&label_id=${labelId}`;
      axios
        .get(endpoint, {
          headers: { Authorization: "Bearer " + access_token },
        })
        .then((response) => {
          const templates = response.data;

          const options = templates.map((template) => ({
            value: template.id,
            name: template.name,
            label: template.label,
          }));
          setTemplatesOptions([
            {
              value: 0,
              name: "None",
              label: 0,
            },
            ...options,
          ]);
          setTemplateName({ name: "None", value: 0 });
        })
        .catch((error) => {
          handleHttpError(error);
        });
    };

    if (accountNameFound !== "None" || accountName !== "null") {
      setIsLoading(true);
      getTemplatesByLibraryId(libraryId, isAccountOnly, isLabelFilter);
      setIsLoading(false);
    }
  }, [libraryId, isAccountOnly, accountId, isLabelFilter, refresh, labelId]);

  console.log({ labelId });

  useEffect(() => {
    setTemplateId(0);
    setTemplatesOptions([]);
  }, [libraryId, labelId, accountId, refresh]);

  const handleRemoteTemplate = (event, newSelection) => {
    if (newSelection) {
      setTemplateId(newSelection.value);
      setTemplateName(newSelection);
    }
  };

  const addRemoteTemplate = () => {
    if (templateId > 0) {
      const value = templateId;
      const indexOfvalue = templatesOptions.findIndex((o) => o.value === value);
      const name = templatesOptions[indexOfvalue].name;
      const updateTemplates = [...templates, { value, name, source: "remote" }];
      setTemplates(removeDuplicates(updateTemplates));
    } else {
      toast.warning("You need to select a template from the dropdown list");
    }
  };

  showManager && setIsAccountOnly(true);

  console.log({ templatesOptions });

  return (
    <Stack>
      {isLoading ? (
        <small
          style={{
            color: "grey",
            fontWeight: "lighter",
            textAlign: "center",
            marginTop: "2rem",
            marginLeft: "2rem",
          }}
        >
          Loading Templates...
        </small>
      ) : (
        <>
          <FormControl
            sx={selectStyle}
            style={{ minWidth: "100%", marginTop: "2rem" }}
          >
            <Autocomplete
              // filterOptions={filterOptions}
              disableClearable
              value={templateName}
              onChange={handleRemoteTemplate}
              size="small"
              disablePortal
              id="combo-box-demo"
              getOptionLabel={(option) => option.name}
              options={templatesOptions}
              sx={{ backgroundColor: "white" }}
              renderInput={(params) => (
                <Stack direction="vertical">
                  <TextField {...params} label="Select Template" />
                  <Tooltip title="Refresh" placement="right">
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => setRefresh((prev) => !prev)}
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              )}
            />
          </FormControl>
          {!showManager &&
            (accountNameFound !== "None" ? (
              <div>
                <Checkbox
                  checked={showManager ? true : isAccountOnly}
                  onChange={() => setIsAccountOnly(!isAccountOnly)}
                  size="small"
                  color={showManager ? "light" : "primary"}
                  disabled={showManager}
                />
                <small>{"Show " + accountNameFound + " only"} </small>
              </div>
            ) : (
              <p style={{ color: "red", padding: "0.5rem" }}>
                * Select an account to list its available templates
              </p>
            ))}
          <div style={{ marginLeft: "auto", paddingRight: "1rem" }}>
            {!showManager ? (
              <Button
                disableElevation
                sx={{ marginTop: 2, paddingRight: 5, paddingLeft: 5 }}
                onClick={addRemoteTemplate}
                variant="contained"
                color="primary"
                disabled={templateId === 0}
              >
                Select
              </Button>
            ) : (
              <Box sx={{ marginTop: 2 }}>
                <TemplateManagerButtons
                  accountId={accountId}
                  libraryId={libraryId}
                  templateId={templateId}
                  selectedTemplate={
                    templatesOptions.filter(
                      (file) => file.value === templateId
                    )[0]
                  }
                  setRefresh={setRefresh}
                  setTemplateName={setTemplateName}
                />
              </Box>
            )}
          </div>
        </>
      )}
    </Stack>
  );
}
