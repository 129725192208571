import { Skeleton, Stack } from "@mui/material";
import "../MerakiRolloutPage.css";

function CheckboxListRow() {
  return (
    <Stack direction="row" spacing={1}>
      <Skeleton animation="wave" height="30px" width="25px" />
      <Skeleton animation="wave" height="30px" width="300px" />
    </Stack>
  );
}

function SitesListCols() {
  return (
    <Stack direction="row" spacing={1}>
      <Skeleton animation="wave" height="300px" width="350px" />
      <Skeleton animation="wave" height="300px" width="30px" />
      <Skeleton animation="wave" height="300px" width="350px" />
    </Stack>
  );
}

export default function Loading({ page, text }) {
  if (page === "checkboxList") {
    return (
      <>
        <p>{text}</p>
        <Stack>
          <CheckboxListRow />
          <CheckboxListRow />
          <CheckboxListRow />
        </Stack>
      </>
    );
  } else if (page === "sitesListSelection") {
    return (
      <>
        <p>{text}</p>
        <div style={{ marginTop: "-70px" }}>
          <SitesListCols />
        </div>
      </>
    );
  } else return <Skeleton animation="wave" height={200} />;
}
