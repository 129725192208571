import Headers from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import CustomCard from "../../components/Card/CustomCard.js";
import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import customDesign from "./../../assets/images/open-book-2.svg";
import stats from "./../../assets/images/stats.svg";
import wlc from "./../../assets/images/analyse.svg";
import directions from "./../../assets/images/directions.svg";
import wizard from "./../../assets/images/wizard.png";
import wifi from "./../../assets/images/wifi.svg";
import datamap from "./../../assets/images/datamap.svg";
import converter from "./../../assets/images/converter.svg";
import Cookies from "universal-cookie";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
import AccountSelector from "../../components/AccountSelector/AccountSelector";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    marginTop: "85px",
    marginBottom: "100px",
  },
  link: {
    paddingTop: 8,
    cursor: "pointer",
    textAlign: "center",
    color: "#4183c4",
  },
  dialog: {
    textAlign: "center",
  },
}));

export default function WizardPage() {
  const classes = useStyles();
  const cookies = new Cookies();
  const history = useHistory();

  const goToWizardView = (wizard_type, title) => {
    cookies.set("wizard_type", wizard_type);
    cookies.set("title", title);
    history.push("/wizard_view");
  };

  const goToRequestPage = () => {
    history.push("/access_request");
  };

  const scopes = cookies.get("scopes");

  return (
    <Container>
      <Headers page="journey" />
      <Grid container className={classes.root} justify="center" spacing={3}>
        <Grid item xs={12}>
          <div className="title-container">
            <h1>Welcome to Automation Wizard</h1>
          </div>
        </Grid>
        {scopes.includes("config_user") ? (
          <Grid item xs={3}>
            <CustomCard
              title="Config Automation"
              description="Want to use ngDS for config automation? Click here!"
              label="Start Here"
              journey="config_automation"
              image={customDesign}
              action={() => {
                goToWizardView("config", "Config Automation");
              }}
            />
          </Grid>
        ) : null}

        {scopes.includes("runbook_user") ? (
          <Grid item xs={3}>
            <CustomCard
              title="Runbook Automation"
              description="Click here to use Runbook Automation"
              label="Start Here"
              image={wizard}
              action={() => {
                goToWizardView("runbook", "Runbook Automation");
              }}
            />
          </Grid>
        ) : null}
      </Grid>
      <div className={classes.link}>
        <span onClick={goToRequestPage}>
          Can't see what you're looking for? Apply for access
        </span>
      </div>
      <Footer />

      <ToastContainer />
    </Container>
  );
}
