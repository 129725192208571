import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Cookies from "universal-cookie";
import Button from "@material-ui/core/Button";
import CustomButton from "../../components/Button/CustomButton.js";
import { ToastContainer, toast } from "react-toastify";
//custom loader
import { css } from "@emotion/react";
import PropagateLoader from "react-spinners/PropagateLoader";
import { handleHttpError, saveBlob, getFileName } from "../../common/utils.js";
import JourneyPageMain from "./JourneyPageMain";

//Loader css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const useStyles = makeStyles((theme) => ({
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    minWidth: "100%",
  },
  grid: {
    marginTop: "6rem",
  },
  mystyle: {
    color: "rgb(85, 20, 180)",
    paddingTop: 30,
  },
  content: {
    height: 240,
    objectFit: "contain",
    textAlign: "center",
  },
  button: {
    minWidth: "225px",
    marginBottom: "1%",
    paddingTop: "3%",
    justifyContent: "center",
    textAlign: "center",
    display: "inline-block",
  },
  button_container: {
    display: "inline-block",
    marginRight: "10%",
    marginLeft: "10%",
  },
  textarea: {
    resize: "none",
    width: "600px",
    height: "120px",
    overflow: "auto",
    outline: "none",
    marginBottom: "20px",
  },
  inputfile: {
    marginTop: 15,
    paddingLeft: "right",
    backgroundColor: "rgb(85, 20, 180)",
    color: "white",
    borderRadius: "3px",
  },
  uploadbutton: {
    paddingTop: 15,
  },
  dcfdownload: {
    paddingTop: "3%",
  },
}));

export default function DesignJourney(props) {
  const classes = useStyles();
  const cookies = new Cookies();
  let [isLoading, setLoading] = useState(false);
  let [isDisabledDcf, setIsDisabledDcf] = useState(false);
  let [isDisabledGenerate, setIsDisabledGenerate] = useState(false);

  const [templateSelected, selectTemplate] = useState(false);
  const [selectDCF, setSelectDCF] = useState(false);
  const [inputReceived, setInputReceived] = useState("");
  const [newDCFComplete, setNewDCFComplete] = useState(false);
  const access_token = cookies.get("access_token");

  const setTemplateSelected = () => {
    selectTemplate(true);
    axios({
      method: "post",
      url: "/api/v1/stats/journeys",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    })
      .then((res) => {
        cookies.set("journey_id", res.data["id"], { path: "/" });
      })
      .catch((err) => {
        handleHttpError(err);
      });
  };
  // CUSTOM JOURNEY FUNCTIONS

  const downloadDocumentCustom = (file, context) => {
    let account_id = cookies.get("account_id");
    let journey_id = cookies.get("journey_id");
    var bodyFormData = new FormData();
    bodyFormData.append("document", file);
    bodyFormData.append("context", JSON.stringify(context));
    let url = "";
    if (
      account_id === "null" ||
      account_id === undefined ||
      account_id === "None"
    ) {
      url = `/api/v1/documents?journey_id=${journey_id}`;
    } else {
      url = `/api/v1/documents?account_id=${account_id}&journey_id=${journey_id}`;
    }
    axios({
      method: "post",
      url: url,
      data: bodyFormData,
      headers: {
        Authorization: "Bearer " + access_token,
        Accept: "application/octet-stream",
      },
      responseType: "blob",
    })
      .then((response) => {
        let fileName = getFileName(response.headers);
        toast.success("Document generated: " + fileName);
        saveBlob(response.data, fileName);
        setIsDisabledGenerate(false);
        setLoading(false);
      })
      .catch((error) => {
        handleHttpError(error);
        setIsDisabledGenerate(false);
        setLoading(false);
      });
  };

  const generateDocumentCustom = () => {
    let file = document.getElementById("document_file").files[0];
    let dcf = document.getElementById("dcf_file").files[0];
    var bodyFormData = new FormData();
    bodyFormData.append("dcf", dcf);
    if (typeof file === "undefined") {
      toast.error("No document template provided!");
    } else if (typeof dcf === "undefined") {
      toast.error("No DCF file provided!");
    } else {
      setLoading(true);
      axios({
        method: "post",
        url: "/api/v1/parsers?output_type=excel",
        data: dcf,
        headers: {
          Authorization: "Bearer " + access_token,
          Accept: "application/json",
          "content-type": "xlsx",
        },
      })
        .then((res) => {
          downloadDocumentCustom(file, res.data);
        })
        .catch((error) => {
          handleHttpError(error);
          setLoading(false);
        });
    }
  };
  const provideDCF = () => {
    let file = document.getElementById("document_file").files[0];
    let formData = new FormData();
    formData.append("document", file);
    axios({
      method: "post",
      url: "/api/v1/documents",
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        Authorization: "Bearer " + access_token,
      },
      responseType: "blob",
      data: formData,
    })
      .then((res) => {
        let fileName = getFileName(res.headers);
        saveBlob(res.data, fileName);
        setIsDisabledDcf(false);
      })
      .catch((error) => {
        handleHttpError(error);
        throw error;
      });
  };

  if (props.libraryID === 0) {
    return (
      <Container>
        <h4>Select your custom design template:</h4>
        <input
          className={classes.inputfile}
          id="document_file"
          type="file"
          label="Select Template"
          name="filename"
          accept=".docx,.txt,.pptx,.vsdx,.vsd,.xlsx,.xml,.csv,.vsdm"
          onChange={() => setTemplateSelected()}
        />
        {templateSelected ? (
          <div>
            <div></div>
            <h4>Do you have a pre-populated DCF spreadsheet?</h4> <div></div>
            <label
              className="container"
              onClick={() => {
                setInputReceived("Yes");
              }}
            >
              Yes, continue
              <input id="yes-radio" type="radio" name="radio" />
            </label>
            <label
              className="container"
              onClick={() => {
                setInputReceived("No");
              }}
            >
              No, help me
              <input id="no-radio" type="radio" name="radio" />
            </label>
          </div>
        ) : (
          <div></div>
        )}
        {inputReceived === "Yes" ? (
          <input
            className={classes.inputfile}
            id="dcf_file"
            type="file"
            label="Select DCF File"
            name="filename"
            accept=".xlsx"
            onChange={() => setSelectDCF(true)}
          />
        ) : (
          <div></div>
        )}

        {inputReceived === "No" ? (
          <div className={classes.button}>
            {!isDisabledDcf ? (
              <CustomButton
                label="Generate DCF"
                color="purple"
                action={() => {
                  provideDCF();
                  setIsDisabledDcf(true);
                }}
              />
            ) : (
              <Button variant="contained" disabled>
                Generate DCF
              </Button>
            )}
          </div>
        ) : (
          <div></div>
        )}

        {selectDCF && inputReceived === "Yes" ? (
          <div className={classes.button}>
            {!isDisabledGenerate ? (
              <CustomButton
                label="Generate Document(s)"
                color="purple"
                action={() => {
                  generateDocumentCustom();
                  setIsDisabledGenerate(true);
                }}
              />
            ) : (
              <Button variant="contained" disabled>
                Generate Document(s)
              </Button>
            )}
          </div>
        ) : (
          <div></div>
        )}
        {inputReceived === "No" ? (
          <div>
            <h4>DCF spreadsheet upload:</h4>

            <input
              className={classes.inputfile}
              id="dcf_file"
              type="file"
              label="Select DCF File"
              name="filename"
              accept=".xlsx"
              onChange={() => setNewDCFComplete(true)}
            />
          </div>
        ) : (
          <div></div>
        )}
        {newDCFComplete ? (
          <div className={classes.button}>
            <CustomButton
              label="Generate Document"
              color="purple"
              action={() => generateDocumentCustom()}
            />
          </div>
        ) : (
          <div></div>
        )}
        {isLoading ? (
          <PropagateLoader color={"#5514B4"} css={override} size={20} />
        ) : null}
        <ToastContainer />
      </Container>
    );
  } else {
    return (
      <JourneyPageMain
        libraryID={props.libraryID}
        templateID={props.templateID}
        exampleDcf={props.exampleDcf}
        access_token={access_token}
      />
    );
  }
}
