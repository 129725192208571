import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import CustomButton from "../../components/Button/CustomButton.js";

//toaster dependancies
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//material ui components
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  TextField,
  Divider,
  FormControl,
  Select,
  InputLabel,
  Typography,
} from "@material-ui/core";

//checkbox
import Checkbox from "react-select";
import { handleHttpError, dynamicSort } from "../../common/utils";
import { LayoutPage } from "../JourneyPage/JourneyPage.js";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "120px",
    marginBottom: "80px",
  },
  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    height: "300",
  },
  grid: {
    marginTop: "6rem",
  },
  header: {
    paddingBottom: 60,
  },
  inputbox: {
    width: 650,
    // marginLeft: "300px",
  },
  dropdown: {
    width: 610,
  },
  sendButton: {
    // marginLeft: 560,
  },
}));

export default function AccessRequestPage(props) {
  const cookies = new Cookies();
  const classes = useStyles();
  const history = useHistory();
  const [scopesList, setScopesList] = useState("");
  const [scopesLoaded, setScopesLoaded] = useState(false);
  const [scopes, setScopes] = useState(null);
  const [accountsList, setAccountsList] = useState(null);
  const [accountsLoaded, setAccountsLoaded] = useState(null);
  const [handleReasonValue, setHandleReason] = useState("");
  const [handleUserProfileValue, setHandleUserProfile] = useState("");
  const [handleAccounts, setHandleAccounts] = useState("");
  const headers = {
    Accept: "application/json",
    Authorization: "Bearer " + props.access_token,
  };

  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };
  const handleSelectedAccounts = (e) => {
    setHandleAccounts(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const handleReason = (e) => {
    setHandleReason(e.target.value);
  };

  const handleUserProfile = (e) => {
    setHandleUserProfile(e.target.value);
  };

  const toastId = React.useRef(null);

  const toastWarning = (message) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.warning(message);
    }
  };
  const toastError = (message) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(message);
    }
  };
  const toastSuccess = (message) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.success(message);
    }
  };
  const sendRequest = () => {
    const bodyData = { description: handleReasonValue };
    if (handleUserProfileValue) {
      bodyData.scope = handleUserProfileValue;
    }
    if (handleAccounts) {
      bodyData.accounts = handleAccounts;
    }
    const origin =
      window.location.protocol +
      "//" +
      window.location.hostname +
      (window.location.port ? ":" + window.location.port : "") +
      "/admin?access";
    if (
      (handleReasonValue === "" && handleUserProfileValue === "") ||
      (handleReasonValue === "" && handleAccounts === "")
    ) {
      toastWarning("You have not completed all fields.");
    } else {
      axios({
        method: "post",
        url: "/oauth2/requests?origin=" + origin,
        data: bodyData,
        headers: headers,
      })
        .then((res) => {
          toastSuccess("Access request successfully submitted.");
          document.getElementById("textInputReason").value = "";
        })
        .catch((err) => {
          handleHttpError(err);
        });
    }
  };
  const getAccounts = () => {
    axios({
      method: "get",
      url: "/oauth2/accounts",
      params: {
        complete: true,
        nicknames: true,
      },
      headers: headers,
    })
      .then((res) => {
        const accounts = [];
        res.data.forEach((entry) => {
          accounts.push({ value: entry.id, label: entry.name });
        });
        accounts.sort(dynamicSort("label"));
        setAccountsList(accounts);
        setAccountsLoaded(true);
      })
      .catch((error) => {
        handleHttpError(error);
      });
  };

  useEffect(() => {
    let all_scopes = [];

    axios({
      method: "get",
      url: "/oauth2/scopes",
      headers: headers,
    })
      .then((results) => {
        results.data.forEach((entry) => {
          all_scopes.push(entry.name);
        });

        const current_scopes = cookies.get("scopes");
        console.log({ current_scopes });
        let unaccessed_scopes = all_scopes.filter(
          (e) => !current_scopes.includes(e)
        ); //checks which scopes user does not currently have
        unaccessed_scopes = unaccessed_scopes.filter(
          (e) =>
            ![
              "admin",
              "catalogue_user",
              "catalogue_admin",
              "sdwan_user",
              "sdwan_admin",
              "wlan_user",
              "wlan_admin",
              "wan_user",
              "wan_admin",
              "runbook_user",
              "mapper_admin",
              "config_user",
            ].includes(e)
        );
        if (unaccessed_scopes.length === 0) {
          setScopes("None");
        }

        setScopesList(unaccessed_scopes);
        setScopesLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
    getAccounts();
  }, []);

  if (scopesLoaded) {
    return (
      <LayoutPage>
        <Grid item xs={12}>
          {/* <div className="title-container"> */}
          <h1 className="colorIndigo">Access Request</h1>
          {/* </div> */}
        </Grid>

        <Grid item xs={12}>
          {scopes !== "None" ? (
            <FormControl variant="outlined">
              <InputLabel
                className={classes.inputbox}
                htmlFor="outlined-age-native-simple"
              >
                Request Profile (Optional)
              </InputLabel>
              <Select
                native
                label="Request Profile (Optional)"
                className={classes.inputbox}
                onChange={handleUserProfile}
                inputProps={{
                  name: "age",
                  id: "outlined-age-native-simple",
                }}
                id="textInputProfile"
              >
                <option aria-label="None" value="" key="None" />
                {scopesList.length === 0 ? (
                  <option value="All" key="All">
                    You have access to all available profiles
                  </option>
                ) : (
                  scopesList.map((add_scope) => (
                    <option key={add_scope} value={add_scope}>
                      {add_scope}
                    </option>
                  ))
                )}
              </Select>
            </FormControl>
          ) : (
            <FormControl
              variant="outlined"
              className={classes.formControl}
              disabled
            >
              <Select
                native
                className={classes.inputbox}
                fullWidth
                inputProps={{
                  name: "name",
                  id: "name-native-disabled",
                }}
              >
                <option key="All" value="All">
                  You have access to all available profiles
                </option>
              </Select>
            </FormControl>
          )}
        </Grid>

        {accountsLoaded ? (
          <Grid item xs={12}>
            <Checkbox
              styles={selectStyles}
              isMulti
              className={classes.inputbox}
              onChange={handleSelectedAccounts}
              id="textInputAccount"
              placeholder="Accounts required"
              options={accountsList}
            ></Checkbox>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <TextField
            label="Reason For Access"
            variant="outlined"
            onChange={handleReason}
            className={classes.inputbox}
            id="textInputReason"
          />
        </Grid>
        <Grid item xs={12}>
          <CustomButton
            label={"SEND REQUEST"}
            color="purple"
            action={() => {
              sendRequest();
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <Typography style={{ padding: 16 }}>
            <small>
              <strong>
                {"Can't Find your customer account in the list?  "}
              </strong>

              {" Please reach out to the ngDS team."}
            </small>
          </Typography>
          <Divider />
        </Grid>
      </LayoutPage>
    );
  } else {
    return <div></div>;
  }
}
