import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
//material ui components
import Container from "@material-ui/core/Container";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { handleHttpError } from "../../common/utils.js";

//material ui components
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CustomButton from "../../components/Button/CustomButton.js";

//swal
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

//toaster components
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "80px",
  },
  purple: {
    color: "rgb(85, 20, 180)",
    paddingBottom: 10,
  },
  templateName: {
    fontSize: "small",
  },
}));

function createData(GuiTemplate, Replace, Delete) {
  return { GuiTemplate, Replace, Delete };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "black",
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function GuiTemplateManagePage(props) {
  const classes = useStyles();
  const cookies = new Cookies();
  const MySwal = withReactContent(Swal);
  const [gui_templatesList, setGuiTemplatesList] = useState(null);
  const [ready, setReady] = useState(false);

  const updateGuiTemplate = (gui_template_id) => {
    var bodyFormData = new FormData();
    let replace_gui_template = document.getElementById("replace_gui_template")
      .files[0];
    bodyFormData.append("template", replace_gui_template);
    axios({
      method: "put",
      url: "/api/v1/gui_templates/" + gui_template_id,
      data: bodyFormData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        toast.success("GuiTemplate successfully updated.");
        window.location.reload();
      })
      .catch((err) => {
        handleHttpError(err);
      });
  };

  const deleteGuiTemplate = (gui_template_id) => {
    axios({
      method: "delete",
      url: "/api/v1/gui_templates/" + gui_template_id,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((res) => {
        toast.success("GuiTemplate successfully deleted.");
        window.location.reload();
      })
      .catch((err) => {
        handleHttpError(err);
      });
  };

  useEffect(() => {
    const gui_templates_list = [];
    axios
      .get(`/api/v1/gui_templates`, {
        headers: { Authorization: "Bearer " + props.access_token },
      })
      .then((response) => {
        const gui_templates = response.data;
        if (gui_templates.length === 0) {
          gui_templates_list.push(
            createData("There seems to be no gui_templates available")
          );
        } else {
          for (let gui_template of gui_templates) {
            console.log(gui_template);
            const gui_templateName = gui_template.description;
            const gui_templateFileName = gui_template.filename;
            const gui_templateID = gui_template.id;
            gui_templates_list.push(
              createData(
                gui_templateFileName,
                <CustomButton
                  label={"Replace"}
                  color="purple"
                  action={() =>
                    MySwal.fire({
                      title: "Are you sure?",
                      html:
                        "You are about to replace <b>" +
                        gui_templateName +
                        "</b>",
                      icon: "warning",
                      width: 500,
                      showCancelButton: true,
                      confirmButtonColor: "green",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Continue",
                    }).then((result) => {
                      if (result.value) {
                        MySwal.fire({
                          title: "File Upload",
                          html: "<br><br><input style='padding-left:70px' accept='.docx,.xlsx,.pptx,.txt,.xml,.j2,.vsdx,.vsd,.vsdm' type='file' id='replace_gui_template' name='replace_gui_template'/>",
                          showCancelButton: "true",
                          showConfirmButton: "true",
                          confirmButtonColor: "green",
                          cancelButtonColor: "red",
                          confirmButtonText: "Yes, replace gui_template!",
                        }).then((res) => {
                          if (res.value) {
                            updateGuiTemplate(gui_templateID);
                          }
                        });
                      }
                    })
                  }
                />,
                <CustomButton
                  label={"Delete"}
                  color="purple"
                  action={() =>
                    MySwal.fire({
                      title: "Are you sure?",
                      html:
                        "You are about to delete <b>" +
                        gui_templateName +
                        "</b>",
                      icon: "warning",
                      width: 500,
                      showCancelButton: true,
                      confirmButtonColor: "green",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Continue",
                    }).then((result) => {
                      if (result.value) {
                        MySwal.fire({
                          title: "Last chance!",
                          html: "You won't be able to revert this!",
                          showCancelButton: "true",
                          showConfirmButton: "true",
                          confirmButtonColor: "green",
                          cancelButtonColor: "red",
                          confirmButtonText: "Yes, delete gui_template!",
                        }).then((res) => {
                          if (res.value) {
                            deleteGuiTemplate(gui_templateID);
                          }
                        });
                      }
                    })
                  }
                />
              )
            );
          }
          setGuiTemplatesList(gui_templates_list);
          setReady(true);
        }
      })
      .catch(function (error) {
        handleHttpError(error);
      });
  }, []);
  if (ready) {
    return (
      <Container>
        <Header />
        <Grid container className={classes.root} justify="center" spacing={3}>
          <Grid item xs={12}>
            <div className="title-container">
              <h1>GuiTemplate Management</h1>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Container>
              <Grid
                container
                className={classes.root}
                justify="center"
                spacing={3}
              >
                <Grid item xs={10}>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>GuiTemplates</StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {gui_templatesList.map((gui_template) => (
                          <StyledTableRow key={gui_template.GuiTemplate}>
                            <StyledTableCell className={classes.templateName}>
                              {gui_template.GuiTemplate}
                            </StyledTableCell>
                            <StyledTableCell>
                              {gui_template.Replace}
                            </StyledTableCell>
                            <StyledTableCell>
                              {gui_template.Delete}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <ToastContainer />
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
        <Footer />
      </Container>
    );
  } else {
    return null;
  }
}
