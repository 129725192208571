import axios from "axios";
import { convertMs } from "../../../../utils/convertMs";

export const create_sites = async (apiKey: string, payload: any) => {
  return await commonPaloAltoPost("create_sites", {
    api_key: apiKey,
    payload,
  });
};

export const create_waninterfaces = async (
  apiKey: string,
  payload: any,
  site_id: any
) => {
  return await commonPaloAltoPost("create_waninterfaces", {
    api_key: apiKey,
    site_id,
    payload,
  });
};

export const update_interfaces = async (
  apiKey: string,
  payload: any,
  site_id: any
) => {
  return await commonPaloAltoPost("update_interfaces", {
    api_key: apiKey,
    site_id,
    payload,
  });
};

export const assign_elements = async (
  apiKey: string,
  payload: any,
  site_id: any
) => {
  return await commonPaloAltoPost("assign_elements", {
    api_key: apiKey,
    site_id,
    payload,
  });
};

export const get_elements = async (apiKey: string, serial_number: string) => {
  return await commonPaloAltoPost("get_elements", {
    api_key: apiKey,
    serial_number,
  });
};

export const create_vlans = async (
  apiKey: string,
  payload: any,
  site_id: any,
  element_id: any
) => {
  return await commonPaloAltoPost("create_vlans", {
    api_key: apiKey,
    site_id,
    element_id,
    payload,
  });
};

export const update_wan_interface_circuit_label = async (
  apiKey: string,
  payload: any
) => {
  return await commonPaloAltoPost("update_wan_interface_circuit_label", {
    api_key: apiKey,
    payload,
  });
};

export const shutdown_ports = async (apiKey: string, payload: any) => {
  return await commonPaloAltoPost("shutdown_ports", {
    api_key: apiKey,
    payload,
  });
};

export const create_dns_services = async (
  apiKey: string,
  payload: any,
  site_id: any
) => {
  return await commonPaloAltoPost("create_dns_services", {
    api_key: apiKey,
    payload,
    site_id,
  });
};

export const update_ntp = async (
  apiKey: string,
  payload: any,
  site_id: any
) => {
  return await commonPaloAltoPost("update_ntp", {
    api_key: apiKey,
    payload,
    site_id,
  });
};

export const create_element_security_zones = async (
  apiKey: string,
  payload: any,
  site_id: any
) => {
  return await commonPaloAltoPost("create_element_security_zones", {
    api_key: apiKey,
    payload,
    site_id,
  });
};

export const update_bgpconfig = async (
  apiKey: string,
  payload: any,
  site_id: any
) => {
  return await commonPaloAltoPost("update_bgpconfig", {
    api_key: apiKey,
    payload,
    site_id,
  });
};

export const create_bgppeers = async (
  apiKey: string,
  payload: any,
  site_id: any
) => {
  return await commonPaloAltoPost("create_bgppeers", {
    api_key: apiKey,
    payload,
    site_id,
  });
};

export const create_static_routes = async (
  apiKey: string,
  payload: any,
  site_id: any
) => {
  return await commonPaloAltoPost("create_static_routes", {
    api_key: apiKey,
    payload,
    site_id,
  });
};

export const parse_context = async (apiKey: string, context: any) => {
  return await commonPaloAltoPost("parse_context", {
    api_key: apiKey,
    context,
  });
};

// MAIN POST FUNCTIONS'
export const commonPaloAltoPost = async (
  action: string,
  params: any // {}
) => {
  // Gets from local Storage
  const user = JSON.parse(localStorage.getItem("user"));
  const account = JSON.parse(localStorage.getItem("currentAccount"));
  const account_id = account?.id;
  const access_token = user?.access_token;

  const response: { data: any[]; error: any[]; code: any[] } = {
    data: [],
    error: [],
    code: [],
  };

  const url = "/api/v1/api_connectors";
  const body = {
    user: {
      ein: user?.uin,
      fullname: user?.fullname,
      account,
    },
    account_id: +account_id,
    connector: "palo_alto",
    params,
    action,
  };
  console.log(
    `Started ${body.connector}: "${body.action}" for account: ${account.name}(${account.id}) by ${user.fullname} (${user.uin})`
  );
  console.log({
    params: {
      ...params,
      api_key: `***${params.api_key?.slice(-4, params.api_key?.length)}`,
    },
  });
  const start = performance.now();

  await axios
    .post(url, body, {
      headers: { Authorization: "Bearer " + access_token },
    })
    .then((resp: any) => {
      console.log({ [`${action}_RESPONSE`]: resp });
      let data = resp.data;
      const isAnArray = Array.isArray(data);
      if (!isAnArray) data = [data];

      data.forEach((item: any) => {
        const status_code = item?._status_code || resp.status;
        if (+status_code !== 200) {
          console.log({ error: "Something whent wrong" });
          response["error"].push(
            item?._error ||
              item?._warning || [{ message: "Something whent wrong" }]
          );
        } else {
          response["data"].push(item);
        }
      });
    })
    .catch((error: any) => {
      console.log({ [`${action}_CATCH_ERROR`]: error });
      response["error"] = error.response?.data?.error?._error ||
        error.response?.data?.error._warning ||
        error.response?.data?.error ||
        error.response?.error?._error ||
        error.response?.error?._warning ||
        error.response?.error || [error.text];
      const isAnArray = Array.isArray(response["error"]);
      if (!isAnArray)
        response["error"] = [{ message: response.error.toString() }];
    })
    .finally(() => {
      const end = performance.now();
      const duration = end - start;
      console.log(
        `Finished ${body.connector}: "${body.action}" in ${convertMs(duration)}`
      );
    });

  console.log({ post_response: response });

  return {
    data: response.data.flat(1),
    error: response.error.flat(1),
    code: response.code.flat(1),
  };
};
