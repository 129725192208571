import { Space, Tag } from "antd";
import React from "react";
import { useMerakiConnectors } from "../../../../../contexts/Connectors/MerakiContext";
import { MerakiNetworksType } from "../../../../../types/MerakiConnectorsType";

const ProductsTagColoredStack = (inputList: string[]) => {
    const meraki = useMerakiConnectors()
    const networks = meraki.networks

    const tagColors = ["blue", "volcano", "cyan", "gold", "green", "orange", "geekblue", "magenta", "lime", "red", "purple"]
    const products = ["appliance", "camera", "cellularGateway", "sensor", "switch", "wireless", "sm", "insight", "licencing"]
    networks?.forEach((network: MerakiNetworksType) =>
        network.productTypes.forEach(product => !products.includes(product) && products.push(product))
    )

    return (
        <Space size={[0, 8]} wrap>
            {inputList.map(
                (item: string) =>
                    <Tag key={item} color={tagColors[products.indexOf(item)]}>{item}</Tag>
            )
            }
        </Space >
    )
}

export default ProductsTagColoredStack