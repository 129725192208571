import {
  Button,
  Checkbox,
  Drawer,
  Space,
  Tag,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import BaseTable from "../../../../components/Tables/BaseTable";
import {
  SelectOutlined,
  ArrowsAltOutlined,
  FileSearchOutlined,
  SettingOutlined,
  SwapOutlined,
  TagOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { TemplatesType } from ".";
import ShowRecordDetails from "../../../../components/Tables/ShowRecordDetails";
import DeleteFile from "./Actions/Delete";
import DownloadFile from "./Actions/Download";
import LabelSet from "./Actions/LabelSet";
import Replace from "./Actions/Replace";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import Management from "./Actions/Management";

const { Text } = Typography;

const account = JSON.parse(localStorage.getItem("currentAccount"));
const accountId = account?.id;
const accountName = account?.name;

const user = JSON.parse(localStorage.getItem("user"));
const scopes = user?.scopes;
const isTemplateAdmin = scopes?.includes("template_admin");

const TemplatesTable = ({
  labels,
  libraryId,
  libraryName,
  templates,
  selectedIds,
  setSelectedIds,
  setToggleRefresh,
}) => {
  const [isAccountOnly, SetIsAccountOnly] = useState<boolean>(true);
  const onChange = (e: CheckboxChangeEvent) => {
    SetIsAccountOnly(e.target.checked);
  };

  const templateHeaders = [
    {
      title: "",
      dataIndex: "id",
      render: (id: string, record: any) => (
        <Button
          icon={<SelectOutlined />}
          disabled={selectedIds.includes(id)}
          onClick={() => {
            setSelectedIds(
              (prev: string[]) => !prev.includes(id) && [...prev, id]
            );
            message.info(`Selected: ${record.name}`);
          }}
          type="link"
          size="small"
        />
      ),
      width: "4%",
      align: "center",
    },
    {
      title: "Template Name",
      dataIndex: "name",
      render: (text: string) => <Text>{text}</Text>,
      align: "left",
    },
    {
      title: "Type",
      dataIndex: ["type"],
      width: "10%",
      render: (text: string) => <Tag color="cyan">{text}</Tag>,
      align: "center",
    },
    {
      title: "Label",
      dataIndex: ["label", "label"],
      width: "10%",
      render: (text: string) => text && <Tag color="volcano">{text}</Tag>,
      align: "center",
    },
  ];

  const columns = templateHeaders.map(
    ({ title, dataIndex, width, render, align }) => ({
      title,
      dataIndex,
      key: title,
      width,
      render,
      align,
    })
  );

  const rowActions = [
    {
      key: "listDetails",
      component: ShowRecordDetails,
      icon: <ArrowsAltOutlined />,
    },
    // {
    //   key: "dataSource",
    //   label: <Text>Generate Data Source</Text>,
    //   icon: <FileSearchOutlined />,
    // },
    {
      key: "Download",
      component: DownloadFile,
      icon: <DownloadOutlined />,
    },
    { type: "divider" },
    {
      key: "admin",
      icon: <SettingOutlined />,
      label: "Admin",
      disabled: !isTemplateAdmin,
      children: [
        {
          key: "Label",
          component: LabelSet,
          icon: <TagOutlined />,
          props: { setToggleRefresh, labels },
        },
        {
          key: "Replace",
          component: Replace,
          icon: <SwapOutlined />,
        },
        { type: "divider" },
        {
          key: "Delete",
          component: DeleteFile,
          danger: true,
          icon: <DeleteOutlined />,
          props: { setToggleRefresh },
        },
      ],
    },
  ];

  const [open, setOpen] = useState(true);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const closeLibary = () => {
    setOpen(false);
  };

  const templateList = templates
    ?.filter((template: TemplatesType) =>
      isAccountOnly ? template.account_id === +accountId : template
    )
    .filter((template: TemplatesType) => template.library.id === +libraryId);

  const templatesLabels: string[] = templateList
    ?.map((template: TemplatesType) => template.label?.label)
    .filter((label: string | undefined) => label !== undefined);

  const uniqueLabels = Array.from(new Set(templatesLabels)).sort();

  const topFilterActions = uniqueLabels?.map((label: string) => ({
    key: label,
    text: label,
    rule: (items: any) =>
      items.filter((item: any) => item.label?.label === label),
    // icon: <ApartmentOutlined />,
    disabled: null,
  }));

  topFilterActions.length > 0 &&
    topFilterActions.unshift({
      key: "reset",
      text: "Any",
      rule: (items: any) => items,
      disabled: null,
    });

  return (
    <>
      <Button onClick={showDrawer}>Templates Library</Button>
      <Drawer
        width={"90%"}
        title={`${libraryName} - Templates Library`}
        placement="right"
        onClose={onClose}
        open={open}
        destroyOnClose
        extra={
          isTemplateAdmin && (
            <Management
              libraryId={libraryId}
              libraryName={libraryName}
              labels={labels}
              templates={templates}
              setToggleRefresh={setToggleRefresh}
            />
          )
        }
      >
        <Space direction="vertical" size="large">
          <Checkbox onChange={onChange} checked={isAccountOnly}>
            {`Show ${accountName} Templates only`}
          </Checkbox>
          <BaseTable
            tableName={"templates"}
            columns={columns}
            dataSource={templateList.filter(
              (template: TemplatesType) => !selectedIds.includes(template.id)
            )}
            rowActions={rowActions}
            topButtons={
              uniqueLabels.length > 0 ? [<Text>Filter by Label:</Text>] : null
            }
            topFiltersActions={topFilterActions}
            // reloadAction={() => connectors?.fetchLocations()}
          />
        </Space>
      </Drawer>
    </>
  );
};

export default TemplatesTable;
