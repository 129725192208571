import React, { createContext, useCallback, useContext, useState } from "react";
import { ChildrenProps } from "../../../types/ChildrenReactType";
import { bfgSites } from "../../../types/BFGConnectorsType";
import axios from "axios";
import { NgdsErrorType } from "../../../types/NgdsErrorType";
import { connectorCall } from "../connectorCall";

function convertMs(millis: number) {
  const minutes = +Math.floor(millis / 60000);
  const seconds = +((millis % 60000) / 1000).toFixed(0);
  return seconds === 60
    ? minutes + 1 + ":00"
    : minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
}

// const sleep = (ms: number) => new Promise(
//     resolve => setTimeout(resolve, ms));

export type BFGConnectorsContextType = {
  sites: bfgSites | undefined;
  loadingSites: boolean;
  errorSites: NgdsErrorType | undefined;
  durationSites: number | undefined;
  get_sites_by_bfg_id: () => Promise<boolean>;
  // addresses: bfgSites | undefined;
  // loadingAddresses: boolean;
  // errorAddresses: NgdsErrorType | undefined;
  // durationAddresses: number | undefined;
  // get_addresses_by_site_id: () => Promise<boolean>;
};

export const BFGConnectorsContext =
  createContext<BFGConnectorsContextType | null>(null);

export const BFGConnectorsContextProvider: React.FC<ChildrenProps> = ({
  children,
}) => {
  // Sites States ALL
  const [sites, setSites] = useState<bfgSites | undefined>(undefined);
  const [loadingSites, setLoadingSites] = useState<boolean>(false);
  const [errorSites, setErrorSites] = useState<NgdsErrorType | undefined>(
    undefined
  );
  const [durationSites, setDurationSites] = useState<number | undefined>(
    undefined
  );

  const get_sites_by_bfg_id = async () => {
    return await connectorCall(
      "bfg",
      "get_sites_by_bfg_id",
      undefined, //params not needed
      setSites,
      setLoadingSites,
      setErrorSites,
      setDurationSites
    );
  };

  // // Sites Addresses ALL
  // const [addresses, setAddresses] = useState<bfgSites | undefined>(undefined);
  // const [loadingAddresses, setLoadingAddresses] = useState<boolean>(false);
  // const [errorAddresses, setErrorAddresses] = useState<
  //   NgdsErrorType | undefined
  // >(undefined);
  // const [durationAddresses, setDurationAddresses] = useState<
  //   number | undefined
  // >(undefined);

  // const get_addresses_by_site_id = async (site_id: number[]) => {
  //   // site_id is an array of numbers
  //   return await connectorCall( "bfg",
  //     "get_addresses_by_site_id",
  //     { site_id },
  //     setAddresses,
  //     setLoadingAddresses,
  //     setErrorAddresses,
  //     setDurationAddresses
  //   );
  // };

  return (
    <BFGConnectorsContext.Provider
      value={{
        //-----------------------------------------------------------
        sites,
        loadingSites,
        errorSites,
        durationSites,
        get_sites_by_bfg_id,
        //-----------------------------------------------------------
        // addresses,
        // loadingAddresses,
        // errorAddresses,
        // durationAddresses,
        // get_addresses_by_site_id,
      }}
    >
      {children}
    </BFGConnectorsContext.Provider>
  );
};

export const useBFGConnectors = () => useContext(BFGConnectorsContext);

export default BFGConnectorsContext;
