import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DesignJourney from "./DesignJourney";
import { handleHttpError } from "../../common/utils.js";

export default function TemplateSelector(props) {
  const history = useHistory();
  const cookies = new Cookies();
  const access_token = cookies.get("access_token");
  const [options, setOptions] = useState();
  const [isLoading, setLoading] = useState(true);
  const [isCrashing, setCrashing] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [libraryTemplates, setLibraryTemplates] = useState();
  const [templateId, setTemplateId] = useState("");
  const [exampleDcf, setExampleDcf] = useState(false);
  const [templateChoice, setTemplateChoice] = useState({
    template: "",
    name: "choice",
  });

  const handleChange = (event) => {
    setShowResults(true);
    axios({
      method: "post",
      url: "/api/v1/stats/journeys",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    })
      .then((res) => {
        cookies.set("journey_id", res.data["id"], { path: "/" });
      })
      .catch((err) => {
        handleHttpError(err);
      });
    const selected_id = event.target.options.selectedIndex; // option id
    const template_id = parseInt(event.target.options[selected_id].value); //template id
    // Get Template object
    let template = libraryTemplates.find((libraryTemplate) => libraryTemplate.id === template_id);
    if (typeof template != "undefined") {
      // Check if template has DCF - Boolean
      setExampleDcf(template.example_dcf);
    } else {
      setExampleDcf(false);
    }

    setTemplateId(template_id);
    const name = event.target.name;
    setTemplateChoice({
      ...templateChoice,
      [name]: event.target.value,
    });
  };
  useEffect(() => {
    setShowResults(false);
    axios
      .get(`/api/v1/libraries/${props.libraryID}/templates`, { headers: { Authorization: "Bearer " + access_token } })
      .then((response) => {
        const templates = response.data;
        setLibraryTemplates(templates);
        const optionsPayload = [{ value: "None", name: "Select a template" }];

        templates.forEach((template) => {
          optionsPayload.push({ value: template.id, name: template.name });
        });

        setOptions(optionsPayload);
        setLoading(false);
      })
      .catch(function (error) {
        handleHttpError(error);
        setCrashing(true);
      });
  }, [props.libraryID]);

  if (isCrashing) {
    return (
      <Container>
        <div className="App">
          There seems to be an error receiving templates. Contact the development team if this issue persists.
        </div>
      </Container>
    );
  }

  if (isLoading) {
    return (
      <Container>
        <div className="App">Loading...</div>
      </Container>
    );
  }
  return (
    <Container>
      <FormControl>
        <InputLabel htmlFor="template-select">Design Templates</InputLabel>
        <Select
          native
          value={templateChoice.template}
          onChange={handleChange}
          inputProps={{
            name: "template",
            id: "template-select",
          }}
        >
          <optgroup label="Templates">
            {options.map((e, key) => {
              return (
                <option key={e.value} value={e.value}>
                  {e.name}
                </option>
              );
            })}
          </optgroup>
        </Select>
      </FormControl>
      {showResults && templateId !== "" && !isNaN(templateId) ? (
        <DesignJourney libraryID={props.libraryID} templateID={templateId} exampleDcf={exampleDcf} />
      ) : null}
    </Container>
  );
}
