import React, { useState, useEffect, useRef } from "react";
import Header from "../../../components/Header/Header.js";
import Footer from "../../../components/Footer/Footer.js";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CustomButton from "../../../components/Button/CustomButton.js";
import SpinnerLoader from "../../../components/Loader/SpinnerLoader.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@mui/material/TextField";

import {
  getFileName,
  saveBlob,
  handleHttpError,
} from "../../../common/utils.js";
import { LayoutPage } from "../../JourneyPage/JourneyPage.js";

const useStyles = makeStyles((theme) => ({
  root: {
    // paddingTop: "100px",
    flexGrow: 1,
    // marginBottom: "80px",
    textAlign: "center",
  },
  subroot: {
    paddingTop: "50px",
    marginBottom: "80px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "40%",
  },
  mystyle: {
    color: "rgb(85, 20, 180)",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function IonixPage({ access_token }) {
  const classes = useStyles();
  const [outputType, setOutputType] = useState("2d");
  const [isConfigLoading, setConfigLoading] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [isWaitingResponse, setWaitingResponse] = useState(false);
  const [statusText, setStatusText] = useState("");
  const [options, setOptions] = React.useState("");
  let ionixBox = useRef(null);

  const handleChange = (event) => {
    setOutputType(event.target.value);
  };

  useEffect(() => {
    const optionsPayload = [{ value: "3d", name: "3D Data Source" }];
    optionsPayload.push({ value: "2d", name: "2D Data Source" });
    setOptions(optionsPayload);
    setLoading(false);
  }, []);

  const startConverter = () => {
    setConfigLoading(true);
    setWaitingResponse(true);
    let output = outputType;
    let bodyFormData = new FormData();
    bodyFormData.append("configs", ionixBox.current.value);
    const currentAccount = JSON.parse(localStorage.getItem("currentAccount"));
    bodyFormData.append("account_id", currentAccount?.id);
    bodyFormData.append("account_name", currentAccount?.name);
    axios({
      method: "post",
      url: `/api/v1/parsers?output_type=ionix&output_format=` + output,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + access_token,
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      data: bodyFormData,
      onUploadProgress: function (progressEvent) {
        // Use this calculation to make a progress bar
        const time = (
          (progressEvent.loaded / progressEvent.total) *
          100
        ).toFixed(0);
        setStatusText("Uploading... " + time + "/100");
        if (time === "100") {
          setStatusText("Converting...");
        }
      },
      responseType: "blob",
      maxContentLength: 100000000,
      maxBodyLength: 1000000000,
    })
      .then((response) => {
        // Generating filename
        let fileName = getFileName(response.headers);
        saveBlob(response.data, fileName);
        setWaitingResponse(false);
        setConfigLoading(false);
      })
      .catch(function (error) {
        setWaitingResponse(false);
        handleHttpError(error);
        setConfigLoading(false);
      });
  };

  return (
    <LayoutPage>
      <Grid container className={classes.root} justify="center" spacing={3}>
        <Grid item xs={12}>
          <div className="title-container">
            <h1 className="colorIndigo">Ionix Converter</h1>
            {/* <h3>Select your design journey:</h3> */}
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className="title-container">
            <h3>
              Please copy the entirety of the Ionix email and paste it in the
              box below:
            </h3>
          </div>
        </Grid>
        <br />
        <Grid item xs={12}>
          <TextField
            label="Ionix Email"
            multiline
            rows={4}
            inputRef={ionixBox}
            style={{ width: 500 }}
            placeholder="Paste email here"
            onChange={() => setConfigLoading(false)}
          />
        </Grid>

        {!isLoading ? (
          <Grid item xs={12}>
            <div className="title-container">
              <h3>Select an output type:</h3>

              <FormControl
                style={{ minWidth: 200 }}
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel>Output Type</InputLabel>
                <Select
                  onChange={handleChange}
                  inputProps={{
                    name: "library",
                    id: "library-select",
                  }}
                  label="Output Selector"
                  value={outputType}
                >
                  {options.map((e, key) => {
                    return (
                      <MenuItem key={e.value} value={e.value}>
                        {e.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          {isConfigLoading ? (
            <div className={classes.button}>
              <Button variant="contained" disabled>
                Convert Configs
              </Button>
            </div>
          ) : (
            <>
              <div className={classes.button}>
                <CustomButton
                  label={"Convert Configs"}
                  color="purple"
                  action={() => {
                    startConverter();
                  }}
                />
              </div>
            </>
          )}

          {isWaitingResponse ? (
            <div className={classes.spinner}>
              <SpinnerLoader />
              {statusText}
            </div>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </LayoutPage>
  );
}
