import { Space, Tooltip, Typography, Button, Modal } from "antd";
import type { ColumnsType } from "antd/es/table";
import { FC, useEffect, useState } from "react";
import {
  ZscalerLocationType,
  VpnCredentialType,
  ZscalerSettingType,
} from "../../../../types/ConnectorsType";
import { useConnectors } from "../../../../contexts/Connectors/ZscalerContext";
import {
  ApiOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  DeleteOutlined,
  ArrowsAltOutlined,
} from "@ant-design/icons";
import React from "react";
import BaseTable from "../../../../components/Tables/BaseTable";
import ChangePSK from "./Actions/ChangePSK";
import DeleteVpnCredential from "./Actions/DeleteVpnCredential";
import CreateVPN from "./Actions/CreateVPN";
import ShowRecordDetails from "../../../../components/Tables/ShowRecordDetails";
import { listVpnCredentialsNotAssociated } from "../functions/fetchZscaler";

const { Text } = Typography;

type VPNCellType = {
  record: VpnCredentialType;
  isZscalerReadOnly?: boolean;
};

const PSKCell: FC<VPNCellType> = ({ record, isZscalerReadOnly }) => {
  const [showPsk, setShowPsk] = useState<boolean>(false);
  const handlePsk = () => setShowPsk(!showPsk);
  const text = record.psk;
  const comments = record.comments;
  const vpn_credential_id = record.id;
  const currentPSK = record.psk;
  const fqdn = record.fqdn;
  const rfc = record.rfc;
  return (
    <Space>
      <Text copyable={{ text }}>{showPsk ? text : "******************"}</Text>
      {showPsk ? (
        <Tooltip placement="top" title="Hide">
          <EyeInvisibleOutlined
            style={{ color: "#FF5A4B" }}
            onClick={handlePsk}
          />
        </Tooltip>
      ) : (
        <Tooltip placement="top" title="Show">
          <EyeOutlined style={{ color: "#1677FF" }} onClick={handlePsk} />
        </Tooltip>
      )}
      <ChangePSK
        vpn_credential_id={vpn_credential_id}
        comments={comments}
        rfc={rfc}
        fqdn={fqdn}
        currentPSK={currentPSK}
        isZscalerReadOnly={isZscalerReadOnly}
      />
    </Space>
  );
};

const VPNCredentials: FC<{
  location?: ZscalerLocationType;
  isZscalerReadOnly?: boolean;
}> = ({ location, isZscalerReadOnly }) => {
  const connectors = useConnectors();
  const locationsAndCredentials = connectors?.locationsAndCredentials;
  const vpnCredentials = locationsAndCredentials?.vpn_credentials;
  const vpnCredentialsError = connectors?.error;
  const vpnCredentialsLoading = connectors?.loading;
  const vpnSettings = connectors?.settings?.filter(
    (set) => set.group === "VPN Credentials"
  );

  const [data, setData] = useState<VpnCredentialType[] | undefined>(undefined);

  useEffect(() => {
    if (location?.vpnCredentials) {
      setData(location.vpnCredentials);
    } else setData(vpnCredentials);
  }, [vpnCredentials, location]);

  const specialRenders = {
    psk: (text: string, record: VpnCredentialType) => (
      <PSKCell record={record} isZscalerReadOnly={isZscalerReadOnly} />
    ),
    fqdn: (text: string) => <Text copyable>{text}</Text>,
  };

  const columns: ColumnsType<ZscalerLocationType> = vpnSettings
    ?.filter((setting: ZscalerSettingType) => setting.display_item_ui === true)
    .map((setting: ZscalerSettingType) => ({
      title: setting.display_name,
      dataIndex: setting.parameter,
      key: setting.parameter,
      align: "center",
      render: specialRenders[setting.parameter],
    }));

  // const topFiltersActions = [
  //   {
  //     key: "1",
  //     text: "Show Not Associated Credentials",
  //     rule: (items: VpnCredentialType[]) =>
  //       items.filter((item: VpnCredentialType) => !item.location),
  //     icon: <ApiOutlined />,
  //     disabled: null,
  //   },
  // ];

  const actionMenu = [
    {
      key: "listDetails",
      component: ShowRecordDetails,
      icon: <ArrowsAltOutlined />,
    },

    {
      key: "delete",
      component: DeleteVpnCredential,
      danger: true,
      icon: <DeleteOutlined />,
      disabled: isZscalerReadOnly,
    },
  ];

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <BaseTable
        tableName={"VPN Credentials"}
        columns={columns}
        dataSource={data}
        topButtons={[
          location ? (
            <CreateVPN location={location} />
          ) : (
            <UnassignedModal
              columns={columns}
              isZscalerReadOnly={isZscalerReadOnly}
            />
          ),
        ]}
        // topFiltersActions={location ? null : topFiltersActions}
        loading={vpnCredentialsLoading && !vpnCredentialsError}
        // reloadAction={() => connectors?.fetchLocationsAndCredentials()}
        rowActions={actionMenu}
      />
    </Space>
  );
};

export default VPNCredentials;

const UnassignedModal = ({ columns, isZscalerReadOnly }) => {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [unassignedCredentials, setUnassignedCredentials] = useState([]);
  const [error, setError] = useState(false);

  const fetchUnassignedCredentials = async () => {
    setLoading(true);
    const notAssociated = await listVpnCredentialsNotAssociated(null);
    if (notAssociated.response.status == 200) {
      setUnassignedCredentials(notAssociated.data);
    } else {
      setError(true);
    }

    setLoading(false);
  };

  const actionMenu = [
    {
      key: "listDetails",
      component: ShowRecordDetails,
      icon: <ArrowsAltOutlined />,
    },
    {
      key: "delete",
      component: DeleteVpnCredential,
      danger: true,
      icon: <DeleteOutlined />,
      disabled: isZscalerReadOnly,
    },
  ];

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
          fetchUnassignedCredentials();
        }}
      >
        Show Not Associate VPN Credentials
      </Button>
      <Modal
        title="Not Associate VPN Credentials"
        width={"80%"}
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
      >
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <BaseTable
            tableName={"VPN Credentials"}
            columns={columns}
            dataSource={unassignedCredentials}
            loading={loading && !error}
            reloadAction={() => fetchUnassignedCredentials()}
            rowActions={actionMenu}
          />
        </Space>
      </Modal>
    </>
  );
};
