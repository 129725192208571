import { useEffect } from "react";

import Loading from "./Loading";
import { useTemplates } from "../library/fetchRequests";
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import { Alert } from "@mui/material";
import "../MerakiRolloutPage.css";

export default function TemplatesOptions({ templatesList, setTemplatesList }) {
  const [templates, isLoading, hasError, error] = useTemplates();

  //Setting initial state
  useEffect(() => {
    // mockdata by adding the autodata to the payload ==== START
    const autodata_port_mapping = {
      devices_required: ["switch"],
      commands_required: ["show interface status"],
    };
    for (const template of templates) {
      if (template.name.includes("Port Mapping")) {
        template["autodata"] = autodata_port_mapping;
      }
    }
    // mockdata by adding the autodata to the payload ==== END

    if (templatesList.length === 0)
      setTemplatesList(
        templates.map((item) => ({
          name: item.name,
          id: item.id,
          checked: false,
          autodata: item.autodata || null,
        }))
      );
  }, [templates]);

  const handleChange = (event) => {
    setTemplatesList((prevState) => {
      return prevState.map((item) =>
        item.id == event.target.value
          ? { ...item, checked: !item.checked }
          : item
      );
    });
  };

  const LoadedData = () => {
    return (
      <FormGroup>
        {templatesList.map((template) => (
          <FormControlLabel
            key={template.id}
            control={
              <Checkbox
                color="primary"
                name={template.name}
                value={template.id}
                checked={template.checked}
              />
            }
            label={template.name}
            onChange={handleChange}
          />
        ))}
      </FormGroup>
    );
  };

  const MainDisplayedData = () => {
    if (!isLoading && templates.length > 0) {
      return <LoadedData />;
    } else if (hasError) {
      return (
        <Alert variant="outlined" severity="error">
          Something went wrong while fetching the data...
        </Alert>
      );
    } else {
      return <Loading page="checkboxList" text="Fetching templates... " />;
    }
  };

  return (
    <div className="backBox">
      <MainDisplayedData />
    </div>
  );
}
