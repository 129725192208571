import { Space, Table, Typography } from 'antd'
import type { ColumnsType } from 'antd/es/table';
import { VipType } from '../../../types/ConnectorsType';
import { useConnectors } from '../../../contexts/Connectors/ZscalerContext';
import React from 'react';

const { Title, Text, Paragraph } = Typography

const Locations = () => {

    const connectors = useConnectors()

    // VIPS Context
    const vips = connectors?.vips
    const error = connectors?.vipsError
    const loading = connectors?.vipsLoading

    const Stack = (inputList: string[] | number[] | undefined) => {
        return (
            inputList ?
                <Space wrap direction='vertical' size={'small'}>
                    {inputList.map((item: string | number) => <small key={item}>{item}</small>)}
                </Space > : <Text type='secondary'>{"None"}</Text>
        )
    }

    const clouds = vips?.map((vip: any) => vip.cloud_name).filter((vip: any) => vip)
    const isSameCloud = [...new Set(clouds)].length === 1  // remove duplicates and check size
    const cloudName = clouds ? clouds[0] : null

    const columns: ColumnsType<VipType> = [
        {
            title: <h5>Cloud Name</h5>,
            dataIndex: 'cloud_name',
            key: 'Cloud Name',
            width: "6.8%",
            align: 'center',
            render: (text) => <small>{text}</small>,
        },
        {
            title: <h5>Region</h5>,
            dataIndex: 'region',
            key: 'Region',
            width: "%",
            align: 'center',
            render: (text) => <small>{text}</small>,
        },
        {
            title: <h5>Country</h5>,
            key: 'country',
            dataIndex: 'country',
            width: "7%",
            align: 'center',
            render: (text) => <small>{text}</small>,
        },
        {
            title: <h5>Data Center</h5>,
            dataIndex: 'data_center',
            key: 'Data Center',
            width: "5%",
            align: 'center',
            render: (text) => <small>{text}</small>,
        },
        {
            title: <h5>Location</h5>,
            dataIndex: 'location',
            key: 'Location',
            width: "6%",
            align: 'center',
            render: (text) => text.split(',')?.map((x: string) => <Paragraph><small key={x}>{x}</small></Paragraph>),
        },
        {
            title: <h5>VPN IPs</h5>,
            // dataIndex: 'vpn_ips',
            key: 'VPN IPs',
            render: (_, { vpn_ips }) => Stack(vpn_ips),
            width: "10%",
            align: 'center'
        },
        {
            title: <h5>VPN Domain Name</h5>,
            dataIndex: 'vpn_domain_name',
            key: 'VPN Domain Name',
            align: 'center',
            render: (text) => <small>{text}</small>,
        },
        {
            title: <h5>GRE IPs</h5>,
            // dataIndex: 'gre_ips',
            key: 'GRE IPs',
            render: (_, { gre_ips }) => Stack(gre_ips),
            width: "10%",
            align: 'center'
        },
        {
            title: <h5>GRE Domain Name</h5>,
            dataIndex: 'gre_domain_name',
            key: 'GRE Domain Name',
            align: 'center',
            render: (text) => <small>{text}</small>,
        },
        {
            title: <h5>PAC IPs</h5>,
            // dataIndex: 'pac_ips',
            key: 'PAC IPs',
            render: (_, { pac_ips }) => Stack(pac_ips),
            width: "10%",
            align: 'center'
        },
        {
            title: <h5>PAC Domain Name</h5>,
            dataIndex: 'pac_domain_name',
            key: 'PAC Domain Name',
            render: (text) => <small>{text}</small>,
            align: 'center'

        },
        {
            title: <h5>SVPN IPs</h5>,
            // dataIndex: 'svpn_ips',
            key: 'SVPN Ips',
            render: (_, { svpn_ips }) => Stack(svpn_ips),
            width: "10%",
            align: 'center'
        },
        {
            title: <h5>SVPN Domain Name</h5>,
            dataIndex: 'svpn_domain_name',
            key: 'SVPN Domain Name',
            render: (text) => <small>{text}</small>,
            align: 'center'
        },
    ]

    // if the Cloud are all the same.. don't dusplay it as a column...
    if (isSameCloud) columns.shift()

    return (
        <Space direction='vertical' size='small' style={{ width: '100%' }}>

            <Title level={4}>Virtual IP addresses (VIPs) available in the Zscaler Cloud</Title>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div><Text strong style={{ paddingLeft: 10, color: "#5514B4" }}>Cloud Name: </Text>{cloudName}</div>
                {!loading && <Text style={{ paddingRight: 10 }}>Found {vips?.length} entries</Text>}
            </div>


            <Table
                loading={loading}
                bordered
                tableLayout='fixed'
                rowKey="id"
                size='small'
                pagination={{
                    hideOnSinglePage: true
                }
                }
                // rowSelection={{
                //     hideSelectAll: true,
                //     type: 'checkbox',
                //     ...rowSelection
                // }}
                columns={columns}
                dataSource={vips}
            // onChange={onChange}

            />
        </Space>
    )
}

export default Locations