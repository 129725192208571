import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Cookies from "universal-cookie";

//material ui components
import Container from "@material-ui/core/Container";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CustomButton from "../../components/Button/CustomButton.js";
import { handleHttpError } from "../../common/utils.js";

//toaster components
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//swal
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function createData(Template, Edit, Duplicate, Delete, Use) {
  return { Template, Edit, Duplicate, Delete, Use };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "100px",
    paddingBottom: "150px",
  },
  purple: {
    color: "rgb(85, 20, 180)",
  },
  templateName: {
    fontSize: "small",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: 'rgba(85, 20, 180,0.9)',
    color: "black",
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function MapperManagePage(props) {
  const classes = useStyles();
  const cookies = new Cookies();
  const history = useHistory();
  const MySwal = withReactContent(Swal);
  const [mappersLoaded, setMappersLoaded] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isCrashing, setCrashing] = useState(false);
  const [mappersList, setMappersList] = useState(null);
  const [mappersUpdated, setMappersUpdated] = useState(false);

  const goToMapper = (action) => {
    cookies.set("mapper_status", action, { path: "/" });
    history.push("/mapper_editor");
  };
  const activateMapper = (mapper_id) => {
    history.push("/data_mapping");
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?mapperId=" +
      mapper_id;
    window.history.pushState({ path: newurl }, "", newurl);
  };
  const duplicateMapper = (mapper_id) => {
    setMappersUpdated(false);
    axios({
      method: "get",
      url: "/api/v1/conversion/mappers/" + mapper_id,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((res) => {
        delete res.data["id"];
        res.data["name"] = "*DUPLICATE* " + res.data["name"];
        axios({
          method: "post",
          url: "/api/v1/conversion/mappers",
          data: res.data,
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + props.access_token,
          },
        })
          .then((results) => {
            console.log("Mapper duplicated");
            setMappersUpdated(true);
          })
          .catch((err) => {
            handleHttpError(err);
          });
      })
      .catch((err) => {
        handleHttpError(err);
      });
  };
  useEffect(() => {
    const mappers_list = [];
    axios
      .get("/api/v1/conversion/mappers", {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + props.access_token,
        },
      })
      .then((response) => {
        const mappers = response.data;
        if (mappers.length === 0) {
          mappers_list.push(
            createData("There seems to be no mappers available")
          );
        } else {
          var i = 0;
          for (let mapper of mappers) {
            const mapper_name = mapper.name;
            const mapper_id = mapper.id;
            const description = mapper.description;

            mappers_list.push(
              createData(
                mapper_name + " - " + description,
                <CustomButton
                  label={"Edit"}
                  color="purple"
                  action={() => goToMapper("edit" + mapper_id)}
                />,
                <CustomButton
                  label={"Duplicate"}
                  action={() =>
                    MySwal.fire({
                      title: "Are you sure?",
                      html: "<p>You are about to duplicate the selected mapper!</p>",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "green",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Confirm",
                    }).then((answer) => {
                      if (answer.value) {
                        duplicateMapper(mapper_id);
                      }
                    })
                  }
                  color="purple"
                />,
                <CustomButton
                  color="red"
                  label={"Delete "}
                  action={() =>
                    MySwal.fire({
                      title: "Are you sure?",
                      html: "<p>You are about to delete selected mapper!</p>",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "green",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Confirm",
                    }).then((answer) => {
                      if (answer.value) {
                        MySwal.fire({
                          title: "Last chance!",
                          html: "<p>You won't be able to revert this!</p>",
                          showCancelButton: "true",
                          showConfirmButton: "true",
                          confirmButtonColor: "green",
                          cancelButtonColor: "red",
                          confirmButtonText: "Yes, delete it!",
                        }).then((ans) => {
                          if (ans.value) {
                            deleteMapper(mapper_id);
                          }
                        });
                      }
                    })
                  }
                />,
                <CustomButton
                  color="green"
                  label={"Use"}
                  action={() => activateMapper(mapper_id)}
                />
              )
            );
            i++;
          }
        }

        setMappersList(mappers_list);
        setMappersLoaded(true);
        setLoading(false);
      })
      .catch(function (error) {
        handleHttpError(error);
        setCrashing(true);
      });
  }, [mappersUpdated]);

  const deleteMapper = (mapper_id) => {
    setMappersUpdated(false);
    axios({
      method: "delete",
      url: "/api/v1/conversion/mappers/" + mapper_id,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((res) => {
        toast.success("Mapper successfully deleted.");
        setMappersUpdated(true);
      })
      .catch((err) => {
        handleHttpError(err);
      });
  };

  if (mappersLoaded) {
    return (
      <Container>
        <Header />

        <Grid container className={classes.root} justify="center" spacing={3}>
          <Grid item xs={12}>
            <div className="title-container">
              <h1 className={classes.purple}>{props.journey} Mapper Manager</h1>
            </div>
          </Grid>
          <Grid item xs={10}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Mappers</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mappersList.map((template) => (
                    <StyledTableRow key={template.Template}>
                      <StyledTableCell className={classes.templateName}>
                        {template.Template}
                      </StyledTableCell>
                      <StyledTableCell>{template.Edit}</StyledTableCell>
                      <StyledTableCell>{template.Duplicate}</StyledTableCell>
                      <StyledTableCell>{template.Delete}</StyledTableCell>
                      <StyledTableCell>{template.Use}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                  <StyledTableRow>
                    <StyledTableCell colSpan={5}>
                      <CustomButton
                        label={"Create new mapper"}
                        color="purple"
                        action={() => goToMapper("create")}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <ToastContainer />
          </Grid>
        </Grid>
        <Footer />
      </Container>
    );
  }
  if (isCrashing) {
    return (
      <Container>
        <Header />
        <Grid container className={classes.root} justify="center" spacing={3}>
          <div className="App">
            There seems to be an error receiving mappers. Contact the
            development team if this issue persists.
          </div>
        </Grid>
        <Footer />
      </Container>
    );
  }
  if (isLoading) {
    return (
      <Container>
        <Header />

        <Grid container className={classes.root} justify="center" spacing={3}>
          <div className="App">Loading...</div>
        </Grid>
        <Footer />
      </Container>
    );
  }
}
