import { Space, Descriptions, Progress, Result, Typography } from "antd"
import React, { FC, useEffect, useRef, useState } from "react"
import { NgdsErrorType } from "../../../../types/NgdsErrorType"

const { Text } = Typography

interface Descriptor {
    key: string
    label: string
    value: any
}

interface Progressor {
    key: string
    text: string
    loading: boolean
    success: boolean
    exception: NgdsErrorType | boolean
    dependOn: boolean
}
interface progressParamsType {
    descriptors: Descriptor[]
    progressors: Progressor[]
}


interface CountUpProgressTypes {
    text: string
    loading: boolean
    success: boolean
    exception: NgdsErrorType | boolean
    dependOn: boolean
}

const CountUpProgress: FC<CountUpProgressTypes> = ({ text, dependOn, loading, exception, success }) => {

    // CountUp START
    const randomStart = Math.floor(Math.random() * 55)
    const [countUp, setCountUp] = useState(randomStart);
    let timer = useRef(undefined);
    useEffect(() => {
        if (!timer.current && loading) {
            timer.current = setInterval(() => {
                setCountUp((prev) => {
                    if (prev < 100) return prev + 1;
                    // if (prev === 99) clearInterval(timer.current);
                    return prev;
                });
            }, 1000);
        }
    }, [loading]);
    // CountUp END

    return (
        <>
            <Text>{text}</Text>
            <Progress
                percent={!dependOn ? 0 : loading ? countUp : 100}
                status={loading ? "active" : exception ? "exception" : success ? "success" : undefined}
            />
        </>
    )
}



export const LoadingProgress: FC<progressParamsType> = (props) => {

    return <Space direction='vertical' size='large' wrap style={{ width: "100%" }}>

        <Descriptions bordered size='small' column={1}>
            {
                props.descriptors.map(param =>
                    <Descriptions.Item key={param.value} label={param.label}><Text>{param.value}</Text></Descriptions.Item>
                )
            }
        </Descriptions>

        {
            props.progressors.map(param => {
                const key = param.key
                const text = param.text
                const loading = param.loading
                const exception = param.exception
                const success = param.success
                const dependOn = param.dependOn
                return <div key={key} >
                    <CountUpProgress text={text} dependOn={dependOn} loading={loading} exception={exception} success={success} />
                </div>
            }
            )
        }
    </Space >
}

export default LoadingProgress