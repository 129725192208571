import portAssignment from "./portAssignment";

export default function uplinksAssignment(correlation) {
  const coreSwitchNames = correlation
    .filter((sw) => sw.new_hostname.includes("mcs"))
    .map((sw) => sw.new_hostname);

  const accessSwicthes = correlation
    .filter((sw) => sw.new_hostname.includes("mas"))
    .map((sw) => sw.new_hostname);

  const primaryLinkEntry = {
    hostname: "",
    toPort: 1,
    toMode: "trunk",
    toVlan: "",
    toDevice: coreSwitchNames[0],
    toNote: "to MX",
    isMapped: true,
  };

  const secondaryLinkEntry = {
    hostname: "",
    toPort: 2,
    toMode: "trunk",
    toVlan: "",
    toDevice: coreSwitchNames[0],
    toNote: "Reserved",
    isMapped: true,
  };

  const accessUplink = (accessSwitch) => {
    return {
      hostname: "",
      toPort: 1,
      toMode: "trunk",
      toVlan: "",
      toDevice: accessSwitch,
      toNote: `to Core - ${coreSwitchNames[0]} `,
    };
  };

  for (const device of correlation) {
    // For Core Switches
    if (device.new_hostname.includes("mcs")) {
      device.port_mapping.unshift(secondaryLinkEntry);
      device.port_mapping.unshift(primaryLinkEntry);
      portAssignment(device, 1);
      portAssignment(device, 2);

      // Create Downlinks ports
      let downlinkPort = 3; //index 2 is the third port
      for (const mas of accessSwicthes) {
        device.port_mapping.splice(downlinkPort - 1, 0, {
          hostname: "",
          name: "",
          toPort: downlinkPort,
          toMode: "trunk",
          toVlan: "",
          toDevice: device.new_hostname,
          toNote: `to MAS - ${mas}`,
          isMapped: true,
        });
        portAssignment(device, downlinkPort);
        downlinkPort += 1;
      }

      // For Access Switches
    } else if (device.new_hostname.includes("mas")) {
      device.port_mapping.unshift(accessUplink(device.new_hostname));
      portAssignment(device, 1);
    }

    // Check if there are ports with uplink statement and mark as mapped
    for (const port of device.port_mapping) {
      // Cofigures first port of Core for MX connection
      if (port.name?.includes("LINK") || port.name?.includes("BOOTS")) {
        port["isMapped"] = true;
      }
    }
  }
}
