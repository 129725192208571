import React, { useEffect, useState } from "react";
import { exportAsExcel } from "../../../../utils/exportAsExcel";
import { useTables } from "../../../../contexts/TablesContext";
import { useMerakiConnectors } from "../../../../contexts/Connectors/MerakiContext";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button, Drawer, Flex, Select } from "antd";
import {
  MerakiProductsType,
  MerakiNetworksType,
} from "../../../../types/MerakiConnectorsType";
import type { SelectProps } from 'antd';


const ExportToExcel = () => {
  const tables = useTables();
  const selectedIds = tables.selectedTablesRowId;

  const meraki = useMerakiConnectors();
  const organizations = meraki.organizations;
  const orgId = meraki.orgId;
  const networks = meraki.networks;
  const products = meraki.products;
  const productsDetails = meraki.productsDetails || {};
  const loadingProductsDetails = meraki.loadingProductsDetails

  const settings = meraki.settings
  const loadingSettings = meraki.loadingSettings
  const fetchSettings = meraki.fetchSettings
  const fetchProductsDetails = meraki.fetchProductsDetails
  const errorProductsDetails = meraki.errorProductsDetails

  useEffect(() => {
    if (!settings && !loadingSettings) fetchSettings && fetchSettings()
  }, [])

  const [operation_list, setOperationList] = useState([])

  const getProductsDetails = async (network_ids, operation_list) => {
    const isOk = await fetchProductsDetails(network_ids, operation_list)
    isOk ? console.log({ "productsDetails": productsDetails }) : console.log({ "ErrorDetails": errorProductsDetails })
  }

  const [loadingExcel, setLoadingExcel] = useState(false)

  const network_ids = selectedIds.Networks || { Networks: [] };

  const exportToExcel = async () => {
    setLoadingExcel(true)

    const organization = organizations.filter((org) => +org.id === +orgId);
    const networksSelected = networks.filter((net: MerakiNetworksType) =>
      network_ids?.includes(net.id)
    );
    const productsSelected = products.filter((dev: MerakiProductsType) =>
      network_ids?.includes(dev.networkId)
    );

    console.log({
      selectedIds,
      networksSelected,
      products,
      productsSelected,
    });

    // await getProductsDetails(network_ids, operation_list)

    const isCreated = await exportAsExcel({
      networks: networksSelected,
      products: productsSelected,
      ...productsDetails,
      organization,
    });
    isCreated ? console.log("File Downloaded") : console.log("Failed Download");


    setLoadingExcel(false)
  };

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const options: SelectProps['options'] = settings?.map((set: any) => ({
    label: set.display_name,
    value: set.tab_name
  }));

  const handleChange = (value: string[]) => {
    setOperationList(value)
    console.log(`selected ${value}`);
  };




  return (
    <>

      <Button
        disabled={selectedIds.Networks?.length === 0}
        size="small"
        icon={<CloudDownloadOutlined />}
        onClick={showDrawer}

      >
        Export to Excel
      </Button>

      <Drawer size='large' title="Export to Excel" onClose={onClose} open={open}>
        <Flex vertical gap='middle'>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
            onChange={handleChange}
            options={options}
          />
          <Button disabled={operation_list.length === 0} loading={loadingProductsDetails} onClick={() => getProductsDetails(network_ids, operation_list)}>
            Get Details
          </Button>
          <Button
            // disabled={Object.keys(productsDetails).length === 0}
            loading={loadingExcel} type="primary" onClick={() => exportToExcel()}>
            Export
          </Button>
        </Flex>
      </Drawer>






    </>
  );
};

export default ExportToExcel;
