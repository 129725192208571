import { useEffect, useState } from "react";
import Setup from "./setup.json";
import axios from "axios";
import Cookies from "universal-cookie";

//gets the user token from cookies
const cookies = new Cookies();
const access_token = cookies.get("access_token");

// creates the reusebale axios fetch with base URL + headers
export const axiosFetch = axios.create({
  baseURL: "api/v1/",
  headers: { Authorization: "Bearer " + access_token },
});

export function useTemplates(libraryName = Setup.libraryName) {
  const [libraryId, setLibraryId] = useState();
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axiosFetch
      .get(`libraries`)
      .then((response) => {
        const libraries_response = response.data;
        setLibraryId(
          libraries_response
            .filter((library) => library.name === libraryName)
            .map((library) => library.id)[0]
        );
      })
      .catch((err) => {
        setError(err);
        setHasError(true);
      });
  }, []);

  useEffect(() => {
    if (libraryId) {
      axiosFetch
        .get(`libraries/${libraryId}/templates`)
        .then((response) => {
          setTemplates(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setHasError(true);
        });
    }
  }, [libraryId]);

  return [templates, isLoading, hasError, error];
}

// export function useSiteList(accountId) {
//   const [sitesList, setSitesList] = useState({});
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState(false);
//   const [hasError, setHasError] = useState(false);

//   useEffect(() => {
//     axiosFetch
//       .get(`autodata/accounts/${accountId}/sites`)
//       .then((response) => {
//         setSitesList(response.data);
//         setIsLoading(false);
//       })
//       .catch((err) => {
//         setError(err);
//         setHasError(true);
//       });
//   }, [accountId]);

//   return [sitesList, isLoading, hasError, error];
// }

export function useSiteTypes(accountId) {
  const [sitesTypes, setSitesTypes] = useState([]);
  const [siteProducts, setSiteProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    axiosFetch
      .get(`sitetypes?account_id=${accountId}`)
      .then((response) => {
        setSitesTypes(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setHasError(true);
      });
  }, [accountId]);

  useEffect(() => {
    if (sitesTypes) {
      //Creates a fetch list for the products id in siteTypeSelectedIds
      const fetchList = sitesTypes.map((type) =>
        axiosFetch.get(`sitetypes/${type.id}`)
      );
      axios
        .all(fetchList)
        .then(
          axios.spread((...responses) => {
            setSiteProducts(responses.map((response) => response.data));
            setIsLoading(false);
          })
        )
        .catch((err) => {
          setError(err);
          setHasError(true);
        });
    }
  }, [sitesTypes]);

  return [siteProducts, isLoading, hasError, error];
}

export function useLegacySwitches(
  sitesIds,
  legacyIdentifier = Setup.legacySwitches
) {
  const [legacySwicthes, setLegacySwitches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(false);

  console.log(legacySwicthes, error);

  useEffect(() => {
    const fetchInventory = sitesIds.map((id) =>
      axiosFetch.get(`autodata/sites/${id}/inventory`)
    );

    axios
      .all(fetchInventory)
      .then(
        axios.spread((...responses) => {
          const inventory_response = responses.map((response) => response.data);

          // now filters up by devices that has a legacyIdentifier in the name, example:  -las- for WBA
          //it is for now set up only for WBA naming convention for access-switches..
          const devices = inventory_response.map((item) => item.devices);

          const target_devices = [];
          for (const device of devices) {
            for (const item of device) {
              if (item.hostname && item.hostname.includes(legacyIdentifier)) {
                target_devices.push(item);
              }
            }
          }
          setLegacySwitches(target_devices);
          setIsLoading(false);
        })
      )
      .catch((err) => {
        setError(err);
        setIsLoading(false);
        setHasError(true);
      });
  }, [sitesIds, legacyIdentifier]);

  return [legacySwicthes, isLoading, hasError, error];
}

// export function useInventory(siteId, legacyIdentifier = Setup.legacySwitches) {
//   const [legacySwicthes, setLegacySwitches] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [hasError, setHasError] = useState(false);
//   const [error, setError] = useState(false);

//   useEffect(() => {
//     axiosFetch
//       .get(`autodata/sites/${siteId}/inventory`)
//       .then((response) => {
//         setLegacySwitches(
//           response.data.devices.filter((device) =>
//             device.hostname.includes(legacyIdentifier)
//           )
//         );
//         setIsLoading(false);
//       })
//       .catch((err) => {
//         setError(err);
//         setHasError(true);
//       });

//     return () => {
//       setLegacySwitches([]);
//       setIsLoading(true);
//       setHasError(false);
//       setError(false);
//     };
//   }, [siteId]);

//   return [legacySwicthes, isLoading, hasError, error];
// }
