import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
//material ui components
import Container from "@material-ui/core/Container";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { handleHttpError } from "../../common/utils.js";
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CustomButton from "../../components/Button/CustomButton.js";

//swal
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

//toaster components
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "80px",
  },
  purple: {
    color: "rgb(85, 20, 180)",
    paddingBottom: 10,
  },
  templateName: {
    fontSize: "small",
  },
}));

function createData(Wizardstore, Rename, Delete, Upload) {
  return { Wizardstore, Rename, Delete, Upload };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "black",
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function WizardstoreManagePage(props) {
  const classes = useStyles();
  const history = useHistory();
  const cookies = new Cookies();
  const scopes = cookies.get("scopes");
  const [isLoading, setLoading] = useState(true);
  const [isCrashing, setCrashing] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const MySwal = withReactContent(Swal);
  const [wizardstoresList, setWizardstoresList] = useState(null);

  const uploadWizardstore = () => {
    var bodyFormData = new FormData();
    let new_wizardstore = document.getElementById("new_wizardstore").files[0];
    let wizardtype = document.getElementById("wizardtype").value;
    let description = document.getElementById("description").value;
    bodyFormData.append("yaml", new_wizardstore);
    bodyFormData.append("wizardtype", wizardtype);
    bodyFormData.append("description", description);
    axios({
      method: "post",
      url: "/api/v1/wizardstores",
      data: bodyFormData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        toast.success("Wizardstore successfully uploaded..");
        window.location.reload();
      })
      .catch((err) => {
        handleHttpError(err);
      });
  };

  const updateWizardstore = (wizardstore_id) => {
    var bodyFormData = new FormData();
    let replace_wizardstore = document.getElementById("replace_wizardstore")
      .files[0];
    let wizardtype = document.getElementById("rwizardtype").value;
    let description = document.getElementById("rdescription").value;
    bodyFormData.append("yaml", replace_wizardstore);
    bodyFormData.append("wizardtype", wizardtype);
    bodyFormData.append("description", description);
    axios({
      method: "put",
      url: "/api/v1/wizardstores/" + wizardstore_id,
      data: bodyFormData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        toast.success("Wizardstore successfully updated.");
        window.location.reload();
      })
      .catch((err) => {
        handleHttpError(err);
      });
  };

  const uploadToWizardstore = (wizardstore_id) => {
    var bodyFormData = new FormData();
    let file = document.getElementById("new_").files[0];
    let description = document.getElementById("tdescription").value;
    bodyFormData.append("yaml", file);
    bodyFormData.append("description", description);
    axios({
      method: "post",
      url: "/api/v1/wizardstores/" + wizardstore_id + "/gui_templates",
      data: bodyFormData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        toast.success("Successfully added.");
        window.location.reload();
      })
      .catch((err) => {
        handleHttpError(err);
      });
  };

  const deleteWizardstore = (wizardstore_id) => {
    axios({
      method: "delete",
      url: "/api/v1/wizardstores/" + wizardstore_id,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((res) => {
        toast.success("Wizardstore successfully delete.");
        window.location.reload();
      })
      .catch((err) => {
        handleHttpError(err);
      });
  };

  useEffect(() => {
    const wizardstores_list = [];
    if (scopes.includes("admin")) {
      setHasAccess(true);
      axios
        .get(`/api/v1/wizardstores`, {
          headers: { Authorization: "Bearer " + props.access_token },
        })
        .then((response) => {
          const wizardstores = response.data;

          for (let wizardstore of wizardstores) {
            const wizardstore_name = wizardstore.description;
            const wizardstore_id = wizardstore.id;
            wizardstores_list.push(
              createData(
                wizardstore_name,
                <CustomButton
                  label={"Replace"}
                  color="purple"
                  action={() =>
                    MySwal.fire({
                      title: "Are you sure?",
                      html:
                        "You are about to replace <b>" +
                        wizardstore_name +
                        "</b>",
                      icon: "warning",
                      width: 500,
                      showCancelButton: true,
                      confirmButtonColor: "green",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Continue",
                    }).then((result) => {
                      if (result.value) {
                        MySwal.fire({
                          title: "File Upload",
                          html: `<br><input style='padding-left:70px' accept='.yaml,.yml' type='file' id='replace_wizardstore' name='replace_wizardstore'/>
                            <select  class="swal2-select" name="rwizardtype" id="rwizardtype">
                            <option value="runbook">Runbook</option>
                            <option value="config">Config</option>
                          </select>
                            <input class="swal2-input" id="rdescription" placeholder="wizard store description" />`,
                          showCancelButton: "true",
                          showConfirmButton: "true",
                          confirmButtonColor: "green",
                          cancelButtonColor: "red",
                          confirmButtonText: "Yes, update wizardstore!",
                        }).then((res) => {
                          if (res.value) {
                            updateWizardstore(wizardstore_id);
                          }
                        });
                      }
                    })
                  }
                />,
                <CustomButton
                  label={"DELETE"}
                  color="purple"
                  action={() =>
                    MySwal.fire({
                      title: "Are you sure?",
                      html:
                        "You are about to delete <b>" +
                        wizardstore_name +
                        "</b>",
                      icon: "warning",
                      width: 500,
                      showCancelButton: true,
                      confirmButtonColor: "green",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Continue",
                    }).then((result) => {
                      if (result.value) {
                        MySwal.fire({
                          title: "Last chance!",
                          html: "You won't be able to revert this.",
                          showCancelButton: "true",
                          showConfirmButton: "true",
                          confirmButtonColor: "green",
                          cancelButtonColor: "red",
                          confirmButtonText: "Yes, delete wizardstore!",
                        }).then((res) => {
                          if (res.value) {
                            deleteWizardstore(wizardstore_id);
                          }
                        });
                      }
                    })
                  }
                />,
                <CustomButton
                  label={"ADD A GUI TEMPLATE"}
                  color="purple"
                  action={() =>
                    MySwal.fire({
                      title: `Upload new GUI template to ${wizardstore_name} Wizardstore?`,
                      html: "You are about to upload a new file to the wizardstore.",
                      icon: "warning",
                      width: 500,
                      showCancelButton: true,
                      confirmButtonColor: "green",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Continue",
                    }).then((result) => {
                      if (result.value) {
                        MySwal.fire({
                          title: "File Upload",
                          html: `<br><input style='padding-left:70px' accept='.xlsx,.yml.yaml' type='file' id='new_' name='new_'/>
                                  <input class="swal2-input" id="tdescription" placeholder="GUI Template description" />`,
                          showCancelButton: "true",
                          showConfirmButton: "true",
                          confirmButtonColor: "green",
                          cancelButtonColor: "red",
                          confirmButtonText: "Yes, upload!",
                        }).then((res) => {
                          if (res.value) {
                            uploadToWizardstore(wizardstore_id);
                          }
                        });
                      }
                    })
                  }
                />
              )
            );
          }
          setWizardstoresList(wizardstores_list);
          setLoading(false);
        })
        .catch(function (error) {
          handleHttpError(error);
          setCrashing(true);
        });
    } else {
      setLoading(false);
    }
  }, []);

  if (isCrashing) {
    return (
      <Container>
        <Header />
        <Grid container className={classes.root} justify="center" spacing={3}>
          <div className="App">
            There seems to be an error receiving wizardstores. Contact the
            development team if this issue persists.
          </div>
        </Grid>
        <Footer />
      </Container>
    );
  }

  if (isLoading) {
    return (
      <Container>
        <Header />
        <Grid container className={classes.root} justify="center" spacing={3}>
          <div className="App">Loading...</div>
        </Grid>
        <Footer />
      </Container>
    );
  }
  if (hasAccess) {
    return (
      <Container>
        <Header />
        <Grid container className={classes.root} justify="center" spacing={3}>
          <Grid item xs={12}>
            <div className="title-container">
              <h1>Wizardstore Management</h1>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Container>
              <Grid
                container
                className={classes.root}
                justify="center"
                spacing={3}
              >
                <Grid item xs={10}>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Wizardstores</StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {wizardstoresList.map((wizardstore) => (
                          <StyledTableRow key={wizardstore.Wizardstore}>
                            <StyledTableCell className={classes.templateName}>
                              {wizardstore.Wizardstore}
                            </StyledTableCell>
                            <StyledTableCell>
                              {wizardstore.Rename}
                            </StyledTableCell>
                            <StyledTableCell>
                              {wizardstore.Delete}
                            </StyledTableCell>
                            <StyledTableCell>
                              {wizardstore.Upload}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                      <StyledTableRow>
                        <StyledTableCell colSpan={4}>
                          <CustomButton
                            label={"Upload new wizardstore"}
                            color="green"
                            action={() =>
                              MySwal.fire({
                                title: "New Wizardstore File Upload",
                                html: `<br><input style='padding-left:70px' accept='.yaml,.yml' type='file' id='new_wizardstore' name='new_wizardstore'/><br/>
                                       <select  class="swal2-select" name="wizardtype" id="wizardtype">
                                        <option value="runbook">Runbook</option>
                                        <option value="config">Config</option>
                                      </select>
                                       <input class="swal2-input" id="description" placeholder="wizard store description" />`,
                                showCancelButton: "true",
                                showConfirmButton: "true",
                                confirmButtonColor: "green",
                                cancelButtonColor: "red",
                                confirmButtonText: "Upload new wizardstore!",
                              }).then((res) => {
                                if (res.value) {
                                  uploadWizardstore();
                                }
                              })
                            }
                          />
                          ,
                          <CustomButton
                            label={"manage gui_templates"}
                            color="purple"
                            action={() => history.push("/gui_template_manager")}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    </Table>
                  </TableContainer>
                  <ToastContainer />
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
        <Footer />
      </Container>
    );
  } else {
    return (
      <Container>
        <Header />
        <Grid container className={classes.root} justify="center" spacing={3}>
          <div className="App">
            You do not have access to this feature. Contact the development team
            if you believe this is not correct.
          </div>
        </Grid>
        <Footer />
      </Container>
    );
  }
}
