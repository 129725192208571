import axios from "axios";
import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core";
import Cookies from "universal-cookie";

// material UI components
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import FormHelperText from "@material-ui/core/FormHelperText";

import { handleHttpError, dynamicSort } from "../../common/utils.js";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setStoreAccount } from "../../features/account";

import { useLocalStorage } from "../../modern/hooks/useLocalStorage";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    paddingTop: 10,
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      backgroundColor: theme.palette.background.paper,
    },
    color: "black",
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  helpertext: {
    color: "white",
  },
  inputlabel: {
    color: "white",
    textAlign: "center",
  },
}));

export default function AccountSelector() {
  const { setStorageItem, getStorageItem } = useLocalStorage();
  const accountState = useSelector((state) => state.account.value);

  const classes = useStyles();
  const cookies = new Cookies();
  const access_token = cookies.get("access_token");
  const dispatch = useDispatch();

  const currentAccount = JSON.parse(localStorage.getItem("currentAccount"));
  const account_id = currentAccount?.id;

  const [accountsData, setAccountsData] = useState([]);

  const setAccount = (account) => {
    console.log("account selected", account);
    cookies.set("account_id", account.id, { path: "/" });
    cookies.set("account_name", account.name, { path: "/" });
    setStorageItem("currentAccount", {
      id: account.id,
      name: account.name,
    });
    dispatch(setStoreAccount({ id: account.id, name: account.name }));
  };

  // Parse user accounts
  const user = JSON.parse(localStorage.getItem("user"));
  const userAccounts = JSON.parse(user.accounts).sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const firstAccount = userAccounts[0];

  function validateCurrentAccount() {
    // Retrieves currentAccount from localStorage
    const currentAccount = JSON.parse(localStorage.getItem("currentAccount"));
    console.log("currentAccount", currentAccount);

    // Check if user accounts are empty
    if (userAccounts.length === 0) {
      console.log("User has no account");
      // temporary set as None, but user should request an account
      setAccount({ id: "None", name: "None" });
    }
    // Check if it's user single account
    else if (userAccounts.length === 1) {
      console.log("User has single account");
      setAccount({ id: firstAccount.id, name: firstAccount.name });
    }
    // Check if user accounts is set up already and it is None
    else if (currentAccount?.name === "None") {
      setAccount({ id: firstAccount.id, name: firstAccount.name });
    }
  }

  useEffect(() => {
    validateCurrentAccount();
  }, [account_id]);

  const handleChange = (event) => {
    const index = event.target.options["selectedIndex"];
    const accountName = event.target.options[index]["outerText"];
    const account_id = event.target.value;
    setAccount({
      id: account_id,
      name: accountName,
    });
  };

  useEffect(() => {
    axios
      .get(`/oauth2/accounts`, {
        headers: { Authorization: "Bearer " + access_token },
      })
      .then((response) => {
        const accountsFresh = response.data;
        cookies.set("accounts", accountsFresh, { path: "/" });
        const user = JSON.parse(localStorage.getItem("user"));
        user.accounts = JSON.stringify(accountsFresh);
        localStorage.setItem("user", JSON.stringify(user));
        validateCurrentAccount();
        const optionsPayload = [];
        if (accountsFresh.length === 0) {
          optionsPayload.push({ value: "None", name: "None" });
        } else {
          for (let entry of accountsFresh) {
            const accountName = entry.name;
            const accountID = entry.id;
            optionsPayload.push({ value: accountID, name: accountName });
          }
          optionsPayload.sort(dynamicSort("name"));
        }
        setAccountsData(optionsPayload);
      })
      .catch(function (error) {
        handleHttpError(error);
      });
  }, [access_token]);

  return (
    <FormControl style={{ marginTop: 10, minWidth: 120 }}>
      <NativeSelect
        id="demo-customized-select-native"
        value={account_id}
        fullWidth
        onChange={handleChange}
        input={<BootstrapInput />}
      >
        {accountsData?.map((e, key) => {
          if (e.value)
            return (
              <option value={e.value} key={e.value}>
                {e.name}
              </option>
            );
        })}
      </NativeSelect>
      <FormHelperText className={classes.inputlabel}>
        Selected Account
      </FormHelperText>
    </FormControl>
  );
}
