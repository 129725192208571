import React, { useContext, useState, useEffect, useRef } from "react";
import { Table, Input, InputRef, Form, Button, Upload, Tabs } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { read, utils, writeFile, WorkBook, WorkSheet } from "xlsx";

const { TabPane } = Tabs;

type RowData = {
  [key: string]: any;
};

const EditableContext = React.createContext<any>(null);

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell: React.FC<any> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[{ required: true, message: `${title} is required.` }]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const App: React.FC = () => {
  const [sheets, setSheets] = useState<{
    [key: string]: { columns: any[]; rows: RowData[] };
  }>({});

  const handleFileUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target?.result as ArrayBuffer);
      const workbook: WorkBook = read(data, { type: "array" });
      const newSheets: { [key: string]: { columns: any[]; rows: RowData[] } } =
        {};

      workbook.SheetNames.forEach((sheetName) => {
        const worksheet: WorkSheet = workbook.Sheets[sheetName];
        const json: any[][] = utils.sheet_to_json(worksheet, { header: 1 });

        const columns = json[0].map((col, index) => ({
          title: col,
          dataIndex: index.toString(),
          key: index.toString(),
          editable: true,
        }));

        const rows = json.slice(1).map((row, rowIndex) => {
          const rowData: RowData = { key: rowIndex.toString() };
          row.forEach((cell, cellIndex) => {
            rowData[cellIndex.toString()] = cell;
          });
          return rowData;
        });

        newSheets[sheetName] = { columns, rows };
      });

      setSheets(newSheets);
    };
    reader.readAsArrayBuffer(file);
    return false;
  };

  const handleSave = (sheetName: string, row: RowData) => {
    const newSheets = { ...sheets };
    const newData = [...newSheets[sheetName].rows];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    newSheets[sheetName].rows = newData;
    setSheets(newSheets);
  };

  const handleExport = () => {
    const workbook: WorkBook = utils.book_new();
    Object.keys(sheets).forEach((sheetName) => {
      const worksheet: WorkSheet = utils.json_to_sheet(sheets[sheetName].rows);
      utils.book_append_sheet(workbook, worksheet, sheetName);
    });
    writeFile(workbook, "export.xlsx");
  };

  const renderTable = (sheetName: string) => {
    const { columns, rows } = sheets[sheetName];
    const mergedColumns = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: RowData) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: (row: RowData) => handleSave(sheetName, row),
        }),
      };
    });

    return (
      <Table
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        dataSource={rows}
        pagination={false}
        style={{ width: "100%" }}
      />
    );
  };

  return (
    <div style={{ padding: "20px" }}>
      <Upload beforeUpload={handleFileUpload} showUploadList={false}>
        <Button icon={<UploadOutlined />}>Upload Excel File</Button>
      </Upload>
      <Tabs>
        {Object.keys(sheets).map((sheetName) => (
          <TabPane tab={sheetName} key={sheetName}>
            {renderTable(sheetName)}
          </TabPane>
        ))}
      </Tabs>
      <Button
        type="primary"
        onClick={handleExport}
        style={{ marginTop: "20px" }}
      >
        Export to Excel
      </Button>
    </div>
  );
};

export default App;
