import portAssignment from "./portAssignment";

const assignNextFree = (item) => {
  const nextFree = item.port_assignment
    .map((entry) => !entry.isAssigned && entry.port)
    .filter((entry) => entry)[0];
  if (nextFree) {
    portAssignment(item, nextFree);
  } else {
    return "unavailable";
  }
  return nextFree;
};

export default function otherPortsAssigment(correlation) {
  for (const device of correlation) {
    const allOtherPorts = device.port_mapping
      .filter(
        (item) =>
          !item.isMapped && item.status === "connected" && item !== false
      )
      .map((entry) => (!entry.name ? { ...entry, name: "" } : { ...entry }));

    const otherPorts = allOtherPorts.map((entry) => ({
      ...entry,
      toPort: assignNextFree(device),
      toDevice: device.new_hostname,
      toMode: entry.vlan === "trunk" ? "trunk" : "access",
      toVlan: entry.vlan === "1" ? "30" : entry.vlan,
      toNote: entry.name,
    }));

    for (const [index, port] of device.port_mapping.entries()) {
      if (otherPorts.some((other) => other.port === port.port)) {
        device.port_mapping[index] = otherPorts.filter(
          (other) => other.port === port.port
        )[0];
      }
    }
  }
}
