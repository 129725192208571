import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CustomButton from "../../components/Button/CustomButton.js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "100px",
    flexGrow: 1,
    marginBottom: "80px",
    textAlign: "center",
  },

  mystyle: {
    color: "rgb(85, 20, 180)",
  },
  inputheader: {
    paddingRight: "30px",
    fontSize: "large",
    fontWeight: "bold",
  },
  inputfile: {
    paddingLeft: "right",
    backgroundColor: "rgb(85, 20, 180)",
    color: "white",
    borderRadius: "3px",
  },
  headergrid: {
    textAlign: "right",
  },
  button: {
    paddingTop: "30px",
  },
}));

export default function RFStatsPage(props) {
  const history = useHistory();
  const classes = useStyles();
  let [isLoading, setLoading] = useState(false);
  const [access, setAccess] = useState("");
  const [siteFile, setSiteFile] = useState(false);
  const [clientFile, setClientFile] = useState(false);
  const [apFile, setApFile] = useState(false);
  const toastId = React.useRef(null);

  const toastError = (message) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(message);
    }
  };
  const toastSuccess = (message) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.success(message);
    }
  };

  useEffect(() => {
    const scopes = cookies.get("scopes");
    if (scopes !== undefined) {
      if (scopes.includes("wlan_user")) {
        setAccess(true);
      }
    } else {
      setAccess(false);
    }
  }, []);

  function downloadStats() {
    setLoading(true);

    var fileDownload = require("js-file-download");
    var bodyFormData = new FormData();
    let client_file = document.getElementById("client_file").files[0];
    let site_file = document.getElementById("site_file").files[0];
    let ap_file = document.getElementById("ap_file").files[0];
    let access_token = "Bearer " + props.access_token;
    bodyFormData.append("sitefile", site_file);
    bodyFormData.append("clientfile", client_file);
    bodyFormData.append("apfile", ap_file);
    axios({
      method: "post",
      url: "/api/v1/wlan/pistat",
      data: bodyFormData,
      onUploadProgress: function (progressEvent) {
        console.log("loaded", progressEvent.loaded);
        console.log("download", progressEvent);
      },
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: access_token,
        Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
    })
      .then((res) => {
        setLoading(false);
        fileDownload(res.data, "rf_stats.xlsx");
        toastSuccess("Results successfully generated!");
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 401) {
          history.push("/login");
        } else if (err.response.status === 400) {
          var reader = new FileReader();
          reader.onload = function () {
            const error = JSON.parse(reader.result);
            toastError(error["error"]);
          };
          reader.readAsText(err.response.data);
        } else {
          toastError("Error with input file!");
          throw err;
        }
      });
  }
  function siteFileSelected() {
    setSiteFile(true);
  }
  function clientFileSelected() {
    setClientFile(true);
  }
  function apFileSelected() {
    setApFile(true);
  }

  if (access) {
    return (
      <Container>
        <Header />
        <Grid container className={classes.root} justify="center" spacing={3}>
          <Grid item xs={12}>
            <div className="title-container">
              <h1 className={classes.mystyle}>RF Stats</h1>
            </div>
            <h2>Let's generate a RF Stats file!</h2>
          </Grid>
          {isLoading ? (
            <Grid item xs={12}>
              <Loader text="A N A L Y S I N G" />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <h3 className={classes.mystyle}>STEP 1: Select Site File</h3>
            <input
              className={classes.inputfile}
              accept=".xlsx"
              type="file"
              id="site_file"
              name="site_file"
              onChange={() => siteFileSelected()}
            />
          </Grid>
          {siteFile ? (
            <Grid item xs={12}>
              <h3 className={classes.mystyle}>STEP 2: Select Client File</h3>
              <input
                className={classes.inputfile}
                accept=".xlsx"
                type="file"
                id="client_file"
                name="client_file"
                onChange={() => clientFileSelected()}
              />
            </Grid>
          ) : (
            <div></div>
          )}
          {clientFile ? (
            <Grid item xs={12}>
              <h3 className={classes.mystyle}>STEP 3: Select AP File</h3>
              <input
                className={classes.inputfile}
                accept=".xlsx"
                type="file"
                id="ap_file"
                name="ap_file"
                onChange={() => apFileSelected()}
              />
            </Grid>
          ) : (
            <div></div>
          )}
          {apFile && siteFile && clientFile ? (
            <Grid item xs={12}>
              <div className={classes.button}>
                <CustomButton
                  className={classes.upload_button}
                  label={"Generate Stats"}
                  color="purple"
                  action={() => downloadStats()}
                />
              </div>
            </Grid>
          ) : (
            <div></div>
          )}
        </Grid>
        <ToastContainer />
        <Footer />
      </Container>
    );
  }
  if (!access) {
    return (
      <Container>
        <Header />
        <Grid container className={classes.root} justify="center" spacing={3}>
          <div className="App">
            <br />
            You do not have access to RF Stats, please <a href="/access_request">click here to request access</a> and
            apply for <strong>wlan_user</strong> if you do.
          </div>
        </Grid>
        <Footer />
      </Container>
    );
  }
}
