import { useState, useEffect } from "react";
import axios, { AxiosResponse } from "axios";

// Define your data type here
interface DataType {
  // Define your data structure here
  // For example:
  // name: string;
  // age: number;
  // address: string;
}

// Testing temp...

// Gets from local Storage
const user = JSON.parse(localStorage.getItem("user"));
const account = JSON.parse(localStorage.getItem("currentAccount"));
const account_id = account?.id;
const access_token = user?.access_token;

export const fetchData = async (action: string, params: any) => {
  const output = {
    data: null,
    error: null,
  };

  const url = "/api/v1/api_connectors";
  const body = {
    user: {
      ein: user?.uin,
      fullname: user?.fullname,
      account,
    },
    account_id: +account_id,
    connector: "bfg",
    params,
    action,
  };

  const start = performance.now();
  console.log(
    `Started ${body.connector}: "${body.action}" for account: ${account.name}(${account.id}))`
  );
  await axios
    .post(url, body, {
      headers: { Authorization: "Bearer " + access_token },
    })
    .then((response: any) => {
      output.data = response.data;
    })
    .catch((error: any) => {
      console.log({ [`error_${action}`]: error });
      let errorText = error?.response?.data?.error;
      // if errorText is not an string, set it to value "Something went wrong"
      if (typeof errorText !== "string") {
        errorText = "Something went wrong";
      }
      output.error = errorText;
    })
    .finally(() => {
      const end = performance.now();
      const duration = end - start;
      console.log(
        `Finished ${body.connector}: "${body.action}" in ${convertMs(duration)}`
      );
    });
  return output;
};

function convertMs(millis: number) {
  const minutes = +Math.floor(millis / 60000);
  const seconds = +((millis % 60000) / 1000).toFixed(0);
  return seconds === 60
    ? minutes + 1 + ":00"
    : minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
}
