import { createSlice } from "@reduxjs/toolkit";

export const accountSlice = createSlice({
  name: "account",
  initialState: {
    value: {
      name: "None",
      id: null,
    },
  },
  reducers: {
    setStoreAccount: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setStoreAccount } = accountSlice.actions;

export default accountSlice.reducer;
