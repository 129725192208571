import { useState } from "react";
import {
  FormGroup,
  FormControlLabel,
  Switch,
  Stack,
  Chip,
} from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";
const TargetSelection = ({
  dataTargetFile,
  setDataTargetFile,
  // useTarget,
  outputOption,
}) => {
  const [isHover, setIsHover] = useState(false);

  const hideInput = {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  };

  const fakeButton = {
    cursor: "pointer",
    display: "inline-block",
    fontSize: "1rem",
    fontWeight: "lighter",
    position: "absolute",
    margin: "auto",
    top: 8,
    left: 0,
    right: 0,
  };

  const fieldsetCSS = {
    position: "relative",
    marginLeft: -1,
    width: "100%",
    border: `1px solid ${isHover ? "black" : "lightgray"}`,
    borderRadius: "0.3rem",
    height: "2.7rem",
  };

  const legendCSS = {
    position: "absolute",
    background: "white",
    paddingLeft: 5,
    paddingRight: 6,
    color: "gray",
    fontSize: "11px",
    fontWeight: "lighter",
    top: -10,
    left: 8,
  };

  const handleTargetFile = (e) => {
    const [file] = e.target.files;
    setDataTargetFile(file);
  };

  const handleDeleteTarget = () => {
    setDataTargetFile(null);
  };

  return (
    <Stack alignItems="center" spacing={2}>
      {/* <ControlTarget useTarget={useTarget} setUseTarget={setUseTarget} /> */}
      {outputOption !== "default" && (
        <>
          <fieldset
            style={fieldsetCSS}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <legend style={legendCSS}>{" Destination File "}</legend>
            <input
              id="target-file"
              type="file"
              accept=".xlsx,.xlsm,.csv"
              onChange={handleTargetFile}
              onClick={(event) => (event.target.value = null)} // to solve same file re-selection bug
              style={hideInput}
            />
            <label style={fakeButton} for="target-file">
              {dataTargetFile ? (
                <Chip
                  icon={<PostAddIcon />}
                  size="small"
                  label={dataTargetFile?.name}
                  onClick={() => setDataTargetFile(null)}
                  onDelete={handleDeleteTarget}
                />
              ) : (
                "Click to select target file"
              )}
            </label>
          </fieldset>
        </>
      )}
    </Stack>
  );
};

export default TargetSelection;

// const ControlTarget = ({ useTarget, setUseTarget }) => {
//   const [preserve, setPreserve] = useState(false);
//   return (
//     <>
//       <FormGroup>
//         <Stack
//           direction="row"
//           justifyContent="space-between"
//           alignItems="center"
//           spacing={2}
//         >
//           <FormControlLabel
//             control={
//               <Switch
//                 size="small"
//                 checked={useTarget}
//                 onChange={() => setUseTarget(!useTarget)}
//               />
//             }
//             label={<small>Populate existing file?</small>}
//           />
//           {useTarget && (
//             <FormControlLabel
//               control={
//                 <Switch
//                   size="small"
//                   checked={preserve}
//                   onChange={() => setPreserve(!preserve)}
//                 />
//               }
//               label={<small>Preserve existing data?</small>}
//             />
//           )}
//         </Stack>
//       </FormGroup>
//     </>
//   );
// };
