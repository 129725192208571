import { createSlice } from "@reduxjs/toolkit";

export const journeySlice = createSlice({
  name: "journey",
  initialState: {
    value: {
      autoDataMode: "None",
      ipList: "None",
      restartJourney: false,
      selectedMode: "None",
    },
  },
  reducers: {
    setJourney: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setJourney } = journeySlice.actions;

export default journeySlice.reducer;
