import React from "react";
import { ChildrenProps } from "../../../../../types/ChildrenReactType";

export const BorderedContent: React.FC<ChildrenProps> = ({ children }) => {
  return (
    <div
      style={{
        padding: 16,
        marginTop: -16,
        borderLeft: "1px solid #F5F5F5",
        borderRight: "1px solid #F5F5F5",
        borderBottom: "1px solid #F5F5F5",
      }}
    >
      {children}
    </div>
  );
};
