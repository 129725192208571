import {
  Button,
  Select,
  Skeleton,
  Space,
  Spin,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import { FC, useState, useEffect } from "react";
import { dynamicSort } from "../../../../utils/sorter";
import React from "react";
import { listLibraries, listLabels, listTemplates } from "../functions";
import ContentWrapper from "../../../../components/ContentWrapper";
import { UploadOutlined } from "@ant-design/icons";

import SelectedList from "./SelectedList";

import TemplatesTable from "./TemplatesTable";

const { Title } = Typography;

export interface LibrariesType {
  id: number;
  name: string;
  scope_required?: string;
  value?: number;
  label?: string;
}

export interface LabelsType {
  description: string;
  id: number;
  label: string;
}

export interface TemplatesType {
  content_type: string;
  example_dcf: boolean;
  extension: string;
  id: number;
  library: LibrariesType;
  name: string;
  type: string;
  account_id?: number;
  label?: LabelsType;
}

const TemplateSelect = ({
  customTemplates,
  setCustomTemplates,
  templatesList,
  setTemplatesList,
}) => {
  const [loadingLibs, setLoadingLibs] = useState<boolean>(false);
  const [libraries, setLibraries] = useState<LibrariesType[]>([]);
  const [libraryId, setLibraryId] = useState<number>(0);
  const [libraryName, setLibraryName] = useState<string | undefined>(undefined);

  const [loadingLabels, setLoadingLabels] = useState<boolean>(false);
  const [labels, setLabels] = useState<LabelsType[]>([]);
  const [labelId, setLabelId] = useState<number>(0);

  const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false);
  const [templates, setTemplates] = useState<TemplatesType[]>([]);
  const [templateId, setTemplateId] = useState<number>(0);

  console.log({
    loadingLibs,
    libraries,
    libraryId,
    templates,
  });

  useEffect(() => {
    const getLibraties = async () => {
      setLoadingLibs(true);
      const librariesResponse = await listLibraries();
      const data = librariesResponse.data;
      if (data) {
        const libraryList = data
          .map((library: any) => ({
            value: library.id,
            label: library.name,
          }))
          ?.sort(dynamicSort("label"));
        setLibraries(libraryList);
      }
      setLoadingLibs(false);
    };
    libraries.length === 0 && getLibraties();
  }, []);

  useEffect(() => {
    const getLabels = async () => {
      setLoadingLabels(true);
      const labelsResponse = await listLabels();
      const data = labelsResponse.data;
      if (data) {
        const labelList = data
          .map((label: any) => ({
            value: label.id,
            label: label.label,
          }))
          ?.sort(dynamicSort("label"));
        setLabels([{ value: 0, label: "All" }, ...labelList]);
      }
      setLoadingLabels(false);
    };
    labels.length === 0 && getLabels();
  }, [templates]);

  const [toggleRefresh, setToggleRefresh] = useState(false);

  const getTemplates = async () => {
    setLoadingTemplates(true);
    const templatesResponse = await listTemplates(
      libraryId
      // isAccountOnly ? accountId : undefined,
      // labelId > 0 ? labelId : undefined
    );
    const data = templatesResponse.data;
    if (data) {
      const templatesList = data?.sort(dynamicSort("name"));
      setTemplates((prev) => [
        ...prev.filter((item) => item.library.id !== libraryId),
        ...templatesList,
      ]);
    }
    setLoadingTemplates(false);
  };

  useEffect(() => {
    if (libraryId > 0) {
      getTemplates();
      setLibraryName(
        libraries?.filter((lib) => lib.value === libraryId)[0]?.label
      );
    }
  }, [libraryId, toggleRefresh]);

  const [selectedIds, setSelectedIds] = useState([]);

  const options = [
    {
      label: "Custom",
      options: [{ label: "Local File / Custom", value: 0 }],
    },
    {
      label: "Libraries",
      options: libraries,
    },
  ];
  const props: UploadProps = {
    name: "templateFile",
    showUploadList: false,
    // accept: ".xlsx,.xlsm,.csv",
    onChange(info) {
      setCustomTemplates(info.fileList);
      // if (info.file.status !== "uploading") {
      //   console.log(info.file, info.fileList);
      // }
    },
    beforeUpload: () => {
      return false; // To not upload it, just load it
    },
  };
  return (
    <ContentWrapper>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Title level={3}>
          Templates
          <div style={{ float: "right" }}>
            {/* <UploadTemplate
              libraryId={libraryId}
              setToggleRefresh={setToggleRefresh}
              labels={labels}
            /> */}
          </div>
        </Title>

        <Space size="small">
          <Spin size="small" spinning={loadingLibs}>
            <Select
              placeholder="Template Source"
              defaultValue={0}
              style={{ width: 200 }}
              onChange={(value: number) => setLibraryId(value)}
              options={options}
            />
          </Spin>
          {libraryId === 0 && (
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>Browse Templates</Button>
            </Upload>
          )}
          {libraryId > 0 && (
            <>
              {loadingTemplates ? (
                <Spin size="small" spinning={loadingTemplates}>
                  <Skeleton.Button active={false} style={{ width: 200 }} />
                </Spin>
              ) : (
                <>
                  <TemplatesTable
                    labels={labels}
                    libraryId={libraryId}
                    libraryName={libraryName}
                    templates={templates}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    setToggleRefresh={setToggleRefresh}
                  />
                </>
              )}
            </>
          )}
        </Space>
      </Space>

      <SelectedList
        customTemplates={customTemplates}
        templates={templates}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        setCustomTemplates={setCustomTemplates}
        templatesList={templatesList}
        setTemplatesList={setTemplatesList}
      />
    </ContentWrapper>
  );
};

export default TemplateSelect;
