import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Tabs,
  Tag,
  Typography,
  message,
} from "antd";

// Components
import ContentWrapper from "../../../../../components/ContentWrapper";

// Contexts
import type { TabsProps } from "antd";
// import Networks from './sections/Networks';
import {
  ApiOutlined,
  ArrowsAltOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
// import Devices from './sections/Devices';
import { usePaloAltoConnectors } from "../../../../../contexts/Connectors/PaloAltoContext";
import { ColumnsType } from "antd/es/table";
import BaseTable from "../../../../../components/Tables/BaseTable";
import { dynamicSort } from "../../../../../utils/sorter";
import ShowRecordDetails from "../../../../../components/Tables/ShowRecordDetails";
import { SiteType } from "../../../../../types/PaloAltoConnectorsType";
// import CreateSite from "./CreateSite";

const { Title, Text } = Typography;

const Elements = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [elementsCopy, setElementsCopy] = useState<any[]>([]);

  const paloAlto = usePaloAltoConnectors();
  const tenantData = paloAlto.tenantData;
  const elements = tenantData?.get_elements;

  const loadingTenantData = paloAlto.loadingTenantData;
  const errorTenantData = paloAlto.errorTenantData;
  const sites = paloAlto.tenantData?.get_sites;

  // find site name attribute value by site id value
  const findSiteName = (siteId: string) => {
    if (siteId === "1") return <Text type="warning">Unassigned</Text>;
    const site = sites?.find((site: SiteType) => site.id === siteId);
    return site?.name || siteId;
  };

  useEffect(() => {
    // function to itereate over the elements and add an entry called site_name
    const elements_copy = elements;
    const addSiteName = () => {
      elements_copy?.forEach((element: any) => {
        element.site_name = findSiteName(element.site_id);
      });
    };
    addSiteName();
    setElementsCopy(elements_copy);
  }, [elements]);

  const columns: ColumnsType<any> = [
    {
      title: "Element  ID",
      dataIndex: "id",
      width: "15%",
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "16%",
    },
    {
      title: "Model",
      dataIndex: "model_name",
      width: "12%",
    },
    {
      title: "Serial Number",
      dataIndex: "serial_number",
      width: "23%",
    },
    {
      title: "State",
      dataIndex: "state",
      width: "7%",
    },
    {
      title: "Assigned To",
      dataIndex: "site_id",
      render: (siteID: string) => findSiteName(siteID),
    },
  ];

  const actionMenu = [
    {
      key: "listDetails",
      component: ShowRecordDetails,
      icon: <ArrowsAltOutlined />,
    },
  ];

  return (
    <Space
      direction="vertical"
      size="large"
      style={{ width: "100%", marginTop: 16 }}
    >
      {contextHolder}
      <BaseTable
        loading={loadingTenantData && !errorTenantData}
        tableName={"Elements"}
        // dataSource={elements?.sort(dynamicSort("name"))}
        dataSource={elementsCopy}
        columns={columns}
        rowActions={actionMenu}
        // topButtons={[<CreateSite />]}
      />
    </Space>
  );
};

export default Elements;
