import { useState, useRef } from "react";
import { read, utils } from "xlsx";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Stack,
  Box,
  IconButton,
  Modal,
  Tooltip,
  Button,
  Chip,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DownloadingIcon from "@mui/icons-material/Downloading";

const SourceSelection = ({
  sourceFileLocation,
  setSourceFileLocation,
  setDataSourceFile,
  setDataSourceUrl,
}) => {
  const label = "Data Source";

  const handleSourceFile = (event) => {
    setSourceFileLocation(event.target.value);
    setDataSourceFile(null);
    setDataSourceUrl(null);
  };

  return (
    <>
      <FormControl size="small" fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          value={sourceFileLocation}
          label={label}
          onChange={handleSourceFile}
        >
          <MenuItem value={"local"}>Local</MenuItem>
          <MenuItem value={"remote"}>Remote</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default SourceSelection;

export const LocalInputFile = ({
  dataSourceFile,
  setDataSourceFile,
  selectedSite,
  setSelectedSite,
}) => {
  const [isHover, setIsHover] = useState(false);

  const hideInput = {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  };

  const fakeButton = {
    cursor: "pointer",
    display: "inline-block",
    fontSize: "1rem",
    fontWeight: "lighter",
    position: "absolute",
    margin: "auto",
    top: 8,
    left: 0,
    right: 0,
  };

  const fieldsetCSS = {
    position: "relative",
    marginLeft: -1,
    width: "100%",
    border: `1px solid ${isHover ? "black" : "lightgray"}`,
    borderRadius: "0.3rem",
    height: "2.7rem",
  };

  const legendCSS = {
    position: "absolute",
    background: "white",
    paddingLeft: 5,
    paddingRight: 6,
    color: "gray",
    fontSize: "11px",
    fontWeight: "lighter",
    top: -10,
    left: 8,
  };
  const [sitesList, setSitesList] = useState([]);
  const [loadingSites, setLoadingSites] = useState(true);

  const handleDeleteSource = () => {
    setDataSourceFile(null);
    setSitesList([]);
    setSelectedSite(null);
  };

  const handleLocalFile = (e) => {
    // ensures the selected site is null/reset
    setSitesList([]);
    setSelectedSite(null);

    // grabs the file from target event
    const [file] = e.target.files;
    setDataSourceFile(file);

    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = read(bstr, { type: "binary" });
      const firstSheetName = wb.SheetNames[0];
      const firstWorksheet = wb.Sheets[firstSheetName];
      // const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      const excelSheetData = utils.sheet_to_json(firstWorksheet, { header: 1 });
      getSitesExcelData(excelSheetData); // Creates the siteList
    };
    reader.readAsBinaryString(file);
  };

  const getSitesExcelData = (excelPayload) => {
    const headers = excelPayload[0].map((item) => item.toString().trim());
    const pattern = /^site[-_/s]?id$/i; // Finds site[-, or _ or " ">]d - case insensitive
    const site_id_list = headers.filter((header) => header.match(pattern));

    if (site_id_list.length > 0) {
      const site_id_value = site_id_list[0]; // gets always the first entry if multiple
      const site_id_index = headers.indexOf(site_id_value);
      const sitesListOptions = excelPayload
        .filter((entry) => entry[site_id_index] !== undefined)
        .map((site) => ({
          value: site[site_id_index],
          label: site[site_id_index],
        }))
        // Removes site-id from optios list
        .filter((entry) => entry.value !== site_id_value)
        // Removes the duplicates
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) => t.value === value.value && t.label === value.label
            )
        );

      console.log({ site_id_value, sitesListOptions, excelPayload });

      setSitesList(sitesListOptions);
      // If there is only one site listed - just add it to the state
      sitesListOptions.length === 1 &&
        setSelectedSite(sitesListOptions[0].label);
    } else {
      console.log(
        "Unable to get data from excel... is site-id on first sheet, first_column"
      );
    }
  };

  return (
    <>
      <fieldset
        style={fieldsetCSS}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <legend style={legendCSS}>{" Source File "}</legend>
        <input
          id="source-file"
          type="file"
          accept=".xlsx,.xlsm,.csv"
          onChange={handleLocalFile}
          onClick={(event) => (event.target.value = null)} // to solve same file re-selection bug
          style={hideInput}
        />
        <label style={fakeButton} htmlFor="source-file">
          {dataSourceFile ? (
            <Chip
              icon={<UploadFileIcon />}
              size="small"
              label={dataSourceFile?.name}
              onDelete={handleDeleteSource}
            />
          ) : (
            "Click to select source file"
          )}
        </label>
      </fieldset>
      <br />

      {sitesList.length > 0 && (
        <Autocomplete
          onChange={(event, newValue) => {
            setSelectedSite(newValue?.label);
          }}
          size="small"
          fullWidth
          // disabled={sitesList.length <= 1}
          value={sitesList.length === 1 ? sitesList[0].value : selectedSite}
          disablePortal
          id="sites"
          options={sitesList}
          renderInput={(params) => (
            <TextField
              {...params}
              // onInputChange={(event, newInputValue) => {
              //   setSelectedInputSite(newInputValue);
              // }}
              placeholder={"Type to filter the options"}
              label="Select Site from Source File"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      )}
    </>
  );
};

export const RemoteInputFile = ({ dataSourceUrl, setDataSourceUrl }) => {
  const handleURL = (event) => {
    setDataSourceUrl(event.target.value);
  };

  return (
    <TextField
      fullWidth
      size="small"
      label="Remote File"
      placeholder="Paste the file's url here"
      InputLabelProps={{
        shrink: true,
      }}
      onChange={handleURL}
    />
  );
};

export const AuthModal = ({ mapperDetails }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const inputPassword = useRef();
  const inputUsername = useRef();

  const style = {
    position: "absolute",
    top: "35%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxHeight: "80%",
    bgcolor: "background.paper",
    // boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Tooltip placement="right" title="Load Remote File">
        <IconButton onClick={handleOpen}>
          <DownloadingIcon />
        </IconButton>
      </Tooltip>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <h3> SharePoint Credentials </h3>
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            m={2}
          >
            <TextField
              size="small"
              label="EIN"
              inputRef={inputUsername}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              size="small"
              label="Password"
              type="password"
              inputRef={inputPassword}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button disableElevation variant="contained" onClick={handleClose}>
              Load File
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};
