// react dependencies
import React, { useEffect, useState } from "react";
import axios from "axios";

// dual list box dependencies
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";

// custom components
import Button from "@material-ui/core/Button";
import CustomButton from "../../../components/Button/CustomButton";
import TabComponent from "../../../components/TabPanel/TabComponent";

//custom loader
import { css } from "@emotion/react";
import PropagateLoader from "react-spinners/PropagateLoader";

// toastr dependencies
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// material UI components

import { makeStyles } from "@material-ui/core/styles";

//Cookies
import Cookies from "universal-cookie";
import { handleHttpError } from "../../../common/utils.js";

//Loader css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    transitionDuration: "0.3s",
  },
  formControl: {
    minWidth: 120,
  },
  content: {
    textAlign: "center",
    height: "auto",
    paddingTop: "200",
    objectFit: "contain",
    paddingBottom: 100,
  },
  button: {
    marginTop: "3rem",
    minWidth: "210px",
    marginRight: "1rem",
    marginLeft: "1rem",
    marginBottom: "3rem",
    justifyContent: "center",
    textAlign: "center",
  },
  smallButton: {
    marginTop: "1rem",
    minWidth: "110px",
    justifyContent: "center",
    textAlign: "center",
  },
  mystyle: {
    color: "rgb(85, 20, 180)",
  },
  progress: {
    marginTop: "5%",
    marginLeft: "25%",
  },
  tabPanel: {
    flexGrow: 1,
    width: "100%",
  },
  table: {
    "& > *": {
      margin: ".5rem",
      display: "inline",
      textAlign: "center",
    },
  },
});

export default function AutoData(props) {
  const classes = useStyles();

  const cookies = new Cookies();
  const account_id = cookies.get("account_id");
  const access_token = cookies.get("access_token");

  const templateId = props.template_id;

  // data states
  const [selectedSites, setSelectedSites] = useState([]);
  const [sitesList, setSitesList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableTabs, setTableTabs] = useState([]);
  const [accountName, setAccountName] = useState();
  const [bfgId, setBfgId] = useState();

  // loading states
  const [isLoadingSites, setLoadingSites] = useState(true);
  const [isLoadingTable, setLoadingTable] = useState(true);

  let [isDisabled, setIsDisabled] = useState(true);
  let [isLoading, setLoading] = useState(false);
  let [isLoadingFinal, setLoadingFinal] = useState(false);

  // dualbox options
  const dualListOptions = {
    moveLeft: "<",
    moveAllLeft: "<<",
    moveRight: ">",
    moveAllRight: ">>",
  };

  const onSelectedSitesChange = (selected) => {
    setSelectedSites(selected);
    setIsDisabled(false);
    // clearing table data
    setTableTabs([]);
    setTableData([]);

    // resetting loading states
    setLoadingTable(true);
  };

  useEffect(() => {
    if (account_id !== "null") {
      axios
        .get(`/oauth2/accounts/${account_id}`, {
          headers: { Authorization: "Bearer " + access_token },
        })
        .then((response) => {
          const account = response.data;
          if (account.bfg_id !== undefined) {
            setBfgId(account.bfg_id);
            setAccountName(account.name);
            getSitesApigw(account.id);
          } else {
            toast.warning("This account does not have access to AutoData");
            setLoadingSites(false);
          }
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    }
  }, [account_id, props.selectedAccount]);

  const getSitesApigw = (accountID) => {
    // SelectedAccount is the BFG_ID
    console.log(`Fetching sites for account id ${accountID} from APIGW...`);
    const sitesEndpoint = `/api/v1/autodata/accounts/${accountID}/sites`;
    axios
      .get(sitesEndpoint, {
        headers: { Authorization: "Bearer " + access_token },
      })
      .then((response) => {
        const sites = response.data;
        const allSites = sites["sites"].map((site) => ({
          value: site.site_id,
          label: site.site_name,
        }));
        setSitesList(allSites);
        setLoadingSites(false);
        setLoading(false);
      })
      .catch(function (error) {
        setLoadingSites(false);
        setLoading(false);
        toast.error(`There is an issue accessing the data for ${accountName}!`);
        handleHttpError(error)
      });
  };

  const startAutoDataApigwNew = async (sites) => {
    setLoadingFinal(true);
    // clearing old table data
    setTableData([]);
    const encodedSites = encodeURIComponent(sites);
    const tabulator_dataset = `api/v1/autodata/templates/${templateId}/customers/${bfgId}/sites/${encodedSites}?schema_overlay=tabulator`;
    console.log(
      `Fetching Tabulator Primary Endpoint for sites bfg ids: ${sites} STARTED...`
    );
    await axios
      .get(tabulator_dataset, {
        headers: { Authorization: "Bearer " + access_token },
      })
      .then((response) => {
        const [tabs, tabulatorData] = response.data;

        setTableData(tabulatorData);
        setTableTabs(tabs);
      })
      .catch(function (error) {
        handleHttpError(error);
        setIsDisabled(false);
      });
    console.log("Fetching devices endpoint api FINISHED...");

    setLoadingFinal(false);
    setLoadingTable(false);
  };

  return (
    <div className={classes.content}>
      <ToastContainer />
      {bfgId !== undefined ? (
        <h2 className={classes.mystyle}>AutoData</h2>
      ) : null}

      {isLoadingSites ? (
        <PropagateLoader color={"#5014b9"} css={override} size={20} />
      ) : null}

      {typeof account_id !== "undefined" && account_id !== "null" ? (
        <div>
          <div id="SelectSitesTitle"></div>
          <div id="site_list" className={classes.list}>
            <br />
            <br />
            {isLoading ? (
              <PropagateLoader color={"#5014b9"} css={override} size={20} />
            ) : null}
            {!isLoadingSites && sitesList.length > 0 ? (
              <div>
                <h3 className={classes.mystyle}>
                  Move across the sites you need from left to right:
                </h3>
                <DualListBox
                  options={sitesList}
                  selected={selectedSites}
                  onChange={onSelectedSitesChange}
                  canFilter
                  icons={dualListOptions}
                />
                <div id="btn-generate-tables" className={classes.button}>
                  {!isDisabled ? (
                    <CustomButton
                      label={"Generate Data Table"}
                      color="purple"
                      action={() => {
                        startAutoDataApigwNew(selectedSites);
                        setIsDisabled(true);
                      }}
                    />
                  ) : (
                    <Button variant="contained" disabled>
                      Generate Data Table
                    </Button>
                  )}
                </div>
                {isLoadingFinal ? (
                  <PropagateLoader color={"#5014b9"} css={override} size={20} />
                ) : null}
              </div>
            ) : !isLoadingSites &&
              sitesList.length === 0 &&
              bfgId !== undefined ? (
              <p>{`There is an issue accessing the data for ${accountName}!`}</p>
            ) : null}
          </div>

          <div id="tabulator">
            {!isLoadingTable && tableData.length > 0 ? (
              <div>
                <TabComponent
                  accountName={accountName}
                  tabs={tableTabs}
                  data={tableData}
                  access_token={props.access_token}
                  template_id={props.template_id}
                  show_buttons={true}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <p>
          You need to select an account in the header in order to continue,
          please.
        </p>
      )}
    </div>
  );
}
