// React dependencies
import React from "react";
import axios from "axios"
import CustomButton from "../../../components/Button/CustomButton.js";
import Cookies from "universal-cookie";

// Material UI components
import { makeStyles } from "@material-ui/core/styles";

//Toaster
import "react-toastify/dist/ReactToastify.css";

//redux
import { handleHttpError, getFileName, saveBlob } from "../../../common/utils.js";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    inputfile: {
        marginTop: 15,
        paddingLeft: "right",
        backgroundColor: "rgb(85, 20, 180)",
        color: "white",
        borderRadius: "3px",
        marginBottom: 30
    },
    upload_button: {
        marginTop: "40px"
    },
}));

export default function GuiTemplateExcelView(props) {
    const classes = useStyles();
    const [inputReceived, setInputReceived] = React.useState("");
    const [selectGuiTemplateFile, setSelectGuiTemplateFile] = React.useState(false);
    const [selectGuiTemplateFileNo, setSelectGuiTemplateFileNo] = React.useState(false);

    const [spreadsheetDownloaded, setSpreadsheetDownloaded] = React.useState(false);

    const yesSelected = () => {
        setSpreadsheetDownloaded(false)
        setInputReceived('Yes')
    }
    const downloadSpreadsheet = () => {
        axios({
            method: "get",
            url: '/api/v1/gui_templates/' + props.gui_templateID + '?output_type=excel',
            headers: {
                Authorization: "Bearer " + props.access_token,
            },
            responseType: "blob",
        })
            .then((response) => {
                let fileName = getFileName(response.headers);
                saveBlob(response.data, fileName);
                setSpreadsheetDownloaded(true)
            })
            .catch((error) => {
                handleHttpError(error);
            });
    }
    const generateGuiTemplateDocument = () => {
        let gui_templateExcel = document.getElementById("gui_template_file").files[0];
        axios({
            method: "post",
            url: '/api/v1/gui_templates/' + props.gui_templateID,
            data: gui_templateExcel,
            headers: {
                "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                Accept: props.acceptType,
                Authorization: "Bearer " + props.access_token,
            },
            responseType: "blob",
        })
            .then((response) => {
                let fileName = getFileName(response.headers);
                saveBlob(response.data, fileName);
            })
            .catch((error) => {
                handleHttpError(error);
            });
    }
    const cookies = new Cookies();
    const wizard_type = cookies.get("wizard_type")
    return (
        <div>
            <h3>Do you have a pre-populated DCF spreadsheet?</h3> <div></div>
            <label
                className="container"
                onClick={() => {
                    yesSelected();
                }}
            >
                Yes, continue
                <input id="yes-radio" type="radio" name="radio" />
            </label>
            <label
                className="container"
                onClick={() => {
                    setInputReceived("No");
                }}
            >
                No, help me
                <input id="no-radio" type="radio" name="radio" />
            </label><br /><br />
            {inputReceived === "Yes" ? (
                <input
                    className={classes.inputfile}
                    id="gui_template_file"
                    type="file"
                    name="filename"
                    accept=".xlsx"
                    onChange={() => setSelectGuiTemplateFile(true)}
                />
            ) : null}
            {selectGuiTemplateFile && inputReceived === 'Yes' ? <CustomButton
                className={classes.gui_templateButton}
                label={`Generate ${wizard_type}`}
                color="purple"
                action={() => generateGuiTemplateDocument()}
            /> : null}

            {inputReceived === 'No' ? <CustomButton
                className={classes.gui_templateButton}
                label={"Download Spreadsheet"}
                color="purple"
                action={() => downloadSpreadsheet()}
            /> : null}
            {inputReceived === 'No' && spreadsheetDownloaded ? <div><br /><h3>Upload populated spreadsheet below:</h3><input
                className={classes.inputfile}
                id="gui_template_file"
                type="file"
                name="filename"
                accept=".xlsx"
                onChange={() => setSelectGuiTemplateFileNo(true)}
            /></div> : null}
            {inputReceived === 'No' && selectGuiTemplateFileNo ? <CustomButton
                className={classes.gui_templateButton}
                label={"Generate Document"}
                color="purple"
                action={() => generateGuiTemplateDocument()} /> : null}


        </div>
    );

}
