import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ReactMarkdown from "react-markdown";
import catalogueDesignMarkdown from "../../markdowns/Catalogue Designs.md";
import customDesignMarkdown from "../../markdowns/Custom Designs.md";
import excelDataMarkdown from "../../markdowns/Excel Data Collection Forms.md";
import goldTemplateAgileMarkdown from "../../markdowns/Gold Templates - Agile Connect SLD.md";
import goldTemplateCiscoMarkdown from "../../markdowns/Gold Templates - Cisco SD-WAN SLD.md";
import aboutNGDSMarkdown from "../../markdowns/About ngDS.md";
import cpeBuilderMarkdown from "../../markdowns/SDWAN Cisco CPE Builder.md";
import templatesMarkdown from "../../markdowns/WLAN Templates.md";
import templateDesignerMarkdown from "../../markdowns/Template Designer Guide.md";
import toolsMarkdown from "../../markdowns/WLAN Tools.md";
import Cookies from "universal-cookie";
import { LayoutPage } from "../JourneyPage/JourneyPage";

const useStyles = makeStyles((theme) => ({
  mystyle: {
    color: "rgb(85, 20, 180)",
  },
  redtext: {
    color: "red",
  },
  root: {
    // paddingBottom: "150px",
  },
  menu: {
    backgroundColor: "rgba(85, 20, 180, 0.9)",
    color: "white",
  },
  gridBorder: {
    // textAlign: "center",
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  titleContainer: {
    // paddingTop: 100,
    textAlign: "center",
  },
}));

export default function HelpPage() {
  const classes = useStyles();
  const cookies = new Cookies();
  const [content, setContent] = useState("about");
  const [aboutNGDSMdFile, setAboutMarkdown] = useState(null);
  const [catalogueDesignMdFile, setCatalogueDesignMarkdown] = useState(null);
  const [customDesignMdFile, setCustomDesignMarkdown] = useState(null);
  const [excelDataMdFile, setExcelDataMarkdown] = useState(null);
  const [goldTemplateAgileMdFile, setGoldTemplateAgileMarkdown] =
    useState(null);
  const [goldTemplateCiscoMdFile, setGoldTemplateCiscoMarkdown] =
    useState(null);
  const [cpeBuilderMdFile, setCpeBuilderMarkdown] = useState(null);
  const [templatesMdFile, setTemplatesMarkdown] = useState(null);
  const [toolsMdFile, setToolsMarkdown] = useState(null);
  const [templateDesignerMdFile, setTemplateDesignerMarkdown] = useState(null);
  const [filenamesList, setFileNames] = useState(null);
  const [contentReady, setContentReady] = useState(false);
  const uin = cookies.get("uin");

  useEffect(() => {
    const markdowns = [
      // catalogueDesignMarkdown,
      customDesignMarkdown,
      excelDataMarkdown,
      // goldTemplateAgileMarkdown,
      // goldTemplateCiscoMarkdown,
      templatesMarkdown,
      // toolsMarkdown,
      templateDesignerMarkdown,
      // cpeBuilderMarkdown,
    ];
    const list = [];
    for (const markdown of markdowns) {
      const filename = markdown.replace("/static/media/", "").split(".")[0];
      list.push(filename);
    }
    setFileNames(list);
    // fetch(catalogueDesignMarkdown)
    //   .then((response) => response.text())
    //   .then((text) => {
    //     setCatalogueDesignMarkdown(text);
    //   });
    fetch(customDesignMarkdown)
      .then((response) => response.text())
      .then((text) => {
        setCustomDesignMarkdown(text);
      });
    fetch(excelDataMarkdown)
      .then((response) => response.text())
      .then((text) => {
        setExcelDataMarkdown(text);
      });
    // fetch(goldTemplateAgileMarkdown)
    //   .then((response) => response.text())
    //   .then((text) => {
    //     setGoldTemplateAgileMarkdown(text);
    //   });
    // fetch(goldTemplateCiscoMarkdown)
    //   .then((response) => response.text())
    //   .then((text) => {
    //     setGoldTemplateCiscoMarkdown(text);
    //   });
    // fetch(cpeBuilderMarkdown)
    //   .then((response) => response.text())
    //   .then((text) => {
    //     setCpeBuilderMarkdown(text);
    //   });
    fetch(templatesMarkdown)
      .then((response) => response.text())
      .then((text) => {
        setTemplatesMarkdown(text);
      });
    // fetch(toolsMarkdown)
    //   .then((response) => response.text())
    //   .then((text) => {
    //     setToolsMarkdown(text);
    //   });
    fetch(templateDesignerMarkdown)
      .then((response) => response.text())
      .then((text) => {
        setTemplateDesignerMarkdown(text);
      });
    fetch(aboutNGDSMarkdown)
      .then((response) => response.text())
      .then((text) => {
        setAboutMarkdown(text);
      });
    setContentReady(true);
  }, []);

  const renderAbout = () => {
    setContent("about");
  };
  const renderSelectedItem = (file) => {
    if (file.includes("Catalogue")) {
      setContent("catalogue");
    } else if (file.includes("Custom")) {
      setContent("custom");
    } else if (file.includes("Excel")) {
      setContent("excel");
    }
    // else if (file.includes("Gold Templates - Agile Connect SLD")) {
    //   setContent("goldagile");
    // } else if (file.includes("Gold Templates - Cisco SD-WAN SLD")) {
    //   setContent("goldcisco");
    // } else if (file.includes("CPE")) {
    //   setContent("cpe");
    // }
    else if (file.includes("Templates")) {
      setContent("templates");
    }
    // else if (file.includes("Tools")) {
    //   setContent("tools");
    // }
    // else if (file.includes("Template Designer Guide")) {
    //   setContent("designguide");
    // }
    else {
      setContent("about");
    }
  };
  return (
    <LayoutPage>
      <Grid item xs={12}>
        <div className={classes.titleContainer}></div>
        <Alert severity="warning">
          The information in the help section may be outdated, and we are
          actively working to update it as quickly as possible. <br />
          In the meantime, if you need assistance with ngDS, please contact us.
        </Alert>
      </Grid>
      <Grid item xs={3}>
        <Paper className={classes.menu}>
          <MenuList>
            <MenuItem onClick={() => renderAbout()}>
              <Typography variant="inherit">About ngDS</Typography>
            </MenuItem>
            {filenamesList?.map((file) => (
              <MenuItem onClick={() => renderSelectedItem(file)}>
                <Typography variant="inherit">{file}</Typography>
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      </Grid>

      <Grid className={classes.gridBorder} id="help_area" item xs={9}>
        {content === "about" ? (
          <ReactMarkdown source={aboutNGDSMdFile} />
        ) : null}
        {/* {content === "catalogue" ? (
              <ReactMarkdown source={catalogueDesignMdFile} />
            ) : null} */}
        {content === "custom" ? (
          <ReactMarkdown source={customDesignMdFile} />
        ) : null}
        {content === "excel" ? (
          <ReactMarkdown source={excelDataMdFile} />
        ) : null}
        {/* {content === "goldagile" ? (
              <ReactMarkdown source={goldTemplateAgileMdFile} />
            ) : null}
            {content === "goldcisco" ? (
              <ReactMarkdown source={goldTemplateCiscoMdFile} />
            ) : null} */}
        {content === "templates" ? (
          <ReactMarkdown source={templatesMdFile} />
        ) : null}
        {/* {content === "cpe" ? (
              <ReactMarkdown source={cpeBuilderMdFile} />
            ) : null}
            {content === "tools" ? (
              <ReactMarkdown source={toolsMdFile} />
            ) : null} */}
        {content === "designguide" ? (
          <ReactMarkdown source={templateDesignerMdFile} />
        ) : null}
      </Grid>
    </LayoutPage>
  );
}
