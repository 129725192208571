import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import TabComponent from "../../../components/TabPanel/TabComponent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";
import { handleHttpError } from "../../../common/utils.js";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    transitionDuration: "0.3s",
  },

  content: {
    textAlign: "center",
    height: 300,
    paddingTop: "200",
    objectFit: "contain",
    paddingBottom: "10%",
  },
  button: {
    width: "80%",
    marginRight: "10%",
    marginLeft: "10%",
    marginBottom: "1%",
    justifyContent: "center",
    textAlign: "center",
  },
  mystyle: {
    color: "rgb(85, 20, 180)",
  },
});

export default function ManuallyView(props) {
  const classes = useStyles();
  const cookies = new Cookies();
  const access_token = cookies.get("access_token");
  const [isLoadingTable, setLoadingTable] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [tableTabs, setTableTabs] = useState([]);
  const toastId = React.useRef(null);

  const toastError = (message) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(message);
    }
  };
  useEffect(() => {
    const templateID = props.template_id;
    axios
      .get(`/api/v1/templates/${templateID}`, {
        headers: { Authorization: "Bearer " + access_token, Accept: "application/json" },
      })
      .then((res) => {
        const parsed_data = {};
        const results = res.data["variables"];
        let data = [];
        let tabs = [];

        for (const [tab, value] of Object.entries(results)) {
          if (tab === "sites") {
            parsed_data[tab] = ["site_id"];
          } else {
            parsed_data[tab] = [tab + "_id"];
          }
          for (let subValue of Object.values(value.properties)) {
            if (subValue.hasOwnProperty("items")) {
              const subTabContent = subValue.items.properties;
              const subTabName = subValue.title;
              const subTabColumns = Object.keys(subTabContent);
              subTabColumns.unshift(subTabName + "_id", "site_id");
              parsed_data[subTabName] = subTabColumns;
            } else {
              parsed_data[tab].push(subValue.title);
            }
          }
          const tabList = Object.keys(parsed_data);
          tabs = tabList.map((tabname) => {
            return { name: tabname };
          });
          for (var i = 0; i < tabList.length; i++) {
            let columns = [];
            let currentTab = tabList[i];
            let headers = parsed_data[currentTab];
            headers.forEach((header) => columns.push({ title: header, field: header, width: "15px", editor: "input" }));
            columns.push({
              title: "Delete",
              formatter: "buttonCross",
              width: "10px",
              headerSort: false,
              cellClick: function (e, cell) {
                if (window.confirm("Are you sure you want to delete this entry?")) cell.getRow().delete();
              },
            });
            data[i] = { index: i, dataset: [{}], columns: columns };
          }
        }
        setTableData(data);
        setTableTabs(tabs);
        setLoadingTable(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          handleHttpError(error);
        } else {
          toastError("ngDS has encountered a problem, please contact your support channels and report an issue");
        }
      });
  }, []);

  return (
    <div className={classes.content}>
      <ToastContainer />
      {!isLoadingTable ? (
        <div>
          <h2 className={classes.mystyle}>Manual Data Input:</h2>
          <div id="tabulator">
            <TabComponent
              tabs={tableTabs}
              data={tableData}
              access_token={props.access_token}
              template_id={props.template_id}
              show_buttons={true}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
