// React dependencies
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";

// Material UI components
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// Custom components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import CustomCard from "../../components/Card/CustomCard.js";

// SVG images
import cngo from "./../../assets/images/reaction.svg";
import configs from "./../../assets/images/plasmaball.svg";
import autodata from "./../../assets/images/future.svg";
import ionix from "./../../assets/images/ionix.png";

// Custom views
import ManualConfigView from "./ConfigConverterViews/ManualConfigView";
import MultipleConfigView from "./ConfigConverterViews/MultipleConfigView";
import AutoDataView from "./ConfigConverterViews/AutoDataView";

//Toaster
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//redux
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "100px",
    flexGrow: 1,
    marginBottom: "80px",
    textAlign: "center",
  },
  subroot: {
    paddingTop: "50px",
    marginBottom: "80px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "40%",
  },
  mystyle: {
    color: "rgb(85, 20, 180)",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function ConfigConverterPage(props) {
  const classes = useStyles();
  const cookies = new Cookies();
  const account_id = cookies.get("account_id");
  const selectedAccount = useSelector((state) => state.account.value);
  const [configMode, setConfigMode] = useState("None");
  const toastId = React.useRef(null);
  const toastWarning = (message) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.warning(message);
    }
  };
  useEffect(() => {
    setConfigMode("None");
  }, [props.templateID, selectedAccount]);

  const setMode = (mode) => {
    const accountID = cookies.get("account_id");
    if (mode === "AutoDataView") {
      if (accountID === "None" || accountID === "null") {
        toastWarning("Select an account from the NavBar to use this feature.");
      } else {
        setConfigMode(mode);
      }
    } else {
      setConfigMode(mode);
    }
  };
  return (
    <Container>
      <Header />
      <Grid container className={classes.root} justify="center" spacing={3}>
        <Grid item xs={12}>
          <div className="title-container">
            <h1 className={classes.mystyle}>Config Converter</h1>
          </div>
        </Grid>
        <Grid
          container
          className={classes.subroot}
          justify="center"
          spacing={3}
        >
          <Grid item xs={12}>
            <div className="title-container">
              <h3>What type of config(s) are we dealing with?</h3>
            </div>
          </Grid>
          <Grid item xs={3}>
            <CustomCard
              title="CnGo"
              description=""
              label="Select"
              image={cngo}
              action={() => {
                setMode("CnGo");
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomCard
              title="Config(s)"
              description=""
              label="Select"
              image={configs}
              action={() => {
                setMode("Config");
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomCard
              title="AutoData"
              description=""
              label="Select"
              image={autodata}
              action={() => {
                setMode("AutoDataView");
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomCard
              title="Ionix"
              description=""
              label="Select"
              image={ionix}
              action={() => {
                setMode("Ionix");
              }}
            />
          </Grid>
          <Grid item xs={12} id="csr-journeys">
            {configMode === "CnGo" || configMode === "Ionix" ? (
              <MultipleConfigView
                mode={configMode}
                access_token={props.access_token}
              />
            ) : null}
            {configMode === "Config" ? (
              <ManualConfigView access_token={props.access_token} />
            ) : null}
            {configMode === "AutoDataView" ? (
              <AutoDataView
                template_id={props.templateID}
                accountID={account_id}
              />
            ) : null}
          </Grid>
        </Grid>
        <ToastContainer />
      </Grid>
      <Footer />
    </Container>
  );
}
