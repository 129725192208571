import { Result } from 'antd'
import React, { FC, ReactNode } from 'react'

type Props = {
    title?: string
    subTitle?: string | ReactNode
}

const ResultOk: FC<Props> = ({
    title = "Success",
    subTitle = "Task completed successfully."
}) => {

    return (
        <div style={{ marginTop: 24, borderStyle: 'dashed', borderColor: '#52c41a', borderWidth: 1, borderRadius: 5, backgroundColor: "#f3f6f4" }}>
            <Result
                status="success"
                title={title}
                subTitle={subTitle}
            />
        </div>
    )
}

export default ResultOk