import React, { useEffect, useState } from 'react'
import { Button, Divider, Drawer, Form, Input, InputNumber, Progress, Select, Space, Typography, Tabs, Descriptions, Result } from 'antd'
import { useConnectors } from '../../../../contexts/Connectors/ZscalerContext'
import { ZscalerLocationType, ZscalerSettingType } from '../../../../types/ConnectorsType'
import { FormInstance } from 'antd/es/form/Form'

type FormProps = {
    form: FormInstance<any>
    record: ZscalerLocationType
    setUpBandwidth: React.Dispatch<React.SetStateAction<number>>
    setDnBandwidth: React.Dispatch<React.SetStateAction<number>>
    setTz: React.Dispatch<React.SetStateAction<string>>
}

const ModifyLocationForm: React.FC<FormProps> = ({ form, record, setUpBandwidth, setDnBandwidth, setTz }) => {

    // Connectors Context
    const connectors = useConnectors()

    // Getting General Form Settings
    const settings = connectors?.settings

    // Getting Specific Group Form Settings
    const subLocationFormFields = settings?.filter((item: any) => item.sublocation === true)

    // const disabledFields = subLocationFormFields?.filter(item => item.variable === true || item.parameter === 'name').map(item => item.parameter)
    const editableFields = ['upBandwidth', "dnBandwidth", "tz"]

    // Setting Initial Values
    const initialValues = {
        name: record?.name,
        id: record?.id,
        tz: record?.tz,
        upBandwidth: record?.upBandwidth,
        dnBandwidth: record?.dnBandwidth
    }

    const onFinish = (values: any) => {
        console.log({ values })
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo)
    }

    const optionValue = (input: ZscalerSettingType) => {

        // TZ handler
        if (input.parameter === "tz") return (
            <Select showSearch value={record.tz} onChange={(value) => setTz(value)}>
                {input.possible_values.map((value: string) => <Select.Option key={value} value={value}>{value}</Select.Option>)}
            </Select >)
        // BW handler
        else if (input.parameter === "dnBandwidth") return (
            <InputNumber style={{ width: "100%" }} value={record.dnBandwidth} onChange={(value) => setDnBandwidth(+value)} />
        )
        else if (input.parameter === "upBandwidth") return (
            <InputNumber style={{ width: "100%" }} value={record.upBandwidth} onChange={(value) => setUpBandwidth(+value)} />
        )
        // Default
        else return <Input disabled={!input.sublocation} />
    }

    const createFields = () => {
        return subLocationFormFields?.map((item) => {
            return <Form.Item
                key={item.display_name}
                label={item.display_name}
                name={[item.parameter]}
                required={item.mandatory ? true : false}
                rules={[{ required: true }]}
                tooltip={item.parameter !== 'name' && item.description}
                hidden={!editableFields?.includes(item.parameter)}
            >
                {optionValue(item)}
            </Form.Item >
        })
    }

    return (
        <>
            <Form
                layout='vertical'
                style={{ marginTop: 30, maxWidth: "100%" }}
                size='small'
                form={form}
                name='new-location-form'
                onFinish={onFinish}
                initialValues={{ ...initialValues }}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                labelWrap
            >
                {createFields()}
            </Form >
        </ >
    )
}

export default ModifyLocationForm