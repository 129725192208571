import { FC } from "react";
import "./index.css";
import React from "react";

// contexts
import { ConfigProvider } from "antd";
import { appTheme } from "./appTheme";
import GlobalContext from "./contexts";
//Pages
import LayoutMain from "./components/LayoutMain";
import { LayoutPage } from "../pages/JourneyPage/JourneyPage";
// Added Children as workaround as Outlet will not work in the legacy version
import { ChildrenProps } from "./types/ChildrenReactType";

// import { useHistory } from "react-router-dom";
// import { useSelector } from "react-redux";
// import NoAccountBanner from "../pages/JourneyPage/NoAccountBanner";

const App: FC<ChildrenProps> = ({ children }) => {
  // const history = useHistory();
  // const accountState = useSelector((state: any) => state.account.value);
  // React.useEffect(() => {
  //   if (accountState.name === "None") history.push("/");
  // }, [accountState]);

  return (
    <GlobalContext>
      <ConfigProvider theme={appTheme}>
        {/* <LayoutMain/> */}
        {/* Added Children as workaround as Outlet will not work in the legacy version */}
        <LayoutPage maxWidth="lg">
          <div style={{ padding: 8 }}>{children}</div>
        </LayoutPage>
      </ConfigProvider>
    </GlobalContext>
  );
};

export default App;
