import { Button, Card, Flex, Typography, Space, Tag } from "antd";
import React from "react";
import { usePaloAltoConnectors } from "../../../contexts/Connectors/PaloAltoContext";
import { ChildrenProps } from "../../../types/ChildrenReactType";
const { Title, Text } = Typography;

const MainCard = ({ children }: ChildrenProps) => {
  const paloAlto = usePaloAltoConnectors();
  const paloAltoConnect = paloAlto.paloAltoConnect;
  const tenantData = paloAlto?.tenantData;
  const tenant = tenantData?.get_tenants;
  const apiKey = paloAlto.apiKey;
  const tenantName = tenant?.name;
  const tenantId = tenant?.tenant_id;
  return (
    <Card
      title={
        <Space>
          <>{tenantName}</>
          <Tag bordered={false}>{tenantId}</Tag>
        </Space>
      }
      extra={
        <Flex align="flex-end" gap="middle">
          <small
            style={{ marginLeft: 8 }}
          >{`Connected with token ***** ${apiKey?.slice(
            -4,
            apiKey.length
          )} `}</small>
          <Button
            size="small"
            type="text"
            onClick={() => paloAltoConnect(undefined)}
          >
            <Text type="danger">
              <small>{"Disconnect"}</small>
            </Text>
          </Button>
        </Flex>
      }
    >
      {children}
    </Card>
  );
};

export default MainCard;
