import { callApiConnector } from "../../Common/functions/_connector_call";

// settings
// get_adoms
// get_devices
// get_firewall_policy
// get_system_status
// get_interfaces
// get_firewall_policies
// get_firewall_addresses

export const getData = async (action: string, params: any) => {
  return callApiConnector("fortinet", action, params);
};
