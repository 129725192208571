import Cookies from "universal-cookie";

class Auth {
  constructor() {
    this.authenticated = false;
  }

  isAuthenticated() {
    const cookies = new Cookies();
    const accessToken = cookies.get("access_token");
    if (accessToken !== undefined) {
      this.authenticated = true;
      return this.authenticated;
    }
  }
}

export default new Auth();
