import React, { useState } from 'react'
import { Form, Input, Select, Descriptions } from 'antd'
import { useConnectors } from '../../../../contexts/Connectors/ZscalerContext'
import { ZscalerLocationType, ZscalerSettingType } from '../../../../types/ConnectorsType'
import { FormInstance } from 'antd/es/form/Form'


type FormProps = {
    form: FormInstance<any>
    record: ZscalerLocationType
    setIpAddresses: React.Dispatch<React.SetStateAction<string>>
    setSubLocationName: React.Dispatch<React.SetStateAction<string | undefined>>
}

const NewSubLocationForm: React.FC<FormProps> = ({ form, record, setIpAddresses, setSubLocationName }) => {

    // Connectors Context
    const connectors = useConnectors()

    const account = JSON.parse(localStorage.getItem("currentAccount")!)
    const accountName = account?.name

    // Getting General Form Settings
    const settings = connectors?.settings
    const settingsLoading = connectors?.settingsLoading
    const settingsError = connectors?.settingsError
    // Getting Specific Group Form Settings
    const subLocationFormFields = settings?.filter((item: any) => item.sublocation === true)


    // Creating Location Names Array for Validation
    const allLocationsNames = connectors?.locations?.map((location: any) => location.name)

    const subBw = (bw: number | undefined, percetage: number) => {
        if (bw) return (bw / 100) * percetage
    }

    // const disabledFields = subLocationFormFields?.filter(item => item.variable === true || item.parameter === 'name').map(item => item.parameter)
    const hiddenFields = ['ofwEnabled', "parentId", "country", "tz"]

    // Setting Initial Values
    const initialSubLocationValues = {
        parentLocationName: record?.name,
        parentId: record?.id,
        country: record?.country,
        tz: record?.tz,
        upBandwidth: subBw(record?.upBandwidth, 5),
        dnBandwidth: subBw(record?.dnBandwidth, 5),
        ofwEnabled: record?.ofwEnabled,
    }

    const handleSubIP = (subLocationName: string) => {
        // Handle IP Address
        let subnet: string
        if (subLocationName.endsWith('-guest')) { subnet = accountName.includes('ETO') ? '192.168.0.0/23' : '192.168.2.0/23'}
        else if (subLocationName.endsWith('-byod')) { subnet = '192.168.4.0/22' }

        if (subnet) {
            form.setFieldValue("ipAddresses", subnet);
            setIpAddresses(subnet)
        }
        else {
            const formIPAddress = form.getFieldValue("ipAddresses")
            setIpAddresses(formIPAddress)
            setIpAddresses(formIPAddress)
        }
    }

    const handleSubName = (subLocationName: string) => {
        setSubLocationName(subLocationName)
        form.setFieldValue("name", subLocationName)
        handleSubIP(subLocationName)
    }

    const SubOptionValue = (input: ZscalerSettingType) => {

        // name handler
        if (input.parameter === "name") return <Select onChange={(value) => handleSubName(value)}>
            <Select.Option value={`${record?.name}-byod`}>{`${record?.name}-byod`}</Select.Option>
            <Select.Option value={`${record?.name}-guest`}>{`${record?.name}-guest`}</Select.Option>
        </Select>
        // ipAddresses handler
        else if (input.parameter === "ipAddresses") return <Input onBlur={(e) => handleSubIP(e.target.value)} />
        // Default
        else return <Input disabled={!input.sublocation} />
    }

    const onFinish = (values: any) => {
        console.log({ values })
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo)
    }

    const createFields = () => {
        return subLocationFormFields?.map((item) => {
            return <Form.Item
                key={item.display_name}
                label={item.parameter !== 'name' ? item.display_name : 'Sub-Location Name'}
                name={[item.parameter]}
                required={item.mandatory ? true : false}
                rules={[{ required: true }]}
                tooltip={item.parameter !== 'name' ? item.description || null : 'Fill with Sub-Location Name'}
                hidden={hiddenFields?.includes(item.parameter)}
            >
                {SubOptionValue(item)}

            </Form.Item >
        })
    }

    return (
        <>
            <Descriptions bordered size='small' column={1}>
                <Descriptions.Item label="Parent Name">{record?.name}</Descriptions.Item>
                <Descriptions.Item label="Parent ID">{record?.id}</Descriptions.Item>
            </Descriptions>

            <Form
                layout='vertical'
                style={{ marginTop: 30, maxWidth: "100%" }}
                size='small'
                form={form}
                name='new-location-form'
                onFinish={onFinish}
                initialValues={{ ...initialSubLocationValues }}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                labelWrap
            >
                {createFields()}
            </Form >
        </ >
    )
}

export default NewSubLocationForm