import { createSlice } from "@reduxjs/toolkit";

export const rolloutSlice = createSlice({
  name: "rollout",
  initialState: {
    value: {
      templatesList: [],
      sitesList: {
        options: [], // keys: value (Site ID), label (Site Name)
        selected: [],
      },
      sitesTypes: {
        options: [],
        selected: [], // (site) id, name, type, products
      },
      autodata: {
        target: [],
        output: [],
      },
    },
  },
  reducers: {
    setRolloutStore: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setRolloutStore } = rolloutSlice.actions;

export default rolloutSlice.reducer;
