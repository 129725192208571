import { Space, Typography } from "antd";
import React from "react";
import { FC } from "react";
import {
  ZscalerSettingType,
  ZscalerLocationType,
} from "../../../../types/ConnectorsType";
import { useConnectors } from "../../../../contexts/Connectors/ZscalerContext";
import {
  ApartmentOutlined,
  AppstoreOutlined,
  DeleteOutlined,
  AppstoreAddOutlined,
  EditOutlined,
  ArrowsAltOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import CreateLocation from "./Actions/CreateLocation";

// import ActionMenu from './ActionsMenu';
// import Results from './ActionsMenu/Results';
import BaseTable from "../../../../components/Tables/BaseTable";
import CreateSubLocation from "./Actions/CreateSubLocation";
import ListSubLocation from "./Actions/ListSubLocation";
import ModifyLocation from "./Actions/ModifyLocation";
import DeleteLocation from "./Actions/DeleteLocation";
import ShowRecordDetails from "../../../../components/Tables/ShowRecordDetails";
import ListVPNs from "./Actions/ListVPNs";
const { Text } = Typography;

const LocationsManagement: FC<{ isZscalerReadOnly?: boolean }> = ({
  isZscalerReadOnly,
}) => {
  const connectors = useConnectors();
  const locationsAndCredentials = connectors?.locationsAndCredentials;
  const locations = locationsAndCredentials?.locations;
  const locationsSettings = connectors?.settings?.filter(
    (set) => set.group === "Location Management"
  );

  const error = connectors?.error;
  const loading = connectors?.loading;

  const columns: ColumnsType<ZscalerLocationType> = locationsSettings
    ?.filter((setting: ZscalerSettingType) => setting.display_item_ui === true)
    .map((setting: ZscalerSettingType) => ({
      title: setting.display_name,
      dataIndex: setting.parameter,
      key: setting.parameter,
      align: "center",
    }));

  const topFilterActions = [
    {
      key: "1",
      text: "Show Sub-Location Parents",
      rule: (items: ZscalerLocationType[]) =>
        items.filter((item: ZscalerLocationType) => item.childCount > 0),
      icon: <ApartmentOutlined />,
      disabled: null,
    },
  ];

  const actionMenu = [
    {
      key: "listDetails",
      component: ShowRecordDetails,
      icon: <ArrowsAltOutlined />,
    },
    {
      key: "modify",
      component: ModifyLocation,
      icon: <EditOutlined />,
      disabled: isZscalerReadOnly,
    },
    {
      key: "credentials",
      component: ListVPNs,
      icon: <KeyOutlined />,
    },
    {
      key: "sublocations",
      label: <Text>Sub-Locations</Text>,
      icon: <ApartmentOutlined />,
      children: [
        {
          key: "create-sub",
          component: CreateSubLocation,
          icon: <AppstoreAddOutlined />,
          disabled: isZscalerReadOnly,
        },
        {
          key: "list-sub",
          component: ListSubLocation,
          icon: <AppstoreOutlined />,
        },
      ],
    },
    {
      key: "divider",
      type: "divider",
    },
    {
      key: "delete",
      component: DeleteLocation,
      danger: true,
      icon: <DeleteOutlined />,
      disabled: isZscalerReadOnly,
    },
  ];

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <BaseTable
        tableName={"Locations"}
        columns={columns}
        dataSource={locations}
        loading={loading && !error}
        topButtons={[<CreateLocation isZscalerReadOnly={isZscalerReadOnly} />]}
        topFiltersActions={topFilterActions}
        // reloadAction={() => connectors?.fetchLocationsAndCredentials()}
        rowActions={actionMenu}
      />
    </Space>
  );
};

export default LocationsManagement;
