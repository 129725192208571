import useSWR from "swr";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const token = cookies.get("access_token");

const fetcher = async (url, token) => {
  return axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
    .then((res) => res.data)
    .catch((error) => console.log(error));
};

export default function useMappers(id = 0) {
  let endpoint = `/api/v1/conversion/mappers`;
  if (id > 0) {
    endpoint = `${endpoint}/${id}`;
  }

  const { data, error } = useSWR([endpoint, token], fetcher);

  return {
    data,
    loading: !error && !data,
    error: error,
  };
}
