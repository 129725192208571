import axios from "axios";

export const parse_datasource = async (site_list: [], datasource: Blob) => {

    const user = JSON.parse(localStorage.getItem("user"));
    const access_token = user?.access_token;

    const bodyFormData = new FormData();
    bodyFormData.append("data_source", datasource);

    const response = {
        data: null,
        error: null
    }

    await axios({
        method: "post",
        url: `/api/v1/contexts?site_list=${site_list}&main_sheet_prepend_all=false`,
        data: bodyFormData,
        headers: {
            Authorization: "Bearer " + access_token,
            "content-type": "xlsx",
        },
    })
        .then((res) => {
            response.data = res.data
        })
        .catch((error) => {
            response.error = error
        });

    return response
};