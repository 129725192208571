import switchesCorrelation from "./portMappingRules/switchesCorrelation";
import uplinksAssignment from "./portMappingRules/uplinksAssignment";
import mmgAssignment from "./portMappingRules/mmgAssignment";
import wapAssignment from "./portMappingRules/wapAssignment";
import otherPortsAssigment from "./portMappingRules/otherPortsAssigment";

export default function portMapping(rolloutSites) {
  for (const site of rolloutSites) {
    const correlation = switchesCorrelation(site);

    uplinksAssignment(correlation);

    // MMG Assignment
    const mmgs = site.devices
      .filter((entry) => entry.type === "MMG")
      .map((entry) => entry.hostname);

    if (mmgs.length > 0) {
      mmgAssignment(mmgs, correlation);
    }

    // WAP Assignment
    const newWaps = site.devices
      .filter((entry) => entry.type === "WAP")
      .map((entry) => entry.hostname);
    wapAssignment(newWaps, correlation, site);

    // Other Ports
    otherPortsAssigment(correlation);

    site["port_mapping"] = correlation.map((item) => item.port_mapping).flat();
  }

  return rolloutSites;
}
