import { Drawer, Space, Divider, Button, Typography } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { ZscalerLocationType } from "../../../../types/ConnectorsType";
import React from "react";
import { getData } from "../ciscoCalls.tsx/get";
import { ciscoAccountType } from "..";
import { LoadingOutlined } from "@ant-design/icons";

const { Text } = Typography;

interface ConnectorTypes {
  uuid: string;
}

type SubProps = {
  record: ConnectorTypes;
  ciscoAccount: ciscoAccountType;
};

const ShowRunning: FC<SubProps> = ({ record, ciscoAccount }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [buttonText, setButtonText] = useState("Copy to Clipboard");

  const recordJsonLike = JSON.stringify(record, null, 4);

  const onClick = useCallback((text: string) => {
    setButtonText("Copied!");
    navigator.clipboard.writeText(text);
    setTimeout(() => {
      setButtonText("Copy to Clipboard");
    }, 2000);
  }, []);

  useEffect(() => {
    const getRunning = async () => {
      setLoading(true);
      // // Call the API with the API key
      const response = await getData("get_device_config_running_by_id", {
        user: ciscoAccount.username,
        password: ciscoAccount.password,
        uuid: record.uuid,
      });
      response.data && setData(response.data);
      response.error && setError(response.error);
      setLoading(false);
    };
    getRunning();
  }, [record]);

  return (
    <>
      <a onClick={() => setOpen(true)}>Show Running</a>
      <Drawer
        title={record.uuid}
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
        width={"50%"}
        extra={
          <Space>
            <Button
              style={{ width: 180 }}
              onClick={() => onClick(data?.config)}
              disabled={!data}
            >
              {buttonText}
            </Button>
          </Space>
        }
      >
        <pre>
          <Text>
            {loading ? (
              <Space direction="horizontal" size={"small"}>
                <LoadingOutlined />
                {"fething config..."}
              </Space>
            ) : (
              data?.config
            )}
          </Text>
        </pre>
      </Drawer>
    </>
  );
};

export default ShowRunning;
