import React, { useState } from "react";
import { Modal, Select, Tag, Typography, message } from "antd";
import axios from "axios";
const { Paragraph, Text } = Typography;

// Gets from local Storage
const user = JSON.parse(localStorage.getItem("user"));
const access_token = user?.access_token;
const account = JSON.parse(localStorage.getItem("currentAccount"));
const account_id = account?.id;
const account_name = account?.name;

const LabselSet = ({ record, setToggleRefresh, labels }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [labelId, setLabelId] = useState(0);

  const setLabel = async () => {
    const templateId = record.id;
    const URL = `/api/v1/templates/${templateId}`;
    const newLabel = {
      label_id: +labelId,
    };
    const options = { headers: { Authorization: "Bearer " + access_token } };
    await axios
      .patch(URL, newLabel, options)
      .then((res) => {
        message.success(`${record.name} successfully deleted.`);
        console.log({ res });
      })
      .catch((err) => {
        message.error(`${record.name} could not be deleted.`);
        console.log({ err });
      })
      .finally(() => {
        setToggleRefresh((prev: boolean) => !prev);
      });
  };

  const showModal = () => {
    if (+record.account_id === +account_id) {
      setIsModalOpen(true);
    } else {
      message.warning(`Template not directly assigned to ${account_name}`);
    }
  };

  const handleOk = () => {
    labelId > 0 && setLabel();
    labelId === 0 && message.error("Select a Label First");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const labelOptions = labels.filter((label: any) => label.label !== "All");

  return (
    <>
      <a onClick={showModal}>Manage Label</a>
      <Modal
        title="Are you sure?"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Select
          placeholder="Set Label"
          style={{ width: "100%", padding: "1rem 0 1rem 0" }}
          defaultValue={record.label?.id}
          onChange={(value: number) => {
            setLabelId(value);
          }}
          options={labelOptions}
        />
      </Modal>
    </>
  );
};

export default LabselSet;
