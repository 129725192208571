import React, { useEffect, useState } from "react";
import { Input, Table, Space, Typography, Button, Spin, Tag } from "antd";
import { ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import ActionColumn from "./ActionColumn";
import { useTables } from "../../contexts/TablesContext";
import type { ColumnsType } from "antd/es/table";

const { Text } = Typography;

const BaseTable = ({
  columns,
  dataSource,
  tableName,
  loading = null,
  topButtons = undefined,
  topFiltersActions = undefined,
  reloadAction = undefined,
  rowKey = "id",
  rowActions = [],
  rowSelectionActions = undefined,
  expandedRowRender = undefined,
  searchBar = true,
}) => {
  const tables = useTables();
  const dispatchTablesRowId = tables.dispatchTablesRowId;

  const [dataSourceInstance, setDataSourceInstance] = useState<any>(dataSource);
  const [searchTags, setSearchTags] = useState<string[]>([]);
  const [autoFocus, setAutoFocus] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState(null);

  // ROW SELECTION START
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    dispatchTablesRowId(tableName, newSelectedRowKeys);
  };

  const rowSelection = {
    hideSelectAll: true,
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;
  // ROW SELECTION END

  const searchableItems = columns?.map((col: any) =>
    Array.isArray(col.dataIndex) ? col.dataIndex[0] : col.dataIndex
  );

  useEffect(() => {
    setDataSourceInstance(dataSource);
    setSearchTags([]);
  }, [dataSource]);

  const clearFilter = () => {
    setDataSourceInstance(dataSource);
    setSearchTags([]);
  };

  // Copy Columns to a mutable instance
  let tableColumns: ColumnsType<any> = columns?.map((col: any) => ({
    ...col,
    key: col.dataIndex,
    shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
  }));

  rowActions.length > 0 &&
    tableColumns?.push({
      title: "Action",
      align: "center",
      width: "6%",
      render: (_: any, record: any) => (
        <ActionColumn record={record} rowActions={rowActions} />
      ),
    });

  // The Component on top of the table with custom buttons and entrie counters with reset filters
  const TableTop = () => {
    // To Clear if data table is filtered
    if (
      topButtons ||
      topFiltersActions ||
      reloadAction ||
      (rowSelectionActions && rowSelectionActions.length > 0)
    ) {
      return (
        <Space>
          {/* Specific Component Buttons */}
          {topButtons}
          {/* Specific Filter Buttons */}
          {topFiltersActions?.map((filter: any, index: number) => (
            <Button
              key={index}
              type="dashed"
              icon={filter.icon}
              onClick={() => {
                setDataSourceInstance(dataSource);
                setDataSourceInstance(filter.rule);
              }}
            >
              {filter.text}
            </Button>
          ))}
          {rowSelectionActions && <SelectionActions />}
          {reloadAction && (
            <Button
              // type="link"
              disabled={dataSource?.length === 0}
              onClick={reloadAction}
              icon={<ReloadOutlined />}
            >
              Reload Table
            </Button>
          )}
        </Space>
      );
    }
    return null;
  };

  // SEARCH SIDE EFFECT
  useEffect(() => {
    const filteredByWords = dataSource
      ?.map(
        (entry: object) =>
          searchTags.every((word) =>
            JSON.stringify(searchableItems.map((key: string) => entry[key]))
              .toLowerCase()
              .includes(word.toLowerCase())
          ) && entry
      )
      .filter((entry: object) => entry);
    setDataSourceInstance(filteredByWords);
  }, [searchTags]);

  const GeneralSearchInput = () => {
    const handleEnter = (value: string) => {
      const valueTrimmed = value?.trim();
      if (valueTrimmed) {
        const values = valueTrimmed.split(" ");
        setSearchTags((prev: string[]) => [...prev, ...values]);
        setInputValue(null);
      }
    };

    const handleSpaces = (key: string) => {
      if (key === " ") handleEnter(inputValue);
      else if (key === "Backspace" && !inputValue) {
        setSearchTags((prev: any) => prev.splice(prev.length, -1));
      }
    };

    const handleCloseTag = (removedTag: string) => {
      const newTags = searchTags.filter((tag) => tag !== removedTag);
      setSearchTags(newTags);
    };

    return (
      <Space direction="vertical" size="small" style={{ width: "100%" }}>
        {/* Displays the Total Entries and Clear Filters if Filtered on Table Right Top Corner */}

        <div style={{ paddingLeft: 4 }}>
          <div style={{ float: "right", paddingRight: 8 }}>
            <Text>
              {dataSource?.length > 0 &&
                `Found ${dataSourceInstance?.length} entries`}
              {dataSource?.length !== dataSourceInstance?.length && (
                <>
                  <Text type="secondary"> | filtered </Text>
                  <Button
                    size="small"
                    type="text"
                    style={{ color: "#ff80ff" }}
                    onClick={() => clearFilter()}
                  >
                    Clear Filter
                  </Button>
                </>
              )}
            </Text>
          </div>
        </div>

        <div>
          <Input
            placeholder={
              searchTags.length < 1
                ? "+ Type to Filter (then press spacebar or enter)"
                : "+ Add more words to your search (then press space or enter)"
            }
            prefix={searchTags.map((tag: string) => (
              <Tag
                bordered={false}
                color="purple"
                closable
                onClose={(e) => {
                  e.preventDefault();
                  handleCloseTag(tag);
                }}
                onMouseOver={() => setAutoFocus(false)}
              >
                {tag}
              </Tag>
            ))}
            suffix={<SearchOutlined style={{ color: "lightgrey" }} />}
            value={inputValue}
            autoFocus={autoFocus}
            onClick={() => setAutoFocus(true)}
            onBlur={() => setAutoFocus(false)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setInputValue(e.target.value.trim())
            }
            onPressEnter={(e: any) => handleEnter(e.target.value)}
            onKeyDown={({ key }) => handleSpaces(key)}
          />
        </div>
      </Space>
    );
  };

  const SelectionActions = () => {
    return (
      <Space wrap>
        {/* <Text type='secondary' style={{ marginLeft: 8 }}>
                {`Selected ${selectedRowKeys.length} rows: `}
            </Text> */}
        {rowSelectionActions?.map((select: any, index: number) => {
          const fn = select.action;
          const icon = select.icon || null;
          return (
            <Button
              icon={icon}
              key={index}
              // type={'text'}
              disabled={!hasSelected}
              // size='small'
              onClick={() => fn(selectedRowKeys)}
            >
              {select.text}
            </Button>
          );
        })}
      </Space>
    );
  };

  return (
    <Spin spinning={loading} tip="Updating Table... please wait.">
      <Space direction="vertical" size="large">
        <TableTop />
        {searchBar && <GeneralSearchInput />}
        <Table
          bordered
          tableLayout="fixed"
          rowKey={rowKey}
          size="small"
          pagination={{
            defaultPageSize: 20,
            hideOnSinglePage: true,
          }}
          columns={tableColumns}
          dataSource={dataSourceInstance}
          rowSelection={rowSelectionActions ? rowSelection : null}
          expandable={expandedRowRender}
        />
      </Space>
    </Spin>
  );
};

export default BaseTable;
