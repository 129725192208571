import React, { memo, useEffect } from 'react'
import { Space, message } from 'antd';

// Contexts
import { useMerakiConnectors } from '../../../../../contexts/Connectors/MerakiContext';
import { NgdsErrorType } from '../../../../../types/NgdsErrorType';
import { ArrowsAltOutlined, DownloadOutlined } from '@ant-design/icons';
import BaseTable from '../../../../../components/Tables/BaseTable';
import { MerakiProductsType } from '../../../../../types/MerakiConnectorsType';
import ShowRecordDetails from '../../../../../components/Tables/ShowRecordDetails';
import type { ColumnsType } from 'antd/es/table/interface';
import ProductsTagColoredStack from '../../functions/TagProductColor.tsx';
import createTagList from '../../functions/createTagList';
import { exportAsExcel } from '../../../../../utils/exportAsExcel';


type DevicesProps = {
    networkId_list?: string[]
}


const Products: React.FC<DevicesProps> = ({ networkId_list }) => {

    const [messageApi, contextHolder] = message.useMessage();

    const meraki = useMerakiConnectors()
    const orgId = meraki.orgId

    const organizations = meraki.organizations
    const loadingOrganizations = meraki.loadingOrganizations
    const errorOrganizations = meraki.errorOrganizations

    const products = meraki.products
    const loadingProducts = meraki.loadingProducts
    const errorProducts = meraki.errorProducts


    const columns: ColumnsType<MerakiProductsType> = [
        {
            title: 'Network Id',
            dataIndex: 'networkId',
            width: "16%"
        },
        {
            title: 'Product Name',
            dataIndex: 'name',
            width: "16%"
        },
        {
            title: 'Product',
            dataIndex: 'productType',
            render: (text: any) => ProductsTagColoredStack([text]),
            width: "10%",
        },
        {
            title: 'Serial',
            dataIndex: 'serial',
            width: "15%"
        },
        {
            title: 'Model',
            dataIndex: 'model',
            width: "10%"
        },
        {
            title: 'MAC',
            dataIndex: 'mac',
            width: "12%"
        },
        {
            title: 'LAN IP',
            dataIndex: 'lanIp',
            width: "10%"
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            width: "10%",
            render: (text: string[]) => createTagList(text)
        },
        // {
        //     title: 'Address',
        //     dataIndex: 'address',
        // },
        // {
        //     title: 'Latitude',
        //     dataIndex: 'lat',
        // },
        // {
        //     title: 'Longitude',
        //     dataIndex: 'lng',
        // },
        // {
        //     title: 'Timezone',
        //     dataIndex: 'timeZone',
        // },
        // {
        //     title: 'Products',
        //     dataIndex: 'productTypes',
        //     render: (text: any) => ProductsTagColoredStack(text),
        // },
    ];

    // Error Handling
    useEffect(() => {
        const errorMessage = (error: NgdsErrorType) => {
            messageApi.open({
                type: 'error',
                content: `${error.response.data.error}`,
                duration: 5,
                style: {
                    marginTop: 64,
                },
            }
            );
        };

        errorProducts && errorMessage(errorProducts)
        errorOrganizations && errorMessage(errorOrganizations)

    }, [errorProducts, errorOrganizations])


    const actionMenu = [
        {
            key: 'listDetails',
            component: ShowRecordDetails,
            icon: <ArrowsAltOutlined />
        }
    ]

    // const handleSelection = (selected: string[]) => {
    //     console.log({ selected_from_meraki: selected })
    // }

    const rowSelectionActions = [
        // {
        //     key: '1',
        //     text: 'Selection to Excel',
        //     icon: <DownloadOutlined />,
        //     type: "primary",
        //     action: async (selected: string[]) => {
        //         console.log({ selected_from_meraki: selected })
        //         const selectedData = products.filter(device => selected.includes(device.serial))
        //         console.log({ selectedData })
        //         const isCreated = await exportAsExcel({ products: selectedData })
        //         isCreated ? console.log("File Downloaded") : console.log("Failed Download")
        //     },
        // }
    ]


    return (
        <Space direction='vertical' style={{ width: "100%", marginTop: 16 }}>
            {contextHolder}
            {/* ORGANIZATION SELCTION */}
            {products
                && < BaseTable
                    loading={loadingProducts}
                    tableName={`Products`}
                    dataSource={products}
                    columns={columns}
                    // reloadAction={() => meraki?.fetchDevices(orgId)}
                    rowActions={actionMenu}
                    rowSelectionActions={true}
                    rowKey={'serial'}
                />

            }
        </Space >

    )
}

export default Products