import React from "react";
import { ChildrenProps } from "../../types/ChildrenReactType";
import { theme } from "antd";

const ContentWrapper: React.FC<ChildrenProps> = ({ children }) => {
  const { token } = theme.useToken();
  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    backgroundColor: "#ffffff",
    padding: "1.5em 2rem 2rem 2rem",
    borderRadius: token.borderRadiusLG,
    border: `1px solid ${token.colorBorder}`,
    width: "100%",
    height: "100%",
  };
  return <div style={contentStyle}>{children}</div>;
};

export default ContentWrapper;
