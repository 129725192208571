import { useGitLabConnectors } from "../../contexts/Connectors/GitLabContext"
import { useEffect, useState } from 'react'
import { Tree } from 'antd';
import type { GetProps, TreeDataNode } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

type DirectoryTreeProps = GetProps<typeof Tree.DirectoryTree>;

const { DirectoryTree } = Tree;

const MerakiScripts: React.FC  = () => {

    // Modal Start

    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('');
    const [downloadPath, setDownloadPath] = useState(null)

    // Modal End

  const gitlab = useGitLabConnectors()
  const files = gitlab.files
  const loadingFiles= gitlab.loadingFiles
  const errorFiles = gitlab.errorFiles
  const list_files = gitlab.list_files
  const downloadFile = gitlab.get_file
  const loadingDownload = gitlab.loadingDownload


  console.log({loadingDownload})

  const gitRepo = "meraki_scripts"

  useEffect(() => {
    if (!files && !loadingFiles) list_files && list_files(gitRepo)
  }, [])
  
  const onSelect: DirectoryTreeProps['onSelect'] = (keys: string[], info: any) => {
    // console.log('Trigger Select', keys, info.node);
    setModalText(info.node.title)
    setDownloadPath(info.node.path)
    setOpen(true)
  };

  // const onExpand: DirectoryTreeProps['onExpand'] = (keys: string[], info: any)=> {
  //   console.log('Trigger Expand', keys, info);
  // };

  useEffect(() => {setOpen(loadingDownload)}, [loadingDownload])


  return (
    <>
      <h1>Meraki Scripts</h1>
      {files ? <Tree
        showLine
        switcherIcon={<DownOutlined />}
        defaultExpandAll
        onSelect={onSelect}
        // onExpand={onExpand}
        treeData={files}
      /> : "Loading..."}

      <Modal
        title="Proceed with download?"
        open={open}
        okText="Download"
        onOk={async() => await downloadFile(gitRepo, downloadPath)}
        confirmLoading={loadingDownload}
        onCancel={() => setOpen(false)}
        cancelButtonProps={{disabled: true}}
      >
        <p>{modalText}</p>
      </Modal>
    </>

  )
}

export default MerakiScripts