import React from "react";
import { Dropdown, MenuProps, Space, Typography } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";
const { Text } = Typography;

const ActionColumn = ({ record, rowActions }) => {
  const items: MenuProps["items"] = [
    {
      key: "ActionDropdown",
      type: "group",
      label: (
        <Text style={{ color: "#5514B4", width: 200 }} ellipsis>
          {record.name || record.fqdn || "Actions"}
        </Text>
      ),
      children: rowActions.map((action: any) => {
        const Component = action.component || null;
        const componentProps = action.props || null;
        const children = action.children?.map((child: any) => {
          const ChildComponent = child.component || null;
          const isDisabled = child.disabled || false;
          return {
            ...child,
            disabled: isDisabled,
            label: ChildComponent ? (
              <ChildComponent record={record} {...child.props} />
            ) : (
              child.label
            ),
          };
        });
        return {
          ...action,
          label: Component ? (
            <Component record={record} {...componentProps} />
          ) : (
            action.label
          ),
          children,
        };
      }),
    },
  ];

  return (
    <>
      <Dropdown
        // placement='topLeft'
        menu={{ items }}
        trigger={["click"]}
      >
        <Space>
          <UnorderedListOutlined style={{ cursor: "pointer" }} />
        </Space>
      </Dropdown>
    </>
  );
};

export default ActionColumn;
