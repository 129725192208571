// React dependencies
// React dependencies
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";

// Material UI components
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// Custom components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import CustomCard from "../../components/Card/CustomCard.js";

// SVG images
import excel from "./../../assets/images/xls.svg";
import manually from "./../../assets/images/keyboard.svg";
import autodata from "./../../assets/images/future.svg";

// Custom views
import ManuallyView from "./JourneyViews/ManuallyView";
import ExcelView from "./JourneyViews/ExcelView";
import AutoDataApigwView from "./JourneyViews/AutoData.js";

//Toaster
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//redux
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 5,
    marginBottom: "80px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "40%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function JourneyPageMain(props) {
  const cookies = new Cookies();
  const selectedAccount = useSelector((state) => state.account.value);
  const [csrMode, setCsrMode] = useState("None");
  const toastId = React.useRef(null);
  const classes = useStyles();
  const toastWarning = (message) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.warning(message);
    }
  };
  useEffect(() => {
    setCsrMode("None");
  }, [props.templateID, selectedAccount]);

  const setMode = (mode) => {
    const account_id = cookies.get("account_id");
    if (mode === "AutoDataApigw") {
      if (account_id === "None" || account_id === "null") {
        toastWarning("Select an account from the NavBar to use this feature.");
      } else {
        setCsrMode(mode);
      }
    } else {
      setCsrMode(mode);
    }
  };
  return (
    <Container>
      <Header />
      <Grid container className={classes.root} justify="center" spacing={3}>
        <Grid item xs={12}>
          <div className="title-container">
            <h3>How shall we populate this design?</h3>
          </div>
        </Grid>
        <Grid item xs={3}>
          <CustomCard
            title="Excel"
            description=""
            label="Select"
            image={excel}
            action={() => {
              setMode("Excel");
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomCard
            title="Manually"
            description=""
            label="Select"
            image={manually}
            action={() => {
              setMode("Manually");
            }}
          />
        </Grid>

        {/* AutoData will be showed only for Catalogue Journey */}
        {props.libraryID === 1 ? (
          <Grid item xs={3}>
            <CustomCard
              title="AutoData"
              description=""
              label="Select"
              image={autodata}
              action={() => {
                setMode("AutoDataApigw");
              }}
            />
          </Grid>
        ) : null}

        <Grid item xs={12} id="csr-journeys">
          {csrMode === "Excel" ? (
            <ExcelView
              template_id={props.templateID}
              exampleDcf={props.exampleDcf}
            />
          ) : null}
          {csrMode === "Manually" ? (
            <ManuallyView template_id={props.templateID} />
          ) : null}
          {csrMode === "AutoDataApigw" ? (
            <AutoDataApigwView template_id={props.templateID} />
          ) : null}
        </Grid>
      </Grid>
      <ToastContainer />
      <Footer />
    </Container>
  );
}
