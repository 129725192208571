import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

function ProductsList({ siteProp, rolloutSites, setRolloutSites }) {
  //Filter products for site on Focus
  const siteProducts = rolloutSites.filter(
    (site) => site.name === siteProp.name
  )[0].products;

  // Validation to avoid negative numbers or decimals or numbers great that 999
  const handleInput = (e) => {
    const minValue = 0;
    const maxValue = 999;
    e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 4);
    if (!e.target.value || e.target.value < minValue) {
      e.target.value = minValue;
    } else if (e.target.value > maxValue) {
      e.target.value = maxValue;
    }
  };

  const handleChange = (e, row) => {
    setRolloutSites((prevState) => {
      return prevState.map((item) =>
        item.name == siteProp.name
          ? {
              ...item,
              products: item.products.map((part) =>
                part.part_number === row.part_number
                  ? { ...part, quantity: parseInt(e.target.value) }
                  : part
              ),
            }
          : item
      );
    });
  };

  const InputPropsData = {
    disableUnderline: true,
    inputProps: {
      min: 0,
      max: 999,
      style: {
        fontSize: 13,
        backgroundColor: "#f5f5f5",
        paddingLeft: 15,
        borderRadius: 15,
        textAlign: "center",
      },
    },
  };

  return (
    <div style={{ padding: "1rem" }}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Vendor</strong>
              </TableCell>
              <TableCell align="right">
                <strong>Type</strong>
              </TableCell>
              <TableCell align="right">
                <strong>Description</strong>
              </TableCell>
              <TableCell align="right">
                <strong>Part Number</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Quantity</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {siteProducts.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="left">{row.vendor}</TableCell>
                <TableCell align="right">{row.type}</TableCell>
                <TableCell align="right">{row.description}</TableCell>
                <TableCell align="right">{row.part_number}</TableCell>
                <TableCell align="right">
                  <TextField
                    InputProps={InputPropsData}
                    id="standard-number"
                    type="number"
                    variant="standard"
                    defaultValue={row.quantity}
                    // Validation
                    onInput={handleInput}
                    onBlur={(e) => handleChange(e, row)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ProductsList;
