import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
export default function SpinnerLoader() {
  //other logic

    return (
      <Loader
        type="Oval"
        color="#5514b4"
        height={100}
        width={100}
        timeout={3000000} //300 secs
      />
    );
  
}