import { useDropzone } from "react-dropzone";
import { useMemo, useEffect } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ArticleIcon from "@mui/icons-material/Article";
import { IconButton } from "@mui/material";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  // boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
};

const focusedStyle = {
  borderColor: "#2196f3",
  borderStyle: "dashed",
};

const acceptStyle = {
  borderColor: "#00e676",
  borderStyle: "dashed",
};

const rejectStyle = {
  borderColor: "#ff1744",
  borderStyle: "dashed",
};

const inputBox = {
  padding: 10,
  backgroundColor: "white",
  color: "rgb(85, 20, 180)",
  width: "100%",
  borderRadius: 3,
  marginBottom: 10,
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
};

export default function DragDropzone({
  additionalSources,
  setAdditionalSources,
  classes,
}) {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    maxFiles: 10,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const removeDuplicates = (array) => {
    return array.filter(
      (data, index) =>
        index === array.findIndex((obj) => obj.name === data.name)
    );
  };

  const handleDelete = (filename) => {
    setAdditionalSources((prevState) =>
      prevState.filter((file) => file.name !== filename)
    );
  };

  useEffect(() => {
    const newAdditionals = removeDuplicates([
      ...additionalSources,
      ...acceptedFiles,
    ]);

    setAdditionalSources(newAdditionals);
  }, [acceptedFiles]);

  const filesDisplay = additionalSources.map((file) => (
    <div className={classes.inputTemplate} key={file.path}>
      <div>
        <ArticleIcon sx={{ marginRight: 1 }} />
      </div>
      <div>{file.path}</div>
      <div className={classes.flexLastRight}>
        <IconButton
          aria-label="Delete"
          onClick={() => handleDelete(file.name)}
          sx={{ "&:hover": { color: "salmon" } }}
        >
          <DeleteForeverIcon />
        </IconButton>
      </div>
    </div>
  ));

  return (
    <section className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop additional data sources here, or click to select</p>
      </div>
      <br />
      <aside>{filesDisplay}</aside>
    </section>
  );
}
