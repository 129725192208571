import { Space, Modal, Typography } from "antd";
import React, { FC, useState } from "react";
import { useConnectors } from "../../../../../contexts/Connectors/ZscalerContext";
import {
  ZscalerLocationType,
  VpnCredentialType,
} from "../../../../../types/ConnectorsType";
import ResultError from "../../Results/ResultError";
import { LoadingProgress } from "../../LoadingProgress";
import { NgdsErrorType } from "../../../../../types/NgdsErrorType";
import {
  deleteLocation,
  deleteVpnCredential,
} from "../../functions/fetchZscaler";

import { sleep } from "../../../../../utils/sleep";

const { Text } = Typography;

type Props = {
  record: ZscalerLocationType;
};

const DeleteLocation: FC<Props> = ({ record }) => {
  const connectors = useConnectors();

  // Delete Location States
  const [deleteLocationLoading, setDeleteLocationLoading] =
    useState<boolean>(false);
  const [deleteLocationError, setDeleteLocationError] = useState<
    NgdsErrorType | undefined
  >(undefined);
  const [deletedLocationOK, setDeleteLocationOk] = useState<boolean>(false);

  // Delete VPN States
  const [deleteVPNLoading, setDeleteVPNLoading] = useState<boolean>(false);
  const [deleteVPNError, setDeleteVPNError] = useState<
    NgdsErrorType | undefined
  >(undefined);
  const [deletedVPNOK, setDeleteVPNOk] = useState<boolean>(false);

  const [open, setOpen] = useState(false);
  const [progressDelete, setProgressDelete] = useState(false);

  const onReset = () => {
    setProgressDelete(false);
  };

  const deleteLocationAndVpn = async (record: ZscalerLocationType) => {
    console.log(`Deleting Location ID ${record.id}`);
    setProgressDelete(true);
    setDeleteLocationLoading(true);
    const response = await deleteLocation({ location_id: record.id });
    setDeleteLocationLoading(false);

    if (response?.hasOwnProperty("error"))
      setDeleteLocationError({ ...response.error });
    else if (response.status !== 200)
      setDeleteLocationError({
        error: "Something whent wrong",
        response: null,
      });
    else setDeleteLocationOk(true);

    setDeleteVPNLoading(true);
    console.log("VPN Credential Delete - Preparing");
    await sleep(5000);
    console.log("VPN Credential Delete - Go");
    record.vpnCredentials.forEach(async (vpn: VpnCredentialType) => {
      console.log(`Deleting Location VPN Credential ID ${vpn.id}`);
      setDeleteVPNLoading(true);
      const response = await deleteVpnCredential({ vpn_credential_id: vpn.id });
      setDeleteVPNLoading(false);

      if (response?.hasOwnProperty("error"))
        setDeleteVPNError({ ...response.error });
      else if (response.status !== 200)
        setDeleteVPNError({ error: "Something whent wrong", response: null });
      else setDeleteVPNOk(true);
    });
    setDeleteVPNLoading(false);
  };

  const handleCancel = () => {
    if (progressDelete) {
      // connectors?.fetchLocations();
      // progressDelete && connectors?.fetchVpnCredentials();
      connectors?.fetchLocationsAndCredentials()
      // progressDelete && connectors?.fetchLocationsAndCredentials();
    }
    onReset();
    setOpen(false);
  };

  const progressParams = {
    descriptors: [
      {
        key: "1",
        label: "Location ID",
        value: record.id,
      },
      {
        key: "2",
        label: "Location Name",
        value: record.name,
      },
      {
        key: "3",
        label: "VPN Credential ID",
        value: record?.vpnCredentials?.map((vpn) => (
          <>
            <Text key={vpn.id}>{vpn.id}</Text>
            <br />
          </>
        )),
      },
      {
        key: "4",
        label: "FQDN",
        value: record?.vpnCredentials?.map((vpn) => (
          <>
            <Text key={vpn.fqdn}>{vpn.fqdn}</Text>
            <br />
          </>
        )),
      },
    ],
    progressors: [
      {
        key: "1",
        text: "Deleting Location",
        loading: deleteLocationLoading,
        exception: deleteLocationError,
        success: deletedLocationOK,
        dependOn: progressDelete,
      },
      {
        key: "2",
        text: "Deleting VPN Credentials",
        loading: deleteVPNLoading,
        exception: deleteVPNError,
        success: deletedVPNOK,
        dependOn: deletedLocationOK,
      },
    ],
  };

  return (
    <>
      <a onClick={() => setOpen(true)}>Delete Location</a>
      <Modal
        maskClosable={!deleteLocationLoading}
        title="Are sure you want to delete it?"
        open={open}
        onOk={() => deleteLocationAndVpn(record)}
        okText={"Delete"}
        okButtonProps={{
          style: { backgroundColor: !progressDelete ? "red" : undefined },
          disabled: progressDelete,
        }}
        cancelButtonProps={{ disabled: progressDelete }}
        confirmLoading={deleteLocationLoading || deleteVPNLoading}
        onCancel={handleCancel}
        closable={!deleteLocationLoading}
      >
        <Space direction="vertical" size="large" wrap style={{ width: "100%" }}>
          {LoadingProgress(progressParams)}
          {deletedVPNOK && deletedLocationOK ? (
            <Text>Task completed!</Text>
          ) : deleteVPNError ? (
            <ResultError errorPayload={deleteVPNError} />
          ) : (
            deleteLocationError && (
              <ResultError errorPayload={deleteLocationError} />
            )
          )}
        </Space>
      </Modal>
    </>
  );
};

export default DeleteLocation;
