import { Space, Modal, Typography } from "antd";
import React, { FC, useState } from "react";
import { useConnectors } from "../../../../../contexts/Connectors/ZscalerContext";
import { VpnCredentialType } from "../../../../../types/ConnectorsType";
import ResultError from "../../Results/ResultError";
import { LoadingProgress } from "../../LoadingProgress";
import { deleteVpnCredential } from "../../functions/fetchZscaler";
import { NgdsErrorType } from "../../../../../types/NgdsErrorType";

const { Text } = Typography;

type Props = {
  record: VpnCredentialType;
};

const DeleteVpnCredential: FC<Props> = ({ record }) => {
  const connectors = useConnectors();

  const [open, setOpen] = useState(false);
  const [progressDelete, setProgressDelete] = useState(false);

  // Delete VPN States
  const [deleteVPNLoading, setDeleteVPNLoading] = useState<boolean>(false);
  const [deleteVPNError, setDeleteVPNError] = useState<
    NgdsErrorType | undefined
  >(undefined);
  const [deletedVPNOK, setDeleteVPNOk] = useState<boolean>(false);

  const handleDeleteFunction = async (vpn_credential_id: number) => {
    setDeleteVPNLoading(true);

    console.log(`Deleting Location VPN Credential ID ${vpn_credential_id}`);
    setDeleteVPNLoading(true);
    const response = await deleteVpnCredential({ vpn_credential_id });
    setDeleteVPNLoading(false);
    if (response?.hasOwnProperty("error"))
      setDeleteVPNError({ ...response.error });
    else if (response.status !== 200)
      setDeleteVPNError({ error: "Something whent wrong", response: null });
    else setDeleteVPNOk(true);
    setDeleteVPNLoading(false);
  };

  const onReset = () => {
    setProgressDelete(false);
  };

  const handleCancel = () => {
    if (deletedVPNOK) {
      // connectors?.fetchLocations();
      // connectors?.fetchVpnCredentials();
      connectors?.fetchLocationsAndCredentials();
    }
    onReset();
    setOpen(false);
  };

  const progressParams = {
    descriptors: [
      {
        key: "1",
        label: "VPN Credential ID",
        value: record.id,
      },
      {
        key: "2",
        label: "FQDN",
        value: record.fqdn,
      },
    ],
    progressors: [
      {
        key: "3",
        text: "Deleting VPN Credentials",
        loading: deleteVPNLoading,
        exception: deleteVPNError,
        success: deletedVPNOK,
        dependOn: progressDelete,
      },
    ],
  };

  console.log({
    deleteVPNLoading,
    deleteVPNError,
    deletedVPNOK,
  });

  const handleDelete = async () => {
    setProgressDelete(true);
    await handleDeleteFunction(record.id);
  };

  return (
    <>
      <a onClick={() => setOpen(true)}>Delete Credential</a>
      <Modal
        maskClosable={!deleteVPNLoading}
        title="Are sure you want to delete it?"
        open={open}
        onOk={handleDelete}
        okText={"Delete"}
        okButtonProps={{
          style: { backgroundColor: !progressDelete ? "red" : undefined },
          disabled: progressDelete,
        }}
        cancelButtonProps={{ disabled: progressDelete }}
        confirmLoading={deleteVPNLoading}
        onCancel={handleCancel}
        closable={!deleteVPNLoading}
      >
        <Space direction="vertical" size="large" wrap style={{ width: "100%" }}>
          {LoadingProgress(progressParams)}

          {deletedVPNOK ? (
            <Text>Task completed!</Text>
          ) : (
            deleteVPNError && <ResultError errorPayload={deleteVPNError} />
          )}
        </Space>
      </Modal>
    </>
  );
};

export default DeleteVpnCredential;
