import React, { useState } from "react";
import { Modal, Typography, message } from "antd";
import axios from "axios";
const { Paragraph, Text } = Typography;

// Gets from local Storage
const user = JSON.parse(localStorage.getItem("user"));
const access_token = user?.access_token;
const account = JSON.parse(localStorage.getItem("currentAccount"));
const account_id = account?.id;
const account_name = account?.name;

const DeleteFile = ({ record, setToggleRefresh }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const deleteTemplate = async (templateId: number) => {
    await axios({
      method: "delete",
      url: "/api/v1/templates/" + templateId,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
      },
    })
      .then((res) => {
        message.success(`${record.name} successfully deleted.`);
        console.log({ res });
      })
      .catch((err) => {
        message.error(`${record.name} could not be deleted.`);
        console.log({ err });
      })
      .finally(() => {
        setToggleRefresh((prev: boolean) => !prev);
      });
  };

  const showModal = () => {
    if (+record.account_id === +account_id) {
      setIsModalOpen(true);
    } else {
      message.warning(`Template not directly assigned to ${account_name}`);
    }
  };

  const handleOk = () => {
    deleteTemplate(record.id);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <a onClick={showModal}>Delete File</a>
      <Modal
        title="Are you sure?"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Paragraph>
          You are about to delete: <strong>{record.name}</strong>
        </Paragraph>
        <Paragraph strong type="danger">
          You won't be able to revert this!
        </Paragraph>
      </Modal>
    </>
  );
};

export default DeleteFile;
