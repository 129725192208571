import axios from "axios";

// Main Function

export const fetchZscaler = async (
  action: string,
  params?: any
): Promise<any> => {
  //  Get Localstorage Data
  const user = JSON.parse(localStorage.getItem("user"));
  const account = JSON.parse(localStorage.getItem("currentAccount"));
  const account_id = account?.id;
  const access_token = user?.access_token;
  const cloud = localStorage.getItem("zscaler_cloud_domain");

  console.log("Running Action: " + action);
  console.log({
    user,
    account,
    cloud,
  });

  if (!account_id || !access_token)
    return {
      error:
        !account_id &&
        "Account ID not found" &&
        !access_token &&
        " Access Token not Found",
    };

  const body = {
    connector: "zscaler",
    account_id: +account_id,
    action,
    params: {
      ...params,
      cloud,
    },
  };

  return await axios
    .post("/api/v1/api_connectors", body, {
      headers: { Authorization: "Bearer " + access_token },
    })
    .then((response: any) => {
      const status = response.status;
      const data = response.data;
      console.log(`${status} - Success Response from ${action}`);
      console.log({ response });
      return {
        status,
        data,
        response,
      };
    })
    .catch((err: any) => {
      const error = err.response?.data;
      const status = error?.status;
      console.log(`${status} - Failed Response from ${action}`);
      console.log({ err });
      return {
        status,
        error,
        response: err,
      };
    });
};

// CREATE VPN CREDENTIAL
export const vpnCreate = async (params: any): Promise<any> => {
  return await fetchZscaler("add_vpn_credential", params);
};

// CREATE LOCATION
export const addLocation = async (params: any): Promise<any> => {
  return await fetchZscaler("add_location", params);
};

// DELETE CREDENTIAL
export const deleteVpnCredential = async (params: any): Promise<any> => {
  return await fetchZscaler("delete_vpn_credential", params);
};

// DELETE LOCATION
export const deleteLocation = async (params: any): Promise<any> => {
  return await fetchZscaler("delete_location", params);
};

// LIST SUBLOCATION
export const listSublocationsByParentId = async (params: any): Promise<any> => {
  return await fetchZscaler("list_sub_locations_by_parent_id", params);
};

// UPDATE LOCATION
export const updateLocation = async (params: any): Promise<any> => {
  return await fetchZscaler("update_location", params);
};

// UPDATE LOCATION
export const updateLocationVpnCredentials = async (
  params: any
): Promise<any> => {
  return await fetchZscaler("update_location_vpn_credentials", params);
};

// UPDATE List Sublocations
export const listSublocationByParentId = async (params: any): Promise<any> => {
  return await fetchZscaler("list_sub_locations_by_parent_id", params);
};

// Get Not Associated Credentials
export const listVpnCredentialsNotAssociated = async (
  params: any
): Promise<any> => {
  return await fetchZscaler("list_vpn_credentials_not_associated", params);
};
