import { PlusOutlined } from "@ant-design/icons";
import { Button, Drawer, Flex } from "antd";
import React, { useState } from "react";
import ButtonDrawer from "./ButtonDrawer";
import DataSourceSelect from "./DataSourceSelect";
import SiteSelect from "./SiteSelect";
import CreatePayloads from "./_CreatePayloads";
import DeployPayloads from "./_DeployPayloads";
import ParseContext from "./ParseContext";
import DeployContext from "./DeployContext";

export interface jsonPayloadsType {
  key: number;
  title: string;
  data: string;
  apiTarget: any;
  additional?: any;
}

export interface apiResponsesType {
  key: number;
  title: string;
  data: string;
}

const DEFAULT = {
  dataSource: undefined,
  selected: undefined,
  deploying: false,
  apiResponses: [],
  disabled: false,
  extracted: [],
  context: [],
};

const DeploySite = () => {
  const [dataSource, setDataSource] = useState<any>(DEFAULT.dataSource);
  const [selected, setSelected] = useState(DEFAULT.selected);
  const [apiResponses, setApiResponses] = useState<apiResponsesType[]>(
    DEFAULT.apiResponses
  );
  const [extracted, setExtracted] = useState(DEFAULT.extracted);
  const [context, setContext] = useState(DEFAULT.context);

  const [disabled, setDisabled] = useState(DEFAULT.disabled);
  const [deploying, setDeploying] = useState(DEFAULT.deploying);

  const reset = () => {
    setDataSource(DEFAULT.dataSource);
    setSelected(DEFAULT.selected);
    setApiResponses(DEFAULT.apiResponses);
    setExtracted(DEFAULT.extracted);
    setContext(DEFAULT.context);
    setDisabled(DEFAULT.disabled);
    setDeploying(DEFAULT.deploying);
  };

  return (
    <ButtonDrawer reset={reset}>
      <Flex vertical gap="middle">
        {/* DATA SOURCE */}
        {!disabled && (
          <DataSourceSelect
            dataSource={dataSource}
            setDataSource={setDataSource}
            reset={() => reset()}
          />
        )}
        {/* SITE SELECT */}
        {dataSource && (
          <SiteSelect
            dataSource={dataSource}
            selected={selected}
            setSelected={setSelected}
            disabled={disabled}
          />
        )}

        {/* CREATE PAYLOADS */}
        {dataSource && selected && deploying === false && (
          <ParseContext
            dataSource={dataSource}
            selected={selected}
            extracted={extracted}
            setExtracted={setExtracted}
            disabled={disabled}
            setDisabled={setDisabled}
            context={context}
            setContext={setContext}
          />
        )}

        {extracted?.length > 0 && (
          <DeployContext
            context={context}
            extracted={extracted}
            deploying={deploying}
            setDeploying={setDeploying}
          />
        )}
      </Flex>
    </ButtonDrawer>
  );
};

export default DeploySite;
