import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

//material ui components
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import LibraryManageView from "./LibraryManageView";

import { handleHttpError } from "../../common/utils.js";
import { LayoutPage } from "../JourneyPage/JourneyPage.js";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "80px",
  },
  purple: {
    color: "rgb(85, 20, 180)",
    paddingBottom: 10,
  },
}));

export default function LibraryManagePage(props) {
  const classes = useStyles();
  const cookies = new Cookies();
  const scopes = cookies.get("scopes");
  const [isLoading, setLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);
  const [options, setOptions] = useState();
  const [libraryId, setLibraryId] = useState();
  const [libraryName, setLibraryName] = useState();
  const [ready, setReady] = useState(false);

  const handleChange = (event) => {
    const selected_id = event.target.value;
    console.log(selected_id);
    if (selected_id !== "None") {
      setLibraryId(parseInt(event.target.value));
      setReady(true);
    } else {
      setLibraryId(event.target.value);
      setReady(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (scopes.includes("admin")) {
      setHasAccess(false);
      axios
        .get(`/api/v1/libraries`, {
          headers: { Authorization: "Bearer " + props.access_token },
        })
        .then((response) => {
          const libraries = response.data;
          const optionsPayload = [{ value: "None", name: "Select a library" }];
          libraries.forEach((entry) => {
            const library_name = entry.name;
            const library_id = entry.id;
            optionsPayload.push({ value: library_id, name: library_name });
          });
          setOptions(optionsPayload);
          setLoading(false);
          setHasAccess(true);
        })
        .catch(function (error) {
          handleHttpError(error);
        });
    } else {
      setLoading(false);
    }
  }, [libraryId]);

  if (isLoading) {
    return (
      <LayoutPage>
        <Grid container className={classes.root} justify="center" spacing={3}>
          <div className="App">Loading...</div>
        </Grid>
      </LayoutPage>
    );
  }
  if (hasAccess) {
    return (
      <LayoutPage maxWidth="lg">
        <Grid item xs={12}>
          <div className="title-container">
            <h1>Library Management</h1>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div id="library_list" className="title-container">
            <FormControl className={classes.formControl}>
              <Select
                native
                value={libraryId}
                onChange={handleChange}
                inputProps={{
                  name: "library",
                  id: "library-select",
                }}
              >
                <optgroup label="Available Libraries">
                  {options.map((e, key) => {
                    return (
                      <option key={key} value={e.value}>
                        {e.name}
                      </option>
                    );
                  })}
                </optgroup>
              </Select>
            </FormControl>
          </div>
          {ready ? (
            <LibraryManageView
              library={libraryId}
              access_token={props.access_token}
            />
          ) : null}
        </Grid>
      </LayoutPage>
    );
  } else {
    return (
      <LayoutPage>
        <Grid container className={classes.root} justify="center" spacing={3}>
          <div className="App">
            You do not have access to this feature. Contact the development team
            if you believe this is not correct.
          </div>
        </Grid>
      </LayoutPage>
    );
  }
}
