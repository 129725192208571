export const useLocalStorage = () => {

    const setStorageItem = (key: string, value: any) => {
        if (value instanceof Object) {
            value = JSON.stringify(value)
        }
        localStorage.setItem(key, value);
    };

    const getStorageItem = (key: string): any => {
        const value = localStorage.getItem(key);
        if (value) return JSON.parse(value);
        return undefined
    };

    const removeStorageItem = (key: string) => {
        localStorage.removeItem(key);
    };

    const removeAllStorageItens = () => {
        localStorage.clear()
    }

    return {
        setStorageItem,
        getStorageItem,
        removeStorageItem,
        removeAllStorageItens,
    };
};