import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import HeaderComponent from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Cookies from "universal-cookie";
import { handleHttpError, dynamicSort} from "../../common/utils.js";
import CustomButton from "../../components/Button/CustomButton.js";

//swal
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

//material ui components
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, Modal, Header } from "semantic-ui-react";

//toaster components
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function createData(
  account,
  originalkey,
  newkey,
  prefix,
  suffix,
  where,
  action
) {
  return { account, originalkey, newkey, prefix, suffix, where, action };
}

function reducer(state, action) {
  switch (action.type) {
    case "OPEN_MODAL":
      return { open: true, dimmer: action.dimmer };
    case "CLOSE_MODAL":
      return { open: false };
    default:
      throw new Error();
  }
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: "150px",
  },
  textfield_root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  center: {
    textAlign: "center",
  },
  arrow: {
    fontSize: 50,
  },
  attributeTextfield: {
    paddingRight: 30,
  },
  formControl: {
    paddingRight: 30,
  },
  saveRule: {
    marginTop: 40,
  },
  textFieldPadding: {
    paddingRight: 20,
    paddingBottom: 20,
  },
  backMessage: {
    marginTop: "120px",
    textAlign: "left",
    cursor: "pointer",
    display: "block",
    height: "100%",
    width: "100%",
    "&:hover": {
      textDecoration: "underline",
      color: "rgb(85,20,180)",
    },
  },
}));

export default function MapperView(props) {
  const classes = useStyles();
  const cookies = new Cookies();
  const history = useHistory();
  const [operator, setOperator] = React.useState("contains");
  const [tableReady, setTableReady] = React.useState(false);
  const [rows, setRows] = React.useState(null);
  const originalMapperNameRef = useRef();
  const mapperNameRef = useRef();
  const originalDescriptionRef = useRef();
  const mapperDescriptionRef = useRef();
  const [masterRules] = React.useState({});
  const [existingMapperName, setExistingMapperName] = React.useState("");
  const [existingMapperDesc, setExistingMapperDesc] = React.useState("");
  const newKeyRef = useRef();
  const originalKeyRef = useRef();
  const prefixRef = useRef();
  const suffixRef = useRef();
  const attributeRef = useRef();
  const searchValueRef = useRef();
  const editOriginalKeyRef = useRef();
  const editNewKeyRef = useRef();
  const editSuffixRef = useRef();
  const editPrefixRef = useRef();
  const editAttributeRef = useRef();
  const editSearchValueRef = useRef();
  const editOperatorRef = useRef();
  const [accountsData, setAccountsData] = useState([]);
  const [accountsDict, setAccountsDict] = useState(null);
  const [editOriginalValue, setEditOriginalValue] = React.useState("");
  const [editNewKeyValue, setEditNewKeyValue] = React.useState("");
  const [editSuffixValue, setEditSuffixValue] = React.useState("");
  const [editPrefixValue, setEditPrefixValue] = React.useState("");
  const [editAttributeValue, setEditAttributeValue] = React.useState("");
  const [editSearchValue, setEditSearchValue] = React.useState("");
  const [editAccountValue, setEditAccountValue] = React.useState("None");
  const [editRuleWithIndex, setEditRuleWithIndex] = React.useState(null);
  let originalValue = null;
  let _prefixValue = null;
  let _newKeyValue = null;
  let _suffixValue = null;
  const [account, setAccount] = React.useState(null);
  const [accountEditor, setAccountEditor] = React.useState(null);

  const handleChangeAccount = (event) => {
    const account_id = event.target.value;
    setAccount(account_id);
  };
  const handleChangeAccountEditor = (event) => {
    const account_id = event.target.value;
    setAccountEditor(account_id);
  };

  const [stateChecked, setStateChecked] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
  });
  const MySwal = withReactContent(Swal);
  const [status, setStatus] = useState(true);
  const [showNewRule, setShowNewRule] = useState(false);
  const [state, dispatch] = React.useReducer(reducer, {
    open: false,
    dimmer: undefined,
  });
  const { open, dimmer } = state;
  const handleOperator = (event) => {
    setOperator(event.target.value);
  };
  const handleChange = (event) => {
    setStateChecked({
      ...stateChecked,
      [event.target.name]: event.target.checked,
    });
  };

  const saveRule = () => {
    if (newKeyRef.current.value === "" || originalKeyRef.current.value === "") {
      toast.warning(
        "There appears to be some missing information! - Please complete mandatory fields"
      );
    } else {
      let prefixValue = "";
      let suffixValue = "";
      let attributeValue = "";
      let accountValue = "";
      let searchValue = "";
      let whereDict = {};
      if (Object.keys(masterRules).length === 0) {
        masterRules["description"] = originalDescriptionRef.current.value;
        masterRules["name"] = originalMapperNameRef.current.value;
        masterRules["rules"] = [];
        try {
          accountValue = account;
        } catch (err) {
          accountValue = null;
        }
        try {
          prefixValue = prefixRef.current.value;
        } catch (err) {
          prefixValue = null;
        }
        try {
          suffixValue = suffixRef.current.value;
        } catch (err) {
          suffixValue = null;
        }
        try {
          attributeValue = attributeRef.current.value;
        } catch (err) {
          attributeValue = null;
        }
        try {
          searchValue = searchValueRef.current.value;
        } catch (err) {
          searchValue = null;
        }
        if (attributeValue === null) {
          whereDict = null;
        } else {
          whereDict["attribute"] = attributeValue;
          whereDict["operator"] = operator;
          whereDict["search_value"] = searchValue;
        }
        const rules = [];
        const newRule = {
          account_id: accountValue,
          new_attribute_key: newKeyRef.current.value,
          old_attribute_key: originalKeyRef.current.value,
          prefix: prefixValue,
          suffix: suffixValue,
          where: whereDict,
        };
        rules.push(newRule);
        setShowNewRule(false);
        setStateChecked({
          ...stateChecked,
          checkedA: false,
          checkedB: false,
          checkedC: false,
        });
        if (status === "create") {
          masterRules["rules"].push(newRule);
          generateTable();
        } else {
          patchMapper(rules, "rule");
        }
      } else {
        try {
          accountValue = account;
        } catch (err) {
          accountValue = null;
        }
        try {
          prefixValue = prefixRef.current.value;
        } catch (err) {
          prefixValue = null;
        }
        try {
          suffixValue = suffixRef.current.value;
        } catch (err) {
          suffixValue = null;
        }
        try {
          attributeValue = attributeRef.current.value;
        } catch (err) {
          attributeValue = null;
        }
        try {
          searchValue = searchValueRef.current.value;
        } catch (err) {
          searchValue = null;
        }
        if (attributeValue === null) {
          whereDict = null;
        } else {
          whereDict["attribute"] = attributeValue;
          whereDict["operator"] = operator;
          whereDict["search_value"] = searchValue;
        }
        const rules = [];
        const newRule = {
          account_id: accountValue,
          new_attribute_key: newKeyRef.current.value,
          old_attribute_key: originalKeyRef.current.value,
          prefix: prefixValue,
          suffix: suffixValue,
          where: whereDict,
        };
        rules.push(newRule);
        setShowNewRule(false);
        if (status === "create") {
          masterRules["rules"].push(newRule);
          generateTable();
        } else {
          patchMapper(rules, "rule");
        }
        setStateChecked({
          ...stateChecked,
          checkedA: false,
          checkedB: false,
          checkedC: false,
        });
      }
    }
    setAccount(null);
  };
  const deleteRule = (index, id) => {
    MySwal.fire({
      title: "Are you sure?",
      html: "You are about to delete the selected rule.",
      icon: "warning",
      width: 500,
      showCancelButton: true,
      confirmButtonColor: "green",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continue",
    }).then((result) => {
      if (result.value) {
        masterRules["rules"].splice(index, 1);
        axios({
          method: "delete",
          url: "/api/v1/conversion/rules/" + id,
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + props.access_token,
          },
        })
          .then((res) => {
            toast.success("Rule successfully deleted.");
            window.location.reload();
          })
          .catch((err) => {
            handleHttpError(err);
          });
      }
    });
  };
  const updateRule = () => {
    let newRule = {};
    if (accountEditor !== null) {
      newRule["account_id"] = accountEditor;
    }

    try {
      if (editOriginalKeyRef.current.value !== "") {
        originalValue = editOriginalKeyRef.current.value;
      } else {
        originalValue = editOriginalValue;
      }
    } catch {
      console.log("A key was not found when updating the rule");
    }
    try {
      if (editNewKeyRef.current.value !== "") {
        _newKeyValue = editNewKeyRef.current.value;
      } else {
        _newKeyValue = editNewKeyValue;
      }
    } catch {
      console.log("A key was not found when updating the rule");
    }
    try {
      if (editSuffixRef.current.value !== "") {
        _suffixValue = editSuffixRef.current.value;
      } else {
        if (editSuffixValue !== undefined) {
          _suffixValue = editSuffixValue;
        } else {
          _suffixValue = null;
        }
      }
    } catch {
      console.log("A key was not found when updating the rule");
    }
    try {
      if (editPrefixRef.current.value !== "") {
        _prefixValue = editPrefixRef.current.value;
      } else {
        if (editPrefixValue !== undefined) {
          _prefixValue = editPrefixValue;
        } else {
          _prefixValue = null;
        }
      }
    } catch {
      console.log("A key was not found when updating the rule");
    }

    newRule["old_attribute_key"] = originalValue;
    newRule["new_attribute_key"] = _newKeyValue;
    newRule["prefix"] = _prefixValue;
    newRule["suffix"] = _suffixValue;
    const attribute = editAttributeRef.current.value;
    const operator_ = editOperatorRef.current.value;
    if (attribute && operator_ === "iterator") {
      newRule["where"] = { attribute: attribute, operator: operator_ };
      dispatch({ type: "CLOSE_MODAL" });
    } else if (
      (attribute && operator_ === "contains") ||
      (attribute && operator_ === "equals")
    ) {
      if (editSearchValueRef.current.value !== "") {
        newRule["where"] = {
          attribute: attribute,
          operator: operator_,
          search_value: editSearchValueRef.current.value,
        };
        dispatch({ type: "CLOSE_MODAL" });
      } else {
        dispatch({ type: "OPEN_MODAL" });
        toast.error('You have some missing information for "Where"');
      }
    } else {
      MySwal.fire({
        title: "Are you sure?",
        html: "You are about to update the rule!",
        icon: "warning",
        width: 500,
        showCancelButton: true,
        confirmButtonColor: "green",
        cancelButtonColor: "#d33",
        confirmButtonText: "Continue",
      }).then((result) => {
        if (result.value) {
          dispatch({ type: "CLOSE_MODAL" });
          setAccountEditor(null);
          masterRules["rules"].splice(editRuleWithIndex, 1, newRule);
          axios({
            method: "patch",
            url: "/api/v1/conversion/rules/" + editRuleWithIndex,
            data: newRule,
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + props.access_token,
            },
          })
            .then((res) => {
              toast.success("Rule successfully updated.");
              window.location.reload();
            })
            .catch((err) => {
              handleHttpError(err);
            });
        }
      });
    }
  };

  const editRule = (index, id, accountValue) => {
    setEditRuleWithIndex(id); //id of rule
    if (accountValue === undefined) {
      setEditAccountValue("None");
    } else {
      setEditAccountValue(accountValue);
    }
    try {
      setEditOriginalValue(masterRules["rules"][index]["old_attribute_key"]);
    } catch (err) {
      setEditOriginalValue(null);
    }
    try {
      setEditNewKeyValue(masterRules["rules"][index]["new_attribute_key"]);
    } catch (err) {
      setEditNewKeyValue(null);
    }
    try {
      setEditSuffixValue(masterRules["rules"][index]["suffix"]);
    } catch (err) {
      setEditSuffixValue(null);
    }
    try {
      setEditPrefixValue(masterRules["rules"][index]["prefix"]);
    } catch (err) {
      setEditPrefixValue(null);
    }
    try {
      setEditAttributeValue(masterRules["rules"][index]["where"]["attribute"]);
    } catch (err) {
      setEditAttributeValue(null);
    }
    try {
      setOperator(masterRules["rules"][index]["where"]["operator"]);
    } catch (err) {
      console.log(err);
    }
    try {
      setEditSearchValue(masterRules["rules"][index]["where"]["search_value"]);
    } catch (err) {
      setEditSearchValue(null);
    }
    dispatch({ type: "OPEN_MODAL" });
  };
  const generateTable = () => {
    const _rows = [];
    let prefixValue = "";
    let accountValue = "";
    let suffixValue = "";
    let whereValue = "";
    masterRules["rules"].forEach((element, index) => {
      try {
        accountValue = element["account_id"];
      } catch (err) {
        accountValue = null;
      }
      try {
        prefixValue = element["prefix"];
      } catch (err) {
        prefixValue = null;
      }
      try {
        suffixValue = element["suffix"];
      } catch (err) {
        suffixValue = null;
      }
      try {
        whereValue =
          element["where"]["attribute"] +
          " - " +
          element["where"]["operator"] +
          " - " +
          element["where"]["search_value"];
      } catch (err) {
        whereValue = null;
      }
      if (status === "create") {
        _rows.push(
          createData(
            accountValue,
            element["old_attribute_key"],
            element["new_attribute_key"],
            prefixValue,
            suffixValue,
            whereValue,
            <button onClick={() => deleteRule(index, element["id"])}>
              Delete
            </button>
          )
        );
      } else {
        _rows.push(
          createData(
            accountValue,
            element["old_attribute_key"],
            element["new_attribute_key"],
            prefixValue,
            suffixValue,
            whereValue,
            <div>
              <button onClick={() => deleteRule(index, element["id"])}>
                Delete
              </button>
              <button
                onClick={() => editRule(index, element["id"], accountValue)}
              >
                Edit
              </button>
            </div>
          )
        );
      }
    });

    setRows(_rows);
    setTableReady(true);
  };

  const saveMapper = () => {
    if (status === "create") {
      axios({
        method: "post",
        url: "/api/v1/conversion/mappers",
        data: masterRules,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + props.access_token,
        },
      })
        .then((res) => {
          history.push("/mapper_manager");
          toast.success("Mapper created");
        })
        .catch((err) => {
          handleHttpError(err);
        });
    } else {
      console.log("edit request here");
    }
  };
  const getAccounts = () => {
    axios
      .get(`/oauth2/accounts?complete=true`, {
        headers: { Authorization: "Bearer " + props.access_token },
      })
      .then((response) => {
        const accounts = response.data;
        const optionsPayload = [];
        const accountsDictionary = {};

        for (let entry of accounts) {
          const accountName = entry.name;
          const accountID = entry.id;
          optionsPayload.push({ value: accountID, name: accountName });
          accountsDictionary[accountID] = accountName;
        }
        optionsPayload.sort(dynamicSort("name"));
        setAccountsData(optionsPayload);
        setAccountsDict(accountsDictionary);
      })
      .catch(function (error) {
        handleHttpError(error);
      });
  };

  const updateMapperDetails = () => {
    if (
      mapperNameRef.current.value === "" &&
      mapperDescriptionRef.current.value === ""
    ) {
      toast.warning("No new name/decription to update.");
    } else {
      MySwal.fire({
        title: "Are you sure?",
        html: "You are about to update the existing name or/ description.",
        icon: "warning",
        width: 500,
        showCancelButton: true,
        confirmButtonColor: "green",
        cancelButtonColor: "#d33",
        confirmButtonText: "Continue",
      }).then((result) => {
        if (result.value) {
          const newDetails = {};
          if (mapperNameRef.current.value === "") {
            newDetails["name"] = originalMapperNameRef.current.value;
          } else {
            newDetails["name"] = mapperNameRef.current.value;
          }
          if (mapperDescriptionRef.current.value === "") {
            newDetails["description"] = originalDescriptionRef.current.value;
          } else {
            newDetails["description"] = mapperDescriptionRef.current.value;
          }
          patchMapper(newDetails, "namedesc");
        }
      });
    }
  };
  const patchMapper = (data, updater) => {
    const mapStatus = cookies.get("mapper_status");
    const mapper_id = mapStatus.replace(/[^0-9]/g, "");
    if (updater === "rule") {
      data = { rules: data };
    }
    axios({
      method: "patch",
      url: "/api/v1/conversion/mappers/" + mapper_id,
      data: data,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + props.access_token,
      },
    })
      .then((res) => {
        toast.success("Mapper successfully updated.");
        if (updater === "namedesc") {
          setExistingMapperName(mapperNameRef.current.value);
          setExistingMapperDesc(mapperDescriptionRef.current.value);
        }
        window.location.reload();
      })
      .catch((err) => {
        handleHttpError(err);
      });
  };
  useEffect(() => {
    getAccounts();
    const mapperStatus = cookies.get("mapper_status");
    if (mapperStatus === "create") {
      setStatus(mapperStatus);
    } else {
      setStatus("edit");
      const mapper_id = mapperStatus.replace(/[^0-9]/g, "");
      axios({
        method: "get",
        url: `/api/v1/conversion/mappers/${mapper_id}`,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + props.access_token,
        },
      })
        .then((res) => {
          const name = res.data["name"];
          let whereValue = "";
          setExistingMapperName(name);
          setExistingMapperDesc(res.data["description"]);
          const rows_ = [];
          masterRules["rules"] = [];
          res.data["rules"].forEach((element, index) => {
            masterRules["rules"].push(element);
            let accountValue = element["account_id"];
            try {
              whereValue =
                element["where"]["attribute"] +
                " - " +
                element["where"]["operator"] +
                (element["where"]["search_value"]
                  ? " - " + element["where"]["search_value"]
                  : "");
            } catch (err) {
              whereValue = null;
            }
            try {
              accountValue = element["account_id"];
            } catch (err) {
              accountValue = null;
            }
            rows_.push(
              createData(
                accountValue,
                element["old_attribute_key"],
                element["new_attribute_key"],
                element["prefix"],
                element["suffix"],
                whereValue,
                <div>
                  <button onClick={() => deleteRule(index, element["id"])}>
                    Delete
                  </button>
                  <button
                    onClick={() => editRule(index, element["id"], accountValue)}
                  >
                    Edit
                  </button>
                </div>
              )
            );
          });
          setRows(rows_);
          setTableReady(true);
        })
        .catch((err) => {
          handleHttpError(err);
        });
    }
  }, []);
  const routeChange = () => {
    history.push("/mapper_manager");
  };
  return (
    <Container>
      <HeaderComponent page="journey" />
      <Grid container className={classes.root} justify="center" spacing={3}>
        <div className={classes.backMessage} onClick={() => routeChange()}>
          ← Back to Mapper Management
        </div>
        <Grid item xs={12}>
          <div className="title-container">
            <h1 className={classes.purple}>
              {props.journey} {status === "create" ? "Create New" : "Edit"}{" "}
              Mapper
            </h1>
          </div>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="mapper-name"
            inputRef={originalMapperNameRef}
            label={
              status === "create" ? "Mapper Name*" : "Existing Mapper Name"
            }
            style={{ margin: 8 }}
            value={status === "edit" ? existingMapperName : null}
            placeholder={status === "create" ? "Enter Mapper Name" : ""}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="mapper-description"
            inputRef={originalDescriptionRef}
            label={
              status === "create"
                ? "Mapper Description*"
                : "Existing Mapper Description"
            }
            style={{ margin: 8 }}
            value={status === "edit" ? existingMapperDesc : null}
            placeholder={status === "create" ? "Enter Mapper Description" : ""}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        {status === "edit" ? (
          <Grid item xs={6}>
            <TextField
              inputRef={mapperNameRef}
              label="New Mapper Name"
              style={{ margin: 8 }}
              placeholder="Enter new mapper name"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              inputRef={mapperDescriptionRef}
              label="New Mapper Description"
              style={{ margin: 8 }}
              placeholder="Enter new description"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        ) : null}
        <Grid className={classes.center} item xs={12}>
          {status === "edit" ? (
            <CustomButton
              label={"Update"}
              color="purple"
              action={() => updateMapperDetails()}
            />
          ) : null}
        </Grid>
        <Grid id="rules" item xs={6}>
          {showNewRule ? (
            <div className={classes.center}>
              <FormControl>
                <InputLabel>Select Account (Optional)</InputLabel>
                <Select
                  value={account}
                  fullWidth
                  label="Select Account"
                  onChange={handleChangeAccount}
                  style={{ width: 400 }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {accountsData.map((e, key) => {
                    return (
                      <MenuItem value={e.value} key={e.value}>
                        {e.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <br />
              <br />
              <TextField
                id="original-name"
                label="Original Key Name*"
                inputRef={originalKeyRef}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
              <ArrowRightAltIcon className={classes.arrow}></ArrowRightAltIcon>
              <TextField
                id="new-name"
                inputRef={newKeyRef}
                label="New Key Name*"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={stateChecked.checkedA}
                      onChange={handleChange}
                      name="checkedA"
                      color="primary"
                    />
                  }
                  label="Prefix"
                />
              </FormGroup>
              {stateChecked.checkedA ? (
                <TextField
                  label="Prefix"
                  inputRef={prefixRef}
                  fullWidth
                  placeholder="Enter Prefix"
                />
              ) : null}
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={stateChecked.checkedB}
                      onChange={handleChange}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Suffix"
                />
              </FormGroup>
              {stateChecked.checkedB ? (
                <TextField
                  label="Suffix"
                  inputRef={suffixRef}
                  fullWidth
                  placeholder="Enter Suffix"
                />
              ) : null}
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={stateChecked.checkedC}
                      onChange={handleChange}
                      name="checkedC"
                      color="primary"
                    />
                  }
                  label="Where"
                />
              </FormGroup>
              {stateChecked.checkedC ? (
                <div>
                  <TextField
                    label="Attribute"
                    inputRef={attributeRef}
                    defaultValue="interfaces:description"
                    className={classes.attributeTextfield}
                  />
                  <FormControl className={classes.formControl}>
                    <InputLabel>Operator</InputLabel>
                    <Select value={operator} onChange={handleOperator}>
                      <MenuItem value={"contains"}>contains</MenuItem>
                      <MenuItem value={"equals"}>equals</MenuItem>
                      <MenuItem value={"iterator"}>iterator</MenuItem>
                    </Select>
                  </FormControl>
                  {operator === "contains" || operator === "equals" ? (
                    <TextField
                      label="Search Value"
                      inputRef={searchValueRef}
                      placeholder="Enter search values"
                      helperText="Use | to seperate multiple search values"
                      className={classes.attributeTextfield}
                    />
                  ) : null}
                </div>
              ) : null}
              <br />
              <br />
              <CustomButton
                className={classes.saveRule}
                label={"Save Rule"}
                color="purple"
                action={() => saveRule()}
              />{" "}
              <br />
              <CustomButton
                className={classes.saveRule}
                label={"Cancel"}
                color="red"
                action={() => setShowNewRule(false)}
              />
            </div>
          ) : null}
        </Grid>
        {tableReady ? (
          <Grid className={classes.center} item xs={10}>
            <h3>Rules:</h3>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Account</TableCell>
                    <TableCell align="left">Original Key</TableCell>
                    <TableCell align="left">New Key</TableCell>
                    <TableCell align="left">Prefix</TableCell>
                    <TableCell align="left">Suffix</TableCell>
                    <TableCell align="left">Where</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell align="left">
                        {accountsDict[row.account]}
                      </TableCell>
                      <TableCell align="left">{row.originalkey}</TableCell>
                      <TableCell align="left">{row.newkey}</TableCell>
                      <TableCell align="left">{row.prefix}</TableCell>
                      <TableCell align="left">{row.suffix}</TableCell>
                      <TableCell align="left">{row.where}</TableCell>
                      <TableCell align="right">{row.action}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : null}

        <Grid className={classes.center} item xs={12}>
          <CustomButton
            label={"Add Rule"}
            color="green"
            action={() => setShowNewRule(true)}
          />
        </Grid>
        <Grid className={classes.center} item xs={12}>
          {rows !== null && status === "create" ? (
            <CustomButton
              className={classes.saveRule}
              label={"Save Mapper"}
              color="purple"
              action={() => saveMapper()}
            />
          ) : null}
        </Grid>
      </Grid>
      <div>
        <Modal
          dimmer={dimmer}
          open={open}
          size="small"
          onClose={() => dispatch({ type: "CLOSE_MODAL" })}
        >
          <Modal.Header>Edit Existing Rule</Modal.Header>
          <Modal.Content>
            <TextField
              className={classes.textFieldPadding}
              label="Existing Account"
              disabled
              value={
                editAccountValue === "None"
                  ? editAccountValue
                  : accountsDict[editAccountValue]
              }
              variant="outlined"
            />
            <FormControl>
              <InputLabel>Select Account (Optional)</InputLabel>
              <Select
                value={accountEditor}
                label="Select Account"
                onChange={handleChangeAccountEditor}
                style={{ width: 200 }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {accountsData.map((e, key) => {
                  return (
                    <MenuItem value={e.value} key={e.value}>
                      {e.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <br />
            <TextField
              className={classes.textFieldPadding}
              label="Original Key"
              disabled
              value={editOriginalValue}
              variant="outlined"
            />
            <TextField
              inputRef={editOriginalKeyRef}
              label="Replacement Original Key"
              placeholder="Enter replacement key"
              variant="outlined"
            />
            <br />
            <TextField
              className={classes.textFieldPadding}
              label="New Key"
              disabled
              value={editNewKeyValue}
              variant="outlined"
            />
            <TextField
              inputRef={editNewKeyRef}
              label="Replacement New Key"
              placeholder="Enter replacement key"
              variant="outlined"
            />
            <br />
            <TextField
              className={classes.textFieldPadding}
              label="Prefix"
              disabled
              value={editPrefixValue}
              variant="outlined"
            />
            <TextField
              inputRef={editPrefixRef}
              label="Replacement Prefix"
              placeholder="Enter replacement prefix"
              variant="outlined"
            />
            <br />
            <TextField
              className={classes.textFieldPadding}
              label="Suffix"
              disabled
              value={editSuffixValue}
              variant="outlined"
            />
            <TextField
              inputRef={editSuffixRef}
              label="Replacement Suffix"
              placeholder="Enter replacement suffix"
              variant="outlined"
            />
            <br />
            <Header>Where:</Header>
            <div>
              <TextField
                label="Attribute"
                inputRef={editAttributeRef}
                value={editAttributeValue}
                className={classes.attributeTextfield}
              />
              <FormControl className={classes.formControl}>
                <InputLabel>Operator</InputLabel>
                <Select
                  value={operator}
                  inputRef={editOperatorRef}
                  onChange={handleOperator}
                >
                  <MenuItem value={"contains"}>contains</MenuItem>
                  <MenuItem value={"equals"}>equals</MenuItem>
                  <MenuItem value={"iterator"}>iterator</MenuItem>
                </Select>
              </FormControl>
              {operator === "contains" || operator === "equals" ? (
                <TextField
                  label="Search Value"
                  inputRef={editSearchValueRef}
                  value={editSearchValue}
                  placeholder="Enter search values"
                  helperText="Use | to seperate multiple search values"
                  className={classes.attributeTextfield}
                />
              ) : null}
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={() => dispatch({ type: "CLOSE_MODAL" })}>
              Cancel
            </Button>
            <Button positive onClick={() => updateRule()}>
              Confirm
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
      <ToastContainer />
      <Footer />
    </Container>
  );
}
