import { useState, useEffect } from "react";
import { read, utils } from "xlsx";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ArticleIcon from "@mui/icons-material/Article";
import { IconButton, Alert, Switch, Button } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

export default function DataSourceReader({
  classes,
  mainDataSource,
  setMainDataSource,
  setJourneyId,
  sitesSelected,
  setSitesSelected,
  sites,
  setSites,
  dataSourceError,
  setDataSourceError,
  isLoading,
  setIsLoading,
  mainSheetPrepend,
  setMainSheetPrepend,
}) {
  const [payload, setPayload] = useState([]);

  // dualbox options
  const dualListOptions = {
    moveLeft: "<",
    moveAllLeft: "<<",
    moveRight: ">",
    moveAllRight: ">>",
  };

  const onChange = (e) => {
    setIsLoading(60);
    const [file] = e.target.files;
    setMainDataSource(file);

    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = read(bstr, { type: "binary" });
      const firstSheetName = wb.SheetNames[0];
      const firstWorksheet = wb.Sheets[firstSheetName];
      // const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      const data = utils.sheet_to_json(firstWorksheet, { header: 1 });

      setPayload(data);
      setIsLoading(100);
    };
    reader.readAsBinaryString(file);
    setJourneyId();
  };

  // TODO: lower case on headers in line 50
  useEffect(() => {
    if (payload.length > 0) {
      const headers = payload[0].map((item) => item.toString().toLowerCase());
      if (headers.includes("site_id") || headers.includes("site-id")) {
        const site_id_index = headers.indexOf("site_id");
        const site_name_index = headers.indexOf("site_name");
        const sitesList = payload
          .filter((entry) => entry[site_id_index] !== undefined)
          .map((site) => ({
            value: site[site_id_index],
            label: `${site[site_id_index]} ${
              site[site_name_index] !== undefined
                ? `(${site[site_name_index]})`
                : ""
            }`,
          }))
          .filter((entry) => entry.value !== "site_id")
          .filter((entry) => entry.value !== "SITE_ID")
          .filter((entry) => entry.value !== "SITE-ID")
          .filter((entry) => entry.value !== "site-id");
        setSites(sitesList);
      } else {
        setDataSourceError(true);
        // setDataSource(null)
      }
    }
  }, [payload]);

  const SiteSelector = () => {
    return (
      <>
        <h4 className="colorIndigo">Select sites</h4>

        <DualListBox
          options={sites.sort((a, b) =>
            a.value.toString().toUpperCase() > b.value.toString().toUpperCase()
              ? 1
              : -1
          )}
          selected={sitesSelected}
          onChange={(site) => setSitesSelected(site)}
          canFilter
          icons={dualListOptions}
        />
        <small style={{ marginLeft: 10 }}>{sites.length} sites found</small>
      </>
    );
  };

  const selectStyle = {
    minWidth: "33%",
    // backgroundColor: "white",
    marginRight: "1rem",
  };

  const inputDataSource = (
    <Button
      disableElevation
      variant="outlined"
      color="primary"
      component="label"
      sx={selectStyle}
    >
      {"Browse Data Source"}
      <input
        type="file"
        multiple
        accept=".xlsx,.xlsm,.csv"
        hidden
        onChange={onChange}
        onClick={(event) => (event.target.value = null)}
      />
    </Button>
  );

  const resetDataSource = () => {
    setMainDataSource(null);
    setSites([]);
    setSitesSelected([]);
    setDataSourceError(false);
    setIsLoading(0);
  };
  const dataSourceSelected = (
    <div className={classes.inputTemplate}>
      <div>
        <ArticleIcon sx={{ marginRight: 1 }} />
      </div>
      <div>{mainDataSource?.name}</div>

      <div className={classes.flexLastRight}>
        <div className={classes.engine}>
          <small>Legacy Mode</small>
          <Switch
            size="small"
            checked={mainSheetPrepend}
            onChange={() => setMainSheetPrepend(!mainSheetPrepend)}
            inputProps={{ "aria-label": "controlled" }}
          />
          <IconButton
            aria-label="Delete"
            onClick={resetDataSource}
            sx={{ "&:hover": { color: "salmon" } }}
          >
            <DeleteForeverIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {!mainDataSource ? inputDataSource : dataSourceSelected}
      {dataSourceError && (
        <Alert severity="error">
          Data Source file is missing a mandatory header: <br />
          Unable to find the <strong>"site_id"</strong> column. <br /> Please
          select a valid data source!
        </Alert>
      )}
      {isLoading > 0 && (
        <LinearProgress
          sx={{ marginX: 0.2 }}
          variant="determinate"
          value={isLoading}
        />
      )}
      {sites.length > 0 && <SiteSelector />}
    </div>
  );
}
