import useMappers from "../../hooks/useMappers";
import { useState } from "react";
import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";

// for RulesModal
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";

import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

// Main
const MapperSelection = ({ mapperId, setMapperId }) => {
  return <SelectMapper mapperId={mapperId} setMapper={setMapperId} />;
};

// SubComponents
const SelectMapper = ({ mapperId, setMapper }) => {
  const { data: mappers, loading, error } = useMappers();

  const handleMapper = (event) => {
    setMapper(event.target.value);
  };

  return (
    <>
      {mappers && (
        <FormControl size="small" fullWidth>
          <InputLabel>Select Mapper</InputLabel>
          <Select
            value={mapperId}
            label="Select Mapper"
            onChange={handleMapper}
          >
            <MenuItem disabled key={0} value={0}>
              <em>Select Mapper</em>
            </MenuItem>
            {mappers.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {loading && <p>Loading...</p>}
      {error && <p>Something went wrong, please try again.</p>}
    </>
  );
};

export const ViewRules = ({ mapperId = 0 }) => {
  const { data: mapperDetails, loading, error } = useMappers(mapperId);

  if (mapperId === 0) return null;

  return (
    <>
      {mapperDetails && (
        <>
          <RulesModal mapperDetails={mapperDetails} />
        </>
      )}
      {loading && <small>Loading...</small>}
      {error && <small>Something went wrong, please try again.</small>}
    </>
  );
};

const RulesModal = ({ mapperDetails }) => {
  const [open, setOpen] = useState(false);
  const [sortBy, setSortBy] = useState("from");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Tooltip placement="right" title="View Rules">
        <IconButton onClick={handleOpen}>
          <VisibilityIcon />
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <h3>Mapper Name: {mapperDetails.name}</h3>
            <IconButton size="small" onClick={handleClose}>
              <VisibilityOffIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <TableContainer sx={{ height: "60vh" }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableCell align="right" width={"50%"}>
                  <h5>
                    <Tooltip placement="left" title="Sort by From">
                      <IconButton
                        onClick={() => {
                          setSortBy("from");
                        }}
                        disabled={sortBy === "from"}
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                    </Tooltip>
                    FROM
                  </h5>
                </TableCell>
                <TableCell align="left" width={"50%"}>
                  <h5>
                    TO
                    <Tooltip placement="right" title="Sort by To">
                      <IconButton
                        onClick={() => {
                          setSortBy("to");
                        }}
                        disabled={sortBy === "to"}
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                    </Tooltip>
                  </h5>
                </TableCell>
              </TableHead>
              <TableBody>
                {mapperDetails?.rules
                  .sort((a, b) =>
                    sortBy === "from"
                      ? a.old_attribute_key > b.old_attribute_key
                        ? 1
                        : -1
                      : a.new_attribute_key > b.new_attribute_key
                      ? 1
                      : -1
                  )
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="right">
                        {row.old_attribute_key}
                      </TableCell>
                      <TableCell align="left">
                        {row.new_attribute_key}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MapperSelection;
