import { Typography, message } from "antd";
import axios from "axios";
import React from "react";
import { saveAs } from "file-saver";

const { Text } = Typography;
const user = JSON.parse(localStorage.getItem("user"));
const access_token = user?.access_token;

const DownloadFile = ({ record }) => {
  const downloadTemplate = async () => {
    const templateId = record.id;
    axios({
      method: "get",
      url: "/api/v1/templates/" + templateId,
      headers: {
        Accept: "application/octet-stream",
        Authorization: "Bearer " + access_token,
      },
      responseType: "blob",
    })
      .then((res) => {
        const disposition = res.headers["content-disposition"];

        const fileName = disposition.split("filename=")[1].split('"')[1];
        console.log({ disposition, fileName });
        saveAs(res.data, fileName);
        message.success(`${record.name} successfully downloaded.`);
        console.log({ res });
      })
      .catch((err) => {
        message.error(`${record.name} could not be downloaded.`);
        console.log({ err });
      });
  };
  return <a onClick={() => downloadTemplate()}>Download Template</a>;
};

export default DownloadFile;
