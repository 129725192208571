import React, { useState, useEffect } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Space, Typography, FormProps } from "antd";
import { ZscalerLocationType } from "../../../../types/ConnectorsType";

const NewMultipleCredentialsForm: React.FC<{
  form: any;
  location?: ZscalerLocationType;
  submitVpn?: (location?: any) => void;
}> = ({ form, location, submitVPN }) => {
  // const [form] = Form.useForm();

  console.log({ location_in_form: location });

  const currentFqdns = location?.vpnCredentials
    .map((credential: any) => credential.fqdn)
    .filter((fqdn: any) => fqdn?.startsWith(location.name));

  function generateFqdn(values = null): string {
    let count = currentFqdns.length + 1;
    if (values) {
      count = values?.vpnCredentials?.length + 2;
    }
    const baseFqdn = `${location?.name}@dhl.com`;
    if (currentFqdns?.includes(baseFqdn)) {
      const newFqdn = `${location?.name}_${count}@dhl.com`;
      return newFqdn;
    }
    return baseFqdn;
  }

  const initialForm = {
    vpnCredentials: [
      {
        rfc: "",
        comments: "Created by BT",
        fqdn: generateFqdn(),
      },
    ],
  };

  const [initialValues, setInitialValues] = useState(initialForm);

  useEffect(() => form.resetFields(), [initialValues]);
  // useEffect(
  //   () => resetter !== undefined && setInitialValues(initialForm),
  //   [resetter]
  // );

  type FieldType = {
    vpnCredentials: ZscalerLocationType["vpnCredentials"];
  };

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      form={form}
      name="multipleVpn"
      style={{ maxWidth: 600 }}
      autoComplete="off"
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.List name="vpnCredentials">
        {(fields, { _, remove }) => (
          <div style={{ display: "flex", rowGap: 16, flexDirection: "column" }}>
            {fields.map((field, index) => (
              <Card
                size="small"
                title={`New Credential #${index + 1}`}
                extra={
                  index > 0 && (
                    <DeleteOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  )
                }
              >
                <Form.Item label="FQDN" name={[field.name, "fqdn"]}>
                  <Input />
                </Form.Item>

                <Form.Item label="Comments" name={[field.name, "comments"]}>
                  <Input />
                </Form.Item>

                <Form.Item label="RFC#" name={[field.name, "rfc"]}>
                  <Input />
                </Form.Item>
              </Card>
            ))}

            <Button
              type="dashed"
              onClick={() => {
                // add();
                setInitialValues({
                  vpnCredentials: [
                    ...form.getFieldsValue().vpnCredentials,
                    {
                      rfc:
                        form.getFieldsValue().vpnCredentials?.length > 0
                          ? form.getFieldsValue().vpnCredentials[0]?.rfc
                          : null,
                      comments:
                        form.getFieldsValue().vpnCredentials?.length > 0
                          ? form.getFieldsValue().vpnCredentials[0]?.comments
                          : null,
                      fqdn: generateFqdn(form.getFieldsValue()),
                    },
                  ],
                });
              }}
              block
            >
              + Include one more
            </Button>
            <Button
              type="primary"
              block
              onClick={() => submitVPN(location?.id)}
            >
              Create
            </Button>
          </div>
        )}
      </Form.List>
    </Form>
  );
};

export default NewMultipleCredentialsForm;
