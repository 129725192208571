import React, { useState } from "react";
import {
  assign_elements,
  update_bgpconfig,
  create_dns_services,
  create_element_security_zones,
  create_sites,
  create_vlans,
  create_waninterfaces,
  get_elements,
  shutdown_ports,
  update_ntp,
  update_wan_interface_circuit_label,
  create_bgppeers,
  create_static_routes,
} from "../functions/post_api_calls";
import { apiResponsesType, jsonPayloadsType } from ".";
import { usePaloAltoConnectors } from "../../../../contexts/Connectors/PaloAltoContext";
import { Button, Card, Timeline, Typography } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  WarningOutlined,
  LoadingOutlined,
  PauseCircleOutlined,
} from "@ant-design/icons";

const { Text, Paragraph } = Typography;

const DeployContext = ({ context, extracted, deploying, setDeploying }) => {
  const siteContext = context[0];
  const siteExtract = extracted[0];

  const palo = usePaloAltoConnectors();
  const apiKey = palo.apiKey;
  const [boundingStatus, setBoundStatus] = useState([]);

  console.log({ extracted_to_deploy: siteExtract });

  const initialPhases = [
    {
      key: "Create Site",
      color: "gray",
      title: <Text type="secondary">{`Create Site`}</Text>,
      dot: <PauseCircleOutlined />,
      apiTarget: create_sites,
      data: { sites: siteContext?.sites, policies: siteContext?.policies },
      skip: false,
    },
    {
      key: "Assign Devices",
      color: "gray",
      title: <Text type="secondary">{"Assign Devices"} </Text>,
      dot: <PauseCircleOutlined />,
      apiTarget: assign_elements,
      data: { devices: siteContext?.devices },
      skip: false,
    },
    {
      key: "Check Bounding Status",
      color: "gray",
      title: <Text type="secondary">{"Check Assignment Status"} </Text>,
      dot: <PauseCircleOutlined />,
      apiTarget: get_elements,
      data: siteContext?.devices,
      skip: false,
    },
    {
      key: "Create Wan Circuit Label",
      color: "gray",
      title: <Text type="secondary">{"Create Wan Circuit Label"} </Text>,
      dot: <PauseCircleOutlined />,
      apiTarget: create_waninterfaces,
      data: { wan: siteContext?.wan },
      skip: false,
    },
    {
      key: "Apply Circuit Label to Wan Interface",
      color: "gray",
      title: (
        <Text type="secondary">{"Apply Circuit Label to Wan Interface"} </Text>
      ),
      dot: <PauseCircleOutlined />,
      apiTarget: update_wan_interface_circuit_label,
      data: { devices: siteContext?.devices, wan: siteContext?.wan },
      skip: false,
    },
    {
      key: "Shutdown Unused Interfaces",
      color: "gray",
      title: <Text type="secondary">{"Shutdown Unused Interfaces"} </Text>,
      dot: <PauseCircleOutlined />,
      apiTarget: shutdown_ports,
      data: {
        devices: siteContext?.devices,
        wan: siteContext?.wan,
      },
      skip: false,
    },
    {
      key: "Create Vlans",
      color: "gray",
      title: <Text type="secondary">{"Create Vlans"} </Text>,
      dot: <PauseCircleOutlined />,
      apiTarget: create_vlans,
      data: { devices: siteContext?.devices, vlans: siteContext?.vlans },
      skip: false,
    },
    {
      key: "Create DNS",
      color: "gray",
      title: <Text type="secondary">{"Create DNS"} </Text>,
      dot: <PauseCircleOutlined />,
      apiTarget: create_dns_services,
      data: { devices: siteContext?.devices },
      skip: false,
    },
    {
      key: "Update NTP",
      color: "gray",
      title: <Text type="secondary">{"Update NTP"} </Text>,
      dot: <PauseCircleOutlined />,
      apiTarget: update_ntp,
      data: { devices: siteContext?.devices },
      skip: false,
    },
    {
      key: "Assign Security Zones",
      color: "gray",
      title: <Text type="secondary">{"Assign Secutity Zones"} </Text>,
      dot: <PauseCircleOutlined />,
      apiTarget: create_element_security_zones,
      data: { vlans: siteContext?.vlans, devices: siteContext?.devices },
      skip: siteExtract.security_zones.length === 0,
    },
    {
      key: "Create BGP Instance",
      color: "gray",
      title: <Text type="secondary">{"Create BGP Instance"} </Text>,
      dot: <PauseCircleOutlined />,
      apiTarget: update_bgpconfig,
      data: { devices: siteContext?.devices },
      skip: siteExtract.bgp.length === 0,
    },
    {
      key: "Create BGP Peers",
      color: "gray",
      title: <Text type="secondary">{"Create BGP Peers"} </Text>,
      dot: <PauseCircleOutlined />,
      apiTarget: create_bgppeers,
      data: { lan: siteContext?.lan, devices: siteContext?.devices },
      skip: siteExtract.bgp_peers.length === 0,
    },
    {
      key: "Create Static Routes",
      color: "gray",
      title: <Text type="secondary">{"Create Static Routes"} </Text>,
      dot: <PauseCircleOutlined />,
      apiTarget: create_static_routes,
      data: {
        static_routes: siteContext?.static_routes,
        devices: siteContext?.devices,
      },
      skip: siteExtract.static_routes.length === 0,
    },
    {
      key: "Create Bypass Pairs",
      color: "gray",
      title: <Text type="secondary">{"Create Bypass Pairs"} </Text>,
      dot: <PauseCircleOutlined />,
      // apiTarget: "create_bypasspairs",
      data: siteContext?.bypasspairs,
      skip: true,
    },
  ];

  function updatePhaseByKey(prev: any, key: string, update: any) {
    return prev.map((item: any) => {
      if (item.key === key) {
        return { ...item, ...update };
      }
      return item;
    });
  }

  const [phases, setPhases] = useState(
    initialPhases.filter((phase: any) => phase.skip == false)
  );
  console.log({ initialPhases });

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function deployAll() {
    setDeploying(true);
    let site_id: string | undefined = undefined;
    // site_id = "1719606931860015245"; //TEST
    for (const phase of phases) {
      console.log(`executing phase ${phase.key}`);
      setPhases((prev: any) =>
        updatePhaseByKey(prev, phase.key, {
          title: `${phase.key}: Running`,
          color: null,
          dot: <LoadingOutlined />,
        })
      );
      const response = await phase.apiTarget(apiKey, phase.data, site_id);
      if (response?.data.length > 0) {
        if (phase.key === "Check Bounding Status") {
          const maxTime = 10 * 60 * 1000; // 10 minutes in milliseconds
          const startTime = Date.now();
          let allBound = false;
          const elements = phase.data;
          const serials = elements.map((item: any) => item.serial_number);

          while (!allBound && Date.now() - startTime < maxTime) {
            const site_elements = await phase.apiTarget(apiKey, null);
            const statuses = site_elements?.data.filter((item: any) =>
              serials.includes(item.serial_number)
            );
            const status_check = statuses.map((ion: any) => ({
              key: ion.serial_number,
              hostname: ion.name,
              state: ion.state,
            }));
            allBound = status_check.every(
              (item: any) => item.state === "bound"
            );
            setBoundStatus(status_check);
            setPhases((prev: any) =>
              updatePhaseByKey(prev, phase.key, {
                title: status_check.map((ion: any) => (
                  <>
                    <Text strong>
                      {`${ion.hostname} ${
                        ion.state !== "bound"
                          ? " bounding in progress..."
                          : " bounded"
                      }`}
                    </Text>
                    <br />
                  </>
                )),
                color: allBound ? "green" : null,
                dot: allBound ? <CheckCircleOutlined /> : <LoadingOutlined />,
              })
            );
            if (!allBound) {
              console.log(
                "Waiting for devices to be bound. This may take a while..."
              );
              await delay(10 * 1000); // wait for 10 seconds before the next iteration
            }
          }
          if (!allBound) {
            setPhases((prev: any) =>
              updatePhaseByKey(prev, phase.key, {
                title: (
                  <Text strong type="danger">
                    {"Failed to bound devices"}
                  </Text>
                ),
                color: "red",
                dot: <CloseCircleOutlined />,
              })
            );
            return;
          }
        } else {
          if (phase.key === "Create Site") {
            site_id = response?.data[0].id; // Forced for a single site
          }
          setPhases((prev: any) =>
            updatePhaseByKey(prev, phase.key, {
              title: <Text strong>{`${phase.key}: Completed`}</Text>,
              color: "green",
              dot: <CheckCircleOutlined />,
            })
          );
        }

        // setApiResponses((prev: apiResponsesType) => ({
        //   ...prev,
        //   key: phase.key,
        //   title: phase.title,
        //   data: response.data,
        // }));
      } else {
        setPhases((prev: any) =>
          updatePhaseByKey(prev, phase.key, {
            title: (
              <>
                <Text type="danger">{`${phase.key}: Failed`}</Text>
                <Paragraph>
                  {response?.error?.map((item: any) => (
                    <Text>{item.message}</Text>
                  ))}
                </Paragraph>
                {/* <pre>{JSON.stringify(response?.error, null, 4)}</pre>, */}
              </>
            ),
            color: "red",
            dot: <CloseCircleOutlined />,
          })
        );
        console.log({ ERROR_RECEIVED: response?.error });
        return;
      }
    }
  }

  return (
    <>
      {deploying === false ? (
        <Button
          onClick={() => deployAll()}
          type="primary"
          // size="small"
        >
          Confirm and Deploy
        </Button>
      ) : (
        <Card>
          <Timeline
            items={phases.map((item) => ({
              children: item.title,
              color: item.color,
              dot: item.dot,
              key: item.key,
            }))}
          />
        </Card>
      )}
    </>
  );
};

export default DeployContext;
