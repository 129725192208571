import {useState, useEffect} from 'react'
import {selectStyle} from "../utils.js"
import axios from 'axios'
import { handleHttpError } from "../../../../common/utils.js";
import Cookies from "universal-cookie";
// MUI
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
  } from "@mui/material";

export default function SelectLibrary({ libraryId, setLibraryId, libraryOptions, setLibraryOptions }) {
    const cookies = new Cookies();
    const access_token = cookies.get("access_token");
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        axios
          .get(`/api/v1/libraries`, {
            headers: { Authorization: "Bearer " + access_token },
          })
          .then((resp) => {
            const libraries = resp.data;
            const options = libraries.map((library) => ({
              value: library.id,
              name: library.name,
              scope_required: library.scope_required,
            }));
            setLibraryOptions(options)
            setIsLoading(false)
          })
          .catch((error) => handleHttpError(error));
    },[])
    
    const handleLibrary = (event) => {
        setLibraryId(event.target.value);
    };
    
    return isLoading ? (
        <small
        style={{ color: "grey", fontWeight: "lighter", textAlign: "center" }}
        >
        Loading Libraries...
        </small>
    ) : (
        <>
        <FormControl sx={selectStyle}>
            <InputLabel id="library-label">Library Name</InputLabel>
            <Select
            sx={{backgroundColor: "white"}}
            size="small"
            labelId="library-label"
            id="library-select"
            value={libraryId}
            label="Library Name"
            onChange={handleLibrary}
            >
            <MenuItem value={0}>None</MenuItem>
            {libraryOptions?.map((option) => (
                <MenuItem
                key={option.value}
                value={option.value}
                name={option.name}
                >
                {option.name}
                </MenuItem>
            ))}
            </Select>
        </FormControl>
        </>
    )
}