import { handleHttpError } from "../../../common/utils.js";
import axios from "axios";

export const removeDuplicates = (array) => {
  return array.filter(
    (data, index) => index === array.findIndex((obj) => obj.name === data.name)
  );
};

export const selectStyle = {
  minWidth: "33%",
  // backgroundColor: "white",
  marginRight: "1rem",
  marginTop: "1rem",
};

export const fetchLibraries = async (access_token) => {
  await axios
    .get(`/api/v1/libraries`, {
      headers: { Authorization: "Bearer " + access_token },
    })
    .then((resp) => {
      const libraries = resp.data;
      const options = libraries.map((library) => ({
        value: library.id,
        name: library.name,
        scope_required: library.scope_required,
      }));
      return options;
    })
    .catch((error) => handleHttpError(error));
};
