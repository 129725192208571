import React from "react";
import { useHistory } from "react-router-dom";
import { Alert, Link } from "@mui/material";

export default function NoAccountBanner() {
  const history = useHistory();

  return (
    <Alert severity="warning" variant="outlined">
      <strong>No customer account!</strong> <br /> You are required to select
      the appropriate customer account in order to access specific customer
      services, artifacts and for automation statistics. If you don't have an
      account associated with your profile,{" "}
      <strong>
        you must request one{" "}
        <Link
          href="#"
          size="small"
          onClick={() => history.push("/access_request")}
        >
          here
        </Link>
      </strong>
    </Alert>
  );
}
