import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import "react-toastify/dist/ReactToastify.css";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import TemplateSelector from "./TemplateSelector";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import DesignJourney from "./DesignJourney";
import { Alert, Link } from "@mui/material";
import NoAccountBanner from "../JourneyPage/NoAccountBanner";
import { LayoutPage } from "../JourneyPage/JourneyPage";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "80px",
    marginBottom: "80px",
  },
}));

export default function JourneyConsolidator(props) {
  const classes = useStyles();
  const history = useHistory();
  const cookies = new Cookies();
  const accounts = cookies.get("accounts");
  const access_token = cookies.get("access_token");
  const [isLoading, setLoading] = useState(true);
  const [isCrashing, setCrashing] = useState(false);
  const [journey, setJourney] = React.useState("");
  const [designJourney, setDesignJourney] = React.useState("");
  const [renderTemplates, setRenderTemplates] = React.useState("");
  const [options, setOptions] = React.useState("");
  const params = new URLSearchParams(window.location.search);
  let journeyName = params.get("journey");
  const handleChange = (event) => {
    setJourney(event.target.value);
    const journeyID = event.target.value;
    history.push({
      pathname: "/design",
      search: `?journey=${journeyID}`,
    });
    if (journeyID === 0) {
      setRenderTemplates(false);
    } else {
      setRenderTemplates(true);
    }
    setDesignJourney(journeyID);
  };
  useEffect(() => {
    axios
      .get(`/api/v1/libraries`, {
        headers: { Authorization: "Bearer " + access_token },
      })
      .then((response) => {
        const libraries = response.data;
        const optionsPayload = [{ value: 0, name: "Custom" }];

        libraries.forEach((library) => {
          optionsPayload.push({ value: library.id, name: library.name });
        });

        setOptions(optionsPayload);
        setLoading(false);
        setJourney(parseInt(journeyName));
        if (journeyName === "0") {
          setRenderTemplates(false);
        } else {
          setRenderTemplates(true);
        }
        setDesignJourney(parseInt(journeyName));
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          history.push("/login");
        }
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        setCrashing(true);
      });
  }, [journeyName]);

  if (isCrashing) {
    return (
      <LayoutPage>
        <Grid item xs={12}>
          <div className="title-container">
            There seems to be an error receiving templates. Contact the
            development team if this issue persists.
          </div>
        </Grid>
        <Footer />
      </LayoutPage>
    );
  }

  if (isLoading) {
    return (
      <LayoutPage>
        <Grid item xs={12}>
          <div className="title-container">Loading...</div>
        </Grid>
      </LayoutPage>
    );
  }
  return (
    <LayoutPage>
      <Grid item xs={12}>
        <div className="title-container">
          <h1 className="colorIndigo">Custom Design</h1>

          {/* <h3>Select your design journey:</h3> */}
        </div>
      </Grid>
      <Grid item xs={12}>
        <Alert severity="warning">
          <strong>Custom Design Service is deprecated.</strong>
          <br /> Please{" "}
          <strong>
            use the{" "}
            <Link
              href="#"
              size="small"
              onClick={() => history.push("/templates")}
            >
              multi-templates
            </Link>
          </strong>{" "}
          service for new projects. For current projects it requires some
          changes to current templates.{" "}
        </Alert>
      </Grid>
      <Grid item xs={12}>
        {accounts?.length === 0 && <NoAccountBanner />}
      </Grid>

      <Grid item xs={12}>
        <div id="template_list" className="title-container">
          {designJourney === 0 ? (
            <DesignJourney libraryID={designJourney} />
          ) : null}
          {renderTemplates && designJourney !== "" ? (
            <TemplateSelector libraryID={designJourney} />
          ) : null}
        </div>
      </Grid>
    </LayoutPage>
  );
}
