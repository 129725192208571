import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { useConnectors } from "../../../../../contexts/Connectors/ZscalerContext";

// Forms
import { ZscalerLocationType } from "../../../../../types/ConnectorsType";
import VPNCredentials from "../../VPNCredentials";

type SubProps = { record: ZscalerLocationType };

const ListVPNs: React.FC<SubProps> = ({ record }) => {
  const [open, setOpen] = useState(false);

  const handleList = () => {
    setOpen(true);
  };

  return (
    <>
      <a onClick={() => handleList()}>{`VPN Credentials`}</a>
      <Modal
        // maskClosable={!list}
        // closable={!list}
        title={`VPN Credentials for ${record.name}`}
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
        width={"80%"}
      >
        <VPNCredentials location={record} />
      </Modal>
    </>
  );
};

export default ListVPNs;
