import "../MerakiRolloutPage.css";
import { Chip } from "@mui/material";
import axios from "axios";
import { axiosFetch } from "../library/fetchRequests";
import { useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";

import { handleHttpError } from "../../../common/utils.js";

export default function BomDataProcessing({
  accountId,
  rolloutSites,
  setRolloutSites,
}) {
  const [isAdditional, setIsAdditional] = useState(false);
  const [hasDevices, setHasDevices] = useState(false);

  const deviceGenerator = (hostname_prefixes, site_name) => {
    let numberOfCores = 1;
    const devices = [];
    for (const site of rolloutSites.filter(
      (siteEntry) => siteEntry.name === site_name
    )) {
      const siteName = site.name.split(" ")[0];
      for (const item of site.products) {
        const multiplier = item.quantity;
        if (multiplier > 0) {
          const prefixes = hostname_prefixes
            .filter((entry) => entry.product_id === item.id)
            .map((entry) => entry.hostname_prefix);
          for (let i = 0; i < multiplier; i++) {
            let prefix = prefixes[0];
            if (prefixes.length > 1 && numberOfCores > 0) {
              numberOfCores -= 1;
              prefix = prefixes[0];
            } else if (prefixes.length > 1 && numberOfCores === 0) {
              prefix = prefixes[1];
            }
            const suffix =
              devices
                .map((device) => device.hostname)
                .filter((hostname) => hostname.includes(prefix)).length + 1;
            const prependSuffix = suffix < 10 ? 0 : "";

            devices.push({
              hostname: prefix
                ? `${prefix}-${siteName}-${prependSuffix}${suffix}`
                : "N/A",
              part_number: item.part_number,
              type: item.type,
              product_id: item.id,
              description: item.description,
              additionals:
                item.additionals != "None"
                  ? item.additionals.map((add) => add.part_number).toString()
                  : item.additionals,
              // Gets the number of ports based on the item description
              ports: item.description.includes("Switch")
                ? parseInt(item.description.replace(/\D/g, "")) || "NA"
                : "NA",
            });
          }
        }
      }
    }
    return devices;
  };

  // Add additionals logic --- START
  useEffect(() => {
    const allProducts = [
      ...new Set(
        rolloutSites
          .map((item) => item.products)
          .flat()
          .map((item) => item.id)
      ),
    ];

    axios
      .all(allProducts.map((id) => axiosFetch.get(`products/${id}`)))
      .then(
        axios.spread((...responses) => {
          const additonalsRes = responses
            .map((response) => response.data)
            .filter((item) => item.additionals);

          // updates the products entries with the additionals
          setRolloutSites((prevState) => {
            return prevState.map((item) => ({
              ...item,
              products: item.products.map((part) =>
                allProducts.includes(part.id)
                  ? {
                      ...part,
                      additionals:
                        additonalsRes.filter((add) => add.id === part.id)[0]
                          ?.additionals || "None",
                    }
                  : part
              ),
            }));
          });
          setIsAdditional(true);
        })
      )
      .catch(function (error) {
        handleHttpError(error);
        // setIsDataAutodataError(true);
      });
  }, []);
  // Add additionals logic --- END

  useEffect(() => {
    if (isAdditional) {
      axiosFetch
        .get(`products/hostnames?account_id=${accountId}`)
        .then((response) => {
          const hostname_prefixes = response.data;
          setRolloutSites((prevState) => {
            return prevState.map((site) => ({
              ...site,
              devices: deviceGenerator(hostname_prefixes, site.name),
            }));
          });

          setHasDevices(true);
        })
        .catch((error) => {
          handleHttpError(error);
          // setIsDataAutodataError(true);
        });
    }
    return setIsAdditional(false);
  }, [isAdditional]);

  const DevicesTable = ({ siteName }) => {
    return (
      <div style={{ padding: "1rem" }}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left" colSpan={4}>
                  <h4>Meraki Devices List</h4>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>
                    <span className="highlightRounded">Hostname</span>
                  </strong>
                </TableCell>
                <TableCell align="left">
                  <strong>Part Number</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>Type</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>Additional Items</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rolloutSites
                .filter((item) => item.name === siteName)
                .map((item) => item.devices)
                .flat()
                .map((row) => (
                  <TableRow key={row.hostname}>
                    <TableCell align="left">
                      <span className="highlightRounded">{row.hostname}</span>
                    </TableCell>
                    <TableCell align="left">{row.part_number}</TableCell>
                    <TableCell align="left">{row.type}</TableCell>
                    <TableCell align="right">{row.additionals}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const sites = rolloutSites.map((site) => (
    <div key={site.name} className="dashedBorderWhite">
      <div className="chip">
        <Chip label={site.name} color="primary" variant="outlined" />
        <Chip label={site.type} variant="outlined" />
      </div>

      {hasDevices ? (
        <DevicesTable siteName={site.name} />
      ) : (
        <div className="messages">
          <p>Loading...</p>
        </div>
      )}
    </div>
  ));

  return <div className="backBox">{sites}</div>;
}
