import { Drawer, Space, Divider, Button, Typography } from "antd"
import { FC, useCallback, useState } from "react"
import { ZscalerLocationType } from "../../../../types/ConnectorsType"
import React from "react"

const { Text } = Typography

interface ConnectorTypes extends ZscalerLocationType {
    name: string
    fqdn: string
}

type SubProps = {
    record: ConnectorTypes
}

const ShowRecordDetails: FC<SubProps> = ({ record }) => {
    const [open, setOpen] = useState(false)


    const recordJsonLike = JSON.stringify(record, null, 4)

    const [buttonText, setButtonText] = useState("Copy to Clipboard")

    const onClick = useCallback(() => {
        setButtonText("Copied!")
        navigator.clipboard.writeText(recordJsonLike)
        setTimeout(() => {
            setButtonText("Copy to Clipboard")
        }, 2000);
    }, []);

    return (
        <>
            <a onClick={() => setOpen(true)}>More Details</a>
            <Drawer
                title={record.name || record.fqdn}
                placement="right"
                onClose={() => setOpen(false)}
                open={open}
                width={450}
                extra={
                    <Space>
                        <Button
                            style={{ width: 180 }}
                            onClick={onClick}
                        >{buttonText}</Button>
                    </Space >
                }
            >
                <pre>
                    <Text>{recordJsonLike}</Text>
                </pre>

            </Drawer>

        </>
    )
}

export default ShowRecordDetails