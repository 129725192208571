import { Space, Tag } from "antd";
import React from "react";


const createTagList = (inputList: string[], bordered: boolean = false) => {


    return (
        <Space size={[0, 8]} wrap>
            {inputList.map(
                (item: string) =>
                    <Tag bordered={bordered} key={item}>{item}</Tag>
            )
            }
        </Space >
    )
}

export default createTagList