import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const PurpleButtonLogin = withStyles((theme) => ({
  root: {
    // marginBottom: "2rem",
    width: "100%",
    // height: "3.7rem",
    color: theme.palette.getContrastText("#5514b4"),
    backgroundColor: "#5514b4",
    "&:hover": {
      backgroundColor: "#5514b4",
    },
  },
}))(Button);

const PurpleButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText("#5514b4"),
    backgroundColor: "#5514b4",
    "&:hover": {
      backgroundColor: "#5514b4",
    },
  },
}))(Button);

const PinkButton = withStyles((theme) => ({
  root: {
    color: "#000000",
    backgroundColor: "#FF80FF",
    "&:hover": {
      backgroundColor: "#FF80FF",
    },
  },
}))(Button);

const GreenButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText("#5514b4"),
    backgroundColor: "#007E00",
    "&:hover": {
      backgroundColor: "#007E00",
    },
  },
}))(Button);

const RedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText("#5514b4"),
    backgroundColor: "#EB0800",
    "&:hover": {
      backgroundColor: "#EB0800",
    },
  },
}))(Button);

const YellowButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText("#000000"),
    backgroundColor: "FFDC00",
    "&:hover": {
      backgroundColor: "FFDC00",
    },
  },
}))(Button);

export default function CustomButton({
  color,
  label,
  action,
  disabled,
  width,
}) {
  return (
    <div>
      {color === "purpleLogin" ? (
        <PurpleButtonLogin
          variant="contained"
          color="primary"
          onClick={action}
          disabled={disabled}
          disableElevation
        >
          {label}
        </PurpleButtonLogin>
      ) : null}
      {color === "purple" ? (
        <PurpleButton
          variant="contained"
          color="primary"
          onClick={action}
          width={width}
        >
          {label}
        </PurpleButton>
      ) : null}
      {color === "pink" ? (
        <PinkButton variant="contained" color="primary" onClick={action}>
          {label}
        </PinkButton>
      ) : null}
      {color === "green" ? (
        <GreenButton variant="contained" color="primary" onClick={action}>
          {label}
        </GreenButton>
      ) : null}
      {color === "red" ? (
        <RedButton variant="contained" color="primary" onClick={action}>
          {label}
        </RedButton>
      ) : null}
      {color === "yellow" ? (
        <YellowButton variant="contained" color="primary" onClick={action}>
          {label}
        </YellowButton>
      ) : null}
    </div>
  );
}
