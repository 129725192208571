import React, { createContext, useContext, useEffect, useState } from 'react'
import { ChildrenProps } from '../../types/ChildrenReactType'

export type ContextType = {
    selectedTablesRowId: {}
    dispatchTablesRowId: (tableName: string, selectedRows: string[] | number[]) => void
    // globalSearch: string[]
    // dispatchGlobalSearch: (searchTags: string[]) => void
    // searchType: "localSearch" | "globalSearch"
    // setSearchType: React.Dispatch<React.SetStateAction<"localSearch" | "globalSearch">>
}

export const TablesContext = createContext<any>(null)

export const TablesContextProvider: React.FC<ChildrenProps> = ({ children }) => {


    // const example = {
    //     tableName1: [],
    //     tableName2: []
    // }

    // Gets from local Storage

    const [selectedTablesRowId, setSelectedTablesRowId] = useState<any>([])
    // const [globalSearch, setGlobalSearch] = useState<any>([])
    // const [searchType, setSearchType] = useState<"localSearch" | "globalSearch">("localSearch")

    const dispatchTablesRowId = (tableName: string, selectedRows: string[] | number[]) => {
        if (selectedRows.length === 0) setSelectedTablesRowId([])
        else setSelectedTablesRowId((prev: any) => ({ ...prev, [tableName]: selectedRows }))
    }

    // const dispatchGlobalSearch = (searchTags: string[]) => {
    //     setGlobalSearch(searchTags)

    // }
    console.log({
        selectedTablesRowId,
        // globalSearch,
        // searchType,
    })

    return (
        <TablesContext.Provider value={{
            selectedTablesRowId,
            dispatchTablesRowId,
            // globalSearch,
            // dispatchGlobalSearch,
            // searchType,
            // setSearchType
        }}>
            {children}
        </TablesContext.Provider>
    )
}



export const useTables = () => useContext(TablesContext)

export default TablesContext



