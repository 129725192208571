import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CustomButton from "../Button/CustomButton.js";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Cookies from "universal-cookie";

// tabulator dependencies
import { ReactTabulator } from "react-tabulator";
import "react-tabulator/lib/styles.css";
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css";

// toastr dependencies
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getFileName, handleHttpError, saveBlob } from "../../common/utils.js";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    transitionDuration: "0.3s",
  },
  formControl: {
    minWidth: 120,
  },
  content: {
    textAlign: "center",
    height: "auto",
    paddingTop: "200",
    objectFit: "contain",
    paddingBottom: 100,
  },
  button: {
    marginTop: "3rem",
    minWidth: "210px",
    marginRight: "1rem",
    marginLeft: "1rem",
    marginBottom: "5rem",
    justifyContent: "center",
    textAlign: "center",
  },
  smallButton: {
    marginTop: "1rem",
    minWidth: "110px",
    justifyContent: "center",
    textAlign: "center",
  },
  mystyle: {
    color: "rgb(85, 20, 180)",
  },
  progress: {
    marginTop: "5%",
    marginLeft: "25%",
  },
  tabPanel: {
    flexGrow: 1,
    width: "100%",
  },
  table: {
    "& > *": {
      margin: ".5rem",
      display: "inline",
      textAlign: "center",
    },
  },
});

// tabpanel component
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function TabComponent(props) {
  const classes = useStyles();
  const cookies = new Cookies();
  const access_token = cookies.get("access_token");
  const tabData = props.tabs;
  var tabulatorData = props.data;
  tabulatorData.forEach((entry) => {
    const lastEntry = entry.columns[entry.columns.length - 1];
    if (lastEntry && lastEntry.title !== "Delete") {
      entry.columns.push({
        title: "Delete",
        formatter: "buttonCross",
        width: "10px",
        headerSort: false,
        cellClick: function (e, cell) {
          if (window.confirm("Are you sure you want to delete this entry?")) {
            onDeleteRow(cell);
          }
        },
      });
    }
  });

  const tempShowButtons = props.show_buttons || false;
  const accountName = props.accountName;

  // temp fix to store initial tabulator data TODO: Better data handling logic
  var tempDataHolder = Object.assign(...tabData.map((k, i) => ({ [k.name.toLowerCase()]: tabulatorData[i].dataset })));
  const [showButtons] = React.useState(tempShowButtons);

  const [currentTab, setCurrentTab] = React.useState(0);
  const [currentTabName, setCurrentTabName] = React.useState(tabData[currentTab].name.toLowerCase());

  const [enableGenerate, setIsEnableGenerate] = useState(true);

  const tableRef = React.createRef(); // table reference

  const options = {
    height: 275,
    movableRows: false,
    history: true,
  };

  const onDeleteRow = (cell) => {
    let data = cell.getRow().getData();
    cell.getRow().delete();

    const index = tempDataHolder[currentTabName].indexOf(data);
    if (index > -1) {
      tempDataHolder[currentTabName].splice(index, 1);
      return toast.success(`Entry deleted from ${currentTabName}!`);
    } else {
      for (const [key, value] of Object.entries(tempDataHolder)) {
        const index2 = value.indexOf(data);
        if (index2 > -1) {
          value.splice(index2, 1);
          return toast.success(`Entry deleted from ${key}!`);
        }
      }
      return toast.error(`Something went wrong! If this persists, please contact admin.`);
    }
  };

  const onAddRow = () => {
    tempDataHolder[currentTabName].push({});
    tableRef.current.table.replaceData(tempDataHolder[currentTabName]);
  };

  const onActionUndo = () => {
    tableRef.current.table.undo();
  };

  const onActionRedo = () => {
    tableRef.current.table.redo();
  };

  const onTabChange = (event, newTab) => {
    let newTabName = tabData[newTab].name.toLowerCase();
    setCurrentTab(newTab);
    setCurrentTabName(newTabName);
  };

  const onDataChange = (newData) => {
    tempDataHolder[currentTabName] = newData;
    setIsEnableGenerate(true);
  };

  const createPayloadFromTable = () => {
    console.log("Generating documents...");
    const sitesList = { sites: [] };
    let primaryData = tempDataHolder["primary"] ? tempDataHolder["primary"] : tempDataHolder["sites"];
    primaryData.forEach((site) => {
      const siteId = site.site_id; //gets site_id value from primary data
      const siteName = site.site_name; //gets site_name value from primary data
      console.log(
        `%c SITE START: Loop iteration for SITE: ${siteName} with ID: ${siteId} `,
        "color: blue; font-weight:bold"
      );

      const siteAuxData = {};

      for (const [key, value] of Object.entries(tempDataHolder)) {
        if (key !== "primary" && key !== "sites") {
          for (const cols of value.entries()) {
            if (cols.hasOwnProperty("site_id") === false) {
              console.log(`COMMON: site id column not found so tab '${key}' will be added as common aux table`);
              siteAuxData[key] = value;
              break;
            } else if (cols["site_id"] && String(cols["site_id"]) === String(siteId)) {
              console.log(
                `SPECIFIC: site id column found in tab '${key}' and it matchs the current site with Site ID ${String(
                  siteId
                )}`
              );

              //Filtering the specific devices based in the site_id
              let thisEntries = value.filter((col) => String(col.site_id) === String(siteId));
              siteAuxData[key] = thisEntries;

              break;
            } else {
              console.log(`SKIPPED: site id ${String(cols["site_id"])} do not matches with current ${String(siteId)}`);
            }
          }
        }
      }

      sitesList.sites.push({
        customer_name: accountName,
        ...site,
        ...siteAuxData,
      });

      console.log(
        `%c SITE FINISH: Loop iteration for SITE: ${siteName} with ID: ${siteId} `,
        "color: pink; font-weight:bold"
      );
    });

    return sitesList;
  };

  const getData = () => {
    const payload = createPayloadFromTable();
    var body = JSON.stringify(payload);
    var template_id = props.template_id;
    axios({
      method: "post",
      url: "/api/v1/templates/" + template_id,
      headers: {
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json",
        Accept: "application/octet-stream",
      },
      responseType: "blob",
      data: body,
    })
      .then((res) => {
        // Generating filename
        let fileName = getFileName(res.headers);
        saveBlob(res.data, fileName);
        setIsEnableGenerate(false);
      })
      .catch((error) => {
        handleHttpError(error);
        setIsEnableGenerate(false);
      });
  };

  return (
    <div className={classes.tabPanel}>
      <ToastContainer />
      <AppBar position="static" color="default">
        <Tabs
          value={currentTab}
          onChange={onTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabData.map((e, key) => {
            return <Tab key={e.name} label={e.name} {...a11yProps(e.value)} />;
          })}
        </Tabs>
      </AppBar>
      <div>
        <ReactTabulator
          ref={tableRef}
          columns={tabulatorData[currentTab].columns}
          data={tempDataHolder[currentTabName]}
          dataChanged={(newData) => onDataChange(newData)}
          options={options}
          data-custom-attr="test-custom-attribute"
          className="custom-css-class"
        />
      </div>

      {showButtons ? (
        <div className={classes.table}>
          <Button className={classes.smallButton} variant="outlined" id="add-row" onClick={onAddRow}>
            Add Row
          </Button>
          <Button className={classes.smallButton} variant="outlined" id="action-undo" onClick={onActionUndo}>
            Undo
          </Button>
          <Button className={classes.smallButton} variant="outlined" id="action-redo" onClick={onActionRedo}>
            Redo
          </Button>
        </div>
      ) : null}
      <div id="btn-generate-document" className={classes.button}>
        {enableGenerate ? (
          <CustomButton
            label="Generate Document(s)"
            color="purple"
            action={() => {
              getData();
            }}
          />
        ) : (
          <Button variant="contained" disabled>
            Generate Document(s)
          </Button>
        )}
      </div>
    </div>
  );
}
